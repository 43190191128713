import React, { FC } from 'react';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import MuiButton from '@material-ui/core/Button';
import { Add, Remove, ChevronLeft } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import ImageUploader from './ImageUploader/ImageUploader';
import { Dialog } from '@material-ui/core';
import { FormState, useBookForm, Direction, ItemIdsToRemove } from './hooks';
import './BookForm.scss';

const checkMarkIcon = '/assets/modal_checkmark.svg';
const alertIcon = '/assets/alert_icon.svg';

export type BookFormProps = {
  isEditing?: boolean;
  initialState?: FormState;
  removeBook?: () => Promise<void>;
  saveBook: (formData: FormState, itemIdsToRemove?: ItemIdsToRemove) => Promise<void>;
};

const BookForm: FC<BookFormProps> = ({ initialState, saveBook, removeBook, isEditing = false }) => {
  const { formState, handlers, modal, deleteModal, errorMessage } = useBookForm({
    initialState,
    saveBook,
    removeBook,
  });

  return (
    <>
      <form className="admin-book-form" autoComplete="off" onSubmit={handlers.handleSubmit}>
        <div className="admin-book-top">
          <ImageUploader setUrl={handlers.handleSetUrl} imageUrl={formState.bookState.path} />
          <div className="admin-book-top__name">
            <TextField
              name="bookName"
              fullWidth={true}
              required={true}
              label="Book name"
              variant="outlined"
              multiline
              rows={6}
              rowsMax={6}
              onChange={handlers.handleChangeBookName}
              value={formState.bookState.name}
            />
          </div>
        </div>
        <div className="admin-book-chapter-container">
          <div className="admin-book-chapter-container__heading">
            <h2>Chapter</h2>
            <button type="button" onClick={handlers.handleAddChapter}>
              <Add />
              <span>Add chapter</span>
            </button>
          </div>
          {formState.chaptersState.map(({ id: chapterId, name }) => {
            const chapterLinks = formState.linksState.filter((link) => link?.chapterId === chapterId);
            return (
              <div key={chapterId} className="admin-book-chapter">
                <div className="admin-book-chapter__name">
                  <TextField
                    name="chapterName"
                    fullWidth
                    required
                    label="Name"
                    variant="outlined"
                    onChange={handlers.handleChangeChapterName(chapterId)}
                    value={name}
                  />
                </div>
                {chapterLinks.map((link, linkIndex) => (
                  <div key={link.id} className="admin-book-lesson">
                    <div className="admin-book-lesson__name">
                      <TextField
                        name="lessonName"
                        fullWidth
                        required
                        label="Lesson name"
                        variant="outlined"
                        onChange={handlers.handleChangeLink(link.id, 'name')}
                        value={link.name || ''}
                      />
                    </div>
                    <div className="admin-book-lesson__link">
                      <TextField
                        name="lessonLink"
                        fullWidth
                        required
                        label="Link"
                        variant="outlined"
                        onChange={handlers.handleChangeLink(link.id, 'path')}
                        value={link.path || ''}
                      />
                    </div>
                    <div className="admin-book-lesson__controls">
                      {(chapterLinks.length > 1 || formState.chaptersState.length > 1) && (
                        <>
                          <MuiButton
                            disableRipple
                            type="button"
                            onClick={handlers.handleRemoveChapterLink(chapterId, link.id)}
                          >
                            <Remove />
                          </MuiButton>
                          <MuiButton
                            type="button"
                            disableRipple
                            className="lesson-bottom-btn"
                            disabled={linkIndex === chapterLinks.length - 1}
                            onClick={handlers.handleMoveChapterLink(chapterId, link.id, Direction.DOWN)}
                          >
                            <ChevronLeft />
                          </MuiButton>
                          <MuiButton
                            type="button"
                            disableRipple
                            className="lesson-top-btn"
                            disabled={linkIndex === 0}
                            onClick={handlers.handleMoveChapterLink(chapterId, link.id, Direction.UP)}
                          >
                            <ChevronLeft />
                          </MuiButton>
                        </>
                      )}
                      <MuiButton
                        disableRipple
                        type="button"
                        onClick={handlers.handleAddChapterLink(chapterId, link.id)}
                      >
                        <Add />
                      </MuiButton>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <div className="admin-book-form__submit-btns">
          {errorMessage && (
            <div className="admin-book-form__error">
              <span>{errorMessage}</span>
            </div>
          )}
          {isEditing && <Button onClick={deleteModal.handleOpenModal}>Delete book</Button>}
          <Button contained type="submit" disabled={!formState.bookState.path}>
            Save
          </Button>
        </div>
      </form>
      <Dialog
        open={deleteModal.isOpen}
        onClose={deleteModal.handleCloseModal}
        aria-labelledby="modal-book-delete"
        className="admin-book-modal"
      >
        <div className="admin-book-modal-delete">
          <img src={alertIcon} alt="alert_icon" />
          <p className="admin-book-modal-delete__title">Are you sure you want to delete book?</p>
          <div className="admin-book-modal-delete__btns">
            <Button onClick={deleteModal.handleConfirm} contained>
              Delete
            </Button>
            <Button onClick={deleteModal.handleCloseModal}>Cancel</Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={modal.isOpen}
        onClose={modal.handleCloseModal}
        aria-labelledby="modal-book-was-saved"
        disableBackdropClick
        className="admin-book-modal"
      >
        <div className="admin-book-modal-content">
          <img src={checkMarkIcon} alt="checkmark_icon" />
          <p className="admin-book-modal-content__title">Success!</p>
          <p className="admin-book-modal-content__subtitle">{isEditing ? 'Book was edited' : 'New book was created'}</p>
          <Button onClick={modal.handleConfirm}>Show books list</Button>
        </div>
      </Dialog>
    </>
  );
};

export default BookForm;
