import React, { useCallback, useMemo, useState } from 'react';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import cn from 'classnames';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import './../Calendar.scss';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const checkbox_active_icon = '/assets/checkbox_active_icon.svg';
const checkbox_icon = '/assets/checkbox_icon.svg';
const close_modal_cross = '/assets/close_modal_cross.svg';
const delete_trash_icon = '/assets/delete_trash_icon.svg';

const weekConst = [
  { name: 'S', allName: 'Sunday' },
  { name: 'M', allName: 'Monday' },
  { name: 'T', allName: 'Tuesday' },
  { name: 'W', allName: 'Wednesday' },
  { name: 'T', allName: 'Thursday' },
  { name: 'F', allName: 'Friday' },
  { name: 'S', allName: 'Saturday' },
];

enum DatePeriod {
  Start,
  End,
}

const ModalAvailabil = ({
  period,
  vacationPeriod,
  handleChangeVacationPeriodStart,
  handleChangeVacationPeriodEnd,
  setVacationPeriod,
  setHasVacation,
  hasVacation,
  closeAvailabilModal,
  handleChangePeriodStart,
  handleChangePeriodEnd,
  chooseWeek,
  week,
  handleAddNewTimeForWeek,
  handleSave,
  handleChangeStartWeek,
  handleChangeEndWeek,
  handleDeleteWeekTime,
}) => {
  const [openedDatePicker, setOpenedDatePicker] = useState<DatePeriod>();
  const [openedVacationDatePicker, setOpenedVacationDatePicker] = useState<DatePeriod>();
  const periodWeek = useCallback(
    (index) => {
      // console.log('periodWeek::index', index);
      const diff = moment(period[0].end).diff(moment(period[0].start), 'days');
      const arrOf: number[] = [];
      let startDay = moment(period[0].start).day();
      for (let i = -1; i < diff; i++) {
        if (startDay < 7) {
          arrOf.push(startDay);
          startDay = startDay + 1;
        } else {
          arrOf.push(0);
        }
        if (startDay === 7) {
          startDay = 0;
        }
      }
      let bool = true;
      arrOf.map((item) => {
        if (item === index) {
          bool = false;
        }
      });
      return bool;
    },
    [period],
  );

  const disabled = useMemo(() => {
    return week.some((itemW) => {
      return itemW.since.some((itemS, index) => {
        return moment(itemW.until[index]).diff(moment(itemS), 'minutes') <= 0;
      });
    });
  }, [week]);

  const openDatePicker = useCallback(
    (period: DatePeriod) => () => {
      setOpenedDatePicker(period);
    },
    [],
  );

  const closeDatePicker = useCallback(() => {
    setOpenedDatePicker(undefined);
  }, []);

  const openVacationDatePicker = useCallback(
    (period: DatePeriod) => () => {
      setOpenedVacationDatePicker(period);
    },
    [],
  );

  const closeVacationDatePicker = useCallback(() => {
    setOpenedVacationDatePicker(undefined);
  }, []);

  const handleChangeCheckBoxVacation = useCallback(
    (e) => {
      setHasVacation(e.target.checked);
      setVacationPeriod({ start: null, end: null });
    },
    [setVacationPeriod, setHasVacation],
  );

  return (
    <div className="calendar_modal_availabil">
      <div className="calendar_modal_availabil_header">
        <p className="calendar_modal_availabil_header_title"></p>
        <img src={close_modal_cross} alt="close_modal_icon" onClick={closeAvailabilModal} />
      </div>
      <div className="calendar_modal_availabil_body">
        <p className="calendar_modal_availabil_body_title">
          Set <span>at least 6 months of availability</span> to get subscription students! Subscription students book
          groups of 10 or more lessons with you that repeat at the same time on the same day each week.
          <span> These are your most valuable students. </span>
          Set a reminder <span>now</span> to add a new month to your availability on the 1st of every month, so you can
          grow and keep your subscription students.
        </p>
        {period.map((item, index) => (
          <div className="calendar_modal_availabil_body_period" key={'period' + index}>
            <KeyboardDatePicker
              className="calendar_modal_availabil_body_period_start"
              autoOk
              variant="inline"
              inputVariant="outlined"
              id="Start"
              label="Start"
              open={openedDatePicker === DatePeriod.Start}
              onClose={closeDatePicker}
              format="MMM DD,yyyy"
              value={item.start}
              InputAdornmentProps={{ position: 'start' }}
              onChange={(date) => handleChangePeriodStart(date, index)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{ onClick: openDatePicker(DatePeriod.Start), disabled: true }}
            />
            <KeyboardDatePicker
              className="calendar_modal_availabil_body_period_end"
              autoOk
              variant="inline"
              inputVariant="outlined"
              id="End"
              label="End"
              open={openedDatePicker === DatePeriod.End}
              onClose={closeDatePicker}
              format="MMM DD,yyyy"
              value={item.end}
              InputAdornmentProps={{ position: 'start' }}
              onChange={(date) => handleChangePeriodEnd(date, index)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{ onClick: openDatePicker(DatePeriod.End), disabled: true }}
            />
          </div>
        ))}
        <FormControlLabel
          control={
            <Checkbox
              checked={hasVacation}
              onChange={handleChangeCheckBoxVacation}
              name="vacation"
              checkedIcon={<img src={checkbox_active_icon} alt="checkedIcon" />}
              icon={<img src={checkbox_icon} alt="icon" />}
            />
          }
          label="Vacation"
        />

        {hasVacation && (
          <div className="calendar_modal_availabil_body_period">
            <KeyboardDatePicker
              className="calendar_modal_availabil_body_period_start"
              autoOk
              variant="inline"
              inputVariant="outlined"
              id="StartVacation"
              label="Start"
              open={openedVacationDatePicker === DatePeriod.Start}
              onClose={closeVacationDatePicker}
              format="MMM DD,yyyy"
              value={vacationPeriod.start}
              InputAdornmentProps={{ position: 'start' }}
              onChange={(date) => handleChangeVacationPeriodStart(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{ onClick: openVacationDatePicker(DatePeriod.Start), disabled: true }}
            />
            <KeyboardDatePicker
              className="calendar_modal_availabil_body_period_end"
              autoOk
              variant="inline"
              inputVariant="outlined"
              id="EndVacation"
              label="End"
              open={openedVacationDatePicker === DatePeriod.End}
              onClose={closeVacationDatePicker}
              format="MMM DD,yyyy"
              value={vacationPeriod.end}
              InputAdornmentProps={{ position: 'start' }}
              onChange={(date) => handleChangeVacationPeriodEnd(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{ onClick: openVacationDatePicker(DatePeriod.End), disabled: true }}
            />
          </div>
        )}
        <div className="calendar_modal_availabil_body_weeks">
          {weekConst.map((item, index) => (
            <div
              key={item.allName + index}
              className={cn('calendar_modal_availabil_body_week', {
                choosed: week.find((item) => item.day === index),
                disable: periodWeek(index),
              })}
              onClick={periodWeek(index) ? () => {} : () => chooseWeek(index)}
            >
              {item.name}
            </div>
          ))}
        </div>
        <p className="calendar_modal_availabil_body_weektext">You can choose one day or several per week.</p>
        {week.map((item, index, arr) => {
          return item.since.map((itemS, indexS) => {
            return (
              <div className="calendar_modal_availabil_body_time" key={item.day + index + indexS}>
                <div
                  className={`
                      calendar_modal_availabil_body_time_weekName 
                      ${indexS !== 0 && 'calendar_modal_availabil_body_time_weekName_second'}
                    `}
                >
                  {weekConst[item.day].allName}
                </div>
                <TimePicker
                  className="calendar_modal_availabil_body_time_start"
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  id="Start"
                  label="Start"
                  format="LT"
                  value={item.since[indexS]}
                  onChange={(date) => handleChangeStartWeek(date, index, indexS)}
                  minutesStep={30}
                />
                <TimePicker
                  className="calendar_modal_availabil_body_time_end"
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  id="End"
                  label="End"
                  format="LT"
                  value={item.until[indexS]}
                  onChange={(date) => handleChangeEndWeek(date, index, indexS)}
                  minutesStep={30}
                />
                <div className="calendar_modal_availabil_body_time_container">
                  {indexS === 0 && (
                    <p
                      className="calendar_modal_availabil_body_time_add"
                      onClick={() => handleAddNewTimeForWeek(item.day)}
                    >
                      + Add new time
                    </p>
                  )}
                  {item.since.length !== 1 && (
                    <img
                      src={delete_trash_icon}
                      alt="delete_slot_icon"
                      onClick={() => handleDeleteWeekTime(index, indexS)}
                      className={
                        indexS === 0
                          ? 'calendar_modal_availabil_body_time_delete'
                          : 'calendar_modal_availabil_body_time_deleteLast'
                      }
                    />
                  )}
                </div>
              </div>
            );
          });
        })}
        <div className="calendar_modal_availabil_footer">
          <Button
            onClick={handleSave}
            contained={true}
            disabled={disabled}
            className="calendar_modal_availabil_footer_btn"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalAvailabil;
