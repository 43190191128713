import React, { FC, useCallback, useRef, useState } from 'react';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import CoachesSlider from 'component/CoachesSlider/CoachesSlider';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { Profile, Instruments } from 'api';
import { getInstrumentNewName } from 'utils/helpers';
import LandingFooter from './LandingFooter/LandingFooter';
import './Landing.scss';
import { Helmet } from 'react-helmet';
import { landingInstruments } from 'utils/constans';

const landing = '/assets/landing/landing.svg';
const landing2 = '/assets/landing/landing2.svg';
const landing3 = '/assets/landing/landing3.svg';
const learnOnlineIcon = '/assets/landing/learn_online.svg';
const findCoachIcon = '/assets/landing/find_coach.svg';
const singersIcon = '/assets/landing/singers_icon.svg';
const cbsIcon = '/assets/landing/cbs_icon.png';
const foxIcon = '/assets/landing/fox_icon.png';
const nbcIcon = '/assets/landing/nbc_icon.png';
const weatherChanelIcon = '/assets/landing/weather_channel.png';
const needHelpImage = '/assets/landing/need_help.svg';
const needHelpMobileImage = '/assets/landing/need_help_mobile.svg';
const h24Icon = '/assets/landing/24h.svg';

type Props = {
  user: Profile | null;
};

const Landing: FC<Props> = ({ user }) => {
  const instrRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [indexInst, setIndexInst] = useState<number>(11);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const handleViewMore = useCallback((length) => {
    setIndexInst(length);
  }, []);

  const handleViewAllCoach = useCallback(() => {
    history.push('/coaches');
  }, [history]);

  const handleClickInstrument = useCallback(
    (inst) => {
      history.push(`/${inst.slug}-teachers`);
    },
    [history],
  );

  const returnInstrument = (inst: Instruments, index: number) => {
    if (index <= indexInst) {
      return (
        <div key={inst.id} onClick={() => handleClickInstrument(inst)} className="landing_second_instContainer_block">
          <img
            src={getInstrumentNewName(inst.name)}
            className="landing_second_instContainer_block_img"
            alt="Instrument"
          />
          <p className="landing_second_instContainer_block_text">{inst.name}</p>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="landing">
      {window.location.hostname === 'bestmusiccoach.com' || window.location.hostname === 'www.bestmusiccoach.com' ? (
        <Helmet>
          <meta name="facebook-domain-verification" content="cgtrh95xz0llq1uu2ycj3kjj3wqb69" />
        </Helmet>
      ) : null}
      <div className="landing_first">
        <div className="landing_first_textBlock">
          <h1 className="landing_first_textBlock_title">Enjoy learning music with lessons made for you!</h1>
          <h2 className="landing_first_textBlock_text">Fully customizable online music coaching.</h2>
          <Button
            onClick={() => {
              if (instrRef.current !== null) {
                instrRef.current.scrollIntoView();
              }
            }}
            contained={true}
          >
            Book a lesson
          </Button>
          <div className="landing_first_as_seen_on">
            <span className="landing_first_as_seen_on_title">As seen on</span>
            <div className="landing_first_as_seen_on_icons">
              <img className="landing_first_as_seen_on_icons_nbc" src={nbcIcon} alt="NBC Icon" />
              <img className="landing_first_as_seen_on_icons_cbs" src={cbsIcon} alt="CBS Icon" />
              <img className="landing_first_as_seen_on_icons_fox" src={foxIcon} alt="Fox Icon" />
              <img
                className="landing_first_as_seen_on_icons_weather"
                src={weatherChanelIcon}
                alt="Weather Chanel Icon"
              />
            </div>
          </div>
        </div>
        <img className="landing_first_img" alt="first_img" src={landing} />
      </div>
      <div className="landing_steps">
        <div className="landing_steps_container">
          <p className="landing_steps_title">Start your music journey in three simple steps!</p>
          <ul className="landing_steps_list">
            <li className="landing_steps_item">
              <div className="landing_steps_item_wrapper">
                <span className="landing_steps_item_index">1</span>
                <img className="landing_steps_item_icon" src={findCoachIcon} alt="zoom_icon" />
              </div>
              <span className="landing_steps_item_text">1.Find your coach</span>
              <span className="landing_steps_item_description">
                Pick a time for your custom lessons and learn music at your own pace with songs you know and love from
                home with coaches who have diplomas from top music schools like Berklee College of Music.
              </span>
            </li>
            <li className="landing_steps_item">
              <div className="landing_steps_item_wrapper">
                <span className="landing_steps_item_index">2</span>
                <img className="landing_steps_item_icon" src={learnOnlineIcon} alt="calendar_icon" />
              </div>
              <span className="landing_steps_item_text">2.Learn Online</span>
              <span className="landing_steps_item_description">
                Meet your coach online on your phone, tablet, or computer for your customized, one-on-one, real-time
                lessons.
              </span>
            </li>
            <li className="landing_steps_item">
              <div className="landing_steps_item_wrapper">
                <span className="landing_steps_item_index">3</span>
                <img className="landing_steps_item_icon" src={singersIcon} alt="singers_icon" />
              </div>
              <span className="landing_steps_item_text">3.Start your journey</span>
              <span className="landing_steps_item_description">
                Achieve and discover your musical dreams with customized practice notes with every lesson so you know
                exactly what to practice every week.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div ref={instrRef} className="landing_second">
        <div className="landing_second_instContainer">
          {landingInstruments.map((item, index) => {
            if (isMobile && index > 7) {
              return null;
            } else {
              return returnInstrument(item!, index);
            }
          })}
        </div>
      </div>

      <div className="landing_third">
        <h2 className="landing_third_title">Learn with the best coaches!</h2>
        <div>
          <CoachesSlider />
        </div>
        <Button className="landing_third_btn-view" contained={false} onClick={handleViewAllCoach}>
          View all
        </Button>
      </div>

      <div className="landing_need_help">
        <div className="landing_need_help_content">
          <img
            className="landing_need_help_content_img"
            src={isMobile ? needHelpMobileImage : needHelpImage}
            alt="Need Help"
          />
          <span className="landing_need_help_content_title">Need help?</span>
          <span className="landing_need_help_content_mail">support@bestmusiccoach.com</span>
          <img className="landing_need_help_content_icon" src={h24Icon} alt="24h" />
        </div>
      </div>

      <div className="landing_fourth">
        <div className="landing_fourth_left">
          <h2 className="landing_fourth_left_title">Boost your skills!</h2>
          <h3 className="landing_fourth_left_text">
            Visit our blog for free music resources and extra learning, anytime on any instrument.
          </h3>
          <Button
            className="landing_fourth_left_btn-visit"
            onClick={() => (window.location.href = 'https://www.bestmusiccoach.com/blog')}
            contained={true}
          >
            Visit blog
          </Button>
        </div>
        <div className="landing_fourth_right">
          <img src={landing2} className="landing_fourth_right_img" alt="right_img" />
        </div>
      </div>
      <div className="landing_fifth">
        <div className="landing_fifth_left">
          <img src={landing3} className="landing_fifth_left_img" alt="left_img" />
        </div>
        <div className="landing_fifth_right">
          <h2 className="landing_fifth_right_title">Shop</h2>
          <h3 className="landing_fifth_right_text">
            All our learning materials are carefully crafted to meet every students musical needs.
          </h3>
          <Button
            className="landing_fifth_right_btn-shop"
            onClick={() => (window.location.href = 'https://store.bestmusiccoach.com/')}
            contained={true}
          >
            Shop
          </Button>
        </div>
      </div>
      <LandingFooter user={user} />
    </div>
  );
};

export default Landing;
