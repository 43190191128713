import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { RolesEnum } from 'api';

const signupAboutMePlaceholder =
  "What are your goals and dreams for music? If you don't know what you want to do yet, that is fine! Your coach will guide you through learning everything you need to know!";
const profileAboutMePlaceholder = 'Write your goals and dreams. What do you want to achieve?';

const intruments = [
  'Guitar',
  'Drums',
  'Piano',
  'Voice',
  'Ukulele',
  'Bass',
  'MusicTheory',
  'EarTraining',
  'MusicProduction',
  'Violin',
  'Viola',
  'Cello',
  'Trumpet',
  'Saxophone',
  'Flute',
  'Clarinet',
  'Banjo',
  'Songwriting',
];

interface ExperienceProps {
  lessons?: any;
  handleChangeLessons?: any;
  handleChangeLevel?: any;
  handleChange?: any;
  values?: any;
  level?: any;
  handleKeyPress?: any;
  isAuthenticated?: any;
  intrument?: any[];
  levelSelect?: any[];
  user?: any;
  editMode?: boolean;
}

export const Experience = ({
  lessons,
  handleChangeLessons,
  handleChangeLevel,
  handleChange,
  values,
  level,
  handleKeyPress,
  isAuthenticated,
  intrument,
  levelSelect,
  user,
  editMode = false,
}: ExperienceProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: !isAuthenticated ? '500px' : '',
      }}
    >
      {isAuthenticated && <Typography className="profileTitle">Experience</Typography>}
      <FormControl className="form_control">
        <option className={`lessonsPlaceholder ${lessons.length ? 'filled' : ''}`} value="none" disabled={!editMode}>
          {lessons.length ? 'Lessons *' : 'No data provided'}
        </option>
        <Select
          labelId="demo-mutiple-name-label"
          className={`lessonsFormField ${!editMode && 'lessonsFormFieldDisabled'}`}
          id="demo-mutiple-name"
          multiple
          disabled={!editMode}
          input={<OutlinedInput notched={true} />}
          renderValue={(value) => {
            const selected: any = value;
            if (selected.length) {
              return selected.join(', ');
            } else {
              return '';
            }
          }}
          value={lessons}
          autoWidth={true}
          onChange={handleChangeLessons}
          defaultValue={lessons}
        >
          {intruments.map((name) => (
            <MenuItem key={name} value={name}>
              {intrument && <Checkbox checked={lessons.indexOf(name) > -1} />}
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className="form_control">
        <option className={`levelPlaceholder ${level ? 'filled' : ''}`} value="none" disabled={!editMode}>
          {level ? 'Level *' : 'No data provided'}
        </option>
        <Select
          labelId="demo-mutiple-name-label"
          className={`expirienceFormField ${!editMode && 'lessonsFormFieldDisabled'}`}
          id="demo-mutiple-name"
          input={<OutlinedInput notched={true} />}
          multiple={user && user?.role === RolesEnum.Coach}
          value={level ? level : []}
          autoWidth={true}
          disabled={!editMode}
          onChange={handleChangeLevel}
          defaultValue={level}
          renderValue={(value) => {
            if (user.role === RolesEnum.Coach) {
              const selected: any = value;
              if (selected.length) {
                return selected.join(', ');
              } else {
                return '';
              }
            } else {
              return value;
            }
          }}
        >
          {['Beginner', 'Intermediate', 'Advanced'].map((name) => (
            <MenuItem key={name} value={name}>
              {user && user?.role === RolesEnum.Coach && levelSelect && (
                <Checkbox checked={levelSelect.indexOf(name) > -1} />
              )}
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        className="profileFormField profileFormFieldAbout"
        variant="outlined"
        name="about"
        label="About me"
        placeholder={isAuthenticated ? profileAboutMePlaceholder : signupAboutMePlaceholder}
        multiline
        rowsMax={7}
        rows={2}
        onChange={handleChange}
        value={values.about || ''}
        fullWidth
        onKeyPress={handleKeyPress}
        disabled={!editMode}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />
    </div>
  );
};

export default Experience;
