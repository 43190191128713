import React, { useState } from 'react';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { isAvatar, getAvatar } from 'utils/image';

const close_modal_cross = '/assets/close_modal_cross.svg';
const delete_lesson_icon = '/assets/delete_lesson_icon.svg';

enum DateType {
  Date,
  Start,
  End,
}

const ModalBook = ({
  lessonModal,
  closeBookModal,
  handleChooseDate,
  handleChooseStart,
  handleChooseEnd,
  handleChooseInstrument,
  handleDeleteLesson,
  handleSaveLesson,
  handleChangeTopic,
}) => {
  const [openedDatePicker, setOpenedDatePicker] = useState<DateType | undefined>();
  const openDatePicker = (dateType: DateType) => () => {
    setOpenedDatePicker(dateType);
  };

  const closeDatePicker = () => {
    setOpenedDatePicker(undefined);
  };
  return (
    <div className="profile_modal_video admin_modal_width">
      <div className="profile_modal_video_header">
        <p className="profile_modal_video_title">{lessonModal?.topic}</p>
        <div style={{ display: 'flex' }}>
          <img
            src={delete_lesson_icon}
            className="deleteLessonIcon"
            onClick={() => handleDeleteLesson(lessonModal?.id)}
            alt="deleteLessonIcon"
          />
          <img
            src={close_modal_cross}
            className="profile_modal_video_close"
            onClick={closeBookModal}
            alt="closeBookModal"
          />
        </div>
      </div>
      <div className="calendar_modal_book_body_buy_row">
        <KeyboardDatePicker
          className="calendar_modal_book_body_buy_row_date"
          autoOk
          variant="inline"
          inputVariant="outlined"
          id="Date"
          label="Date"
          format="MMM DD,yyyy"
          value={moment(+lessonModal.startAt)}
          InputAdornmentProps={{ position: 'start' }}
          onChange={handleChooseDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          open={openedDatePicker === DateType.Date}
          InputProps={{ onClick: openDatePicker(DateType.Date), disabled: true }}
          onClose={closeDatePicker}
        />
        <KeyboardTimePicker
          className="calendar_modal_book_body_buy_row_start"
          autoOk
          variant="inline"
          inputVariant="outlined"
          id="Start"
          label="Start time"
          format="LT"
          value={moment(+lessonModal.startAt)}
          InputAdornmentProps={{ position: 'start' }}
          onChange={handleChooseStart}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minutesStep={30}
          open={openedDatePicker === DateType.Start}
          InputProps={{ onClick: openDatePicker(DateType.Start), disabled: true }}
          onClose={closeDatePicker}
        />
        <KeyboardTimePicker
          className="calendar_modal_book_body_buy_row_end"
          autoOk
          variant="inline"
          inputVariant="outlined"
          id="End"
          label="End time"
          format="LT"
          value={moment(+lessonModal.finishAt)}
          InputAdornmentProps={{ position: 'start' }}
          onChange={handleChooseEnd}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minutesStep={30}
          open={openedDatePicker === DateType.End}
          InputProps={{ onClick: openDatePicker(DateType.End), disabled: true }}
          onClose={closeDatePicker}
        />
      </div>
      <div className="calendar_modal_book_body_buy_row">
        <Select
          className="calendar_modal_book_body_buy_row_lesson admin-modal-book-select"
          label="Timezone"
          input={<OutlinedInput notched={true} />}
          value={lessonModal.musicInstrumentId || +lessonModal?.musicInstrumentId[0] || ''}
          onChange={handleChooseInstrument}
        >
          {lessonModal?.coachProfile?.instruments?.map((instrument) => (
            <MenuItem key={instrument.name} value={instrument.id || ''}>
              {instrument.name || ''}
            </MenuItem>
          ))}
        </Select>
        <div className="calendar_modal_book_body_buy_row_empty" />
      </div>
      <TextField
        variant="outlined"
        name="Title"
        label="Title"
        type="text"
        required
        onChange={handleChangeTopic}
        value={lessonModal?.topic || ''}
        fullWidth
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />
      <div className="calendar_tooltip_coach modal_student admin-coach-tooltip">
        <UserAvatar
          classes="calendar_tooltip_coach_avatar"
          avatar={
            lessonModal?.studentProfile?.files
              ? isAvatar(lessonModal?.studentProfile?.files)
                ? getAvatar(lessonModal?.studentProfile?.files)
                : ''
              : ''
          }
          firstName={lessonModal?.studentProfile?.firstName}
          lastName={lessonModal?.studentProfile?.lastName}
        />
        <p className="calendar_tooltip_coach_name">
          {lessonModal?.studentProfile?.firstName} {lessonModal?.studentProfile?.lastName}
        </p>
      </div>
      <div className="modal_save_button">
        <Button onClick={handleSaveLesson} contained={true}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ModalBook;
