import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';
import Auth from '@aws-amplify/auth';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWSS3_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
  },
});

Auth.configure({
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT,
});

Storage.configure({
  bucket: process.env.REACT_APP_AWSS3_BUCKET,
  region: process.env.REACT_APP_AWS_REGION,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  credentials: {
    accessKeyId: 'AKIARIMHDEJYNAXSIN5H',
    secretAccessKey: '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',
  },
});

Amplify.register(Auth);
Amplify.register(Storage);
