import React from 'react';
import { useGetChildProfileQuery, useProfileQuery } from 'api/index';
import { Link, useRouteMatch } from 'react-router-dom';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import ProfileInfo from '../../components/ProfileInfo/Info';
import Loader from '../../components/CustomLoader';
import { getAdminAdultRoute } from '../../modules/routes';
import { LinkIcon } from './components/LinkIcon';
import './Children.scss';

export default function ChildProfile() {
  const params: any = useRouteMatch().params;
  const profileQuery = useProfileQuery({ variables: {
    id: params.childId,
    timezoneOffset: new Date().getTimezoneOffset(),
    } });
  const childProfileQuery = useGetChildProfileQuery({ variables: { id: params.childId } });
  const loading = profileQuery?.loading;
  const parent = childProfileQuery.data?.getChildProfile?.parent;

  const [profile, setProfile] = React.useState<any>({
    id: '1',
    firstName: 'fName',
    lastName: 'lName',
    createdAt: '1571236216000',
    files: [],
    email: 'example@example.com',
  });
  const { firstName, lastName, id, files, createdAt } = profile;

  React.useEffect(() => {
    if (profileQuery?.data?.getProfile) {
      setProfile({ ...profile, ...profileQuery?.data?.getProfile });
    }
  }, [profileQuery?.data?.getProfile]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="child_profile">
      <div className="child_profile__header">
        <ProfileCard firstName={firstName} lastName={lastName} id={id} date={createdAt} files={files} />
        {parent && (
          <>
            <ProfileCard
              firstName={parent?.firstName}
              lastName={parent?.lastName}
              id={parent?.id}
              date={parent?.createdAt || createdAt}
              files={parent?.files}
            />
            <Link to={getAdminAdultRoute(parent?.id)}>
              <LinkIcon />
            </Link>
          </>
        )}
      </div>
      <ProfileInfo user={profile} reGetUser={profileQuery.refetch} />
    </div>
  );
}
