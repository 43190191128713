import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBackIos as BackIcon, ArrowForwardIos as NextIcon } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import { listTimezones } from '../../../utils/constans';
import './Availability.scss';

const sun_icon = '/assets/sun_icon.svg';
const moon_icon = '/assets/moon_icon.svg';

const currentTimeZoneName = `UTC ${moment().format('Z')}`;
const currentTimeZoneValue = listTimezones().find((tz) => tz.name === currentTimeZoneName)?.data || null;

const SimplePopoverAvailability = ({ handleDateFilter, handleClearDateFilter, dateFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [indexOfDay, setIndexOfDay] = useState<number>(0);
  const [days, setDays] = useState<number[] | null>(null);
  const [indexOfTime, setIndexOfTime] = useState<number[] | null>(null);
  const [timeZone, setTimeZone] = useState<string>(`UTC ${moment().format('Z')}`);
  const [timeZoneOffset, setTimeZoneOffset] = useState<number | null>(null);

  const clearfilter = useCallback(() => {
    setIndexOfDay(0);
    setDays(null);
    setIndexOfTime(null);
    setTimeZone(currentTimeZoneName);
    setTimeZoneOffset(null);
  }, []);

  useEffect(() => {
    if (dateFilter === null) {
      clearfilter();
    }
  }, [dateFilter, clearfilter]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearFilter = useCallback(() => {
    handleClearDateFilter();
    handleClose();
  }, [handleClose, handleClearDateFilter]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSubit = useCallback(() => {
    const arrOfdate: any[] = [];
    const timeOffset = timeZoneOffset || 0;
    if (days && days?.length) {
      days.forEach((day) => {
        if (indexOfTime && indexOfTime?.length) {
          indexOfTime.forEach((time) => {
            arrOfdate.push({
              since: moment()
                .second(0)
                .minute(0)
                .hour(time)
                .day(day)
                .millisecond(0)
                .add(timeOffset, 'hours')
                .toISOString(),
              until: moment()
                .second(0)
                .minute(0)
                .hour(time + 1)
                .day(day)
                .add(timeOffset, 'hours')
                .toISOString(),
            });
          });
        } else {
          for (let i = 0; i < 24; i++) {
            arrOfdate.push({
              since: moment()
                .second(0)
                .minute(0)
                .hour(i)
                .day(day)
                .millisecond(0)
                .add(timeOffset, 'hours')
                .toISOString(),
              until: moment()
                .second(0)
                .minute(0)
                .hour(i + 1)
                .day(day)
                .add(timeOffset, 'hours')
                .toISOString(),
            });
          }
        }
      });
    } else if (indexOfTime && indexOfTime?.length) {
      indexOfTime.forEach((time) => {
        for (let i = 0; i < 7; i++) {
          arrOfdate.push({
            since: moment()
              .second(0)
              .minute(0)
              .hour(time)
              .day(i + indexOfDay)
              .millisecond(0)
              .add(timeOffset, 'hours')
              .toISOString(),
            until: moment()
              .second(0)
              .minute(0)
              .hour(time + 1)
              .day(i + indexOfDay)
              .add(timeOffset, 'hours')
              .toISOString(),
          });
        }
      });
    }
    handleDateFilter(arrOfdate);
    handleClose();
  }, [days, indexOfTime, indexOfDay, handleDateFilter, timeZoneOffset]);

  const isPastDate = (date) => moment(date).isBefore(moment());

  const handleBackWeek = useCallback(() => {
    setIndexOfDay(indexOfDay - 7);
  }, [indexOfDay]);

  const handleNextWeek = useCallback(() => {
    setIndexOfDay(indexOfDay + 7);
  }, [indexOfDay]);

  const handleDay = useCallback(
    (day) => {
      if (isPastDate(moment().day(day))) {
        return;
      }

      if (days) {
        if (days.find((item) => day === item)) {
          const arr = days.filter((item) => item !== day);
          setDays(arr);
        } else {
          setDays([...days, day]);
        }
      } else {
        setDays([day]);
      }
    },
    [days],
  );

  const handleTime = useCallback(
    (time: number) => {
      if (indexOfTime) {
        if (indexOfTime.find((item) => time === item)) {
          const arr = indexOfTime.filter((item) => item !== time);
          setIndexOfTime(arr);
        } else {
          setIndexOfTime([...indexOfTime, time]);
        }
      } else {
        setIndexOfTime([time]);
      }
    },
    [indexOfTime],
  );

  const isCheckedTime = useCallback(
    (time) => {
      let bool = false;
      if (indexOfTime) {
        indexOfTime.forEach((item) => {
          if (time === item) {
            bool = true;
          }
        });
      }
      return bool;
    },
    [indexOfTime],
  );

  const handleChangeTimezone = useCallback((data) => {
    setTimeZoneOffset(
      (currentTimeZoneValue || 0) - (listTimezones().find((tz) => tz.name === data.target.value)?.data || 0),
    );
    setTimeZone(data.target.value);
  }, []);

  const timezoneObj = listTimezones().find((el) => {
    return el.name.indexOf(timeZone) > -1;
  });
  const timezoneName = timezoneObj?.[0]?.name;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        className={`availabilityOpenButton ${dateFilter ? 'open' : ''}`}
      >
        Availability
        {dateFilter && (
          <div className="availabilityOpenButtonCount">
            {(days?.length ? days?.length : 0) +
              (indexOfTime?.length ? indexOfTime?.length : 0) +
              (indexOfDay !== 0 ? 1 : 0) +
              (timeZone !== `UTC ${moment().format('Z')}` ? 1 : 0)}
          </div>
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="instrumentPopoverFiltesDate">
          <div className="datePaginationRow">
            <IconButton onClick={handleBackWeek} disabled={indexOfDay <= 0}>
              <BackIcon fontSize="small" />
            </IconButton>
            <p className="datePaginationText">
              {`${moment()
                .day(0 + indexOfDay)
                .format('DD')} - 
                  ${moment()
                    .day(6 + indexOfDay)
                    .format('DD')} 
                  ${
                    moment()
                      .day(0 + indexOfDay)
                      .format('MMMM') ===
                    moment()
                      .day(6 + indexOfDay)
                      .format('MMMM')
                      ? moment()
                          .day(0 + indexOfDay)
                          .format('MMM')
                      : moment()
                          .day(0 + indexOfDay)
                          .format('MMM') +
                        ' - ' +
                        moment()
                          .day(6 + indexOfDay)
                          .format('MMM')
                  }, 
                  ${moment()
                    .day(0 + indexOfDay)
                    .format('YYYY')}`}
            </p>
            <IconButton onClick={handleNextWeek}>
              <NextIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="dateRow">
            <div
              className={cn('dateCollumn', {
                dateCollumnSelected: days && days.find((item) => item === 0 + indexOfDay),
                dateCollumnDisabled: isPastDate(moment().day(0 + indexOfDay)),
              })}
              onClick={() => handleDay(0 + indexOfDay)}
            >
              <p className="dateWeek">Sun</p>
              <p className="dateWeekDate">
                {moment()
                  .day(0 + indexOfDay)
                  .format('DD')}
              </p>
            </div>
            <div
              className={cn('dateCollumn', {
                dateCollumnSelected: days && days.find((item) => item === 1 + indexOfDay),
                dateCollumnDisabled: isPastDate(moment().day(1 + indexOfDay)),
              })}
              onClick={() => handleDay(1 + indexOfDay)}
            >
              <p className="dateWeek">Mon</p>
              <p className="dateWeekDate">
                {moment()
                  .day(1 + indexOfDay)
                  .format('DD')}
              </p>
            </div>
            <div
              className={cn('dateCollumn', {
                dateCollumnSelected: days && days.find((item) => item === 2 + indexOfDay),
                dateCollumnDisabled: isPastDate(moment().day(2 + indexOfDay)),
              })}
              onClick={() => handleDay(2 + indexOfDay)}
            >
              <p className="dateWeek">Tue</p>
              <p className="dateWeekDate">
                {moment()
                  .day(2 + indexOfDay)
                  .format('DD')}
              </p>
            </div>
            <div
              className={cn('dateCollumn', {
                dateCollumnSelected: days && days.find((item) => item === 3 + indexOfDay),
                dateCollumnDisabled: isPastDate(moment().day(3 + indexOfDay)),
              })}
              onClick={() => handleDay(3 + indexOfDay)}
            >
              <p className="dateWeek">Wed</p>
              <p className="dateWeekDate">
                {moment()
                  .day(3 + indexOfDay)
                  .format('DD')}
              </p>
            </div>
            <div
              className={cn('dateCollumn', {
                dateCollumnSelected: days && days.find((item) => item === 4 + indexOfDay),
                dateCollumnDisabled: isPastDate(moment().day(4 + indexOfDay)),
              })}
              onClick={() => handleDay(4 + indexOfDay)}
            >
              <p className="dateWeek">Thu</p>
              <p className="dateWeekDate">
                {moment()
                  .day(4 + indexOfDay)
                  .format('DD')}
              </p>
            </div>
            <div
              className={cn('dateCollumn', {
                dateCollumnSelected: days && days.find((item) => item === 5 + indexOfDay),
                dateCollumnDisabled: isPastDate(moment().day(5 + indexOfDay)),
              })}
              onClick={() => handleDay(5 + indexOfDay)}
            >
              <p className="dateWeek">Fri</p>
              <p className="dateWeekDate">
                {moment()
                  .day(5 + indexOfDay)
                  .format('DD')}
              </p>
            </div>
            <div
              className={cn('dateCollumn', {
                dateCollumnSelected: days && days.find((item) => item === 6 + indexOfDay),
                dateCollumnDisabled: isPastDate(moment().day(6 + indexOfDay)),
              })}
              onClick={() => handleDay(6 + indexOfDay)}
            >
              <p className="dateWeek">Sat</p>
              <p className="dateWeekDate">
                {moment()
                  .day(6 + indexOfDay)
                  .format('DD')}
              </p>
            </div>
          </div>
          <div className="timeContainer">
            <div className="timetitle">
              <img src={sun_icon} alt="sun_icon" />
              <p>am</p>
            </div>
            <div className="timeRow">
              <div className="timeColumn">
                <div className={isCheckedTime(0) ? 'timeSelected' : ''} onClick={() => handleTime(0)}>
                  00:00
                </div>
                <div className={isCheckedTime(6) ? 'timeSelected' : ''} onClick={() => handleTime(6)}>
                  06:00
                </div>
              </div>
              <div className="timeColumn">
                <div className={isCheckedTime(1) ? 'timeSelected' : ''} onClick={() => handleTime(1)}>
                  01:00
                </div>
                <div className={isCheckedTime(7) ? 'timeSelected' : ''} onClick={() => handleTime(7)}>
                  07:00
                </div>
              </div>
              <div className="timeColumn">
                <div className={isCheckedTime(2) ? 'timeSelected' : ''} onClick={() => handleTime(2)}>
                  02:00
                </div>
                <div className={isCheckedTime(8) ? 'timeSelected' : ''} onClick={() => handleTime(8)}>
                  08:00
                </div>
              </div>
              <div className="timeColumn">
                <div className={isCheckedTime(3) ? 'timeSelected' : ''} onClick={() => handleTime(3)}>
                  03:00
                </div>
                <div className={isCheckedTime(9) ? 'timeSelected' : ''} onClick={() => handleTime(9)}>
                  09:00
                </div>
              </div>
              <div className="timeColumn">
                <div className={isCheckedTime(4) ? 'timeSelected' : ''} onClick={() => handleTime(4)}>
                  04:00
                </div>
                <div className={isCheckedTime(10) ? 'timeSelected' : ''} onClick={() => handleTime(10)}>
                  10:00
                </div>
              </div>
              <div className="timeColumnLast">
                <div className={isCheckedTime(5) ? 'timeSelected' : ''} onClick={() => handleTime(5)}>
                  05:00
                </div>
                <div className={isCheckedTime(11) ? 'timeSelected' : ''} onClick={() => handleTime(11)}>
                  11:00
                </div>
              </div>
            </div>
          </div>
          <div className="timeContainer">
            <div className="timetitle">
              <img src={moon_icon} alt="moon_icon" />
              <p>pm</p>
            </div>
            <div className="timeRow">
              <div className="timeColumn">
                <div className={isCheckedTime(12) ? 'timeSelected' : ''} onClick={() => handleTime(12)}>
                  12:00
                </div>
                <div className={isCheckedTime(18) ? 'timeSelected' : ''} onClick={() => handleTime(18)}>
                  18:00
                </div>
              </div>
              <div className="timeColumn">
                <div className={isCheckedTime(13) ? 'timeSelected' : ''} onClick={() => handleTime(13)}>
                  13:00
                </div>
                <div className={isCheckedTime(19) ? 'timeSelected' : ''} onClick={() => handleTime(19)}>
                  19:00
                </div>
              </div>
              <div className="timeColumn">
                <div className={isCheckedTime(14) ? 'timeSelected' : ''} onClick={() => handleTime(14)}>
                  14:00
                </div>
                <div className={isCheckedTime(20) ? 'timeSelected' : ''} onClick={() => handleTime(20)}>
                  20:00
                </div>
              </div>
              <div className="timeColumn">
                <div className={isCheckedTime(15) ? 'timeSelected' : ''} onClick={() => handleTime(15)}>
                  15:00
                </div>
                <div className={isCheckedTime(21) ? 'timeSelected' : ''} onClick={() => handleTime(21)}>
                  21:00
                </div>
              </div>
              <div className="timeColumn">
                <div className={isCheckedTime(16) ? 'timeSelected' : ''} onClick={() => handleTime(16)}>
                  16:00
                </div>
                <div className={isCheckedTime(22) ? 'timeSelected' : ''} onClick={() => handleTime(22)}>
                  22:00
                </div>
              </div>
              <div className="timeColumnLast">
                <div className={isCheckedTime(17) ? 'timeSelected' : ''} onClick={() => handleTime(17)}>
                  17:00
                </div>
                <div className={isCheckedTime(23) ? 'timeSelected' : ''} onClick={() => handleTime(23)}>
                  23:00
                </div>
              </div>
            </div>
          </div>
          <div>
            <FormControl variant="outlined" style={{ marginTop: '28px', width: '100%' }}>
              <InputLabel htmlFor="outlined-age-native-simple">Timezone</InputLabel>
              <Select
                className="timeZoneField"
                label="Timezone"
                input={<OutlinedInput notched={true} />}
                inputProps={{
                  name: 'age',
                  id: 'outlined-age-native-simple',
                }}
                value={timezoneName}
                onChange={handleChangeTimezone}
              >
                {listTimezones().map((x) => (
                  <MenuItem key={x.name} value={x.name}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="levelPopoverButtonAva">
          <div className="levelPopoverButton">
            <p className="levelPopoverButtonClear" onClick={handleClearFilter}>
              Clear
            </p>
            <Button className="levelPopoverButtonButton" size="small" variant="contained" onClick={handleSubit}>
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default SimplePopoverAvailability;
