import React from 'react';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

interface PricesProps {
  handleChangeAmount?: any;
  amount30min?: any;
  amount60min?: any;
  amountSubscription30min?: any;
  amountSubscription60min?: any;
  editMode?: boolean;
  lastDigits?: number;
}

const Prices = ({
  handleChangeAmount,
  amount30min,
  amount60min,
  amountSubscription30min,
  amountSubscription60min,
  editMode = false,
  lastDigits,
}: PricesProps) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '48px',
        }}
      >
        <Typography className="profileTitle">Lessons</Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FormControl fullWidth className="profilePriceField" variant="outlined">
            <OutlinedInput
              type="number"
              id="outlined-adornment-amount"
              value={amount30min / 100}
              onChange={handleChangeAmount('amount30min')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              notched={true}
              disabled={!editMode}
            />
          </FormControl>
          <FormControl fullWidth className="profileTimeField" variant="outlined">
            <OutlinedInput placeholder="30min" id="outlined-adornment-amount" readOnly disabled />
          </FormControl>
          <FormControl fullWidth className="profilePriceField" variant="outlined">
            <OutlinedInput
              type="number"
              id="outlined-adornment-amount"
              value={amount60min / 100}
              onChange={handleChangeAmount('amount60min')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              notched={true}
              disabled={!editMode}
            />
          </FormControl>
          <FormControl fullWidth className="profileTimeField" variant="outlined">
            <OutlinedInput placeholder="60min" id="outlined-adornment-amount" readOnly disabled />
          </FormControl>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '48px',
        }}
      >
        <Typography className="profileTitle">Subscription</Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FormControl fullWidth className="profilePriceField" variant="outlined">
            <OutlinedInput
              type="number"
              id="outlined-adornment-amount"
              value={amountSubscription30min / 100}
              onChange={handleChangeAmount('amountSubscription30min')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              notched={true}
              disabled={!editMode}
            />
          </FormControl>
          <FormControl fullWidth className="profileTimeField" variant="outlined">
            <OutlinedInput placeholder="30min" id="outlined-adornment-amount" readOnly disabled />
          </FormControl>
          <FormControl fullWidth className="profilePriceField" variant="outlined">
            <OutlinedInput
              type="number"
              id="outlined-adornment-amount"
              value={amountSubscription60min / 100}
              onChange={handleChangeAmount('amountSubscription60min')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              notched={true}
              disabled={!editMode}
            />
          </FormControl>
          <FormControl fullWidth className="profileTimeField" variant="outlined">
            <OutlinedInput placeholder="60min" id="outlined-adornment-amount" readOnly disabled />
          </FormControl>
        </div>
      </div>
      {lastDigits && (
        <>
          <Typography className="profileTitle">Card information</Typography>
          {editMode && (
            <>
              <p className="profileText">
                Set up your account with Stripe to get paid directly into your bank or onto your debit card. Under
                “vendor website”, you can put www.bestmusiccoach.com.
              </p>
              <div className={`profileMediaActions`}>
                <div>
                  <AddIcon />
                  <Typography className={`profileUploadVideoButton ${false ? 'profileDisabled' : ''}`} variant="body2">
                    Connect
                  </Typography>
                </div>
              </div>
            </>
          )}
          <div className="profile-card-info">{`XXXX-XXXX-XXXX-${lastDigits}`}</div>
        </>
      )}
    </>
  );
};

export default Prices;
