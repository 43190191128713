import React, { FC, useState, ChangeEvent, FormEvent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Rating from '@material-ui/lab/Rating';
import { Close as CloseIcon } from '@material-ui/icons';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import { useCreateFeedbackMutation, GetCoachDocument } from 'api';
import './FeedbackModal.scss';

type FeedbackModalProps = {
  open: boolean;
  handleClose: () => void;
  coachId: string;
};

const FeedbackModal: FC<FeedbackModalProps> = ({ open, coachId, handleClose }) => {
  const [rating, setRating] = useState<number>(0);
  const [notes, setNotes] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const [createFeedback, { loading }] = useCreateFeedbackMutation({
    variables: {
      coachId,
      starCount: rating,
      comment: notes,
    },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GetCoachDocument, variables: { id: coachId } }],
  });

  const handleChangeNotes = (e: ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  const handleCreateFeedback = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (rating === 0) {
      setError(true);
      return;
    }

    await createFeedback();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}>
      <Box className="feedback-modal-body">
        <div className="feedback-modal-heading">
          <p className="feedback-modal-heading_title">Leave Review</p>
          <IconButton className="feedback-modal-heading_btn-close" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <form className="feedback-modal-main">
          <div>
            <Rating
              name="feedback-controller"
              classes={{ label: 'feedback-modal-main_rating' }}
              value={rating}
              onChange={(_, newValue) => {
                setError(false);
                setRating(newValue ?? 1);
              }}
            />
            <span style={{ opacity: error ? 1 : 0 }} className="feedback-modal-main_txt-error">
              Rate, please
            </span>
          </div>
          <TextField
            className="feedback-modal-main_inp-notes"
            value={notes}
            onChange={handleChangeNotes}
            variant="outlined"
            multiline={true}
            rows={3}
            rowsMax={7}
          />
          <Button
            disabled={loading}
            contained={true}
            type="submit"
            onClick={handleCreateFeedback}
            className="feedback-modal-main_btn-submit"
          >
            {loading ? 'Sending ...' : 'Leave review'}
          </Button>
        </form>
      </Box>
    </Dialog>
  );
};

export default FeedbackModal;
