import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import CalendarComponent from 'component/Calendar/Calendar';
import {
  useGetCoachQuery,
  useUpdateProfileInfoMutation,
  useListLessonsQuery,
  useCreateOrderMutation,
  useCompleteOrderMutation,
  useGetCouponInfoLazyQuery,
  RolesEnum,
  GetCoachDocument,
  LessonSortByEnum,
  SortOrderEnum,
  LessonType,
  AppointmentPeriodEnum,
  CouponTypeEnum,
  useListUserCouponsQuery,
  ListUserCouponsDocument,
  ListCoachCalendarSlotsDocument,
  ListLessonsDocument,
  useListCoachCalendarSlotsQuery,
  useListChildrenQuery,
} from 'api/index';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import ModalAvailabil from './ModalAvailabil/ModalAvailabil';
import ModalBook from './ModalBook/ModalBook';
import Lodaer from 'component/Loader/Loader';
import { isAvatar, getAvatar } from 'utils/image';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import { ModalLesson } from './ModalLesson/ModalLesson';
import { useMediaQuery } from 'react-responsive';
import { removeNegativePrice } from 'utils/helpers';
import { getTimezoneDisplayName } from '../../utils/constans';
import './Calendar.scss';

const bookedIcon = '/assets/calendar/booked_icon.svg';
const notAvailableIcon = '/assets/calendar/not_available_icon.svg';
const bookedByYouIcon = '/assets/calendar/booked_by_you_icon.svg';
const subscribedLessonIcon = '/assets/calendar/subscribed_lesson_icon.svg';
const toolSettingsIcon = '/assets/calendar/tool_settings.svg';
const checkMarkIcon = '/assets/modal_checkmark.svg';
const errorMarkIcon = '/assets/croos_pay.svg';
const availableForBookingIcon = '/assets/calendar/available_for_booking.svg';
const availableForSubscriptionIcon = '/assets/calendar/available_for_subscription.svg';

const fixInstrumentId = (id) => {
  if (typeof id === 'string') {
    const [userId, instrumentId] = id.split(':');
    return Number(instrumentId);
  }
  return id;
};

interface WeekDay {
  day: number;
  since: any[];
  until: any[];
}

interface SubDataInterface {
  week: WeekDay[];
  amountOfWeeks: number;
}

const Calendar = ({ user, reGetUser }) => {
  const location: any = useLocation();
  const history: any = useHistory();
  const useGetCoach = useGetCoachQuery({ variables: { id: location?.state?.id } });
  const useListLessons = useListLessonsQuery({
    variables: {
      limit: 2000,
      query: { sortBy: LessonSortByEnum.CreatedAt, order: SortOrderEnum.Asc },
      coachId: location?.state?.id,
      since: '',
      until: '',
    },
    fetchPolicy: 'network-only',
  });
  const { data: children, loading: childrenLoading } = useListChildrenQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: 999,
    },
  });
  const childrenLessons: Array<any> = children?.listChildren?.data || [];
  const listUserCoupons = useListUserCouponsQuery({ fetchPolicy: 'network-only' }).data?.listUserCoupons;
  const isTablet = useMediaQuery({ query: '(max-width: 769px)' });
  // console.log('calendarpage', user, reGetUser);
  // const useCalendarCoach = useCalendarCoachForAdultQuery({
  //   variables: {
  //     coachId: location?.state?.id,
  //     timeSlots: {
  //       startAt: '',
  //       finishAt: '',
  //       //startAt: moment().utc().year(2019).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
  //       // finishAt: moment().utc().year(2026).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
  //       // startAt: moment().subtract(14, 'days').format(),
  //       // finishAt: moment().add(14, 'days').format()
  //     }
  //   }
  // });
  const currentTimeZone = getTimezoneDisplayName();
  const [bookErrors, setBookErrors] = useState({
    timeError: '',
    balanceError: '',
  });
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    paid: 0,
  });
  const [lessonModal, setLessonModal] = useState({
    isOpen: false,
    lesson: null,
  });

  const listCoachCalendarSlotsRes = useListCoachCalendarSlotsQuery({
    variables: {
      coachId: location?.state?.id!,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });
  // console.log('listCoachCalendarSlotsRes.error', listCoachCalendarSlotsRes.error);
  // console.log('listCoachCalendarSlotsRes.data', listCoachCalendarSlotsRes.data);
  const listCoachCalendarSlots = listCoachCalendarSlotsRes.data?.listCoachCalendarSlots?.slots?.map((s) => ({
    ...s,
    since: new Date(s.since).getTime(),
    until: new Date(s.until).getTime(),
  }));
  const [errorModal, setErrorModal] = useState(false);
  const [weeksAmountError, setWeeksAmountError] = useState(false);
  // const [updateTimeSlot] = useUpdateTimeSlotMutation();
  const [updateProfile] = useUpdateProfileInfoMutation();
  const [createOrderMut] = useCreateOrderMutation({
    onError: (err) => {
      if (
        err.message ===
        `Can't make subscription without attached card. Please attach a valid card to your account before use subscription.`
      ) {
        handleGoToSubcribePay();
        return;
      }
      if (err.message === 'NOT_ALLOWED_TIME') {
        setBookErrors({
          ...bookErrors,
          timeError: 'This time is not allowed. Please, select another one',
        });
        return;
      } else if (err.message === 'NOT_ENOUGH') {
        setBookErrors({
          ...bookErrors,
          balanceError: 'Please add to your balance to book this lesson',
        });
        return;
      } else if (err.message === 'COUPON_NOT_VALID') {
        setCouponError('Invalid coupon');
      }
    },
  });
  const createOrder = (...args) => {
    // console.log('createOrder.2:args', JSON.stringify(args));
    return createOrderMut(...args);
  };
  const [completeOrder] = useCompleteOrderMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ListLessonsDocument,
        variables: {
          limit: 2000,
          query: { sortBy: LessonSortByEnum.CreatedAt, order: SortOrderEnum.Asc },
          coachId: location?.state?.id,
          since: '',
          until: '',
        },
      },
      { query: GetCoachDocument, variables: { id: location?.state?.id } },
      { query: ListUserCouponsDocument },
      {
        query: ListCoachCalendarSlotsDocument,
        variables: {
          coachId: location?.state?.id,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    ],
    onError: (err) => {
      if (err.message === 'BALANCE_TOO_LOW') {
        handleGoToSubcribePay();
        return;
      }
      if (err.message === 'COACH_NOT_AVAILABLE') {
        setBookErrors({
          ...bookErrors,
          timeError: 'Coach is not available. Please, select another time',
        });
      } else {
        setBookErrors({
          ...bookErrors,
          timeError: 'This time is not allowed. Please, select another one',
        });
      }
    },
  });
  const [lesson, setLesson] = useState<any>(useListLessons?.data?.listLessons?.data || null);
  const [coach, setCoach] = useState<any>(useGetCoach?.data?.getCoach);
  const [isAvailableModal, setIsAvailableModal] = useState(false);
  const [isBookModal, setIsBookModal] = useState(false);
  const [weekDate, setWeekDate] = useState<WeekDay[]>([]);
  const [period, setPeriod] = useState<any>([
    {
      start: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      end: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
    },
  ]);
  const [vacationPeriod, setVacationPeriod] = useState<any>({
    start: null,
    end: null,
  });
  const [hasVacation, setHasVacation] = useState(false);
  const initialBookState = {
    date: moment(),
    start: moment(),
    end: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
    instrument: 1,
    for: null,
    coupon: '',
    eventExtendedProps: null,
  };
  const [bookData, setBookData] = useState<
    Omit<typeof initialBookState, 'eventExtendedProps'> & { eventExtendedProps?: any }
  >(initialBookState);
  const [subData, setSubData] = useState<SubDataInterface>({
    week: [],
    amountOfWeeks: 5,
  });
  const [singleLessonPrice, setSingleLessonPrice] = useState({
    costsPerHalfHour: 0,
    costsPerHour: 0,
  });
  const [couponError, setCouponError] = useState<string>('');

  const handleGoToSubcribePay = () => {
    const oneLessonCost =
      moment(bookData.start).diff(moment(bookData.end), 'hour') < 0
        ? coach?.subscriptionPerHour / 100
        : coach?.subscriptionPerHalfHour / 100;
    const cost = subData?.week?.length ? oneLessonCost * subData?.week?.length : oneLessonCost;
    // console.log('handleGoToSubcribePay::cost', cost);
    // console.log('handleGoToSubcribePay::oneLessonCost', oneLessonCost);
    // console.log('handleGoToSubcribePay::subData?.week?.length', subData?.week?.length);
    // console.log('handleGoToSubcribePay::user?.balance?.amount', user?.balance?.amount);
    history.push('/buy-lesson', {
      data: JSON.stringify({
        cost,
        curBalance: null,
        bookData: bookData,
        id: location?.state?.id,
        subData: subData,
        coach: coach,
      }),
    });
  };

  const [getCouponInfo] = useGetCouponInfoLazyQuery({
    variables: {
      code: bookData.coupon,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getCouponInfo: info }) => {
      if (info?.amount) {
        const costsPerHour = useGetCoach?.data?.getCoach?.costsPerHour || 0;
        const costsPerHalfHour = useGetCoach?.data?.getCoach?.costsPerHalfHour || 0;
        if (info?.type === CouponTypeEnum.FixedPrice) {
          setSingleLessonPrice({
            costsPerHour: removeNegativePrice(costsPerHour - info.amount),
            costsPerHalfHour: removeNegativePrice(costsPerHalfHour - info.amount),
          });
        } else if (info?.type === CouponTypeEnum.FirstLesson) {
          setSingleLessonPrice({
            costsPerHour: removeNegativePrice(Math.round(costsPerHour / 2)),
            costsPerHalfHour: removeNegativePrice(Math.round(costsPerHalfHour / 2)),
          });
        } else {
          setSingleLessonPrice({
            costsPerHalfHour: removeNegativePrice(Math.round(costsPerHalfHour - costsPerHalfHour * info.amount)),
            costsPerHour: removeNegativePrice(Math.round(costsPerHour - costsPerHour * info.amount)),
          });
        }
      } else {
        setCouponError('Cannot get coupon amount');
      }
    },
    onError: (err) => {
      if (err?.message === 'NOT_EXIST') {
        setCouponError('Invalid coupon');
      }
      setSingleLessonPrice({
        costsPerHalfHour: useGetCoach?.data?.getCoach?.costsPerHalfHour || 0,
        costsPerHour: useGetCoach?.data?.getCoach?.costsPerHour || 0,
      });
    },
  });

  const [userChildrenLessons, setUserChildrenLessons] = useState<any>([]);

  const fetchChildrenLessons = useCallback(() => {
    const lessons = childrenLessons.reduce((acc, child) => {
      const updatedChildLessons = child?.lessons
        ? child.lessons.map((lesson) => {
            const updatedLesson = { ...lesson, studentProfile: child };
            return updatedLesson;
          })
        : [];
      return [...acc, ...updatedChildLessons];
    }, []);
    setUserChildrenLessons(lessons);
  }, [childrenLessons]);

  useEffect(() => {
    if (!useGetCoach.loading && !childrenLoading) {
      if (user && user?.children && user?.role === RolesEnum.Adult && !useGetCoach.data?.getCoach) {
        fetchChildrenLessons();
      }
    }
  }, [user, fetchChildrenLessons, useGetCoach.data, useGetCoach.loading, childrenLoading]);

  useEffect(() => {
    if (bookData.coupon) {
      getCouponInfo();
    }
  }, [bookData.coupon, getCouponInfo]);

  useEffect(() => {
    setSubData({
      week: [],
      amountOfWeeks: 5,
    });
  }, [isBookModal]);

  useEffect(() => {
    setSingleLessonPrice({
      costsPerHalfHour: useGetCoach?.data?.getCoach?.costsPerHalfHour || 0,
      costsPerHour: useGetCoach?.data?.getCoach?.costsPerHour || 0,
    });
  }, [useGetCoach?.data?.getCoach]);

  const handleEvents = useCallback(() => {
    return null;
  }, []);

  const returnCost = useCallback(() => {
    if (moment(bookData.start).diff(moment(bookData.end), 'hour') < 0) {
      return singleLessonPrice.costsPerHour / 100;
    } else {
      return singleLessonPrice.costsPerHalfHour / 100;
    }
  }, [bookData, singleLessonPrice]);

  const returnCostSub = useCallback(() => {
    if (moment(bookData.start).hour() - moment(bookData.end).hour() === 0) {
      return coach?.subscriptionPerHalfHour / 100;
    } else if (moment(bookData.start).hour() - moment(bookData.end).hour() === -1) {
      return coach?.subscriptionPerHour / 100;
    } else {
      return 0;
    }
  }, [bookData, coach]);

  const closeBookModal = useCallback(() => {
    setIsBookModal(false);
    setBookErrors({
      ...bookErrors,
      timeError: '',
      balanceError: '',
    });
    setBookData({
      date: moment(),
      start: moment(),
      end: moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
      instrument: 1,
      for: null,
      coupon: '',
    });
  }, []);

  const openBookModal = useCallback(() => {
    setIsBookModal(true);
    setCouponError('');
  }, [subData]);

  const handleConfirmBook = useCallback(
    (setIsLoading) => async (event) => {
      try {
        event.preventDefault();
        setIsLoading(true);
        setBookErrors({
          ...bookErrors,
          timeError: '',
          balanceError: '',
        });

        const pickedTimeslotStart = moment(+bookData.start);
        const pickedTimeslotEnd = moment(+bookData.end);
        const isBooked = coach?.lessons.some(
          (lesson) =>
            moment(+lesson?.startAt).isSame(pickedTimeslotStart) || moment(+lesson?.finishAt).isSame(pickedTimeslotEnd),
        );
        if (isBooked) {
          setBookErrors({
            ...bookErrors,
            timeError: 'This time is not allowed. Please, select another one',
          });
          return;
        }

        if ((user?.balance?.amount || 0) >= returnCost() * 100) {
          let time = [
            {
              startAt: moment(bookData.start).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              finishAt: moment(bookData.end).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          ];

          const vars = {
            timeSlots: time,
            coachId: coach.id,
            childId: bookData.for,
            costs:
              moment(bookData.start).diff(moment(bookData.end), 'hour') < 0
                ? coach?.costsPerHour
                : coach?.costsPerHalfHour,
            musicInstrumentsId: [+fixInstrumentId(bookData.instrument)],
            type: LessonType.Individual,
            periodCount: 1,
            periodType: AppointmentPeriodEnum.OneTime,
          };

          if (bookData.coupon) {
            // @ts-ignore
            vars.coupon = {
              code: bookData.coupon,
            };
          }

          const create: any = await createOrder({
            variables: {
              ...vars,
            },
          });
          if (create?.data?.createOrder?.id) {
            const complete: any = await completeOrder({
              variables: {
                orderId: create?.data?.createOrder?.id,
              },
            });
            if (complete?.data?.completeOrder?.status) {
              closeBookModal();
              reGetUser();
              const listLesson: any = await useListLessons.refetch();
              if (listLesson?.data?.listLesson?.data) {
                setLesson(listLesson?.data?.listLesson?.data);
              }
              setInfoModal({
                isOpen: true,
                paid: returnCost(),
              });
              (window as any)?.ga('create', 'UA-161929200-1', 'auto');
              (window as any)?.ga('send', 'pageview', '/purchase-confirmed');
            }
          }
        } else {
          history.push('/buy-lesson', {
            data: JSON.stringify({
              cost: returnCost(),
              curBalance: user?.balance?.amount / 100,
              bookData: { ...bookData, coupon: !couponError ? bookData.coupon : undefined },
              id: location?.state?.id,
              coach,
            }),
          });
        }
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error);
      }
      setIsLoading(false);
    },
    [bookData, coach, user, history, createOrder, returnCost],
  );

  const updateLessonList = useCallback(async () => {
    try {
      const [listLesson]: any[] = await Promise.all([
        useListLessons.refetch(),
        listCoachCalendarSlotsRes.refetch(),
        useGetCoach.refetch(),
      ]);
      if (listLesson?.data?.listLesson?.data) {
        setLesson(listLesson?.data?.listLesson?.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [useListLessons, useGetCoach]);

  const handleConfirmSub = useCallback(
    (setIsLoading) => async (event) => {
      setIsLoading(true);
      try {
        event.preventDefault();
        let time: any = [];
        if (subData?.week.length === 0) {
          time.push({
            startAt: moment(bookData.start).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            finishAt: moment(bookData.end).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        }

        const currentWeekday = moment(bookData.start).weekday();

        subData?.week?.forEach((itemW) => {
          const weekdayOffset = itemW.day < currentWeekday ? itemW.day + 7 : itemW.day;
          itemW.since.forEach((item, index) => {
            time.push({
              startAt: moment(item).weekday(weekdayOffset).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              finishAt: moment(itemW.until[index]).weekday(weekdayOffset).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
          });
        });

        setBookErrors({
          ...bookErrors,
          timeError: '',
          balanceError: '',
        });
        if (time.length * subData.amountOfWeeks < 5) {
          setWeeksAmountError(true);
          return;
        }
        handleGoToSubcribePay();
      } catch (error: any) {
        setErrorModal(true);
        // tslint:disable-next-line: no-console
        console.log(error.message);
      }
      setIsLoading(false);
    },
    [bookData, coach, user, history, createOrder, subData, returnCostSub],
  );

  const handleChooseChildren = useCallback(
    (id) => {
      setBookData({ ...bookData, for: id.target.value });
    },
    [bookData],
  );

  const handleChooseInstrument = useCallback(
    (instrument) => {
      setBookData({ ...bookData, instrument: instrument.target.value });
    },
    [bookData],
  );

  const handleChooseDate = useCallback(
    (date) => {
      setBookData({ ...bookData, date });
    },
    [bookData],
  );

  const handleChooseStart = useCallback(
    (start) => {
      if (moment(start).hours() >= moment(bookData.end).hours()) {
        setBookData({ ...bookData, start, end: moment(start).add(30, 'minutes').format('YYYY-MM-DD[T]HH:mm:ss.SSS') });
      } else {
        setBookData({ ...bookData, start });
      }
    },
    [bookData],
  );

  const handleChooseEnd = useCallback(
    (end) => {
      if (+moment(end).format('x') - +moment(bookData.start).format('x') > 3600000) {
        setBookData({ ...bookData, end, start: moment(end).subtract(30, 'minutes') });
      } else {
        setBookData({ ...bookData, end });
      }
    },
    [bookData],
  );

  const handleChangeLessonDuration = (event) => {
    // console.log('handleChangeLessonDuration', event.target.value);
    if (event.target.value === '30 minutes') {
      // console.log('handleChangeLessonDuration::set 30');
      setBookData({ ...bookData, end: moment(bookData.start).add(30, 'minutes').format('YYYY-MM-DD[T]HH:mm:ss.SSS') });
    }
    if (event.target.value === '60 minutes') {
      // console.log('handleChangeLessonDuration::set 60');
      setBookData({ ...bookData, end: moment(bookData.start).add(60, 'minutes').format('YYYY-MM-DD[T]HH:mm:ss.SSS') });
    }
  };

  const handleChangeCoupon = (coupon) => {
    setCouponError('');
    setBookData({ ...bookData, coupon: coupon });
  };

  const handleDateSelect = useCallback(
    (data) => {
      if (!moment(data?.start).isAfter(moment().add(24, 'hours'))) {
        setErrorModal(true);
        return;
      }
      setBookData({
        date: data.start,
        start: data.start,
        end: data.end,
        instrument: coach?.instruments[0]?.id,
        for: null,
        coupon: '',
      });
      openBookModal();
    },
    [coach],
  );

  const handleDateClick = useCallback(
    (data) => {
      if (
        data?.jsEvent?.srcElement?.className === 'fc-non-business' ||
        data?.jsEvent?.srcElement?.className === 'fc-booked-event' ||
        !moment(data?.date).isAfter(moment().add(24, 'hours'))
      ) {
        return;
      }
      if (user?.id === coach?.id || !coach || data.view.type === 'dayGridMonth') return;
      setBookData({
        date: data.date,
        start: data.date,
        end: moment(data.date).add(30, 'minutes').format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
        instrument: coach?.instruments[0]?.id,
        for: null,
        coupon: '',
      });
      openBookModal();
    },
    [coach, user],
  );

  const handleOpenLessonModal = useCallback((pickedLesson) => {
    setLessonModal({
      isOpen: true,
      lesson: pickedLesson,
    });
  }, []);

  const handleCloseLessonModal = useCallback(() => {
    setLessonModal({
      isOpen: false,
      lesson: null,
    });
  }, []);

  const handleEventClick = useCallback(
    ({ event, ...info }) => {
      // console.log('handleEventClick::event.extendedProps', event.extendedProps);
      if (
        (event.classNames.includes('available_for_subscription') ||
          event.classNames.includes('available_for_one_time')) &&
        user.role !== RolesEnum.Coach
      ) {
        const endAdjustMinutes = event.extendedProps?.oneHourOneTimeLessonAvailable ? 60 : 30;
        setBookData({
          date: event.start,
          start: event.start,
          end: moment(event.start).add(endAdjustMinutes, 'minutes').format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
          instrument: coach?.instruments[0]?.id,
          for: null,
          coupon: '',
          eventExtendedProps: event.extendedProps,
        });
        openBookModal();
      }

      if (lesson?.length !== 0 || userChildrenLessons?.length !== 0 || coach?.lessons !== 0) {
        let pickedLesson = lesson.find(({ id }) => id === event.id);
        if (!pickedLesson && user?.role === RolesEnum.Adult && !coach) {
          pickedLesson = userChildrenLessons.find(({ id }) => id === event.id);
        }

        const pickedLessonIndex = lesson
          .filter((item) => item?.coachProfile?.id === pickedLesson?.coachProfile?.id)
          .sort((a, b) => {
            if (a?.startAt !== null && b?.startAt !== null && a?.startAt !== undefined && b?.startAt !== undefined) {
              if (+a?.startAt > +b?.startAt) {
                return 1;
              }
              if (+a?.startAt < +b?.startAt) {
                return -1;
              }
            }
            return 0;
          })
          .findIndex(({ id }) => id === event.id);

        if (pickedLesson) {
          handleOpenLessonModal({
            lessonNumber: pickedLessonIndex !== -1 ? pickedLessonIndex + 1 : '',
            ...pickedLesson,
          });
          return;
        }

        const pickedBookedLesson = coach?.lessons.find(({ id }) => id === event.id);
        const isMyChild = !!user?.children?.find((parent) => pickedBookedLesson?.studentProfile?.id === parent?.id);

        if (pickedBookedLesson && isMyChild) {
          handleOpenLessonModal({
            lessonNumber: pickedLessonIndex !== -1 ? pickedLessonIndex + 1 : '',
            ...pickedBookedLesson,
            coachProfile: coach,
          });
        }
      }
    },
    [handleOpenLessonModal, lesson, coach, user, userChildrenLessons],
  );

  const closeAvailableModal = useCallback(() => {
    setIsAvailableModal(false);
  }, []);

  const openAvailableModal = useCallback(() => {
    setIsAvailableModal(true);
  }, []);

  const handleSaveWeek = useCallback(
    async (updatedWeekDate) => {
      const isVacationDate = (date) => {
        if (!vacationPeriod?.start || !vacationPeriod?.end) {
          return false;
        } else if (date.isBetween(vacationPeriod.start, vacationPeriod.end, 'day', [])) {
          return true;
        } else {
          return false;
        }
      };

      if (hasVacation && vacationPeriod.start === null) return;
      if (hasVacation && vacationPeriod.end === null) return;

      const instruments = user.instruments.map((item) => {
        return { id: item.id, level: item.level };
      });
      const arrTime: any = [];
      var i = 0;
      var dates: any[] = [];

      while (i < moment(period[0].end).diff(period[0].start, 'days') + 1) {
        var date = moment(period[0].start).add(i, 'days');

        if (!isVacationDate(date)) {
          dates.push(date);
        }
        i++;
      }

      dates.map((day) => {
        updatedWeekDate.map((itemW) => {
          if (day.day() === itemW.day) {
            itemW.since.map((itemS, index) => {
              const time = {
                since: moment(day)
                  .set({
                    hour: moment(itemS).hour(),
                    minute: moment(itemS).minute(),
                    second: 0,
                    millisecond: 0,
                  })
                  .utc()
                  .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                until: moment(day)
                  .set({
                    hour: moment(itemW.until[index]).hour(),
                    minute: moment(itemW.until[index]).minute(),
                    second: 1,
                  })
                  .utc()
                  .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              };
              arrTime.push(time);
            });
          }
        });
      });
      const time = await updateProfile({
        variables: {
          instruments: instruments,
          // level: user.level,
          time: arrTime,
          vacationDate: {
            start: vacationPeriod.start ? vacationPeriod.start : null,
            end: vacationPeriod.end ? vacationPeriod.end : null,
          },
        },
      });
      if (time) {
        useGetCoach.refetch();
      }
    },
    [user, period, vacationPeriod],
  );

  const chooseWeek = useCallback(
    (newWeek) => {
      const weekDay = {
        day: newWeek,
        since: [moment('09:00', 'hh:mm')],
        until: [moment('18:00', 'hh:mm')],
      };
      if (weekDate.length !== 0) {
        if (weekDate.find((item) => item.day === newWeek)) {
          let arr = weekDate
            .filter((item) => item.day !== newWeek)
            .sort((a, b) => {
              if (a.day > b.day) {
                return 1;
              }
              if (a.day < b.day) {
                return -1;
              }
              return 0;
            });
          setWeekDate([...arr]);
          handleSaveWeek([...arr]);
        } else {
          setWeekDate(
            [...weekDate, weekDay].sort((a, b) => {
              if (a.day > b.day) {
                return 1;
              }
              if (a.day < b.day) {
                return -1;
              }
              return 0;
            }),
          );
          handleSaveWeek(
            [...weekDate, weekDay].sort((a, b) => {
              if (a.day > b.day) {
                return 1;
              }
              if (a.day < b.day) {
                return -1;
              }
              return 0;
            }),
          );
        }
      } else {
        setWeekDate([weekDay]);
        handleSaveWeek([weekDay]);
      }
      // console.log('chooseWeekDay::weekDate', weekDate);
    },
    [weekDate],
  );

  const chooseBooKWeek = useCallback(
    (newWeek) => {
      const weekDay = {
        day: newWeek,
        since: [bookData.start],
        until: [bookData.end],
      };
      if (subData?.week?.length !== 0) {
        if (subData?.week?.find((item) => item.day === newWeek)) {
          let arr = subData?.week
            ?.filter((item) => item.day !== newWeek)
            .sort((a, b) => {
              if (a.day > b.day) {
                return 1;
              }
              if (a.day < b.day) {
                return -1;
              }
              return 0;
            });
          let obj: any = subData;
          obj.week = arr;
          setWeeksAmountError(false);
          setSubData({
            ...obj,
            amountOfWeeks: Math.ceil(5 / (obj.week.length || 1)),
          });
        } else {
          let obj: any = subData;
          obj.week = [...subData.week, weekDay].sort((a, b) => {
            if (a.day > b.day) {
              return 1;
            }
            if (a.day < b.day) {
              return -1;
            }
            return 0;
          });
          setWeeksAmountError(false);
          setSubData({
            ...obj,
            amountOfWeeks: Math.ceil(5 / (obj.week.length || 1)),
          });
        }
      } else {
        let obj: any = subData;
        obj.week = [weekDay];
        setSubData({ ...obj });
      }
    },
    [subData, bookData],
  );

  const handleChangePeriodStart = useCallback(
    (date, index) => {
      let arr = period;
      arr[index].start = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      if (arr[index].end < arr[index].start) {
        arr[index].end = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      }
      setPeriod([...arr]);
      // setWeekDate([]);
    },
    [period],
  );

  const handleChangePeriodEnd = useCallback(
    (date, index) => {
      let arr = period;
      arr[index].end = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      if (arr[index].end < arr[index].start) {
        arr[index].start = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      }
      setPeriod([...arr]);
      // setWeekDate([]);
    },
    [period],
  );

  const handleChangeVacationPeriodStart = useCallback(
    (date) => {
      vacationPeriod.start = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      if (vacationPeriod.end < vacationPeriod.start) {
        vacationPeriod.end = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      }
      setVacationPeriod(vacationPeriod);
    },
    [vacationPeriod],
  );

  const handleChangeVacationPeriodEnd = useCallback(
    (date, index) => {
      vacationPeriod.end = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      if (vacationPeriod.end < vacationPeriod.start) {
        vacationPeriod.start = moment(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      }
      setVacationPeriod(vacationPeriod);
    },
    [vacationPeriod],
  );

  const handleChangeStartWeek = useCallback(
    (date, indexDay, indexArr) => {
      let week = JSON.parse(JSON.stringify(weekDate));
      week[indexDay].since[indexArr] = date;
      setWeekDate(week);
    },
    [weekDate],
  );

  const handleChangeStartWeekSub = useCallback(
    (date, indexDay, indexArr) => {
      let week = JSON.parse(JSON.stringify(subData));
      week.week[indexDay].since[indexArr] = date;
      week.week[indexDay].until[indexArr] =
        moment(bookData.start).diff(moment(bookData.end), 'hour') < 0
          ? moment(date).add(1, 'hours')
          : moment(date).add(30, 'minutes');
      setSubData({ ...week });
    },
    [subData, bookData.start, bookData.end],
  );

  const handleChangeEndWeek = useCallback(
    (date, indexDay, indexArr) => {
      let week = JSON.parse(JSON.stringify(weekDate));
      week[indexDay].until[indexArr] = date;
      setWeekDate(week);
    },
    [weekDate],
  );

  const handleChangeEndWeekSub = useCallback(
    (date, indexDay, indexArr) => {
      let week = JSON.parse(JSON.stringify(subData));
      week.week[indexDay].until[indexArr] = date;
      setSubData({ ...week });
    },
    [subData],
  );

  const handleAddNewTimeForWeek = useCallback(
    (day) => {
      const week = weekDate.map((item) => {
        if (item.day === day) {
          const newItem = item;
          newItem.since.push(moment().startOf('hour'));
          newItem.until.push(moment().startOf('hour').add(1, 'hour'));
          return newItem;
        } else {
          return item;
        }
      });
      setWeekDate(week);
    },
    [weekDate],
  );

  const handleSave = useCallback(async () => {
    const isVacationDate = (date) => {
      if (!vacationPeriod?.start || !vacationPeriod?.end) {
        return false;
      } else if (date.isBetween(vacationPeriod.start, vacationPeriod.end, 'day', [])) {
        return true;
      } else {
        return false;
      }
    };

    if (hasVacation && vacationPeriod.start === null) return;
    if (hasVacation && vacationPeriod.end === null) return;

    const instruments = user.instruments.map((item) => {
      return { id: item.id, level: item.level };
    });
    const arrTime: any = [];
    var i = 0;
    var dates: any[] = [];

    while (i < moment(period[0].end).diff(period[0].start, 'days') + 1) {
      var date = moment(period[0].start).add(i, 'days');

      if (!isVacationDate(date)) {
        dates.push(date);
      }
      i++;
    }

    dates.map((day) => {
      weekDate.map((itemW) => {
        if (day.day() === itemW.day) {
          itemW.since.map((itemS, index) => {
            const time = {
              since: moment(day)
                .set({
                  hour: moment(itemS).hour(),
                  minute: moment(itemS).minute(),
                  second: 0,
                  millisecond: 0,
                })
                .utc()
                .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              until: moment(day)
                .set({
                  hour: moment(itemW.until[index]).hour(),
                  minute: moment(itemW.until[index]).minute(),
                  second: 1,
                })
                .utc()
                .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            };
            arrTime.push(time);
          });
        }
      });
    });
    const time = await updateProfile({
      variables: {
        instruments: instruments,
        // level: user.level,
        time: arrTime,
        vacationDate: {
          start: vacationPeriod.start ? vacationPeriod.start : null,
          end: vacationPeriod.end ? vacationPeriod.end : null,
        },
      },
    });
    if (time) {
      useGetCoach.refetch();
      closeAvailableModal();
    }
  }, [user, period, weekDate, vacationPeriod]);

  const handleDeleteWeekTime = useCallback(
    (index, indexS) => {
      let week = JSON.parse(JSON.stringify(weekDate));
      week[index].since.splice(indexS, 1);
      week[index].until.splice(indexS, 1);
      setWeekDate(week);
    },
    [weekDate],
  );

  // const returnLessonBookCoach = useCallback(async () => {
  //   try {
  //     let id: string = location?.state?.id;
  //     if (!useCalendarCoach.data) {
  //       if (user?.role === RolesEnum.Coach && id === undefined) {
  //         const r = await useCalendarCoach.refetch({
  //           coachId: user.id,
  //           timeSlots: {
  //             startAt: moment().utc().year(2019).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
  //             finishAt: moment().utc().year(2026).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
  //           }
  //         });
  //         setLesson(r.data.calendarCoachForAdult?.booked || []);
  //       } else {
  //         await useCalendarCoach.refetch({
  //           coachId: id,
  //           timeSlots: {
  //             startAt: moment().utc().year(2019).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
  //             finishAt: moment().utc().year(2026).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
  //           }
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     // tslint:disable-next-line: no-console
  //     console.log(error);
  //   }
  // } ,                                       [useCalendarCoach, location, user]);

  useEffect(() => {
    // returnLessonBookCoach();
    if (location?.state?.id) {
      // useGetCoach.refetch({id: location?.state?.id})
    } else if (location?.state?.payDate) {
      useGetCoach.refetch({ id: location?.state?.coachid });
      setBookData({
        date: location?.state?.payDate?.date,
        start: location?.state?.payDate?.start,
        end: location?.state?.payDate?.end,
        instrument: location?.state?.payDate?.instrument,
        for: location?.state?.payDate?.for,
        coupon: location?.state?.payDate?.coupon,
      });
      openBookModal();
    } else {
      useGetCoach.refetch({ id: user?.id });
    }
    if (useListLessons?.data?.listLessons?.data) {
      setLesson(useListLessons?.data?.listLessons?.data);
    }
    if (useGetCoach.data?.getCoach) {
      setCoach(useGetCoach?.data?.getCoach);
      if (!location?.state?.id) {
        if (useGetCoach?.data?.getCoach?.avialableTime?.length) {
          let week: any[] = [];
          let start: any = 0;
          let end: any = 0;
          if (useGetCoach?.data?.getCoach?.avialableTime[0]) {
            start = useGetCoach?.data?.getCoach?.avialableTime[0].since;
          }
          if (useGetCoach?.data?.getCoach?.avialableTime[useGetCoach?.data?.getCoach?.avialableTime.length - 1]) {
            end =
              useGetCoach?.data?.getCoach?.avialableTime[useGetCoach?.data?.getCoach?.avialableTime.length - 1]?.until;
          }
          setPeriod([
            {
              start: moment(+start).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              end: moment(+end).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            },
          ]);
          useGetCoach?.data?.getCoach?.avialableTime.map((item) => {
            if (item?.since && item?.until) {
              const day = moment(+item?.since).day();
              const since = [moment(+item?.since)];
              const until = [moment(+item?.until)];
              if (week.find((item) => item.day === day)) {
                week[week.findIndex((item) => item.day === day)].since.push(moment(+item?.since));
                week[week.findIndex((item) => item.day === day)].until.push(moment(+item?.until));
              } else {
                week.push({
                  day,
                  since,
                  until,
                });
              }
            }
          });
          week.map((itemW, indexW) => {
            if (itemW.since.length) {
              let sinceD = itemW.since.filter((itemS, pos) => {
                return itemW.since.findIndex((itemS2) => itemS2.hour() === itemS.hour()) === pos;
              });
              let untilD = itemW.until.filter((itemS, pos) => {
                return itemW.until.findIndex((itemS2) => itemS2.hour() === itemS.hour()) === pos;
              });
              week[indexW].since = sinceD;
              week[indexW].until = untilD;
            }
          });
          setWeekDate([...week].sort((a, b) => a?.day - b?.day));
        }
      }
    }
  }, [
    useGetCoach?.data?.getCoach,
    location,
    user,
    useListLessons?.data?.listLessons,
    // useCalendarCoach,
    // returnLessonBookCoach
  ]);

  const handleChangeAmountOfLessons = useCallback(
    (event) => {
      setWeeksAmountError(false);
      let obj = subData;
      obj.amountOfWeeks = event.target.value;
      setSubData({ ...obj });
    },
    [subData],
  );

  const returnCoachInfo = () => {
    return (
      <div className="calendar_coach">
        <UserAvatar
          avatar={isAvatar(coach?.files || []) ? getAvatar(coach?.files || []) : ''}
          firstName={coach?.firstName || ''}
          lastName={coach?.lastName || ''}
        />
        <p className="calendar_coach_name">
          {coach?.firstName || ''} {coach?.lastName || ''}
        </p>
      </div>
    );
  };

  const returnCalendarInfo = () => {
    return (
      <>
        <div className="calendar_info">
          {user?.role !== RolesEnum.Child && (
            <div className="calendar_info_block">
              <img src={availableForSubscriptionIcon} alt="available_icon" className="" />
              <p className="calendar_info_block_text">Available: Subscription & Single Lesson</p>
            </div>
          )}
          {user?.role !== RolesEnum.Child && (
            <div className="calendar_info_block">
              <img src={availableForBookingIcon} alt="available_icon" className="" />
              <p className="calendar_info_block_text">Available: Single Lesson Only</p>
            </div>
          )}
          <div className="calendar_info_block">
            <img src={notAvailableIcon} alt="available_icon" className="" />
            <p className="calendar_info_block_text">Not Available</p>
          </div>
          {user?.role === RolesEnum.Adult && (
            <div className="calendar_info_block">
              <img src={bookedIcon} alt="available_icon" className="" />
              <p className="calendar_info_block_text">Booked by Other Students</p>
            </div>
          )}
          {user?.role !== RolesEnum.Coach && (
            <div className="calendar_info_block">
              <img src={bookedByYouIcon} alt="available_icon" className="" />
              <p className="calendar_info_block_text">My Subscribed Lessons</p>
            </div>
          )}
          {user?.role !== RolesEnum.Coach && (
            <div className="calendar_info_block">
              <img src={subscribedLessonIcon} alt="available_icon" className="" />
              <p className="calendar_info_block_text">My Single Lessons</p>
            </div>
          )}
          {user?.role === RolesEnum.Coach && (
            <div className="calendar_info_block">
              <img src={bookedByYouIcon} alt="available_icon" className="" />
              <p className="calendar_info_block_text">Subscribed Lessons</p>
            </div>
          )}
          {user?.role === RolesEnum.Coach && (
            <div className="calendar_info_block">
              <img src={subscribedLessonIcon} alt="available_icon" className="" />
              <p className="calendar_info_block_text">Single Lessons</p>
            </div>
          )}
        </div>
        <div className="callendar_timezone">
          <Button disabled>Your Timezone: {currentTimeZone}</Button>
        </div>
      </>
    );
  };

  const returnSetAvailability = () => {
    return (
      <div className="calendar_availability" onClick={openAvailableModal}>
        <div className="calendar_availability_container">
          <img alt="" src={toolSettingsIcon} className="calendar_availability_container_img" />
          <p className="calendar_availability_container_text">Set availability</p>
        </div>
      </div>
    );
  };

  const handleCloseInfoModal = () => {
    setInfoModal((prev) => ({ ...prev, isOpen: false }));
    setTimeout(() => {
      setInfoModal((prev) => ({ ...prev, paid: 0 }));
    }, 2000);
  };
  const handleViewCoaches = () => history.push('/coaches');

  const handleCloseErrorModal = () => {
    setErrorModal(false);
  };

  const bookedLessons = useMemo(() => {
    if (!coach && user?.role === RolesEnum.Adult) {
      return userChildrenLessons;
    }
    return (
      coach?.lessons?.map((lesson) => ({
        ...lesson,
        coachProfile: {
          id: coach?.id,
          files: coach?.files,
          firstName: coach?.firstName,
          lastName: coach?.lastName,
        },
      })) || []
    );
  }, [coach, userChildrenLessons, user]);

  useEffect(() => {
    if (user?.vacationDate) {
      setVacationPeriod({
        start: moment(user.vacationDate.start),
        end: moment(user.vacationDate.end),
      });
      setHasVacation(true);
    }
  }, [user]);

  // console.log('return?', useListLessons.loading, useGetCoach.loading);
  const res = (
    <div className="calendar">
      <Modal open={isAvailableModal} onClose={closeAvailableModal} className="calendar_modal">
        <Box>
          <ModalAvailabil
            period={period}
            vacationPeriod={vacationPeriod}
            closeAvailabilModal={closeAvailableModal}
            handleChangePeriodStart={handleChangePeriodStart}
            handleChangePeriodEnd={handleChangePeriodEnd}
            handleChangeVacationPeriodStart={handleChangeVacationPeriodStart}
            handleChangeVacationPeriodEnd={handleChangeVacationPeriodEnd}
            setVacationPeriod={setVacationPeriod}
            setHasVacation={setHasVacation}
            hasVacation={hasVacation}
            chooseWeek={chooseWeek}
            week={weekDate}
            handleAddNewTimeForWeek={handleAddNewTimeForWeek}
            handleSave={handleSave}
            handleChangeStartWeek={handleChangeStartWeek}
            handleChangeEndWeek={handleChangeEndWeek}
            handleDeleteWeekTime={handleDeleteWeekTime}
          />
        </Box>
      </Modal>
      {isBookModal && (
        <Modal open={isBookModal} onClose={closeBookModal} className="calendar_modal">
          <ModalBook
            closeBookModal={closeBookModal}
            bookData={bookData}
            bookErrors={bookErrors}
            coach={coach}
            user={user}
            weeksAmountError={weeksAmountError}
            singleLessonPrice={singleLessonPrice}
            subData={subData}
            couponError={couponError}
            setCouponError={setCouponError}
            chooseBooKWeek={chooseBooKWeek}
            handleConfirmBook={handleConfirmBook}
            handleChooseChildren={handleChooseChildren}
            handleChooseInstrument={handleChooseInstrument}
            handleChooseDate={handleChooseDate}
            handleChooseStart={handleChooseStart}
            handleChooseEnd={handleChooseEnd}
            handleConfirmSub={handleConfirmSub}
            handleChangeStartWeekSub={handleChangeStartWeekSub}
            handleChangeEndWeekSub={handleChangeEndWeekSub}
            handleChangeAmountOfLessons={handleChangeAmountOfLessons}
            handleChangeCoupon={handleChangeCoupon}
            coupons={listUserCoupons}
            handleChangeLessonDuration={handleChangeLessonDuration}
            currentTimeZone={currentTimeZone}
          />
        </Modal>
      )}
      <Dialog
        open={infoModal.isOpen}
        onClose={handleCloseInfoModal}
        aria-labelledby="book-info-modal"
        disableScrollLock
        className="book-info-modal"
      >
        <div className="book-info-modal-content">
          <img src={checkMarkIcon} alt="checkmark_icon" />
          {!!infoModal.paid && (
            <p className="book-info-modal-content__title">
              {`$${infoModal.paid} has been withdrawn from your balance.`}
            </p>
          )}
          {user?.balance?.amount && user?.balance?.amount / 100 && (
            <p className="book-info-modal-content__subtitle">
              {`Your new balance is $${user?.balance?.amount / 100}.`}
            </p>
          )}
          <div className="book-info-modal-content__btns">
            <Button onClick={handleViewCoaches}>View other coaches</Button>
            <Button onClick={handleCloseInfoModal} contained>
              Book another lesson
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={errorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="book-info-modal"
        disableScrollLock
        className="book-info-modal"
      >
        <div className="book-info-modal-content">
          <img src={errorMarkIcon} alt="errormark_icon" />
          <p className="book-info-modal-content__title">Ooops!</p>
          <div className="book-error-modal-content__subtitle">
            <span>Sorry, selected time slots are not available.</span>
            <span>Please, try another time slot.</span>
          </div>
          <div className="book-info-modal-content__btns">
            <Button onClick={handleViewCoaches}>View other coaches</Button>
            <Button onClick={handleCloseErrorModal} contained>
              Try another slot
            </Button>
          </div>
        </div>
      </Dialog>
      {lessonModal.isOpen && (
        <ModalLesson
          isOpen={lessonModal.isOpen}
          handleCloseModal={handleCloseLessonModal}
          lesson={lessonModal.lesson}
          role={user?.role}
          updateLessonList={updateLessonList}
          lessons={lesson}
          user={user}
        />
      )}
      {returnCalendarInfo()}
      {coach && user?.id !== coach?.id && returnCoachInfo()}
      {user?.role === RolesEnum.Coach && returnSetAvailability()}
      {!useListLessons.loading && !useGetCoach.loading ? (
        <CalendarComponent
          handleEvents={handleEvents}
          handleDateSelect={handleDateSelect}
          handleDateClick={isTablet ? handleDateClick : undefined}
          selectMinDistance={isTablet ? 10 : undefined}
          handleEventClick={handleEventClick}
          dataB={coach && user?.id !== coach?.id ? coach?.avialableTime || [] : coach?.avialableTime || []}
          role={user?.role}
          user={user}
          lesson={lesson || []}
          selectable={user?.id !== coach?.id ? (coach ? true : false) : false}
          lessonBook={bookedLessons}
          admin={false}
          coach={coach}
          listCoachCalendarSlots={listCoachCalendarSlots}
        />
      ) : (
        <Lodaer />
      )}
    </div>
  );
  // console.log('retunr??', !!res);
  return res;
};

export default Calendar;
