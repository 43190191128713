import React, { FC, useCallback, useRef, useState } from 'react';
import Storage from '@aws-amplify/storage';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import './ImageUploader.scss';

const isFile = (file) => file instanceof File;

export type ImageUploaderProps = {
  imageUrl?: string;
  setUrl: (url: string | undefined) => void;
};

const { REACT_APP_AWSS3_BUCKET, REACT_APP_AWS_REGION } = process.env;

const ImageUploader: FC<ImageUploaderProps> = ({ imageUrl, setUrl }) => {
  const [file, setFile] = useState<any | null | File>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadImage = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]?.name) {
        const name = event.target.files[0].name;
        const fileObj = event.target.files[0];
        try {
          const res: any = await Storage.put(`books/${name}`, fileObj);
          setUrl(`https://${REACT_APP_AWSS3_BUCKET}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/public/${res.key}`);
          setFile(fileObj);
        } catch (e) {
          console.error(e, 'e uploadImage');
        }
      }
    },
    [setUrl],
  );

  const removeImage = useCallback(async () => {
    setUrl(undefined);
    setFile(null);
  }, [setUrl]);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const fileUrl = isFile(file) ? URL.createObjectURL(file) : imageUrl;

  if (fileUrl || imageUrl) {
    return (
      <div className="admin-book-image-uploader admin-book-image-uploader--uploaded">
        <img src={fileUrl} alt="book" />
        <Button variant="contained" type="button" onClick={removeImage}>
          <DeleteIcon />
        </Button>
      </div>
    );
  }

  return (
    <div className="admin-book-image-uploader" onClick={handleClick}>
      <ControlPoint />
      <span>Add photo</span>
      <input type="file" ref={inputRef} onChange={uploadImage} />
    </div>
  );
};

export default ImageUploader;
