import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useListInstrumentsQuery } from 'api';
import { useHistory } from 'react-router-dom';
import { getAdminLessonRoute } from '../../../modules/routes';
import moment from 'moment';
import React from 'react';
import './BasicTable.scss';

interface BasicTableProps {
  rows: any;
}

export default function BasicTable({ rows }: BasicTableProps) {
  const history = useHistory();
  const instrumentsQuery = useListInstrumentsQuery();
  const instruments = instrumentsQuery.data?.listInstruments || [];
  return (
    <TableContainer component={Paper} elevation={0} className="lessons_basic_table_container">
      <Table className="lessons_basic_table" aria-label="simple table">
        <TableHead>
          <TableRow className="lessons_basic_table_row">
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Coach</TableCell>
            <TableCell>Student</TableCell>
            <TableCell>Instrument</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              className="lessons_basic_table_row"
              key={row?.id}
              onClick={() => history.push(getAdminLessonRoute(row.id))}
            >
              <TableCell>{moment(Number.parseInt(row?.startAt || '')).format('DD MMM, YYYY')}</TableCell>
              <TableCell>{moment(Number.parseInt(row?.startAt || '')).format('LT')}</TableCell>
              <TableCell>{`${row?.coachProfile?.firstName} ${row?.coachProfile?.lastName}`}</TableCell>
              <TableCell>{`${row?.studentProfile?.firstName} ${row?.studentProfile?.lastName}`}</TableCell>
              <TableCell>
                {instruments
                  ?.find((item) => Number.parseInt(item?.id || '1') === row?.musicInstrumentId[0])
                  ?.name?.replace('_', '  ') || 'unknown'}
              </TableCell>
              <TableCell>1-on-1</TableCell>
              <TableCell>
                {(row?.finishAt &&
                  row?.startAt &&
                  `${moment
                    .duration(Number.parseInt(row?.finishAt) - Number.parseInt(row?.startAt))
                    .asMinutes()} Min`) ||
                  'not set'}
              </TableCell>
              <TableCell align="right">{`$ ${row?.costs / 100}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
