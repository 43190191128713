import React from 'react';

const AdultIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.499 8.75C15.499 11.3744 13.4853 13.5 10.999 13.5C8.51277 13.5 6.49902 11.3744 6.49902 8.75C6.49902 6.12562 8.51277 4 10.999 4C13.4853 4 15.499 6.12562 15.499 8.75ZM1.99902 20.625C1.99902 17.4662 7.99527 15.875 10.999 15.875C12.309 15.875 14.1882 16.1777 15.8909 16.781L13.499 23H3.12402C2.50527 23 1.99902 22.4656 1.99902 21.8125V20.625Z"
        fill="#605F5E"
        {...props}
      />
      <path
        d="M18.2948 14H19.6837L23 23H21.105L20.4482 21.0932H17.4872L16.852 23H15L18.2948 14ZM18.9408 16.6186L18.0148 19.4153H19.8775L18.9408 16.6186Z"
        fill="#605F5E"
        {...props}
      />
    </svg>
  );
};
export default AdultIcon;
