import { useState, useCallback, ChangeEvent, KeyboardEvent, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import validateEmail from '../../utils/emailUtils';
import { useListInstrumentsQuery, useInviteChildrenMutation, Instruments, Level } from '../../api/index';
import moment from 'moment';

export const useChildInvitation = () => {
  interface IFormValues {
    email: any;
    firstName: string;
    lastName: string;
    lessons: Array<Instruments>;
    level?: Level;
    birthdate: string
  }

  const INITIAL_VALUES_STATE: IFormValues = {
    email: null,
    firstName: '',
    lastName: '',
    lessons: [],
    level: undefined,
    birthdate: '',
  };

  const INITIAL_ERRORS_STATE = {
    email: '',
    firstName: '',
    lastName: '',
    lessons: '',
    level: '',
    birthdate: ''
  };

  const [values, setValues] = useState<IFormValues>(INITIAL_VALUES_STATE);
  const [errors, setErrors] = useState(INITIAL_ERRORS_STATE);
  const { push } = useHistory();

  const { data } = useListInstrumentsQuery();
  const [inviteChildren, { loading: invitationPending }] = useInviteChildrenMutation({
    onCompleted: () => {
      window.alert('Invitation was sent. Please, check your email');
      push('/my-children');
    },
    onError: (err) => {
      if (err.message === 'USER_WITH_EMAIL_EXIST') {
        setErrors({
          ...errors,
          email: 'User with this email already exists',
        });
      }
    },
  });

  const instruments = data?.listInstruments || [];

  const handleKeyPress = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    if (Object.keys(errors).some((k) => errors[k])) {
      setErrors(INITIAL_ERRORS_STATE);
    }
  };

  const handleChangeText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name, required } = event.target;

      if (value && required && errors[name].length) {
        setErrors({
          ...errors,
          [name]: '',
        });
      }

      setValues({
        ...values,
        [name]: value,
      });
    },
    [values, errors],
  );

  const handleChangeBirthdate = (date) => {
    setErrors({ ...errors, birthdate: '' })

    if (!date) {
      setErrors({ ...errors, birthdate: 'Please enter a valid birth date' });
    }
    
    setValues({
      ...values,
      birthdate: moment(date).format("MMM DD,yyyy"),
    });

  }

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setErrors({ ...errors, email: '' });
    if(value) {
      if (!validateEmail(value)) {
        setErrors({ ...errors, email: 'Please enter a valid email address' });
      }
    }
    
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeSelect = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value, name = 'level' } = event.target;
    
    if (value && errors[name].length) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDeleteLesson = (i: Instruments) => {
    const filteredArr = values.lessons.filter((l) => l.id !== i.id);
    setValues({
      ...values,
      lessons: filteredArr,
    });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const err = {
      email: '',
      firstName: '',
      lastName: '',
      lessons: '',
      level: '',
      birthdate: ''
    };

    for (const [key, value] of Object.entries(values)) {
      if(key !== 'email') {
        if (!value || (typeof value === 'object' && !value.length)) {
          err[key] = "Field can't be empty";
        } else {
          err[key] = errors[key];
        }
      }
    }

    setErrors(err);

    if (Object.values(err).every((e) => !e)) {
      inviteChildren({
        variables: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          musicInstruments: values.lessons.map((i) => {
            return { id: i.id, level: Level[`${values.level ? values.level : 'BEGINNER'}`] };
          }),
          birthdate: values.birthdate,
          // level: [values.level?.toUpperCase() as Level],
        },
      });
    }
  };

  return {
    values,
    errors,
    instruments,
    invitationPending,
    handleKeyPress,
    handleChangeText,
    handleChangeEmail,
    handleChangeSelect,
    handleDeleteLesson,
    handleSubmit,
    handleChangeBirthdate,
  };
};
