import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Add as AddIcon } from '@material-ui/icons';
import ChildCard from 'component/ChildCard/ChildCard';
import Loader from 'component/Loader/Loader';
import './MyChildren.scss';
import { Profile, useListChildrenQuery } from 'api';

type Props = {
  user: Profile;
};

const MyChildren: FC<Props> = ({ user }) => {
  const { push } = useHistory();

  const { data, loading } = useListChildrenQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: 999,
    },
  });

  const listChildren = data?.listChildren?.data || [];

  const handleOpenNewChild = () => {
    push('/invite-children');
  };

  return (
    <div className="my-children">
      <Button onClick={handleOpenNewChild} className="my-children_btn-add" startIcon={<AddIcon />}>
        Add child
      </Button>
      {loading ? (
        <div className="my-children_loader">
          <Loader />
        </div>
      ) : (
        <ul className="my-children_list">
          {listChildren?.map((child: any) => (
            <ChildCard child={child!} key={child?.id} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default MyChildren;
