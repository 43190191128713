import React, { useCallback, useState, ChangeEvent, useEffect } from 'react';
// import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
  Button,
  TextField,
  Modal,
} from '@material-ui/core';
import Line from 'component/Line/Line';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RolesEnum, useUpdateLessonMutation, useDeleteLessonMaterialsMutation, ListLessonsDocument } from 'api';
import './PracticeNote.scss';

const close_modal_cross = '/assets/close_modal_cross.svg';

const PracticeNote = ({ user, lessonId, note }) => {
  interface FormValues {
    id: string;
    title: string;
    timeInSec: number;
    bpm: number;
    studentGoal: string;
    studentNotes: string;
    coachNotes: string;
  }

  const INITIAL_VALUES_STATE: FormValues = {
    id: '',
    title: '',
    timeInSec: 0,
    studentGoal: '',
    bpm: 0,
    studentNotes: '',
    coachNotes: '',
  };

  const INITIAL_ERRORS_STATE = {
    id: '',
    title: '',
    timeInSec: '',
    bpm: '',
    studentNotes: '',
    coachNotes: '',
    studentGoal: '',
  };

  const [values, setValues] = useState<FormValues>(INITIAL_VALUES_STATE);
  const [errors, setErrors] = useState(INITIAL_ERRORS_STATE);
  const [updateLessonMutation] = useUpdateLessonMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListLessonsDocument, variables: { offset: 0, limit: 2000, since: '', until: '' } }],
  });
  const [deleteNote] = useDeleteLessonMaterialsMutation({
    variables: { id: values.id },
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ListLessonsDocument, variables: { offset: 0, limit: 2000, since: '', until: '' } }],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (note) {
      setValues({
        id: note.id,
        title: note.title,
        timeInSec: note.timeInSec / 60,
        studentGoal: note.studentGoal,
        bpm: note.bpm,
        studentNotes: note.studentNotes,
        coachNotes: note.coachNotes,
      });
    }
  }, [note]);

  const handleSaveNote = async () => {
    try {
      await updateLessonMutation({
        variables: {
          lessonId: lessonId,
          lessonMaterials: {
            id: values.id ? values.id : null,
            title: values.title,
            timeInSec: +values.timeInSec * 60,
            studentGoal: values.studentGoal,
            bpm: +values.bpm,
            studentNotes: values.studentNotes,
            coachNotes: values.coachNotes,
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateNote = async () => {
    try {
      setIsLoading(true);
      await updateLessonMutation({
        variables: {
          lessonId: lessonId,
          lessonMaterials: {
            title: values.title,
            timeInSec: +values.timeInSec * 60,
            studentGoal: values.studentGoal,
            bpm: +values.bpm,
            studentNotes: values.studentNotes,
            coachNotes: values.coachNotes,
          },
        },
      });
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteNote = async () => {
    try {
      await deleteNote();
      setOpenModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name, required } = event.target;

      if (value && required && errors[name].length) {
        setErrors({
          ...errors,
          [name]: '',
        });
      }

      setValues({
        ...values,
        [name]: value,
      });
    },
    [values, errors],
  );

  return (
    <div>
      <Accordion className="practise_note" defaultExpanded={values.id.length === 0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
          <div className="practise_note_accordion_summary">
            <TextField
              className="practise_note_accordion_summary_input"
              onFocus={(event) => event.stopPropagation()}
              onClick={(event) => event.stopPropagation()}
              onChange={handleChangeText}
              variant="outlined"
              label="Title"
              disabled={user.role !== RolesEnum.Coach}
              name="title"
              value={values.title}
            />
            <TextField
              className="practise_note_accordion_summary_input"
              onFocus={(event) => event.stopPropagation()}
              onClick={(event) => event.stopPropagation()}
              variant="outlined"
              label="BPM"
              onChange={handleChangeText}
              disabled={user.role !== RolesEnum.Coach}
              name="bpm"
              value={values.bpm}
            />
            <TextField
              className="practise_note_accordion_summary_input"
              onFocus={(event) => event.stopPropagation()}
              onClick={(event) => event.stopPropagation()}
              onChange={handleChangeText}
              value={values.timeInSec}
              label="Duration (in minutes)"
              disabled={user.role !== RolesEnum.Coach}
              name="timeInSec"
              variant="outlined"
            />
          </div>
        </AccordionSummary>
        <Line />
        <AccordionDetails>
          <div className="practise_note_accordion_details">
            <TextField
              className="practise_note_accordion_details_coachs"
              variant="outlined"
              label="Coach's notes"
              multiline
              onChange={handleChangeText}
              disabled={user.role !== RolesEnum.Coach}
              name="coachNotes"
              value={values.coachNotes}
            />
            <TextField
              disabled={user.role !== RolesEnum.Coach}
              className="practise_note_accordion_details_goal"
              variant="outlined"
              label="Student's goal"
              multiline
              inputProps={{ maxLength: '300' }}
              onChange={handleChangeText}
              name="studentGoal"
              value={values.studentGoal}
            />
            <TextField
              className="practise_note_accordion_details_student"
              variant="outlined"
              label="Student's notes"
              name="studentNotes"
              onChange={handleChangeText}
              inputProps={{ maxLength: '300' }}
              value={values.studentNotes}
              disabled={user.role === RolesEnum.Coach}
              multiline
            />
          </div>
        </AccordionDetails>
        <Line />
        <AccordionActions>
          {values.id.length > 0 && user.role === RolesEnum.Coach && (
            <Button onClick={() => setOpenModal(true)} className="practise_note_accordion_delete_buttton">
              Delete note
            </Button>
          )}
          {values.id.length === 0 && (
            <Button disabled={isLoading} onClick={handleCreateNote} className="practise_note_accordion_save_buttton">
              Create note
            </Button>
          )}
          {values.id.length > 0 && (
            <Button onClick={handleSaveNote} className="practise_note_accordion_save_buttton">
              Save note
            </Button>
          )}
        </AccordionActions>
      </Accordion>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="profile_modal_confirmation">
          <div className="profile_modal_confirmation_header">
            <p className="profile_modal_confirmation_title">Are you sure you want to delete note?</p>
            <img
              src={close_modal_cross}
              className="profile_modal_confirmation_close"
              onClick={() => setOpenModal(false)}
              alt="close-icon"
            />
          </div>
          <div className="profile_modal_confirmation_button_container">
            <Button onClick={handleDeleteNote} className="practise_note_accordion_delete_buttton">
              Delete note
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PracticeNote;
