import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { Spinner } from '../../component/Button/Button';
import Loader from 'component/Loader/Loader';
import validateEmail from '../../utils/emailUtils';
import './ForgotPassword.scss';

const mainImage = '/assets/resetPass.png';

function SignIn() {
  const history = useHistory();
  const [firstStep, setFirstStep] = useState<boolean>(true);
  const [load, setLoad] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [louder, setLouder] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>('');
  const [errorsEmail, setErrorsEmail] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const handleChangePassword = (event) => {
    setErrors('');
    if (event.target.name === 'password' && !event.target.value) {
      setErrors('');
    }
    setPass(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setErrorsEmail('');
    if (event.target.name === 'email' && !validateEmail(event.target.value)) {
      setErrorsEmail('');
    }
    setEmail(event.target.value);
  };

  const handleChangeCode = (event) => {
    setErrors('');
    if (event.target.name === 'newPassword' && !event.target.value) {
      setErrors('');
    }
    setCode(event.target.value);
  };

  const handleMouseDownPassword = useCallback((event) => event.preventDefault(), []);

  const nextStep = useCallback(async () => {
    if (!validateEmail(email)) {
      setErrorsEmail('Please enter a valid email address');
      return;
    } else {
      setErrorsEmail('');
    }
    setLouder(true);
    try {
      const ress = await Auth.forgotPassword(email.toLowerCase());
      if (ress) {
        setFirstStep(false);
      }
    } catch (e: any) {
      // tslint:disable-next-line: no-console
      console.log(e);
      if (e.code === 'UserNotFoundException') {
        setErrorsEmail(`Email doesn't exist`);
      } else {
        setErrorsEmail(`${e.message.slice(0, 60)}...`);
      }
      setLouder(false);
    }
    setLouder(false);
  }, [email]);

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') {
      return;
    }
  };

  const handleResendCode = useCallback(async () => {
    try {
      await Auth.forgotPassword(email.toLowerCase());
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e);
      setLouder(false);
    }
  }, [email]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoad(true);
      const submit = await Auth.forgotPasswordSubmit(email.toLowerCase(), code, pass);
      setLoad(false);
      if (submit === undefined) {
        history.push('/signin');
      }
    } catch (e: any) {
      // tslint:disable-next-line: no-console
      console.log(e);
      setErrors(`${e.message.slice(0, 60)}...`);
      setLoad(false);
    }
  }, [email, code, pass, history]);

  const fieldsLink = (
    <>
      <TextField
        className={`loginFormField loginPasswordFieldPass forgotPasswordInputField ${
          errors ? 'loginFormFieldError' : ''
        }`}
        error={!!errors}
        helperText={errors ? errors : ''}
        name="code"
        label="Code"
        variant="outlined"
        onChange={handleChangeCode}
        onKeyPress={handleKeyPress}
        value={code || ''}
        fullWidth={true}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />
      <TextField
        className={`loginFormField loginPasswordFieldPass forgotPasswordInputField ${
          errors ? 'loginFormFieldError' : ''
        }`}
        error={!!errors}
        helperText={errors ? errors : ''}
        name="password"
        label="Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        onChange={handleChangePassword}
        onKeyPress={handleKeyPress}
        value={pass || ''}
        fullWidth={true}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className="loginVisibilityIcon"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  const fieldsLinkEmail = (
    <>
      <TextField
        className={`loginFormField loginPasswordFieldPass forgotPasswordInputField ${
          errorsEmail ? 'loginFormFieldError' : ''
        }`}
        error={!!errorsEmail}
        helperText={errorsEmail ? errorsEmail : ''}
        name="email"
        label="Email"
        variant="outlined"
        onChange={handleChangeEmail}
        onKeyPress={handleKeyPress}
        value={email || ''}
        fullWidth={true}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />
    </>
  );

  const actionsLink = (
    <div className="loginActionsPass">
      <Button className={`loginButton`} variant="contained" color="secondary" onClick={nextStep}>
        {louder ? <Spinner style={{ position: 'absolute', color: '#fff' }} /> : 'Send'}
      </Button>
    </div>
  );

  const text = useMemo(
    () => ({
      submit: firstStep ? 'Send' : 'Save',
      title: firstStep ? 'Restore your password' : 'Create new password',
      subTitle: firstStep
        ? 'Enter the email address you signed up with.'
        : 'You should receive an email in a moment.  Enter the verification code you receive and provide new password.',
    }),
    [firstStep],
  );

  const actionsLinkResey = (
    <div className="forgotActionsPassCode forgotActionsPassCode2">
      <Typography onClick={handleResendCode} className="signinPolicy" variant="body2">
        <span>Resend code</span>
      </Typography>
      <Button className={`loginButton`} variant="contained" color="secondary" onClick={handleSubmit}>
        {louder ? <Spinner style={{ position: 'absolute', color: '#fff' }} /> : text.submit}
      </Button>
    </div>
  );

  return (
    <div className="forgotContainer">
      <div className="forgotColumn loginLeftColumn">
        <div className="forgot_text_container">
          <Typography className="loginLeftColumnTitle" variant="h4">
            <span>{text.title}</span>
          </Typography>
          <span className="signupLeftColumnSecondTitle">{text.subTitle}</span>
        </div>
        <img src={mainImage} alt="" className="loginLogo" />
      </div>
      <div className="forgotColumn loginRightColumn forgotRightColumn">
        {firstStep ? (
          <>
            {fieldsLinkEmail}
            {actionsLink}
          </>
        ) : load ? (
          <Loader />
        ) : (
          <>
            {fieldsLink}
            {actionsLinkResey}
          </>
        )}
      </div>
    </div>
  );
}

export default SignIn;
