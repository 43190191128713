import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const MainInfo = ({ handleChange, values, editMode = false }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '36px',
      }}
    >
      <Typography className="profileTitle">Personal Info</Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <TextField
          disabled={!editMode}
          className="profileFormField profileFormFieldEmail"
          variant="outlined"
          name="email"
          label="Email"
          type="text"
          required
          onChange={handleChange}
          value={values.email || ''}
          fullWidth
          InputProps={{
            classes: {
              focused: `focusedInputField`,
            },
          }}
        />
      </div>
    </div>
  );
};

export default MainInfo;
