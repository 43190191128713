import React, { useEffect, useCallback, useState } from 'react';
import { Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import Header from './component/Header/Header';
import SignIn from './page/SignIn/SignIn';
import SignUp from './page/SignUp/SignUp';
import Profile from './page/PersonalInfo/Info';
import Courses from './page/Courses/Courses';
import Coach from './page/Coach/Coach';
import Lessons from './page/Lessons/Lessons';
import Lesson from './page/Lesson/Lesson';
import Calendar from './page/Calendar/Calendar';
import BuyLesson from './page/BuyLesson/BuyLesson';
import Landing from './page/Landing/Landing';
import Balance from './page/Balance/Balance';
import Loader from './component/Loader/Loader';
import ForgotPassword from './page/ForgotPassword/ForgotPassword';
import AdminPanel from './page/Admin';
import ClassRoom from './page/ClassRoom/ClassRoom';
import ChildInvitation from './page/ChildInvitation/ChildInvitation';
import MyChildren from './page/MyChildren/MyChildren';
import ChildProfile from './page/ChildProfile/ChildProfile';
import SignUpChild from './page/SignUpChild/SignUpChild';
import ChildPasswordSetting from './page/ChildPasswordSetting/ChildPasswordSetting';
import CoachBalance from './page/CoachBalance/CoachBalance';
import TermsAndConditions from './page/TermsAndConditions/TermsAndConditions';
// import Books from 'page/Books/Books';
// import Book from 'page/Book/Book';
import Auth from '@aws-amplify/auth';
import { useProfileQuery, RolesEnum, useUpdateTimezoneOffsetMutation, useListInstrumentsQuery } from './api';
// import LandingFooter from 'page/Landing/LandingFooter/LandingFooter';
// import instrument from './page/Courses/Filters/Instrument';
// import { log } from 'util';
import { instrumentRoutes } from './utils/constans';

type UserData = {
  email: string;
  password: string;
};

const isAdmin = (user: any) => user?.data?.getProfile?.role === 'ADMIN';

const useImperativeProfile = () => {
  const profileQuery = useProfileQuery({
    fetchPolicy: 'network-only',
  });
  const getProfile = useCallback(
    async (auth: any) => {
      const user = await profileQuery.refetch({
        id: auth.attributes.sub,
        timezoneOffset: new Date().getTimezoneOffset(),
      });
      return user;
    },
    [profileQuery],
  );
  return getProfile;
};

const booksRoute = '/books';
const allowedRoutes = [
  '/signin',
  '/signup',
  '/terms-and-conditions',
  '/signup-child',
  '/child-password-setting',
  '/pw-reset',
  '/coaches',
  '/',
  booksRoute,
  ...instrumentRoutes
];

const fetchRole = async (email: string): Promise<string | null> => {
  try {
  const response = await fetch(process.env.REACT_APP_GRAPHQL as string, {
    method: 'POST',
    body: JSON.stringify({
                         operationName: 'getUserRole',
                           variables: { email }
                         })
        });
  const { role } = await response.json();
  return role || null;
  } catch (err) {
    return null;
  }
};

export default function Router() {
  const [isUser, setIsUser] = useState(false);
  const [isUserAdmin, setUserAdmin] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState(false);
  const [user, setUser] = useState<any>(null);
  const history = useHistory();
  const location = useLocation();
  const getProfile = useImperativeProfile();
  const [updateTimezoneOffsetMutation] = useUpdateTimezoneOffsetMutation();
  const instrumentsList = useListInstrumentsQuery().data?.listInstruments;

  const reGetUser = async () => {
    const auth = await Auth.currentAuthenticatedUser();
    const userData = await getProfile(auth);
    setUser(userData.data.getProfile);
  };

  const getAuth = useCallback(async () => {
    try {
      setIsLoader(true);
      const auth = await Auth.currentAuthenticatedUser();
      await Auth.currentCredentials();
      const userData = await getProfile(auth);
      setUser(userData.data.getProfile);
      setIsUser(true);
      if (
        !isAdmin(userData) &&
        userData.data.getProfile?.instruments?.length === 0 &&
        userData.data.getProfile?.birthdate === null
      ) {
        if (userData.data.getProfile?.children?.length! === 0) {
          if (location.pathname !== '/invite-children') {
            history.push('/personal-info');
          }
        }
      }

      setIsLoader(false);
    } catch (e) {
      setIsUser(false);
      if (
        !allowedRoutes.includes(location.pathname) &&
        location.pathname !== booksRoute &&
        !location.pathname.startsWith('/coach')
      ) {
        history.push('/signup');
      }
      setIsLoader(false);
    }
  }, [getProfile, history, location.pathname]);

  const signOut = useCallback(
    async (callBack, withRedirect = true) => {
      try {
        await Auth.signOut();
        setUserAdmin(false);
        if (withRedirect) {
          history.push('/signin');
        }
        setUser(null);
        setIsUser(false);
        Cookies.remove('jwt');
        if (callBack) {
          callBack();
        }
      } catch (error: any) {
        throw Error(error);
      }
    },
    [history],
  );

  const handleGetProfile = async (token: string, credentials: any) => {
    Cookies.set('jwt', token);
    const userData = await getProfile(credentials);
    setUser(userData.data.getProfile);
    setIsUser(true);
  };

  const signIn = useCallback(
    async (values: UserData) => {
      let token;
      try {
        const auth = await Auth.signIn(values.email.toLowerCase(), values.password);
        token = auth?.signInUserSession?.idToken?.jwtToken;
        if (token) {
          Cookies.set('jwt', token);
          const userData = await getProfile(auth);
          setUser(userData.data.getProfile);
          setIsUser(true);
          if (!isAdmin(userData) && userData.data.getProfile?.instruments === null) {
            history.push('/personal-info');
          } else {
            if (userData.data.getProfile?.role === RolesEnum.Coach) {
              history.push('/lessons');
            } else {
              history.push('/coaches');
            }
          }
        }
        setIsLoader(false);

        updateTimezoneOffsetMutation({
          variables: {
            timezoneOffset: new Date().getTimezoneOffset(),
          },
        });

        return 'SignIn';
      } catch (e: any) {
        // tslint:disable-next-line: no-console
        console.log('SingIn::e.message', e.message);
        // tslint:disable-next-line: no-console
        console.log('SingIn::e', e);
        setIsLoader(false);
        const role = await fetchRole(values.email.toLowerCase());
        if (e.message === 'User is not confirmed.') {
          history.push('/signup', {
            notConfirmed: true,
            notConfirmedUserRole: role,
            email: values.email.toLowerCase(),
            password: values.password,
          });
        } else {
          return 'Error';
        }
        // tslint:disable-next-line: no-console
        console.log(e);
      }
    },
    [getProfile, history, updateTimezoneOffsetMutation],
  );

  useEffect(() => {
    getAuth();
  }, [history, location.pathname, instrumentsList]);

  useEffect(() => {
    if (user?.role === 'ADMIN' && !isUserAdmin) {
      setUserAdmin(true);
    }
  }, [isUserAdmin, user]);

  if (isLoader) {
    return <Loader fullScreen={true} />;
  }

  if (isUserAdmin && user?.role === 'ADMIN') {
    return <AdminPanel user={user} signOut={signOut} />;
  }

  // console.log('router res 1');
  const res =  (
    <>
      <Switch>
        <Route path="/signin">
          <Header user={user} signOut={signOut} />
          <SignIn signIn={signIn} />
        </Route>
        <Route path="/signup">
          <Header user={user} signOut={signOut} />
          <SignUp getAuth={getAuth} signIn={signIn} />
        </Route>
        <Route path="/signup-child">
          <Header user={user} signOut={signOut} />
          <SignUpChild user={user} signOut={signOut} />
        </Route>
        <Route path="/child-password-setting">
          <Header user={user} signOut={signOut} />
          <ChildPasswordSetting user={user} handleGetProfile={handleGetProfile} />
        </Route>
        <Route path="/personal-info">
          <Header user={user} signOut={signOut} />
          <Profile user={user} reGetUser={reGetUser} />
        </Route>
        <Route path="/coaches">
          {user?.role === RolesEnum.Coach ? (
            <Redirect to="/" />
          ) : (
            <>
              <Header user={user} signOut={signOut} />
              <Courses user={user} />
            </>
          )}
        </Route>
        {instrumentsList?.map((instrument) =>
           (
              <Route key={instrument?.slug} path={`/${instrument?.slug}-teachers`}>
                <Header user={user} signOut={signOut}/>
                <Courses user={user} instrument={instrument}/>
              </Route>
          )
        )}
        <Route path="/pw-reset">
          <Header user={user} signOut={signOut} />
          <ForgotPassword />
        </Route>
        <Route path="/coach/:id">
          <Header user={user} signOut={signOut} />
          <Coach user={user} />
        </Route>
        <Route path="/lessons">
          <Header user={user} signOut={signOut} />
          <Lessons user={user} />
        </Route>
        <Route path="/lesson/:id">
          <Header user={user} signOut={signOut} />
          <Lesson user={user} />
        </Route>
        <Route path="/calendar">
          <Header user={user} signOut={signOut} />
          <Calendar user={user} reGetUser={reGetUser} />
        </Route>
        <Route path="/buy-lesson">
          <Header user={user} signOut={signOut} />
          <BuyLesson reGetUser={reGetUser} user={user} />
        </Route>
        <Route path="/balance">
          <Header user={user} signOut={signOut} />
          {user?.role === RolesEnum.Coach ? (
            <CoachBalance user={user} />
          ) : (
            <Balance user={user} reGetUser={reGetUser} />
          )}
        </Route>
        <Route path="/class-room">
          <ClassRoom user={user} />
        </Route>
        <Route path="/my-children">
          <Header user={user} signOut={signOut} />
          <MyChildren user={user} />
        </Route>
        <Route path="/invite-children">
          <Header user={user} signOut={signOut} />
          <ChildInvitation user={user} />
        </Route>
        <Route path="/terms-and-conditions">
          <Header user={user} signOut={signOut} />
          <TermsAndConditions user={user} />
        </Route>
        <Route path="/edit-child-profile/:id">
          <Header user={user} signOut={signOut} />
          <ChildProfile user={user} isParent />
        </Route>
        {/*<Route exact path="/books">*/}
        {/*  <Header user={user} signOut={signOut} />*/}
        {/*  <Books user={user} />*/}
        {/*  <LandingFooter user={user} />*/}
        {/*</Route>*/}
        {/*<Route path="/books/:id/:lessonId">*/}
        {/*  <Header user={user} signOut={signOut} />*/}
        {/*  <Book user={user} />*/}
        {/*  <LandingFooter user={user} />*/}
        {/*</Route>*/}
        {/*<Route path="/books/:id/">*/}
        {/*  <Header user={user} signOut={signOut} />*/}
        {/*  <Book user={user} />*/}
        {/*  <LandingFooter user={user} />*/}
        {/*</Route>*/}
        <Route path="/">
          <Header user={user} signOut={signOut} />
          <Landing user={user} />
        </Route>
      </Switch>
    </>
  );
  // console.log('router ret', !!res);
  return res;
}
