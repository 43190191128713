import React, { FC } from 'react';
import { ChildrenProfile } from 'api';
import AvatarUploader from 'component/AvatarUploader/AvatarUploader';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import Loader from 'component/Loader/Loader';
import Experience from '../PersonalInfo/Experience';
import MainInfo from '../PersonalInfo/MainInfo';
import './ChildProfile.scss';
import { useChildProfile } from './hooks';
import Modal from '@material-ui/core/Modal';
import ConfirmationModal from '../PersonalInfo/Modal/ConfirmationModal';

type Props = {
  user: ChildrenProfile;
  isParent: boolean;
};

const ChildProfile: FC<Props> = ({ user, isParent = false }) => {
  const {
    values,
    errors,
    child,
    instruments,
    loadingProfile,
    childUpdating,
    isSavedModalOpen,
    setIsSavedModalOpen,
    handleChangeLessons,
    handleChangeLevel,
    handleDeleteLesson,
    handleChangeText,
    handleSubmit,
    updateChildProfile,
    refetchChild,
    handleChangeBirthdate,
  } = useChildProfile();

  if (loadingProfile) {
    return (
      <div className="child-profile-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <form className="child-profile">
        <div className="child-profile_avatar">
          <AvatarUploader user={child} reGetUser={refetchChild} updateUser={updateChildProfile} isChild />
        </div>
        <MainInfo
          errors={{ firstName: errors.firstName, lastName: errors.lastName, email: errors.email, birthdate: errors.birthdate, workWithAges: ''}}
          values={values}
          handleChange={handleChangeText}
          user={child}
          handleChangeBirthdate={handleChangeBirthdate}
        />
        <Experience
          user={child}
          lessons={values.lessons}
          handleChangeLessons={handleChangeLessons}
          handleChangeLevel={handleChangeLevel}
          handleDeleteLesson={handleDeleteLesson}
          level={values.level}
          intrument={instruments!}
          handleChange={handleChangeText}
          values={values}
          errors={errors}
          isAuthenticated={true}
          isParent={isParent}
        />
        <Button
          disabled={childUpdating}
          onClick={handleSubmit}
          type="submit"
          className="child-profile_btn"
          contained={true}
        >
          Save
        </Button>
      </form>
      <Modal open={isSavedModalOpen} onClose={() => setIsSavedModalOpen(false)} className="profile_modal">
        <ConfirmationModal
          title="Saved"
          handleOnOkClick={() => setIsSavedModalOpen(false)}
          handleClose={() => setIsSavedModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ChildProfile;
