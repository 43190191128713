import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from '../../modules/routes';
import BooksList from './BooksList/BooksList';
import Book from './Book/Book';
import NewBook from './NewBook/NewBook';

const Books = () => {
  return (
    <Switch>
      <Route exact key={routes.adminBooks} path={routes.adminBooks}>
        <BooksList />
      </Route>
      <Route exact key={routes.adminNewBook} path={routes.adminNewBook}>
        <NewBook />
      </Route>
      <Route exact key={routes.adminBook} path={routes.adminBook}>
        <Book />
      </Route>
    </Switch>
  );
};

export default Books;
