import React, { useCallback, useMemo, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import Tooltip from '@material-ui/core/Tooltip';
import UserAvatar from '../UserAvatar/UserAvatar';
import { RolesEnum, Profile } from 'api/index';
import moment from 'moment';
import { isAvatar, getAvatar } from 'utils/image';
import './Calendar.scss';

const availableTimeAdult = [
  {
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    startTime: `${moment().hour(0)}:${moment().minute(0)}`,
    endTime: `${moment().hour(23)}:${moment().minute(59)}`,
  },
];

const availableTimeCoach = [
  {
    daysOfWeek: [],
    startTime: `${moment().hour(0)}:${moment().minute(0)}`,
    endTime: `${moment().hour(23)}:${moment().minute(59)}`,
  },
];

const sortLessons = (a, b) => {
  if (a?.startAt !== null && b?.startAt !== null && a?.startAt !== undefined && b?.startAt !== undefined) {
    if (+a?.startAt > +b?.startAt) {
      return 1;
    }
    if (+a?.startAt < +b?.startAt) {
      return -1;
    }
  }
  return 0;
};

interface CalendarProps {
  handleDateSelect: any;
  handleEventClick: any;
  handleEvents: any;
  dataB: any[];
  lesson: any[];
  role: RolesEnum;
  selectable: boolean;
  lessonBook: any[];
  admin: boolean;
  user?: Profile;
  coach?: Profile;
  handleDateClick?: (data: DateClickArg) => void;
  selectMinDistance?: number;
  listCoachCalendarSlots?: any[];
}

const Calendar = ({
  handleDateSelect,
  handleEventClick,
  handleEvents,
  dataB,
  role,
  lesson,
  selectable,
  lessonBook,
  admin,
  user,
  handleDateClick,
  selectMinDistance,
  coach,
  listCoachCalendarSlots
}: CalendarProps) => {
  // console.log('calendar', user, role);
  const [calendarDate, setCalendarDate] = useState<any>(null);
  // const listCoachCalendarSlots = useListCoachCalendarSlotsQuery({
  //   variables: {
  //     coachId: coach?.id!,
  //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  //   },
  // }).data?.listCoachCalendarSlots?.slots?.map((s) => ({
  //   ...s,
  //   since: new Date(s.since).getTime(),
  //   until: new Date(s.until).getTime(),
  // }));

  const handleDateSet = (date) => {
    setCalendarDate({ start: date?.startStr, end: date?.endStr });
  };

  const returnBusinessHours = () => {
    if (coach && dataB.length === 0) {
      return availableTimeCoach;
    }
    let businessHours: any[] = [
      {
        daysOfWeek: [],
      },
    ];
    if (dataB?.length) {
      businessHours = [];
      dataB.map((date) => {
        const obj = {
          daysOfWeek: [moment(+date?.since).day()],
          startTime: `${moment(+date.since).hour()}:${moment(+date.since).minute()}0`,
          endTime: `${moment(+date.until).hour()}:${moment(+date.until).minute()}0`,
        };
        if (calendarDate) {
          if (
            moment(+date.since).format('x') >= moment(calendarDate.start).format('x') &&
            moment(+date.since).format('x') <= moment(calendarDate.end).format('x')
          ) {
            businessHours.push(obj);
          }
        }
      });
      if (businessHours.length === 0) {
        businessHours = [
          {
            daysOfWeek: [],
          },
        ];
      }
    }
    return dataB?.length ? businessHours : availableTimeAdult;
  };

  const returnLesson = useCallback(() => {
    const arr: any[] = [];
    // console.log('returnLesson::listCoachCalendarSlots?.length', listCoachCalendarSlots?.length);
    if (listCoachCalendarSlots?.length !== 0) {
      const cutListCoachCalendarSlots = listCoachCalendarSlots
          ?.filter((item) => moment(+item?.since!).isSameOrAfter(moment().add(24, 'hours')))
      cutListCoachCalendarSlots?.forEach((item, i) => {
          // console.log('item', item, i);
          const nextItem = cutListCoachCalendarSlots[i + 1];
          const nextItemIsAdjuscent = nextItem?.since === item?.until;
          const nextSlotAllowsOneHourSubscription = nextItemIsAdjuscent && nextItem?.type === 'subscriptionAvailable';
          if (item.type === 'subscriptionAvailable') {
            arr.push({
              // t: 'subs',
              start: moment(+item?.since!)
                .utc()
                .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              end: moment(+item?.until!)
                .utc()
                .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              editable: false,
              display: 'block',
              classNames: 'available_for_subscription',
              extendedProps: {
                type: item.type,
                oneHourSubscriptionAvailable: nextSlotAllowsOneHourSubscription,
                oneHourOneTimeLessonAvailable:
                  (nextItemIsAdjuscent && nextItem?.type === 'oneTimeLessonAvailable')
                  || nextSlotAllowsOneHourSubscription
              }
            });
          }
          if (item.type === 'oneTimeLessonAvailable') {
            arr.push({
              // t: '1tim',
              start: moment(+item?.since!)
                .utc()
                .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              end: moment(+item?.until!)
                .utc()
                .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              editable: false,
              display: 'block',
              classNames: 'available_for_one_time',
              extendedProps: {
                type: item.type,
                oneHourSubscriptionAvailable: false,
                oneHourOneTimeLessonAvailable:
                  (nextItemIsAdjuscent && nextItem.type === 'oneTimeLessonAvailable')
                  || nextSlotAllowsOneHourSubscription
              }
            });
          }
        });
    }
    arr.push({
      t: '-',
      start: moment().add(-1, 'years').utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      end: moment()
        .minute(moment().minute() > 30 ? 59 : 0)
        .add(24, 'hours')
        .utc()
        .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
      editable: false,
      display: 'background',
      classNames: 'fc-non-business',
    });
    // console.log('returnLesson:::lesson.length', lesson.length);
    if (lesson.length !== 0) {
      lesson.map((item) => {
        const oneTimeLesson = item.periodType === 'ONE_TIME' ? 'one-time-lesson' : '';
        const subscriptionLesson = item.periodType === 'SUBSCRIPTION' ? 'subscription-lesson' : '';
        const arrItem = {
          // t: 'lesson',
          id: item.id,
          title: item.topic,
          start: moment(+item.startAt)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          end: moment(+item.finishAt)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          editable: false,
          display: 'block',
          classNames: `${oneTimeLesson} ${subscriptionLesson}`,
        };
        // if (arrItem.start === '1970-01-01T00:00:00.000Z') {
        //   console.log('item', item);
        //   console.log('arrItem', arrItem);
        //   console.log('---');
        // }
        arr.push(arrItem);
      });
    }

    // console.log('returnLesson:::lessonBook.length', lessonBook.length);
    // Important! All lessons of teacher.
    if (lessonBook.length !== 0) {
      lessonBook.map((item) => {
        const oneTimeLesson = item.periodType === 'ONE_TIME' ? 'one-time-lesson' : '';
        const subscriptionLesson = item.periodType === 'SUBSCRIPTION' ? 'subscription-lesson' : '';
        arr.push({
          // t: 'lessonbook',
          id: item.id,
          start: moment(+item.startAt)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          end: moment(+item.finishAt)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          editable: false,
          backgroundColor: '#E0E0E0',
          display: !!user?.children?.find((c) => item?.studentProfile?.id === c?.id) ? 'block' : 'background',
          classNames: !!user?.children?.find((c) => item?.studentProfile?.id === c?.id) ? `${oneTimeLesson} ${subscriptionLesson}` : '',
        });
      });
    }
    // // DF debugigng code left behind
    // const fname = '_e33';
    // const lc = localStorage.getItem(fname);
    // const lcJson = lc ? JSON.parse(lc) : {};
    // const newlc = { ...lcJson, [new Date().toISOString()]: arr };
    // console.log('newlc', newlc);
    // localStorage.setItem(fname, JSON.stringify(newlc));
    return arr;
  }, [lesson, lessonBook, listCoachCalendarSlots, user]);

  const childrenLessons = useMemo(() => {
    return lessonBook.filter((bookLesson) => {
      return user?.children?.some((child) => child?.id === bookLesson?.studentProfile?.id);
    });
  }, [lessonBook, user]);

  const returnTooltip = (data) => {
    let lessonWithOneCoach: any[] = [];
    if (lesson.length !== 0) {
      lessonWithOneCoach = lesson.filter((item) => item?.coachProfile?.id === data?.coachProfile?.id);
      lessonWithOneCoach.sort(sortLessons);
    }
    const userLessonNumber = lessonWithOneCoach.findIndex((item) => item.id === data?.id) + 1;
    const childLessonNumber = [...childrenLessons].sort(sortLessons).findIndex((item) => item.id === data?.id) + 1;
    if (userLessonNumber === 0 && childLessonNumber === 0) {
      return '';
    }
    return (
      <div className="calendar_tooltip">
        <p className="calendar_tooltip_lesson">Lesson: {userLessonNumber || childLessonNumber}</p>
        <p className="calendar_tooltip_topic">{data?.topic || ''}</p>
        {role !== RolesEnum.Coach && (
          <div className="calendar_tooltip_coach">
            <UserAvatar
              classes="calendar_tooltip_coach_avatar"
              avatar={
                data?.coachProfile?.files
                  ? isAvatar(data?.coachProfile.files)
                    ? getAvatar(data?.coachProfile.files)
                    : ''
                  : ''
              }
              firstName={data?.coachProfile.firstName}
              lastName={data?.coachProfile.lastName}
            />
            <p className="calendar_tooltip_coach_name">
              {data?.coachProfile?.firstName || ''} {data?.coachProfile?.lastName || ''}
            </p>
          </div>
        )}
        {role === RolesEnum.Coach ? (
          <div className="calendar_tooltip_coach">
            <UserAvatar
              classes="calendar_tooltip_coach_avatar"
              avatar={
                data?.studentProfile?.files
                  ? isAvatar(data?.studentProfile?.files)
                    ? getAvatar(data?.studentProfile?.files)
                    : ''
                  : ''
              }
              firstName={data?.studentProfile?.firstName}
              lastName={data?.studentProfile?.lastName}
            />
            <p className="calendar_tooltip_coach_name">
              {data?.studentProfile?.firstName || ''} {data?.studentProfile?.lastName || ''}
            </p>
          </div>
        ) : (
          role === RolesEnum.Adult &&
          data?.studentProfile?.__typename !== 'ParentProfile' && (
            <div className="calendar_tooltip_coach">
              <UserAvatar
                classes="calendar_tooltip_coach_avatar"
                avatar={
                  data?.studentProfile?.files
                    ? isAvatar(data?.studentProfile?.files)
                      ? getAvatar(data?.studentProfile?.files)
                      : ''
                    : ''
                }
                firstName={data?.studentProfile?.firstName}
                lastName={data?.studentProfile?.lastName}
              />
              <p className="calendar_tooltip_coach_name">
                {data?.studentProfile?.firstName || ''} {data?.studentProfile?.lastName || ''}
              </p>
            </div>
          )
        )}
      </div>
    );
  };

  const renderEventContent = (eventInfo) => {
    const userLesson = lesson.find((item) => item.id === eventInfo.event.id);
    const childLesson = childrenLessons.find((item) => item.id === eventInfo.event.id);
    const tooltip = returnTooltip(userLesson || childLesson);
    return (
      <Tooltip disableHoverListener={eventInfo.event.display === 'background'} title={tooltip} interactive>
        <div className={eventInfo.event.display === 'background' ? 'fc-booked-event' : ''}>
          <b className="fc-event-time">{eventInfo.timeText}</b>
          <i className="fc-event-title">{eventInfo?.event?.title !== 'null' ? eventInfo?.event?.title : ''}</i>
        </div>
      </Tooltip>
    );
  };

  return (
    <FullCalendar
      allDaySlot={false}
      events={returnLesson()}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      headerToolbar={{
        right: 'timeGridWeek,timeGridDay prev,next,today',
      }}
      initialView="timeGridWeek"
      editable={true}
      selectable={selectable}
      selectMirror={true}
      dayMaxEvents={true}
      weekends={true}
      selectOverlap={false}
      selectAllow={(e) => {
        if (e.end.getTime() / 1000 - e.start.getTime() / 1000 <= 4000) {
          return true;
        } else {
          return false;
        }
      }}
      datesSet={handleDateSet}
      dateClick={handleDateClick}
      selectMinDistance={selectMinDistance}
      select={handleDateSelect}
      eventContent={renderEventContent}
      eventClick={handleEventClick}
      eventsSet={handleEvents}
      selectConstraint="businessHours"
      businessHours={returnBusinessHours()}
      views={{
        dayGridMonth: {
          eventTimeFormat: {
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'lowercase',
          },
        },
      }}
      buttonText={{
        today: 'Today',
        month: 'Month',
        week: 'Week',
        day: 'Day',
        list: 'List',
      }}
    />
  );
};

export default Calendar;
