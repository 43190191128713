import { ProfileSortByEnum, useAdminListAdultQuery } from 'api/index';
import React, { useCallback } from 'react';
import Loader from '../../components/CustomLoader';
import BasicPagination from '../../components/BasicPagination';
import './Adults.scss';
import BasicTable from './components/BasicTable';

export default function AdultsList() {
  const [limit, setLimit] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);

  const listAdultsQuery = useAdminListAdultQuery({
    variables: {
      limit: 10,
      offset: 0,
      // order: SortOrderEnum.Asc,
    },
  });

  const rows = listAdultsQuery?.data?.adminListAdult?.data || [];
  const total = listAdultsQuery?.data?.adminListAdult?.meta?.count || 0;
  const loading = listAdultsQuery?.loading;

  const requestList = useCallback(
    (page, limit) => {
      let variables = {
        limit: limit,
        offset: page * limit,
        sortBy: ProfileSortByEnum.CreatedAt,
      };
      listAdultsQuery.refetch(variables);
    },
    [listAdultsQuery],
  );

  const rowPage = React.useCallback(
    (event) => {
      setLimit(event.target.value);
      setPage(0);
      requestList(0, event.target.value);
    },
    [requestList],
  );

  const nextPage = React.useCallback(
    (e, np) => {
      setPage(np);
      requestList(np, limit);
    },
    [requestList, limit],
  );

  const onChangePage = (e, numberOfPage) => {
    global.window && (global.window as any).scrollTo(0, 0);
    nextPage(e, numberOfPage);
  };

  const onPageClick = (e: React.ChangeEvent<unknown>, value: number) => {
    const page = value - 1;
    nextPage(e, page);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="adults_list">
      <div className="adults_list_title">Adults</div>
      <BasicTable rows={rows} />
      <BasicPagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        onChangeRowsPerPage={rowPage}
        onChangePage={onChangePage}
        onPageClick={onPageClick}
        total={total}
        limit={limit}
        page={page}
      />
    </div>
  );
}
