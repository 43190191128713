import React from 'react';
import cn from 'classnames';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CouponType, Maybe, CouponTypeEnum } from 'api';
import './BasicTable.scss';

interface BasicTableProps {
  rows: Maybe<Array<CouponType>>;
}

export default function BasicTable({ rows }: BasicTableProps) {
  const getCurrentStatus = (row: CouponType) => {
    if (row.deletedAt) {
      return 'deactivated';
    } else {
      return 'active';
    }
  };

  const getCurrentType = ({ type }: CouponType) =>
    ({
      [CouponTypeEnum.FixedPrice]: '$',
      [CouponTypeEnum.Percent]: '%',
      [CouponTypeEnum.FirstLesson]: '%',
    }[type] || '$');

  const getAmount = (row: CouponType) => (row.type === CouponTypeEnum.FixedPrice ? row.amount / 100 : row.amount * 100);

  return (
    <TableContainer component={Paper} elevation={0} className="coupon_basic_table_container">
      <Table className="coupon_basic_table" aria-label="simple table">
        <TableHead>
          <TableRow className="coupon_basic_table_row">
            <TableCell>Name</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Type (%,$)</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow className="coupon_basic_table_row" key={row?.id}>
              <TableCell>{row?.text || ''}</TableCell>
              <TableCell>{row?.code || ''}</TableCell>
              <TableCell>{getCurrentType(row)}</TableCell>
              <TableCell>{getAmount(row)}</TableCell>
              <TableCell
                className={cn({
                  deleted: row?.deletedAt,
                })}
              >
                {getCurrentStatus(row)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
