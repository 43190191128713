// Core
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './Loader.scss';

const Loader = ({ center, fullScreen, small, overlay, overlayShrinked }) => (
  <div
    className={classNames('sk-loader__wrapper', {
      'sk-loader__wrapper_center': center,
      'sk-loader__wrapper_fullScreen': fullScreen,
      'sk-loader__wrapper_overlay': overlay,
      'sk-loader__wrapper_overlay_shrinked': overlayShrinked,
    })}
  >
    <div
      className={classNames('sk-loader__spinner', {
        'sk-loader__spinner_small': small,
        'sk-loader__wrapper_overlay': overlay,
      })}
    >
      <div className="sk-loader__dot1" />
      <div className="sk-loader__dot2" />
    </div>
  </div>
);

Loader.propTypes = {
  center: PropTypes.bool,
  fullScreen: PropTypes.bool,
  small: PropTypes.bool,
  overlay: PropTypes.bool,
  overlayShrinked: PropTypes.bool,
};

export default Loader;
