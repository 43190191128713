import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import Chip from '@material-ui/core/Chip';
import { Close as DeleteIcon } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Instruments } from 'api/index';
import { formatCamelCaseString, sliceInstrumentNames } from 'utils/helpers';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from 'utils/enums';
import { useChildInvitation } from './hooks';
import cn from 'classnames';
import './ChildInvitation.scss';

const headerImg = '/assets/child_invite_head.png';

const ChildInvitation = ({ user }) => {
  const {
    values,
    errors,
    instruments,
    invitationPending,
    handleChangeSelect,
    handleChangeText,
    handleKeyPress,
    handleDeleteLesson,
    handleSubmit,
    handleChangeBirthdate,
  } = useChildInvitation();
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)` });

  return (
    <div className="child_invitation">
      <h6 className="child_invitation_title">
        Do you want your child to become a great musician, or have fun learning a new skill?
      </h6>
      <p className="child_invitation_subtitle">Add your child to Best Music Coach.</p>
      <img className="child_invitation_img" src={headerImg} alt="invite_icon" />
      <form className="child_invitation_form">
        {/* <TextField
          className={`loginFormField ${errors.email ? 'loginFormFieldError' : ''}`}
          variant="outlined"
          fullWidth={true}
          label="Enter child’s email (optional)"
          onKeyPress={handleKeyPress}
          name="email"
          onChange={handleChangeEmail}
          error={!!errors.email}
          helperText={errors.email ? errors.email : ''}
          value={values.email ?? ''}
        /> */}
        <TextField
          className={`loginFormField ${errors.firstName ? 'loginFormFieldError' : ''}`}
          variant="outlined"
          fullWidth={true}
          label="First name"
          required={true}
          onKeyPress={handleKeyPress}
          name="firstName"
          onChange={handleChangeText}
          error={!!errors.firstName}
          helperText={errors.firstName ? errors.firstName : ''}
          value={values.firstName ?? ''}
        />
        <TextField
          className={`loginFormField ${errors.lastName ? 'loginFormFieldError' : ''}`}
          variant="outlined"
          fullWidth={true}
          label="Last name"
          required={true}
          onKeyPress={handleKeyPress}
          name="lastName"
          onChange={handleChangeText}
          error={!!errors.lastName}
          helperText={errors.lastName ? errors.lastName : ''}
          value={values.lastName ?? ''}
        />
        <KeyboardDatePicker
          disabled={false}
          error={!!errors.birthdate}
          className={`loginFormField MuiInputBase-fullWidth loginFormField-datePicker ${errors.birthdate ? 'loginFormFieldError' : ''}`}
          autoOk={true}
          variant="inline"
          inputVariant="outlined"
          name="birthdate"
          label="Birth date"
          openTo="year"
          helperText={errors.birthdate ? errors.birthdate : ''}
          format="MMM DD,yyyy"
          value={values.birthdate || ''}
          InputAdornmentProps={{ position: 'start' }}
          onChange={handleChangeBirthdate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <FormControl className="child-lesson-select" fullWidth={true} required={true}>
          <InputLabel className="selectLabel" id="demo-simple-select-label1">
            Lessons
          </InputLabel>
          <Select
            MenuProps={{ variant: 'menu' }}
            labelId="demo-simple-select-label1"
            className="select-input-box"
            input={
              <OutlinedInput
                notched={true}
                className={cn('select-input-box', {
                  'select-input-box--empty': !values.lessons.length,
                })}
              />
            }
            multiple
            label="lessons"
            name="lessons"
            value={values.lessons}
            error={!!errors.lessons}
            onChange={handleChangeSelect}
            renderValue={(value) => {
              if (value) {
                const selected: any = value as Instruments;
                if (selected.length) {
                  const { lastElementIndex, selectedSlice } = sliceInstrumentNames(selected, isMobile);
                  return (
                    <>
                      {selectedSlice.map((i) => (
                        <Chip
                          key={i.id}
                          label={formatCamelCaseString(i.name)}
                          className="select-chip"
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => handleDeleteLesson(i)}
                          deleteIcon={<DeleteIcon fontSize="small" className="select-chip_icon" />}
                        />
                      ))}
                      {lastElementIndex !== -1 && <span> ...</span>}
                    </>
                  );
                } else {
                  return '';
                }
              } else {
                return '';
              }
            }}
          >
            {instruments.map((item) => (
              <MenuItem key={item?.id} value={item as any}>
                <Checkbox checked={!!values.lessons.find((i: Instruments) => i.id === item?.id)} />
                {item?.name.replace(/([A-Z])/g, ' $1').trim()}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className="child_invitation_form_formError">{errors.lessons}</FormHelperText>
        </FormControl>
        <FormControl fullWidth={true} required={true}>
          <InputLabel className="selectLabel" id="demo-simple-select-label">
            Level
          </InputLabel>
          <Select
            MenuProps={{ variant: 'menu' }}
            labelId="demo-simple-select-label"
            className="lessonsFormField"
            input={<OutlinedInput notched={true} />}
            name="level"
            label="Level"
            value={values.level ?? []}
            error={!!errors.level}
            onChange={handleChangeSelect}
          >
            {['Beginner', 'Intermediate', 'Advanced'].map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className="child_invitation_form_formError">{errors.level}</FormHelperText>
        </FormControl>
        <Button
          disabled={invitationPending}
          onClick={handleSubmit}
          type="submit"
          className="child_invitation_form_btn"
          contained={true}
        >
          Invite
        </Button>
      </form>
    </div>
  );
};

export default ChildInvitation;
