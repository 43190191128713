import UrlParse from 'url-parse';
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware as applyReduxMiddleware, createStore as createReduxStore } from 'redux';
import thunk from 'redux-thunk';
// import { createLogger as createReduxLogger } from 'redux-logger';
import randomString from 'random-string';
import * as faceapi from 'face-api.js';
import Logger from './Logger';
import * as utils from './utils';
import randomName from './randomName';
import deviceInfo from './deviceInfo';
import RoomClient from './RoomClient';
import RoomContext from './RoomContext';
import * as stateActions from './redux/stateActions';
import reducers from './redux/reducers';
import Room from './components/Room';

const logger = new Logger('');
const reduxMiddlewares = [thunk];

// if (process.env.NODE_ENV === 'development')
// {
// 	const reduxLogger = createReduxLogger(
// 		{
// 			duration  : true,
// 			timestamp : false,
// 			level     : 'log',
// 			logErrors : true
// 		});

// 	reduxMiddlewares.push(reduxLogger);
// }

let roomClient;
const store = createReduxStore(reducers, undefined, applyReduxMiddleware(...reduxMiddlewares));

if (global.window) {
  (global.window as any).STORE = store;
}

RoomClient.init({ store });

if ((global.window as any)?.innerWidth && (global.window as any)?.innerHeight) {
  require('domready')(async () => {
    logger.debug('DOM ready');

    await utils.initialize();

    run(null, null, null, null, null, null);
  });
}

const run = async (user, coach, history, lesson, pathReturn, lessonId) => {
  logger.debug('run() [environment:%s]', process.env.NODE_ENV);
  const urlParser = new UrlParse((global.window as any)?.location?.href, true);
  // const peerId = randomString({ length: 8 }).toLowerCase();
  const peerId = user?.id || randomString({ length: 8 }).toLowerCase();
  // let roomId = urlParser.query.roomId;
  let roomId = lesson?.id || lessonId;
  // let displayName = urlParser.query.displayName || (cookiesManager.getUser() || {}).displayName;
  let displayName = user?.firstName + ' ' + user?.lastName;
  const handler = urlParser.query.handler;
  const useSimulcast = urlParser.query.simulcast !== 'false';
  const useSharingSimulcast = urlParser.query.sharingSimulcast !== 'false';
  const forceTcp = urlParser.query.forceTcp === 'true';
  const produce = urlParser.query.produce !== 'false';
  const consume = urlParser.query.consume !== 'false';
  const forceH264 = urlParser.query.forceH264 === 'true';
  const forceVP9 = urlParser.query.forceVP9 === 'true';
  const svc = urlParser.query.svc;
  const datachannel = urlParser.query.datachannel !== 'false';
  const info = urlParser.query.info === 'true';
  const faceDetection = urlParser.query.faceDetection === 'true';
  const externalVideo = urlParser.query.externalVideo === 'true';
  const throttleSecret = urlParser.query.throttleSecret;

  // Enable face detection on demand.
  if (faceDetection) await faceapi.loadTinyFaceDetectorModel('/resources/face-detector-models');

  if (info) {
    // eslint-disable-next-line require-atomic-updates
    (global.window as any).SHOW_INFO = true;
  }

  if (throttleSecret) {
    // eslint-disable-next-line require-atomic-updates
    (global.window as any).NETWORK_THROTTLE_SECRET = throttleSecret;
  }

  if (!roomId) {
    roomId = randomString({ length: 8 }).toLowerCase();

    urlParser.query.roomId = roomId;
    // (global.window as any).history.pushState('', '', urlParser.toString());
  }

  // Get the effective/shareable Room URL.
  const roomUrlParser = new UrlParse((global.window as any).location.href, true);

  for (const key of Object.keys(roomUrlParser.query)) {
    // Don't keep some custom params.
    switch (key) {
      case 'roomId':
      case 'handler':
      case 'simulcast':
      case 'sharingSimulcast':
      case 'produce':
      case 'consume':
      case 'forceH264':
      case 'forceVP9':
      case 'forceTcp':
      case 'svc':
      case 'datachannel':
      case 'info':
      case 'faceDetection':
      case 'externalVideo':
      case 'throttleSecret':
        break;
      default:
        delete roomUrlParser.query[key];
    }
  }
  delete roomUrlParser.hash;

  const roomUrl = roomUrlParser.toString();

  let displayNameSet;

  // If displayName was provided via URL or Cookie, we are done.
  if (displayName) {
    displayNameSet = true;
  }
  // Otherwise pick a random name and mark as "not set".
  else {
    displayNameSet = false;
    displayName = randomName();
  }

  // Get current device info.
  const device = deviceInfo();

  store.dispatch(stateActions.setRoomUrl(roomUrl));

  store.dispatch(stateActions.setRoomFaceDetection(faceDetection));

  store.dispatch(
    stateActions.setMe({
      peerId,
      displayName: user?.firstName + ' ' + user?.lastName,
      displayNameSet,
      device,
    }),
  );

  roomClient = new RoomClient({
    roomId,
    peerId,
    displayName,
    device,
    handlerName: handler,
    useSimulcast,
    useSharingSimulcast,
    forceTcp,
    produce,
    consume,
    forceH264,
    forceVP9,
    svc,
    datachannel,
    externalVideo,
  });

  // NOTE: For debugging.
  // eslint-disable-next-line require-atomic-updates
  (global.window as any).CLIENT = roomClient;
  // eslint-disable-next-line require-atomic-updates
  (global.window as any).CC = roomClient;

  return (
    <Provider store={store}>
      <RoomContext.Provider value={roomClient}>
        <Room user={user} coach={coach} history={history} lesson={lesson} pathReturn={pathReturn} />
      </RoomContext.Provider>
    </Provider>
  );

  // render(
  // 	<Provider store={store}>
  // 		<RoomContext.Provider value={roomClient}>
  // 			<Room />
  // 		</RoomContext.Provider>
  // 	</Provider>,
  // 	document.getElementById('mediasoup-demo-app-container')
  // );
};

export default run;

// NOTE: Debugging stuff.
if (global.window) {
  (global.window as any).__sendSdps = function () {
    logger.warn('>>> send transport local SDP offer:');
    logger.warn(roomClient._sendTransport._handler._pc.localDescription.sdp);

    logger.warn('>>> send transport remote SDP answer:');
    logger.warn(roomClient._sendTransport._handler._pc.remoteDescription.sdp);
  };

  (global.window as any).__recvSdps = function () {
    logger.warn('>>> recv transport remote SDP offer:');
    logger.warn(roomClient._recvTransport._handler._pc.remoteDescription.sdp);

    logger.warn('>>> recv transport local SDP answer:');
    logger.warn(roomClient._recvTransport._handler._pc.localDescription.sdp);
  };

  let dataChannelTestInterval = null;

  (global.window as any).__startDataChannelTest = function () {
    let number = 0;

    const buffer = new ArrayBuffer(32);
    const view = new DataView(buffer);

    dataChannelTestInterval = (global.window as any).setInterval(() => {
      if ((global.window as any).DP) {
        view.setUint32(0, number++);
        roomClient.sendChatMessage(buffer);
      }
    }, 100);
  };

  (global.window as any).__stopDataChannelTest = function () {
    (global.window as any).clearInterval(dataChannelTestInterval);

    const buffer = new ArrayBuffer(32);
    const view = new DataView(buffer);

    if ((global.window as any).DP) {
      view.setUint32(0, Math.pow(2, 32) - 1);
      (global.window as any).DP.send(buffer);
    }
  };

  (global.window as any).__testSctp = async function ({ timeout = 100, bot = false } = {}) {
    let dp;

    if (!bot) {
      await (global.window as any).CLIENT.enableChatDataProducer();

      dp = (global.window as any).CLIENT._chatDataProducer;
    } else {
      await (global.window as any).CLIENT.enableBotDataProducer();

      dp = (global.window as any).CLIENT._botDataProducer;
    }

    logger.warn(
      '<<< testSctp: DataProducer created [bot:%s, streamId:%d, readyState:%s]',
      bot ? 'true' : 'false',
      dp.sctpStreamParameters.streamId,
      dp.readyState,
    );

    function send() {
      dp.send(`I am streamId ${dp.sctpStreamParameters.streamId}`);
    }

    if (dp.readyState === 'open') {
      send();
    } else {
      dp.on('open', () => {
        logger.warn('<<< testSctp: DataChannel open [streamId:%d]', dp.sctpStreamParameters.streamId);

        send();
      });
    }

    setTimeout(() => (global.window as any).__testSctp({ timeout, bot }), timeout);
  };

  setInterval(() => {
    if ((global.window as any).CLIENT._sendTransport) {
      (global.window as any).H1 = (global.window as any).CLIENT._sendTransport._handler;
      (global.window as any).PC1 = (global.window as any).CLIENT._sendTransport._handler._pc;
      (global.window as any).DP = (global.window as any).CLIENT._chatDataProducer;
    } else {
      delete (global.window as any).PC1;
      delete (global.window as any).DP;
    }

    if ((global.window as any).CLIENT._recvTransport) {
      (global.window as any).H2 = (global.window as any).CLIENT._recvTransport._handler;
      (global.window as any).PC2 = (global.window as any).CLIENT._recvTransport._handler._pc;
    } else {
      delete (global.window as any).PC2;
    }
  }, 2000);
}
