import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { routes } from '../../../modules/routes';

export type ChapterLinkState = {
  id: string;
  chapterId: string;
  order?: number;
  name?: string;
  path?: string;
  isCreated?: boolean;
};

export type BookChapterState = {
  id: string;
  name?: string;
  order?: number;
  isCreated?: boolean;
};

export type BookFormState = {
  name?: string;
  path?: string;
};

export type FormState = {
  book: BookFormState;
  chapters: BookChapterState[];
  links: ChapterLinkState[];
};

export enum Direction {
  UP,
  DOWN,
}

export type ItemIdsToRemove = {
  removedLinks: Array<{
    chapterId: string;
    linkIds: string[];
  }>;
  removedChapterIds: string[];
};

const initialBookState: BookFormState = {
  name: '',
  path: '',
};
const initialBookChapters: BookChapterState[] = [
  {
    id: uuidv4(),
    name: '',
    isCreated: true,
  },
];
const initialLinksState: ChapterLinkState[] = [
  {
    chapterId: initialBookChapters[0].id,
    id: uuidv4(),
    name: '',
    isCreated: true,
  },
];

const errorMessage = 'Something went wrong...';

export type UseBookForm = {
  initialState: FormState | undefined;
  removeBook?: () => Promise<void>;
  saveBook: (formData: FormState, itemIdsToRemove?: ItemIdsToRemove) => Promise<any>;
};

export const useBookForm = ({ saveBook, removeBook, initialState }: UseBookForm) => {
  const [bookState, setBookState] = useState(initialState?.book || initialBookState);
  const [chaptersState, setChaptersState] = useState(initialState?.chapters || initialBookChapters);
  const [linksState, setLinksState] = useState(initialState?.links || initialLinksState);
  const [removedLinks, setRemovedLinks] = useState<ItemIdsToRemove['removedLinks']>([]);
  const [removedChapterIds, setRemovedChapterIds] = useState<string[]>([]);

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const history = useHistory();

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalIsOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const handleRemoveBook = async () => {
    if (removeBook) {
      try {
        await removeBook();
        history.push(routes.adminBooks);
      } catch (e) {
        setFormErrorMessage(errorMessage);
        console.error(e);
      }
    }
  };

  const handleConfirm = useCallback(() => history.push(routes.adminBooks), [history]);

  const handleSetUrl = useCallback((url: string | undefined) => {
    setBookState((prev) => ({
      ...prev,
      path: url,
    }));
  }, []);

  const handleAddChapter = useCallback(() => {
    const newChapter = {
      id: uuidv4(),
      isCreated: true,
    };
    const newLink: ChapterLinkState = {
      id: uuidv4(),
      chapterId: newChapter.id,
      isCreated: true,
    };
    setChaptersState((prev) => [...prev, newChapter]);
    setLinksState((prev) => [...prev, newLink]);
  }, []);

  const handleAddChapterLink = useCallback(
    (chapterId: string, linkId: string) => () => {
      setLinksState((prev) => {
        const clickedLinkIndex = prev.findIndex(({ id }) => id === linkId);
        if (clickedLinkIndex !== -1) {
          return [
            ...prev.slice(0, clickedLinkIndex + 1),
            { id: uuidv4(), chapterId, isCreated: true },
            ...prev.slice(clickedLinkIndex + 1),
          ];
        }
        return [...prev, { id: uuidv4(), chapterId, isCreated: true }];
      });
    },
    [],
  );

  const handleRemoveChapterLink = useCallback(
    (chapterId: string, linkId: string) => () => {
      const clickedChapterLinkIndex = linksState.findIndex((link) => linkId === link.id);
      if (
        (!linksState[clickedChapterLinkIndex - 1] ||
          linksState[clickedChapterLinkIndex - 1].chapterId !== linksState[clickedChapterLinkIndex].chapterId) &&
        (!linksState[clickedChapterLinkIndex + 1] ||
          linksState[clickedChapterLinkIndex + 1].chapterId !== linksState[clickedChapterLinkIndex].chapterId)
      ) {
        const chapterToRemove = chaptersState.find((chapter) => chapterId !== chapter.id);
        if (chapterToRemove && !chapterToRemove.isCreated) {
          setRemovedChapterIds((prev) => [...prev, chapterId]);
        }
        setChaptersState((prev) => prev.filter((chapter) => chapterId !== chapter.id));
      }

      const linkToRemove = linksState.find((link) => linkId !== link.id);
      if (linkToRemove && !linkToRemove.isCreated) {
        setRemovedLinks((prev) => {
          const removedLinks = prev.find((linkToRemove) => linkToRemove?.chapterId === chapterId);
          if (removedLinks) {
            return prev.map((linkToRemove) => {
              if (linkToRemove?.chapterId === chapterId) {
                return {
                  ...linkToRemove,
                  linkIds: [...linkToRemove.linkIds, linkId],
                };
              }
              return linkToRemove;
            });
          }
          return [...prev, { chapterId: chapterId, linkIds: [linkId] }];
        });
      }
      setLinksState((prev) => prev.filter((link) => linkId !== link.id));
    },
    [chaptersState, linksState],
  );

  const handleMoveChapterLink = useCallback(
    (chapterId: string, linkId: string, direction: Direction) => () => {
      setLinksState((prev) => {
        let newLinks: Array<ChapterLinkState> = [];
        if (direction === Direction.UP) {
          newLinks = prev.reduce((accum, link, index) => {
            if (prev[index + 1]?.id === linkId && prev[index + 1]?.chapterId === chapterId) {
              return [...accum, prev[index + 1]];
            } else if (link?.id === linkId) {
              return [...accum, prev[index - 1]];
            } else {
              return [...accum, link];
            }
          }, [] as Array<ChapterLinkState>);
        } else {
          newLinks = prev.reduce((accum, link, index) => {
            if (prev[index - 1]?.id === linkId && prev[index - 1]?.chapterId === chapterId) {
              return [...accum, prev[index - 1]];
            } else if (link?.id === linkId) {
              return [...accum, prev[index + 1]];
            } else {
              return [...accum, link];
            }
          }, [] as Array<ChapterLinkState>);
        }
        return newLinks;
      });
    },
    [],
  );

  const handleChangeBookName = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.persist();
    setBookState((prev) => ({
      ...prev,
      name: event.target.value,
    }));
  }, []);

  const handleChangeChapterName = useCallback(
    (chapterId: string) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      event.persist();
      setChaptersState((prev) => {
        return prev.map((chapter) => {
          if (chapter.id === chapterId) {
            return {
              ...chapter,
              name: event.target.value,
            };
          }
          return chapter;
        });
      });
    },
    [],
  );

  const handleChangeLink = useCallback(
    (linkId: string, field: 'name' | 'path') => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      event.persist();
      setLinksState((prev) => {
        return prev.map((link) => {
          if (linkId === link.id) {
            return {
              ...link,
              [field]: event.target.value,
            };
          }
          return link;
        });
      });
    },
    [],
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormErrorMessage('');
    try {
      await saveBook(
        {
          book: bookState,
          links: linksState,
          chapters: chaptersState,
        },
        {
          removedChapterIds,
          removedLinks,
        },
      );
      setModalIsOpen(true);
    } catch (e) {
      setFormErrorMessage(errorMessage);
      console.error(e);
    }
  };

  return {
    formState: {
      bookState,
      linksState,
      chaptersState,
      removedLinkIds: removedLinks,
      removedChapterIds,
    },
    errorMessage: formErrorMessage,
    modal: {
      isOpen: modalIsOpen,
      handleCloseModal,
      handleConfirm,
    },
    deleteModal: {
      isOpen: deleteModalIsOpen,
      handleCloseModal: handleCloseDeleteModal,
      handleConfirm: handleRemoveBook,
      handleOpenModal: handleOpenDeleteModal,
    },
    handlers: {
      handleAddChapter,
      handleAddChapterLink,
      handleChangeBookName,
      handleChangeChapterName,
      handleChangeLink,
      handleMoveChapterLink,
      handleRemoveChapterLink,
      handleSubmit,
      handleSetUrl,
      handleRemoveBook,
    },
  };
};
