import React, { useState } from 'react';
import { Modal, Button, TextField } from '@material-ui/core';
import StripeInputComponent from '../../page/BuyLesson/StripeInput';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import './AddCardModal.scss';
import Line from '../Line/Line';
import { useAddBankCardMutation } from 'api';

const closeModalIcon = '/assets/close_modal_cross.svg';
const stripeIcon = '/assets/stripe_icon_black.svg';

const AddCardModal = () => {
  const [AddBankCard] = useAddBankCardMutation();
  const stripe = useStripe();
  const elements = useElements();
  const StripeInput: any = StripeInputComponent;
  const addNewCard = async () => {
    const token = await stripe?.createToken(elements?.getElement(CardNumberElement)!);
    await AddBankCard({ variables: { cardTokenId: token?.token?.id! } });
    setIsModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Button className="add__card__modal__open__button" onClick={() => setIsModalOpen(true)}>
        + Add new card
      </Button>
      <Modal className="add__card__modal__wrapper" open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="add__card__modal">
          <div className="add__card__modal__close__button__wrapper">
            <img
              src={closeModalIcon}
              alt="Close Modal Icon"
              className="add__card__modal__close__button"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <p className="add__card__modal__title">Add new card</p>
          <Line />
          <div className="add__card__modal__inputs">
            <div>
              <TextField
                className="buyLesson_row_cardNumber"
                variant="outlined"
                name="cardNumber"
                type="text"
                fullWidth={true}
                InputProps={{
                  inputComponent: StripeInput,
                  classes: {
                    focused: `focusedInputField`,
                  },
                  inputProps: {
                    component: CardNumberElement,
                  },
                }}
              />
            </div>
            <div className="buyLesson_row">
              <TextField
                className="buyLesson_row_exp"
                variant="outlined"
                name="exp"
                type="text"
                fullWidth={true}
                InputProps={{
                  inputComponent: StripeInput,
                  classes: {
                    focused: `focusedInputField`,
                  },
                  inputProps: {
                    component: CardExpiryElement,
                  },
                }}
              />
              <TextField
                className="buyLesson_row_cvv"
                variant="outlined"
                name="cvv"
                type="text"
                fullWidth={true}
                InputProps={{
                  inputComponent: StripeInput,
                  classes: {
                    focused: `focusedInputField`,
                  },
                  inputProps: {
                    component: CardCvcElement,
                  },
                }}
              />
            </div>
          </div>
          <div className="add__card__modal__buttons">
            <Button onClick={() => setIsModalOpen(false)} className="add__card__modal__button__cancel">
              Cancel
            </Button>
            <Button onClick={addNewCard} className="add__card__modal__button__add">
              Add card
            </Button>
          </div>
          <div className="buyLesson_stripe_row">
            <a href="https://stripe.com" rel="noopener noreferrer" target="_blank">
              <img src={stripeIcon} alt="stripe-icon" />
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddCardModal;
