import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useHistory } from 'react-router-dom';
import { CouponTypeEnum } from 'api';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import { useCreateCoupon, CouponType, FrequencyType } from './hooks';
import './Coupon.scss';

const checkMarkIcon = '/assets/modal_checkmark.svg';

const Coupon: FC = () => {
  const { push } = useHistory();
  const {
    values,
    errors,
    modal,
    generatedCode,
    TYPES,
    FREQUENCY_TYPES,
    handleChangeText,
    handleChangeType,
    handleChangeFrequencyType,
    handleCloseModal,
    handleSubmit,
  } = useCreateCoupon();

  return (
    <div className="coupon_profile">
      <p className="title">New coupon</p>
      <form className="form">
        <div className="form_main">
          <TextField
            name="name"
            fullWidth={true}
            required={true}
            label="Name"
            variant="outlined"
            onChange={handleChangeText}
            error={!!errors.name}
            helperText={errors.name}
            value={values.name}
          />
          <div className="form_values">
            <FormControl fullWidth={true}>
              <InputLabel className="selectLabel" id="demo-simple-select-label">
                Coupon type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className="lessonsFormField"
                input={<OutlinedInput notched={true} />}
                name="type"
                label="Coupon type"
                value={values.type.label}
                onChange={handleChangeType}
                renderValue={(value) => (value as string) || ''}
              >
                {TYPES.map((type: CouponType) => (
                  <MenuItem key={type.value} value={type as any}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              value={values.value}
              className="discount_value"
              fullWidth={true}
              required={true}
              label="Discount value"
              variant="outlined"
              name="value"
              disabled={values.type.value === CouponTypeEnum.FirstLesson}
              onChange={handleChangeText}
              error={!!errors.value}
              helperText={errors.value}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {values.type.value === CouponTypeEnum.FixedPrice ? '$' : '%'}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form_values_frequency">
            <FormControl fullWidth={true}>
              <InputLabel className="selectLabel" id="demo-simple-select-label1">
                Frequency
              </InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                className="lessonsFormField"
                input={<OutlinedInput notched={true} />}
                name="frequency"
                label="Frequency"
                value={values.frequency.label}
                onChange={handleChangeFrequencyType}
                renderValue={(value) => (value as string) || ''}
              >
                {FREQUENCY_TYPES.map((type: FrequencyType) => (
                  <MenuItem key={type.value} value={type as any}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <Button className="btn-create" contained={true} onClick={handleSubmit}>
          Create
        </Button>
      </form>
      <Dialog
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-coupon-was-created"
        disableBackdropClick={true}
        disableScrollLock={true}
        className="coupon-modal"
      >
        <div className="coupon-modal_content">
          <img src={checkMarkIcon} alt="checkmark_icon" />
          <p className="coupon-modal_content_title">{generatedCode}</p>
          <p className="coupon-modal_content_subtitle">Code was successfully generated</p>
          <Button onClick={() => push('/admin/coupon')}>Show coupon list</Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Coupon;
