import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const close_modal_cross = '/assets/close_modal_cross.svg';

const VideoModal = ({ handleClose, handleChangeVideoUrl, videoUrl, handleKeyPress, handleSubmitVideo }) => {
  return (
    <div className="profile_modal_video">
      <div className="profile_modal_video_header">
        <p className="profile_modal_video_title">Video</p>
        <img alt="" src={close_modal_cross} className="profile_modal_video_close" onClick={handleClose} />
      </div>
      <TextField
        className="profile_modal_video_input"
        variant="outlined"
        name="video"
        label="Video URL"
        placeholder="Paste YouTube URL"
        onChange={handleChangeVideoUrl}
        value={videoUrl || ''}
        fullWidth
        onKeyPress={handleKeyPress}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />
      <div className="profile_modal_video_button_container">
        <Button className="profileButton" variant="contained" color="secondary" onClick={handleSubmitVideo}>
          Attach
        </Button>
      </div>
    </div>
  );
};

export default VideoModal;
