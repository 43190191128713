import { DateTime } from 'luxon';
import * as tzdb from '@vvo/tzdb';

export const widthSizeScreen = {
  lg: 1366,
  md: 1024,
  sm: 769,
  xs: 480,
};

/**
 * These are for the DST time
 */
/**
 * Deprecated
 */
// export const UTC = [
//   // {
//   //   name: 'UTC -12:00 Dateline Standard Time',
//   //   displayName: 'Dateline Standard Time',
//   //   data: -12,
//   // },
//   {
//     name: 'UTC -11:00 Coordinated Universal Time-11',
//     displayName: 'Coordinated Universal Time-11',
//     data: -11,
//   },
//   {
//     name: 'UTC -10:00 Hawaii Standard Time',
//     displayName: 'Hawaii Standard Time',
//     data: -10,
//   },
//   {
//     name: 'UTC -09:00 Hawaii-Aleutian Daylight Time',
//     displayName: 'Hawaii-Aleutian Daylight Time',
//     data: -9,
//   },
//   {
//     name: 'UTC -08:00 Alaska Daylight Time',
//     displayName: 'Alaska Daylight Time',
//     data: -8,
//   },
//   {
//     name: 'UTC -07:00 Pacific Daylight Time',
//     displayName: 'Pacific Daylight Time',
//     data: -7,
//   },
//   {
//     name: 'UTC -06:00 Mountain Daylight Time',
//     displayName: 'Mountain Daylight Time',
//     data: -6,
//   },
//   {
//     name: 'UTC -05:00 Central Daylight Time',
//     displayName: 'Central Daylight Time',
//     data: -5,
//   },
//   {
//     name: 'UTC -04:00 Eastern Daylight Time',
//     displayName: 'Eastern Daylight Time',
//     data: -4,
//   },
//   {
//     name: 'UTC -03:00 E. South America Standard Time',
//     displayName: 'South America Standard Time',
//     data: -3,
//   },
//   {
//     name: 'UTC -02:00 Mid-Atlantic Standard Time',
//     displayName: 'Mid-Atlantic Standard Time',
//     data: -2,
//   },
//   {
//     name: 'UTC -01:00 Azores Standard Time',
//     displayName: 'Azores Standard Time',
//     data: -1,
//   },
//   {
//     name: 'UTC 00:00 Greenwich Standard Time',
//     displayName: 'Greenwich Standard Time',
//     data: 0,
//   },
//   {
//     name: 'UTC +01:00 Central Europe Standard Time',
//     displayName: 'Central Europe Standard Time',
//     data: 1,
//   },
//   {
//     name: 'UTC +02:00 FLE Standard Time',
//     displayName: 'FLE Standard Time',
//     data: 2,
//   },
//   {
//     name: 'UTC +03:00 Arabic Standard Time',
//     displayName: 'Arabic Standard Time',
//     data: 3,
//   },
//   {
//     name: 'UTC +04:00 Russian Standard Time',
//     displayName: 'Russian Standard Time',
//     data: 4,
//   },
//   {
//     name: 'UTC +05:00 West Asia Standard Time',
//     displayName: 'West Asia Standard Time',
//     data: 5,
//   },
//   {
//     name: 'UTC +06:00 Central Asia Standard Time',
//     displayName: 'Central Asia Standard Time',
//     data: 6,
//   },
//   {
//     name: 'UTC +07:00 SE Asia Standard Time',
//     displayName: 'SE Asia Standard Time',
//     data: 7,
//   },
//   {
//     name: 'UTC +08:00 China Standard Time',
//     displayName: 'China Standard Time',
//     data: 8,
//   },
//   {
//     name: 'UTC +09:00 Tokyo Standard Time',
//     displayName: 'Tokyo Standard Time',
//     data: 9,
//   },
//   {
//     name: 'UTC +10:00 West Pacific Standard Time',
//     displayName: 'West Pacific Standard Time',
//     data: 10,
//   },
//   {
//     name: 'UTC +11:00 Central Pacific Standard Time',
//     displayName: 'Central Pacific Standard Time',
//     data: 11,
//   },
//   {
//     name: 'UTC +12:00 New Zealand Standard Time',
//     displayName: 'New Zealand Standard Time',
//     data: 12,
//   },
// ];

const countAlternativeNames = (timezoneObjects) => {
  const alternativeNamesCounted = {};
  timezoneObjects.forEach((t) => {
    if (alternativeNamesCounted[t.alternativeName]) {
      alternativeNamesCounted[t.alternativeName] += 1;
    } else {
      alternativeNamesCounted[t.alternativeName] = 1;
    }
  });
  return alternativeNamesCounted;
};

const selectBestIanaNameByTimezoneOffset = (tzOffsetInMinutes) => {
  const timezonesByOffset = tzdb.getTimeZones().filter((el) => {
    return el.rawOffsetInMinutes === tzOffsetInMinutes;
  });
  const alternativeNamesCounted = countAlternativeNames(timezonesByOffset);
  const sortedTimezones = timezonesByOffset.slice().sort((x1, x2) => {
    if (alternativeNamesCounted[x1.alternativeName] > alternativeNamesCounted[x2.alternativeName]) {
      return -1;
    }
    if (alternativeNamesCounted[x1.alternativeName] < alternativeNamesCounted[x2.alternativeName]) {
      return 1;
    }
    if (x1.countryName === x2.countryName) {
      return 0;
    }
    /**
     * Prioritize US and Canada
     */
    if (x1.countryName === 'United States') {
      return -1;
    }
    if (x2.countryName === 'United States') {
      return 1;
    }
    if (x1.countryName === 'Canada') {
      return -1;
    }
    if (x2.countryName === 'Canada') {
      return 1;
    }
    /**
     * Deprioritize Russia
     */
    if (x1.countryName === 'Russia') {
      return 1;
    }
    if (x2.countryName === 'Russia') {
      return -1;
    }
    if (x1.continentName === x2.continentName) {
      return 0;
    }
    // /**
    //  * Prioritize if has countryName in the tz name
    //  */
    // const name1 = getTimezoneDisplayName(x1.name);
    // const name2 = getTimezoneDisplayName(x2.name);
    // if (name1.indexOf(x1.countryName) > -1 && name2.indexOf(x2.countryName) === -1) {
    //   return -1;
    // }
    // if (name1.indexOf(x1.countryName) === -1 && name2.indexOf(x2.countryName) > -1) {
    //   return 1;
    // }
    /**
     * Prioritize Europe
     */
    if (x1.continentName === 'Europe') {
      return -1;
    }
    if (x1.continentName === 'Europe') {
      return 1;
    }
    return 0;
  });
  const bestTimezone = sortedTimezones[0];
  return bestTimezone.name;
};

// const fixNameToNonDst = (el) => {
//   // console.log(el);
//   const { data: tzOffsetHours } = el;
//   const realTzHours = tzOffsetHours - 1;
//   const tzOffsetInMinutes = realTzHours * 60;
//   // console.log('tzOffsetInMinutes', tzOffsetInMinutes);
//   const zone = selectBestTimezone(tzOffsetInMinutes);
//   if (zone) {
//     return getTimezoneDisplayName(zone.name);
//   }
//   const zone2 = selectBestTimezone(-tzOffsetHours * 60);
//   return getTimezoneDisplayName(zone2.name);
//   // const fixedOffset = el.name.replace(`${tzOffsetHours}`, `${tzOffsetHours - 1}`);
//   // const fixedDaylight = fixedOffset.replace('Daylight', 'Standard');
//   // return fixedDaylight;
// };
//
// const fixNameToDstName = (el) => {
//   const { data: tzOffsetHours } = el;
//   const realTzHours = tzOffsetHours;
//   const tzOffsetInMinutes = realTzHours * 60;
//   const zone = selectBestTimezone(tzOffsetInMinutes);
//   return getTimezoneDisplayName(zone.name);
//   // return el.name.replace('Standard', 'Daylight');
// };

let timezones: { data: number; name: string }[] | null = null;

const _listTimezones = () => {
  // @ts-ignore
  const timezonesList = [...new Set(tzdb.getTimeZones().map((el) => el.rawOffsetInMinutes)).values()].map(
    (tzOffsetInMinutes) => {
      const el = {
        data: tzOffsetInMinutes / 60,
        name: getTimezoneDisplayName(selectBestIanaNameByTimezoneOffset(tzOffsetInMinutes)),
      };
      return el;
    },
  );
  return timezonesList;
};

export const listTimezones = () => {
  if (!timezones) {
    timezones = _listTimezones();
  }
  return timezones;
};

export const getTimezoneDisplayName = (tzIanaName: string | null = null) => {
  const tz = tzIanaName || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneStr = DateTime.fromJSDate(new Date(), { zone: tz }).toFormat('ZZ ZZZZZ');
  return ['UTC', timezoneStr].join(' ');
};

export const instrumentRoutes = [
  '/guitar-teachers',
  '/drum-teachers',
  '/piano-teachers',
  '/ukulele-teachers',
  '/voice-teachers',
  '/bass-teachers',
  '/music-theory-teachers',
  '/ear-training-teachers',
  '/music-production-teachers',
  '/violin-teachers',
  '/viola-teachers',
  '/cello-teachers',
  '/trumpet-teachers',
  '/saxophone-teachers',
  '/flute-teachers',
  '/clarinet-teachers',
  '/banjo-teachers',
  '/song-writing-teachers',
  '/composition-teachers',
];

export const landingInstruments = [
  { name: 'Guitar', slug: 'guitar', id: '1' },
  { name: 'Bass', slug: 'bass', id: '2' },
  { name: 'Piano', slug: 'piano', id: '3' },
  { name: 'Voice', slug: 'voice', id: '4' },
  { name: 'Drums', slug: 'drums', id: '5' },
  { name: 'Music Theory', slug: 'music-theory', id: '6' },
  { name: 'Ear Training', slug: 'ear-training', id: '7' },
  { name: 'Song writing', slug: 'song-writing', id: '8' },
  { name: 'Composition', slug: 'composition', id: '9' },
];
