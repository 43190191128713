import React, { useCallback, useState, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Level } from 'api/index';

import './Level.scss';

const radio_icon = '/assets/radio_icon.svg';
const radio_icon_active = '/assets/radio_icon_active.svg';

const SimplePopoverLevel = ({ handleLevelFilter, handleClearLevelFilter, levelFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrFilter] = useState(Object.values(Level));
  const [value, setValue] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (levelFilter === null) {
      setValue(null);
    }
  }, [levelFilter]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  const handleSubit = useCallback(() => {
    handleLevelFilter(value);
    handleClose();
  }, [handleLevelFilter, value]);

  const handleClear = useCallback(() => {
    handleClearLevelFilter();
    setValue(null);
    handleClose();
  }, [handleClearLevelFilter]);

  const returnValue = (item) => {
    return (
      <FormControlLabel
        className="levelPopoverListItem"
        value={item}
        control={<Radio checkedIcon={<img alt='' src={radio_icon_active} />} icon={<img alt='' src={radio_icon} />} />}
        label={item.charAt(0) + item.slice(1).toLowerCase()}
        key={item}
      />
    );
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        className={`levelOpenButton ${levelFilter ? 'open' : ''}`}
      >
        Level
        {levelFilter && <div className="levelOpenButtonCount">1</div>}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <RadioGroup
          className="levelPopoverList"
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={(e) => handleChangeValue(e)}
        >
          {arrFilter.map((item) => {
            return returnValue(item);
          })}
        </RadioGroup>
        <div className="levelPopoverButton">
          <p className="levelPopoverButtonClear" onClick={handleClear}>
            Clear
          </p>
          <Button className="levelPopoverButtonButton" size="small" variant="contained" onClick={handleSubit}>
            Apply
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default SimplePopoverLevel;
