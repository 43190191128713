import React, { FC, useCallback } from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../ButtonMatireal/ButtonMatireal';
import { useHistory } from 'react-router';
import './AuthModal.scss';

const authImage = '/assets/sign_up_image.png';

type AuthModalProps = {
  open: boolean;
  handleClosePopup: () => void;
};

export const AuthModal: FC<AuthModalProps> = ({ open, handleClosePopup }) => {
  const history = useHistory();
  const handleRedirect = useCallback(
    (path: string) => () => {
      if (window.location.pathname === "/books") sessionStorage.setItem("signUpFrom", "BOOKS");
      history.push(path);
    },
    [history],
  );
  return (
    <Dialog open={open} onClose={handleClosePopup} aria-labelledby="not-authorized" className="auth-user-modal">
      <div className="auth-user-modal-content">
        <img src={authImage} alt="auth_image" />
        <p className="auth-user-modal-content__title">To get started, sign up or log in</p>
        <div className="auth-user-modal-content__btns">
          <Button onClick={handleRedirect('/signin')}>Log in</Button>
          <Button onClick={handleRedirect('/signup')} contained>
            Sign up
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
