import { useProfileQuery } from 'api/index';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import Loader from '../../components/CustomLoader';
import './Adults.scss';
import ProfileInfo from '../../components/ProfileInfo/Info';

export default function AdultProfile() {
  const params: any = useRouteMatch().params;
  const profileQuery = useProfileQuery({ variables: { id: params.adultId,
      timezoneOffset: new Date().getTimezoneOffset(),
  } });
  const loading = profileQuery?.loading;

  const [profile, setProfile] = React.useState<any>({
    id: '1',
    firstName: 'fName',
    lastName: 'lName',
    createdAt: '1571236216000',
    files: [],
    email: 'example@example.com',
  });
  const { firstName, lastName, id, files, createdAt } = profile;

  React.useEffect(() => {
    if (profileQuery?.data?.getProfile) {
      setProfile({ ...profile, ...profileQuery?.data?.getProfile });
    }
  }, [profileQuery?.data?.getProfile]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="adult_profile">
      <ProfileCard firstName={firstName} lastName={lastName} id={id} date={createdAt} files={files} />
      <ProfileInfo user={profile} reGetUser={profileQuery.refetch} />
    </div>
  );
}
