import React from 'react';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import cn from 'classnames';
import useWindowDimensions from 'utils/hooks/useWindowDimentions';

import './Rating.scss';

interface SimpleRatingProps {
  row: boolean;
  value: number;
  views: number;
  starClasses?: string;
  className?: string;
}

export default function SimpleRating({ row, value, views, starClasses = '', className = '' }: SimpleRatingProps) {
  const { width } = useWindowDimensions();
  return (
    <>
      <Box
        className={cn(
          {
            ratingContainer: row,
            ratingContainerColumn: !row,
          },
          className,
        )}
        component="fieldset"
        mb={3}
        borderColor="transparent"
      >
        <div className="ratingWrapper">
          <Rating
            className={cn(starClasses)}
            name="read-only"
            value={value}
            readOnly={true}
            size={width < 797 ? 'small' : 'medium'}
          />
          {row && <Typography component="legend">{views} reviews</Typography>}
        </div>
      </Box>
      {!row && <Typography className="views">{views} reviews</Typography>}
    </>
  );
}
