import { useProfileQuery } from 'api/index';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import Loader from '../../components/CustomLoader';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import ProfileInfo from '../../components/ProfileInfo/Info';
import './Coaches.scss';

export default function CoachProfile() {
  const params: any = useRouteMatch().params;
  const profileQuery = useProfileQuery({ variables: { id: params.coachId,
      timezoneOffset: new Date().getTimezoneOffset(),
    } });
  const [user, setUser] = React.useState<any>({
    id: '1',
    firstName: 'fName',
    lastName: 'lName',
    createdAt: '1571236216000',
    files: [],
    email: 'example@example.com',
  });

  const { firstName, lastName, id, files, createdAt } = user;
  const loading = profileQuery?.loading;

  React.useEffect(() => {
    if (profileQuery?.data?.getProfile) {
      setUser({ ...user, ...profileQuery?.data?.getProfile });
    }
  }, [profileQuery?.data?.getProfile]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="coaches_profile ">
      <ProfileCard firstName={firstName} lastName={lastName} id={id} date={createdAt} files={files} />
      <ProfileInfo user={user} reGetUser={profileQuery.refetch} />
    </div>
  );
}
