import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { useMediaQuery } from 'react-responsive';

const cardCheckMark = '/assets/card-checkmark.png';

const ChooseCardCarousel = ({ cardsList, setCardId, cardId }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  const getCardBrandClassname = (cardBrand) => {
    if (cardBrand.toLowerCase() === 'visa') {
      return 'visa';
    } else if (cardBrand.toLowerCase() === 'mastercard') {
      return 'mastercard';
    } else return 'other-card';
  };

  return (
    <>
      {!cardsList ? null : (
        <>
          <p className="buyLesson_choose_card">Сhoose payment card</p>
          <Carousel
            className="carousel"
            animation="slide"
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysInvisible={cardsList.length <= 3 ? true : false}
          >
            {!isMobile
              ? cardsList
                  .map((card, i) => {
                    if (i % 3 !== 0) {
                      return null;
                    }
                    return (
                      <div className="carousel_slide">
                        <div
                          onClick={() => setCardId(cardsList[i]?.cardId!)}
                          className={`carousel_slide_card ${getCardBrandClassname(
                            !cardsList[i]?.brand ? '' : cardsList[i]?.brand,
                          )}`}
                          key={i}
                        >
                          <img
                            className={`carousel_slide_card_checkmark ${cardId !== cardsList[i]?.cardId ? 'none' : ''}`}
                            src={cardCheckMark}
                            alt="checkmark"
                          />

                          <p className="carousel_slide_card_number">XXXX - XXXX - XXXX - {cardsList[i]?.last4}</p>
                          <p className="carousel_slide_card_expiration">
                            {cardsList[i]?.expMonth} / {cardsList[i]?.expYear}
                          </p>
                        </div>
                        <div
                          onClick={() => setCardId(cardsList[i + 1]?.cardId!)}
                          className={`carousel_slide_card ${getCardBrandClassname(
                            !cardsList[i + 1]?.brand ? '' : cardsList[i + 1]?.brand,
                          )} ${cardsList[i + 1] ? '' : 'none'}`}
                          key={i + 1}
                        >
                          <img
                            className={`carousel_slide_card_checkmark ${
                              cardId !== cardsList[i + 1]?.cardId ? 'none' : ''
                            }`}
                            src={cardCheckMark}
                            alt="checkmark"
                          />

                          <p className="carousel_slide_card_number">XXXX - XXXX - XXXX - {cardsList[i + 1]?.last4}</p>
                          <p className="carousel_slide_card_expiration">
                            {cardsList[i + 1]?.expMonth} / {cardsList[i + 1]?.expYear}
                          </p>
                        </div>
                        <div
                          onClick={() => setCardId(cardsList[i + 2]?.cardId!)}
                          className={`carousel_slide_card ${getCardBrandClassname(
                            !cardsList[i + 2]?.brand ? '' : cardsList[i + 2]?.brand,
                          )} ${cardsList[i + 2] ? '' : 'none'}`}
                          key={i + 2}
                        >
                          <img
                            className={`carousel_slide_card_checkmark ${
                              cardId !== cardsList[i + 2]?.cardId ? 'none' : ''
                            }`}
                            src={cardCheckMark}
                            alt="checkmark"
                          />
                          <p className="carousel_slide_card_number">XXXX - XXXX - XXXX - {cardsList[i + 2]?.last4}</p>
                          <p className="carousel_slide_card_expiration">
                            {cardsList[i + 2]?.expMonth} / {cardsList[i + 2]?.expYear}
                          </p>
                        </div>
                      </div>
                    );
                  })
                  .filter(Boolean)
              : cardsList.map((card, i) => {
                  return (
                    <div className="carousel_slide">
                      <div
                        onClick={() => setCardId(card?.cardId!)}
                        className={`carousel_slide_card ${getCardBrandClassname(
                          !card?.brand ? '' : card?.brand,
                        )}`}
                        key={i}
                      >
                        <img
                          className={`carousel_slide_card_checkmark ${cardId !== card?.cardId ? 'none' : ''}`}
                          src={cardCheckMark}
                          alt="checkmark"
                        />
                        <p className="carousel_slide_card_number">XXXX - XXXX - XXXX - {card?.last4}</p>
                        <p className="carousel_slide_card_expiration">
                          {card?.expMonth} / {card?.expYear}
                        </p>
                      </div>
                    </div>
                  );
                })}
          </Carousel>
        </>
      )}
    </>
  );
};

export default ChooseCardCarousel;
