import React from 'react';

const LessonsIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4C18.0636 4 19.1727 4.14559 20.1818 4.48529C20.8636 4.72794 21.4545 5.01912 22 5.45588V19.6265C22 19.8691 21.7727 20.1118 21.5455 20.1118C21.4545 20.1118 21.4091 20.1118 21.3182 20.0632C20.0455 19.3353 18.5 19.0441 17 19.0441C15.4545 19.0441 13.2273 19.675 12 20.5C10.6818 19.4324 8.77273 19.0441 7 19.0441C5.68182 19.0441 3.90909 19.4809 2.68182 20.1118C2.63636 20.1118 2.60227 20.1239 2.56818 20.136C2.53409 20.1482 2.5 20.1603 2.45455 20.1603C2.22727 20.1603 2 19.9176 2 19.675V5.45588C3.31818 4.38824 5.22727 4 7 4C8.77273 4 10.6818 4.38824 12 5.45588C13.3182 4.38824 15.2273 4 17 4ZM17 17.1032C18.0909 17.1032 19.1818 17.2488 20.1818 17.5885V6.4267C19.1818 6.08699 18.0909 5.94141 17 5.94141C15.4545 5.94141 13.2273 6.57229 12 7.39729V18.5591C13.2273 17.7341 15.4545 17.1032 17 17.1032Z"
        fill="#605F5E"
        {...props}
      />
    </svg>
  );
};
export default LessonsIcon;
