import React, { useState, useCallback, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './Instrument.scss';

const guitar_icon = '/assets/instruments/guitar.svg';
const drums_icon = '/assets/instruments/drums.svg';
const piano_icon = '/assets/instruments/piano.svg';
const clarinet_icon = '/assets/instruments/clarinet.svg';
const music_theory_icon = '/assets/instruments/music_theory.svg';
const checkbox_icon = '/assets/checkbox_icon.svg';
const checkbox_active_icon = '/assets/checkbox_active_icon.svg';

const replaceUndrescore = (str: string) => str.replace('_', ' ');
const replaceWhitespace = (str: string) => str.replace(' ', '_');

const SimplePopoverIntstrument = ({
  handleInstrFilter,
  handleClearInstrFilter,
  listInstruments,
  instrFilter,
  choosenInstrName,
  setChosenInstrName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [Guitar, setGuitar] = useState({
    Guitar: false,
    Bass: false,
    Ukulele: false,
    Banjo: false,
    Viola: false,
    Violin: false,
    Cello: false,
  });
  const [Drums, setDrums] = useState({
    Drums: false,
  });
  const [Piano, setPiano] = useState({
    Piano: false,
  });
  const [Clarinet, setClarinet] = useState({
    Clarinet: false,
    Flute: false,
    Saxophone: false,
    Trumpet: false,
  });
  const [MusicTheory, setMusicTheory] = useState({
    Music_theory: false,
    Ear_training: false,
    Song_writing: false,
    Music_production: false,
    Voice: false,
    Composition: false,
  });

  const clearfilter = useCallback(() => {
    setGuitar({
      Guitar: false,
      Bass: false,
      Ukulele: false,
      Banjo: false,
      Viola: false,
      Violin: false,
      Cello: false,
    });
    setDrums({ Drums: false });
    setPiano({ Piano: false });
    setClarinet({
      Clarinet: false,
      Flute: false,
      Saxophone: false,
      Trumpet: false,
    });
    setMusicTheory({
      Music_theory: false,
      Ear_training: false,
      Voice: false,
      Music_production: false,
      Song_writing: false,
      Composition: false,
    });
  }, []);

  const handleInitialFiltration = (name) => {
    // const instrumentKey = (key) => ({ target: { name: key } });
    Object.entries(Guitar).forEach(([key, value]) => {
      if (key.toLowerCase() === name) {
        setGuitar((prevState) => ({
          ...prevState,
          [key]: true,
        }));
      }
    });
    Object.entries(Piano).forEach(([key, value]) => {
      if (key.toLowerCase() === name) {
        setPiano((prevState) => ({
          ...prevState,
          [key]: true,
        }));
      }
    });
    Object.entries(Clarinet).forEach(([key, value]) => {
      if (key.toLowerCase() === name) {
        setClarinet((prevState) => ({
          ...prevState,
          [key]: true,
        }));
      }
    });
    Object.entries(MusicTheory).forEach(([key, value]) => {
      if (key.toLowerCase() === name) {
        setMusicTheory((prevState) => ({
          ...prevState,
          [key]: true,
        }));
      }
    });
    Object.entries(Drums).forEach(([key, value]) => {
      if (key.toLowerCase() === name) {
        setDrums((prevState) => ({
          ...prevState,
          [key]: true,
        }));
      }
    });
  };

  useEffect(() => {
    if (instrFilter === null) {
      clearfilter();
    }
    if (choosenInstrName !== null) {
      handleInitialFiltration(choosenInstrName);
      setChosenInstrName(null);
    }
  }, [clearfilter, instrFilter, choosenInstrName]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClear = useCallback(() => {
    clearfilter();
    handleClearInstrFilter();
    handleClose();
  }, [clearfilter, handleClearInstrFilter, handleClose]);

  const handleSubit = useCallback(() => {
    const data: any = [];
    listInstruments.forEach((instr) => {
      if (instr?.id) {
        if (Guitar[instr.name.charAt(0) + instr.name.slice(1).toLowerCase()]) {
          data.push(instr.id);
        }
        if (Drums[instr.name.charAt(0) + instr.name.slice(1).toLowerCase()]) {
          data.push(instr.id);
        }
        if (Piano[instr.name.charAt(0) + instr.name.slice(1).toLowerCase()]) {
          data.push(instr.id);
        }
        if (Clarinet[instr.name.charAt(0) + instr.name.slice(1).toLowerCase()]) {
          data.push(instr.id);
        }
        if (MusicTheory[replaceWhitespace(instr.name.charAt(0) + instr.name.slice(1).toLowerCase())]) {
          data.push(instr.id);
        }
      }
    });
    handleInstrFilter(data);
    handleClose();
  }, [listInstruments, handleInstrFilter, handleClose, Guitar, Drums, Piano, Clarinet, MusicTheory]);

  const handleChangeCheckBoxGuitar = useCallback(
    (e) => {
      let obj = { ...Guitar };
      obj[e.target.name] = e.target.checked;
      setGuitar(obj);
    },
    [Guitar],
  );
  const handleChangeCheckBoxDrums = useCallback(
    (e) => {
      let obj = { ...Drums };
      obj[e.target.name] = e.target.checked;
      setDrums(obj);
    },
    [Drums],
  );
  const handleChangeCheckBoxPiano = useCallback(
    (e) => {
      let obj = { ...Piano };
      obj[e.target.name] = e.target.checked;
      setPiano(obj);
    },
    [Piano],
  );
  const handleChangeCheckBoxClarinet = useCallback(
    (e) => {
      let obj = { ...Clarinet };
      obj[e.target.name] = e.target.checked;
      setClarinet(obj);
    },
    [Clarinet],
  );
  const handleChangeCheckBoxMusicTheory = useCallback(
    (e) => {
      let obj = { ...MusicTheory };
      obj[e.target.name] = e.target.checked;
      setMusicTheory(obj);
    },
    [MusicTheory],
  );

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        className={`instrumentOpenButton ${instrFilter ? 'open' : ''}`}
      >
        Instrument
        {instrFilter && <div className="instrumentOpenButtonCount">{instrFilter?.length}</div>}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="instrumentPopoverFiltes">
          <div className="instrumentPopoverFiltesRight">
            <img src={guitar_icon} className="instrumentPopoverFiltesIcon" alt="instrumentPopoverFiltesIcon" />
            <FormGroup>
              {Object.keys(Guitar).map((item, index) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Object.values(Guitar)[index]}
                        onChange={handleChangeCheckBoxGuitar}
                        name={item}
                        checkedIcon={<img src={checkbox_active_icon} alt="checkedIcon" />}
                        icon={<img src={checkbox_icon} alt="icon" />}
                      />
                    }
                    label={item}
                  />
                );
              })}
            </FormGroup>
          </div>
          <div className="instrumentPopoverFiltesRight">
            <div>
              <img src={drums_icon} className="instrumentPopoverFiltesIcon" alt="instrumentPopoverFiltesIcon" />
              <FormGroup>
                {Object.keys(Drums).map((item, index) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Object.values(Drums)[index]}
                          onChange={handleChangeCheckBoxDrums}
                          name={item}
                          checkedIcon={<img src={checkbox_active_icon} alt="checkedIcon" />}
                          icon={<img src={checkbox_icon} alt="icon" />}
                        />
                      }
                      label={item}
                    />
                  );
                })}
              </FormGroup>
            </div>
            <div className="instrumentPopoverFiltesTop">
              <img src={piano_icon} className="instrumentPopoverFiltesIcon" alt="instrumentPopoverFiltesIcon" />
              <FormGroup>
                {Object.keys(Piano).map((item, index) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Object.values(Piano)[index]}
                          onChange={handleChangeCheckBoxPiano}
                          name={item}
                          checkedIcon={<img src={checkbox_active_icon} alt="checkedIcon" />}
                          icon={<img src={checkbox_icon} alt="icon" />}
                        />
                      }
                      label={item}
                    />
                  );
                })}
              </FormGroup>
            </div>
          </div>
          <div className="instrumentPopoverFiltesRight">
            <img src={clarinet_icon} className="instrumentPopoverFiltesIcon" alt="instrumentPopoverFiltesIcon" />
            <FormGroup>
              {Object.keys(Clarinet).map((item, index) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Object.values(Clarinet)[index]}
                        onChange={handleChangeCheckBoxClarinet}
                        name={item}
                        checkedIcon={<img src={checkbox_active_icon} alt="checkedIcon" />}
                        icon={<img src={checkbox_icon} alt="icon" />}
                      />
                    }
                    label={item}
                  />
                );
              })}
            </FormGroup>
          </div>
          <div>
            <img src={music_theory_icon} className="instrumentPopoverFiltesIcon" alt="instrumentPopoverFiltesIcon" />
            <FormGroup>
              {Object.keys(MusicTheory).map((item, index) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Object.values(MusicTheory)[index]}
                        onChange={handleChangeCheckBoxMusicTheory}
                        name={item}
                        checkedIcon={<img src={checkbox_active_icon} alt="checkedIcon" />}
                        icon={<img src={checkbox_icon} alt="icon" />}
                      />
                    }
                    label={replaceUndrescore(item)}
                  />
                );
              })}
            </FormGroup>
          </div>
        </div>
        <div className="levelPopoverButton">
          <p className="levelPopoverButtonClear" onClick={handleClear}>
            Clear
          </p>
          <Button className="levelPopoverButtonButton" size="small" variant="contained" onClick={handleSubit}>
            Apply
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default SimplePopoverIntstrument;
