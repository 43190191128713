import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { alpha, Theme, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NoSsr from '@material-ui/core/NoSsr';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/KeyboardArrowUp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Link from 'component/Link/Link';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import useWindowDimensions from 'utils/hooks/useWindowDimentions';
import { isAvatar, getAvatar } from 'utils/image';
import './Header.scss';
import { RolesEnum } from 'api';

const logo = '/assets/logo.png';
const childrenIcon = '/assets/children_icon.svg';
const contactSupportIcon = '/assets/contact_support_icon.svg';
const logoutIcon = '/assets/logout_icon.svg';
const menuId = 'primary-search-account-menu';

const lessonsIcon = '/assets/lessons_icon.svg';
const calendarIcon = '/assets/calendar_icon.svg';
const profileIcon = '/assets/profile_icon.svg';
const balanceIcon = '/assets/balance.svg';
const myChildrenIcon = '/assets/my_children_icon.svg';
const coachesIcon = '/assets/coaches_icon.svg';
const blogIcon = '/assets/blog_icon.svg';
const shopIcon = '/assets/shop_icon.svg';
const signupIcon = '/assets/signup_icon.svg';
const loginIcon = '/assets/login_icon.svg';
const booksIcon = '/assets/books_icon.svg';

const drawerWidth = 200;

const blogURL = 'https://www.bestmusiccoach.com/blog';
const sheetMusicUrl = 'https://shop.bestsheetmusic.com';
const shopUrl = 'https://store.bestmusiccoach.com';
const booksUrl = 'https://www.bestmusiccoach.com/books-and-courses';

const isLoginUser = [
  {
    title: 'Coaches',
    iconSrc: coachesIcon,
    className: 'coachesIcon',
    to: '/coaches',
  },
  {
    title: 'Books & Courses',
    iconSrc: booksIcon,
    className: 'booksIcon',
    to: booksUrl,
  },
  {
    title: 'Sheet Music',
    iconSrc: shopIcon,
    className: 'shopIcon',
    to: sheetMusicUrl,
  },
  {
    title: 'Blog',
    iconSrc: blogIcon,
    className: 'blogIcon',
    to: blogURL,
  },
  {
    title: 'Calendar',
    iconSrc: calendarIcon,
    className: 'calendarIcon',
    to: '/calendar',
  },
  {
    title: 'Lessons',
    iconSrc: lessonsIcon,
    className: 'lessonsIcon',
    to: '/lessons',
  },
  {
    title: 'Profile',
    iconSrc: profileIcon,
    className: 'profileMIcon',
    to: '/personal-info',
  },
  {
    title: 'Contact support',
    iconSrc: contactSupportIcon,
    className: 'contactSupportIcon',
    to: 'https://bestmusiccoach.freshdesk.com/support/home',
  },
  {
    title: 'Log Out',
    iconSrc: logoutIcon,
    className: 'logoutIcon',
    to: '/signin',
  },
];

const isLogoutUser = [
  {
    title: 'Coaches',
    iconSrc: coachesIcon,
    className: 'coachesIcon',
    to: '/coaches',
  },
  {
    title: 'Books & Courses',
    iconSrc: booksIcon,
    className: 'booksIcon',
    to: booksUrl,
  },
  {
    title: 'Sheet Music',
    iconSrc: shopIcon,
    className: 'shopIcon',
    to: sheetMusicUrl,
  },
  {
    title: 'Blog',
    iconSrc: blogIcon,
    className: 'blogIcon',
    to: blogURL,
  },
  {
    title: 'Contact support',
    iconSrc: contactSupportIcon,
    className: 'contactSupportIcon',
    to: '',
  },
];

const useStyles = makeStyles(({ palette, spacing, transitions, mixins, shape, breakpoints }: Theme) => ({
  appBar: {
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    height: '43px',
    padding: spacing(0, 1),
    ...mixins.toolbar,
    minHeight: '43px',
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: spacing(3),
    transition: transitions.create('margin', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    top: '0',
    maxHeight: '64px',
    '& header': {
      backgroundColor: '#fff',
      color: '#000',
      boxShadow: '0px 0px 20px rgba(2, 1, 86, 0.1)',
    },
    boxShadow: '0px 0px 20px rgba(2, 1, 86, 0.1) !important',
  },
  menuItem: {
    fontFamily: 'Avenir !important',
    fontStyle: 'normal !important',
    fontSize: '16px !important',
    '&:hover': {
      '& span': {
        color: '#00A651 !important',
      },
      '& div': {
        backgroundColor: '#00A651 !important',
      },
    },
  },
  logoIcon: {
    left: '64px',
  },
  search: {
    position: 'relative',
    borderRadius: shape.borderRadius,
    backgroundColor: alpha(palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(palette.common.white, 0.25),
    },
    marginRight: spacing(2),
    marginLeft: 0,
    width: '100%',
    [breakpoints.up('sm')]: {
      marginLeft: spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${spacing(4)}px)`,
    transition: transitions.create('width'),
    width: '100%',
    [breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    position: 'relative',
    right: '50px',
    [breakpoints.up('sm')]: {
      display: 'flex',
      right: '8px',
    },
  },
  sectionMobile: {
    display: 'flex',
    [breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

interface HeaderProps {
  user: any;
  signOut: (callBack: () => void) => void;
}

function Header({ user, signOut }: HeaderProps) {
  const { width: screenWidth } = useWindowDimensions();
  const width = screenWidth;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const userProps = {};
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const showExtendContent = false;
  const [activeMenuItem, setActiveMenuItem] = useState('Sign up');
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (location.pathname) {
      switch (location.pathname) {
        case '/signin':
          setActiveMenuItem('Login');
          break;
        case '/signup':
          setActiveMenuItem('Sign up');
          break;
        case '/personal-info':
          setActiveMenuItem('Profile');
          break;
        case '/books':
          setActiveMenuItem('Books');
          break;
        case '/coaches':
          setActiveMenuItem('Coaches');
          break;
        case '/calendar':
          setActiveMenuItem('Calendar');
          break;
        case '/pw-reset':
          setActiveMenuItem('Sign up');
          break;
        case '/lessons':
          setActiveMenuItem('Lessons');
          break;
        case '/balance':
          setActiveMenuItem('Balance');
          break;
        default:
          setActiveMenuItem('');
          break;
      }
    }
    if (location.pathname.startsWith('/books')) {
      setActiveMenuItem('Books');
    }
  }, [location]);

  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  const handleProfile = useCallback(() => {
    history.push('/personal-info');
    handleMenuClose();
  }, [handleMenuClose, history]);

  const handleBalance = useCallback(() => {
    history.push('/balance');
    handleMenuClose();
  }, [handleMenuClose, history]);

  const handleChildren = useCallback(() => {
    history.push('/my-children');
    handleMenuClose();
  }, [handleMenuClose, history]);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const photo = user?.files ? (isAvatar(user.files) ? getAvatar(user.files) : '') : '';
  const { firstName = '', lastName = '' } = user || {};

  const handleDrawerClose = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleDrawerOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleLogOut = useCallback(
    (to) => {
      if (to === '/signin') {
        signOut(handleDrawerClose);
      }
    },
    [handleDrawerClose, signOut],
  );

  let drawerMenu = user ? isLoginUser : isLogoutUser;

  return (
    <div className="header">
      <NoSsr>
        {width > 767 ? (
          <Menu
            className="profileMenu"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted={true}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem
              className={`${classes.menuItem} ${activeMenuItem === 'Profile' && 'choose_menu'}`}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              onClick={handleProfile}
            >
              <div
                className={`profileMenuIcon profileIcon`}
                style={{ mask: `url(${profileIcon})`, WebkitMask: `url(${profileIcon})` }}
              />
              <span>Profile</span>
            </MenuItem>
            {user?.role !== RolesEnum.Child && (
              <MenuItem
                className={`${classes.menuItem} ${activeMenuItem === 'Balance' && 'choose_menu'}`}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
                onClick={handleBalance}
              >
                <div
                  className={`profileMenuIcon profileIcon`}
                  style={{ mask: `url(${balanceIcon})`, WebkitMask: `url(${balanceIcon})` }}
                />
                <span>Balance</span>
              </MenuItem>
            )}
            {user?.role === RolesEnum.Adult && (
              <MenuItem
                className={`${classes.menuItem} ${activeMenuItem === 'My children' && 'choose_menu'}`}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
                onClick={handleChildren}
              >
                <div
                  className={`profileMenuIcon childrenIcon`}
                  style={{ mask: `url(${childrenIcon})`, WebkitMask: `url(${childrenIcon})` }}
                />
                <span>My children</span>
              </MenuItem>
            )}
            <MenuItem
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              className={`${classes.menuItem} ${activeMenuItem === 'Contact support' && 'choose_menu'}`}
              onClick={() => (window.location.href = 'https://bestmusiccoach.freshdesk.com/support/home')}
            >
              <div
                className={`profileMenuIcon contactSupportIcon`}
                style={{ mask: `url(${contactSupportIcon})`, WebkitMask: `url(${contactSupportIcon})` }}
              />
              <span>Contact support</span>
            </MenuItem>
            <MenuItem
              className={`${classes.menuItem} ${activeMenuItem === 'Log out' && 'choose_menu'}`}
              style={{ display: 'flex', justifyContent: 'flex-start' }}
              onClick={() => signOut(handleMenuClose)}
            >
              <div
                className={`profileMenuIcon logoutIcon`}
                style={{ mask: `url(${logoutIcon})`, WebkitMask: `url(${logoutIcon})` }}
              />
              <span>Log out</span>
            </MenuItem>
          </Menu>
        ) : (
          <Drawer
            className="mobileSideBar"
            variant="persistent"
            anchor="right"
            open={isOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton className="drawerCloseIconButton" onClick={handleDrawerClose}>
                {isOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </div>
            <List>
              {!user
                ? [
                    {
                      title: 'Coaches',
                      iconSrc: coachesIcon,
                      className: 'coachesIcon',
                      to: '/coaches',
                    },
                    {
                      title: 'Books & Courses',
                      iconSrc: booksIcon,
                      className: 'booksIcon',
                      to: booksUrl,
                    },
                    {
                      title: 'Blog',
                      iconSrc: blogIcon,
                      className: 'blogIcon',
                      to: blogURL,
                    },
                    {
                      title: 'Sheet Music',
                      iconSrc: shopIcon,
                      className: 'shopIcon',
                      to: sheetMusicUrl,
                    },
                    {
                      title: 'Shop',
                      iconSrc: shopIcon,
                      className: 'shopIcon',
                      to: shopUrl,
                    },
                    {
                      title: 'Login',
                      iconSrc: loginIcon,
                      className: 'loginIcon',
                      to: '/signin',
                    },
                    {
                      title: 'Sign up',
                      iconSrc: signupIcon,
                      className: 'signupIcon',
                      to: '/signup',
                    },
                  ].map(({ title, iconSrc, className, to }) => {
                    if (title === 'Blog' || title === 'Sheet Music' || title === 'Shop' || title === "Books & Courses") {
                      return (
                        <a
                          key={'to' + title}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={to}
                          style={{textDecoration: 'none', color: '#000000'}}
                        >
                          <ListItem
                            onClick={() => history.push(to)}
                            className={`listItem ${activeMenuItem === title ? 'active' : ''}`}
                            button={true}
                            key={title}
                          >
                            <ListItemIcon className="listItemIcon">
                              <div
                                className={`profileMenuIcon ${className} ${
                                  activeMenuItem === title ? 'focusedDrawer' : ''
                                }`}
                                style={{ mask: `url(${iconSrc})`, WebkitMask: `url(${iconSrc})` }}
                              />
                            </ListItemIcon>
                            <ListItemText className="listItemText" primary={title} />
                          </ListItem>
                        </a>
                      );
                    } else {
                      return (
                          <Link key={'to' + title} to={to}>
                            <ListItem
                                onClick={handleDrawerClose}
                                className={`listItem ${activeMenuItem === title ? 'active' : ''}`}
                                button={true}
                                key={title}
                            >
                              <ListItemIcon className="listItemIcon">
                                <div
                                    className={`profileMenuIcon ${className} ${
                                        activeMenuItem === title ? 'focusedDrawer' : ''
                                    }`}
                                    style={{ mask: `url(${iconSrc})`, WebkitMask: `url(${iconSrc})` }}
                                />
                              </ListItemIcon>
                              <ListItemText className="listItemText" primary={title} />
                            </ListItem>
                          </Link>
                      );
                    }
                  })
                : drawerMenu.map(({ title, iconSrc, className, to }) => {
                    if (title === 'Balance' && user.role === RolesEnum.Child) {
                      return <Fragment key="Balance" />;
                    }
                    if (title === 'Coaches' && user.role === RolesEnum.Coach) {
                      return <Fragment key="Coaches" />;
                    }
                    if (title === 'Contact support' || title === 'Blog' || title === 'Sheet Music' || title === 'Books & Courses') {
                      return (
                        <ListItem
                          onClick={() => {
                            window.location.href = to;
                          }}
                          button={true}
                          key={title}
                          className={`listItem ${activeMenuItem === title ? 'active' : ''}`}
                        >
                          <ListItemIcon className="listItemIcon">
                            <div
                              className={`profileMenuIcon ${className} ${
                                activeMenuItem === title ? 'focusedDrawer' : ''
                              }`}
                              style={{ mask: `url(${iconSrc})`, WebkitMask: `url(${iconSrc})` }}
                            />
                          </ListItemIcon>
                          <ListItemText className="listItemText" primary={title} />
                        </ListItem>
                      );
                    }
                    if (title !== 'Profile') {
                      return (
                        <Link key={'to' + title} to={to}>
                          <ListItem
                            onClick={() => {
                              handleLogOut(to);
                              handleDrawerClose();
                            }}
                            button={true}
                            key={title}
                            className={`listItem ${activeMenuItem === title ? 'active' : ''}`}
                          >
                            <ListItemIcon className="listItemIcon">
                              <div
                                className={`profileMenuIcon ${className} ${
                                  activeMenuItem === title ? 'focusedDrawer' : ''
                                }`}
                                style={{ mask: `url(${iconSrc})`, WebkitMask: `url(${iconSrc})` }}
                              />
                            </ListItemIcon>
                            <ListItemText className="listItemText" primary={title} />
                          </ListItem>
                        </Link>
                      );
                    } else {
                      return (
                        <Accordion key="accordion" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                          <AccordionSummary
                            expandIcon={
                              user.role === RolesEnum.Adult || user.role === RolesEnum.Coach ? <ExpandMoreIcon /> : null
                            }
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Link to={to}>
                              <ListItem
                                onClick={handleDrawerClose}
                                button={true}
                                key={title}
                                className={`listItem ${activeMenuItem === title ? 'active' : ''}`}
                              >
                                <ListItemIcon className="listItemIcon">
                                  <div
                                    className={`profileMenuIcon ${className} ${
                                      activeMenuItem === title ? 'focusedDrawer' : ''
                                    }`}
                                    style={{ mask: `url(${iconSrc})`, WebkitMask: `url(${iconSrc})` }}
                                  />
                                </ListItemIcon>
                                <ListItemText className="listItemText" primary={title} />
                              </ListItem>
                            </Link>
                          </AccordionSummary>
                          <AccordionDetails className="accordion-details-flex">
                            {(user.role === RolesEnum.Adult || user.role === RolesEnum.Coach) && (
                              <Link to="/balance">
                                <ListItem
                                  onClick={handleDrawerClose}
                                  button={true}
                                  key="Balance"
                                  className={`listItem ${activeMenuItem === 'Balance' ? 'active' : ''}`}
                                >
                                  <ListItemIcon className="listItemIcon">
                                    <div
                                      className={`profileMenuIcon ${className} ${
                                        activeMenuItem === 'Balance' ? 'focusedDrawer' : ''
                                      }`}
                                      style={{ mask: `url(${balanceIcon})`, WebkitMask: `url(${balanceIcon})` }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText className="listItemText" primary="Balance" />
                                </ListItem>
                              </Link>
                            )}
                            {user.role === RolesEnum.Adult && (
                              <Link to="/my-children">
                                <ListItem
                                  onClick={handleDrawerClose}
                                  button={true}
                                  key={'My Children'}
                                  className={`listItem ${activeMenuItem === 'My Children' ? 'active' : ''}`}
                                >
                                  <ListItemIcon className="listItemIcon">
                                    <div
                                      className={`profileMenuIcon ${className} ${
                                        activeMenuItem === 'My Children' ? 'focusedDrawer' : ''
                                      }`}
                                      style={{ mask: `url(${myChildrenIcon})`, WebkitMask: `url(${myChildrenIcon})` }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText className="listItemText" primary={'My Children'} />
                                </ListItem>
                              </Link>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                  })}
            </List>
          </Drawer>
        )}
        {width > 767 ? (
          <Toolbar className="toolBar">
            <Link to="/" classes="barLogo">
              <img src={logo} alt="" />
            </Link>
            <div className="toolBarLinks">
              <Typography className={classes.title} variant="h6" noWrap={true}>
                <a href={blogURL} className="headerNavLink">
                  Blog
                </a>
              </Typography>
              <Typography className={classes.title} variant="h6" noWrap={true}>
                <a href="http://store.bestmusiccoach.com/" className="headerNavLink">
                  Shop
                </a>
              </Typography>
              <Typography className={classes.title} variant="h6" noWrap={true}>
                <a href="http://bestsheetmusic.com/" className="headerNavLink">
                  Sheet Music
                </a>
              </Typography>
              <Typography className={classes.title} variant="h6" noWrap={true}>
                <a href={booksUrl} className={`headerNavLink ${activeMenuItem === 'Books' ? 'active' : ''}`}>
                  Books & Courses
                </a>
              </Typography>
              {user?.role !== RolesEnum.Coach && (
                <Typography className={classes.title} variant="h6" noWrap={true}>
                  <Link to="/coaches" classes={`headerNavLink ${activeMenuItem === 'Coaches' ? 'active' : ''}`}>
                    Coaches
                  </Link>
                </Typography>
              )}
              {user && (
                <>
                  <Typography className={classes.title} variant="h6" noWrap={true}>
                    <Link to="/calendar" classes={`headerNavLink ${activeMenuItem === 'Calendar' ? 'active' : ''}`}>
                      Calendar
                    </Link>
                  </Typography>
                  <Typography className={classes.title} variant="h6" noWrap={true}>
                    <Link to="/lessons" classes={`headerNavLink ${activeMenuItem === 'Lessons' ? 'active' : ''}`}>
                      Lessons
                    </Link>
                  </Typography>
                </>
              )}
              {showExtendContent && (
                <>
                  <Typography className={classes.title} variant="h6" noWrap={true}>
                    Calendar
                  </Typography>
                  <Typography className={classes.title} variant="h6" noWrap={true}>
                    Lessons
                  </Typography>
                </>
              )}
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {!user && (
                <div className="toolBarAuthButtons">
                  <Link to="/signup" classes="barLogo">
                    <Typography
                      className={`toolBarAuthSignup ${activeMenuItem === 'Sign up' ? 'active' : ''}`}
                      variant="h6"
                      noWrap={true}
                    >
                      Sign up
                    </Typography>
                  </Link>
                  <Link to="/signin" classes="barLogo">
                    <Typography
                      className={`toolBarAuthSignin ${activeMenuItem === 'Login' ? 'active' : ''}`}
                      variant="h6"
                      noWrap={true}
                    >
                      Login
                    </Typography>
                  </Link>
                </div>
              )}
              {user && (
                <div onClick={handleProfileMenuOpen} className="profileMenuContainer">
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    color="inherit"
                    className="avatarImageButton"
                  >
                    <div style={{ width: '100%', position: 'relative' }}>
                      <UserAvatar
                        firstName={firstName}
                        lastName={lastName}
                        avatar={photo}
                        {...userProps}
                        classes="avatarImage"
                      />
                    </div>
                  </IconButton>
                  {isMenuOpen ? (
                    <ExpandLessIcon className="profileControlIcon" />
                  ) : (
                    <ExpandMoreIcon className="profileControlIcon" />
                  )}
                </div>
              )}
            </div>
          </Toolbar>
        ) : (
          <Toolbar className="toolBar">
            <Link to="/" classes="logo_link">
              <img src={logo} alt="" className="logo_image" />
            </Link>

            <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerOpen}>
              <MenuIcon className="header_menu_icon" />
            </IconButton>
          </Toolbar>
        )}
      </NoSsr>
    </div>
  );
}

export default Header;
