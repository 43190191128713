import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SwipeableViews from 'react-swipeable-views';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Calendar from 'component/Calendar/Calendar';
import LessonCard from 'component/LessonCard/LessonCard';
import {
  useUpdateProfileInfoMutation,
  Profile as ProfileGraphqlProps,
  Level,
  useListInstrumentsQuery,
  useAdminListLessonQuery,
  RolesEnum,
  useAdminDeleteLessonMutation,
  useAdminUpdateLessonMutation,
} from 'api';
import { Spinner } from 'component/Button/Button';
import Prices from './Prices';
import MainInfo from './MainInfo';
import Experience from './Experience';
import Lesson from './Lesson';
import BookModal from './../Modal/BookModal';
import { convertUrlToEmbedYoutube } from 'utils/helpers';
import { ReviewList } from './ReviewList';
import './Info.scss';

const close_modal_cross = '/assets/close_modal_cross.svg';
const open_modal_icon = '/assets/open_modal_icon.svg';
const empty_rewies = '/assets/empty_rewies.svg';

interface ProfileProps {
  editMode?: boolean;
  user: ProfileGraphqlProps;
  reGetUser: () => void;
}

const Profile = ({ user, reGetUser, editMode = false }: ProfileProps) => {
  const history = useHistory();
  const [adminDeleteLessonMutation] = useAdminDeleteLessonMutation();
  const [adminUpdateLessonMutation] = useAdminUpdateLessonMutation();
  const [values, setValues] = useState<any>({
    skill: '',
    lesson: '',
    about: '',
    firstName: '',
    lastName: '',
    email: '',
    amount30min: null,
    amount60min: null,
    amountSubscription30min: null,
    amountSubscription60min: null,
  });
  const useListLessons = useAdminListLessonQuery({
    variables: {
      limit: 20000,
      since: '',
      until: '',
      userId: user.role === RolesEnum.Adult || user.role === RolesEnum.Child ? user?.id || undefined : undefined,
      coachId: user.role === RolesEnum.Coach ? user.id || undefined : undefined,
    },
  });
  const lessonsData = useListLessons?.data?.adminListLesson?.data || [];
  const [lessons, setLessons] = useState<string[]>([]);
  const [level, setLevel] = useState<any>(undefined);
  const [updateProfile] = useUpdateProfileInfoMutation();
  const intrumentsQuery = useListInstrumentsQuery();
  const intruments = intrumentsQuery?.data?.listInstruments;
  const [intrument, setInstument] = useState<any[]>([]);
  const [levelSelect, setLevelSelect] = useState<any[]>([]);
  const [isAuthenticated, setisAuthenticated] = useState<boolean>(true);
  const [disabledNext, setDisabledNext] = useState<boolean>(true);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [imageModal, setImageModal] = useState<any>(null);
  const [indexTab, setIndexTab] = useState<number>(0);
  const [isOpenLesson, setIsOpenLesson] = useState<string | null>(null);
  const [isBookModal, setIsBookModal] = useState(false);
  const [lessonModal, setLessonModal] = useState<any | null>(null);

  useEffect(() => {
    if (user) {
      setValues({
        // skill:
        //   user?.level !== null && user?.level !== undefined && user?.level[0] !== undefined && user?.level[0] !== null
        //     ? user?.level[0].charAt(0) + user?.level[0].slice(1).toLowerCase()
        //     : '',
        lesson: user.instruments,
        about: user.about,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        amount30min: user?.costsPerHalfHour ? user.costsPerHalfHour : 0,
        amount60min: user?.costsPerHour ? user?.costsPerHour : 0,
        amountSubscription30min: user?.subscriptionPerHalfHour ? user?.subscriptionPerHalfHour : 0,
        amountSubscription60min: user?.subscriptionPerHour ? user.subscriptionPerHour : 0,
      });
      // if (user.level) {
      //   setisAuthenticated(true);
      // }
      if (user) {
          setisAuthenticated(true);
        }
      const arr: string[] = [];
      user?.instruments?.forEach((item) => {
        if (item?.name) {
          arr.push(item?.name.charAt(0) + item?.name.slice(1).toLowerCase());
        }
      });
      setLessons(arr);
      // if (user.role === RolesEnum.Coach) {
      //   const newlevel: string[] = [];
      //   if (user.level) {
      //     user.level.forEach((item) => {
      //       if (item) {
      //         const str: string = `${item.charAt(0)}${item.slice(1).toLowerCase()}`;
      //         newlevel.push(str);
      //       }
      //     });
      //   }
      //   setLevel(newlevel.length !== 0 ? newlevel : undefined);
      // } else {
      //   setLevel(
      //     user?.level !== null && user?.level !== undefined && user?.level[0] !== undefined && user?.level[0]
      //       ? user.level[0].charAt(0) + user.level[0].slice(1).toLowerCase() + ''
      //       : undefined,
      //   );
      // }
    }
  }, [user]);

  const handleChange = (event) =>
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });

  const handleSubmit = useCallback(async () => {
    if (isAuthenticated) {
      const instrumentsArr: string[] = [];
      intruments?.forEach((item) => {
        lessons.forEach((item2) => {
          if (item?.name.toLowerCase() === item2.toLowerCase()) {
            instrumentsArr.push(item?.id);
          }
        });
      });
      try {
        // const levelCoach: Level[] = [];
        // if (level.length && user.role === RolesEnum.Coach) {
        //   level.forEach((item) => {
        //     levelCoach.push(Level[`${item}`]);
        //   });
        // }
        const instrumentsArray = instrumentsArr.map(e => {return {id: e, level: Level[`BEGINNER`]}})
        const newProfile = await updateProfile({
          variables: {
            // level: level ? (user.role === RolesEnum.Coach ? levelCoach : Level[`${level}`]) : undefined,
            instruments: instrumentsArray ? instrumentsArray : null,
            about: values.about ? values.about : '',
            costsPerHalfHour: values.amount30min ? Number.parseInt(values.amount30min) : 0,
            costsPerHour: values.amount60min ? Number.parseInt(values.amount60min) : 0,
            subscriptionPerHalfHour: values.amountSubscription30min
              ? Number.parseInt(values.amountSubscription30min)
              : 0,
            subscriptionPerHour: values.amountSubscription60min ? Number.parseInt(values.amountSubscription60min) : 0,
          },
        });
        if (newProfile) {
          setisAuthenticated(true);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      const instrumentsArr: string[] = [];
      intruments?.forEach((item) => {
        lessons.forEach((item2) => {
          if (item?.name.toLowerCase() === item2.toLowerCase()) {
            instrumentsArr.push(item?.id);
          }
        });
      });
      try {
        // const levelCoach: Level[] = [];
        // if (level.length !== 0 && user.role === RolesEnum.Coach) {
        //   level.forEach((item) => {
        //     levelCoach.push(Level[`${item}`]);
        //   });
        // }
        const instrumentsArray = instrumentsArr.map(e => {return {id: e, level: Level[`BEGINNER`]}})
        const newProfile = await updateProfile({
          variables: {
            // level: level ? (user.role === RolesEnum.Coach ? levelCoach : Level[`${level}`]) : undefined,
            instruments: instrumentsArray ? instrumentsArray : null,
            about: values.about ? values.about : '',
          },
        });
        if (newProfile) {
          setisAuthenticated(true);
          history.push('/coaches');
        }
      } catch (e) {
        console.log(e);
      }
    }
    reGetUser();
  }, [
    isAuthenticated,
    reGetUser,
    intruments,
    lessons,
    //level,
    user.role,
    updateProfile,
    values.about,
    values.amount30min,
    values.amount60min,
    values.amountSubscription30min,
    values.amountSubscription60min,
    history,
  ]);

  const handleChangeAmount = (prop) => (event) => setValues({ ...values, [prop]: event.target.value });

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();
  };

  const handleChangeLessons = useCallback(
    (event) => {
      if (event.target.value.length === 0) {
        setDisabledNext(true);
      } else if (level === undefined) {
        setDisabledNext(true);
      } else {
        setDisabledNext(false);
      }
      setLessons(event.target.value);
      setInstument(event.target.value);
    },
    [level],
  );

  const handleChangeLevel = useCallback(
    (event) => {
      if (event.target.value === '' && lessons.length === 0) {
        setDisabledNext(true);
      } else {
        if (lessons.length === 0) {
          setDisabledNext(true);
        } else {
          setDisabledNext(false);
        }
      }
      if (user.role === RolesEnum.Coach && event.target.value.length === 0) {
        setLevel(undefined);
      } else {
        setLevel(event.target.value);
      }
      setLevelSelect(event.target.value);
    },
    [lessons, user],
  );

  const handleChangeTab = (event, value) => {
    setIndexTab(value);
  };

  const handleChangeIndex = (index) => {
    setIndexTab(index);
  };

  const closeImageModal = useCallback(() => {
    setIsOpenImage(false);
    setImageModal(null);
  }, []);

  const openImageModal = useCallback((image) => {
    setIsOpenImage(true);
    setImageModal(image);
  }, []);

  const isFileDiploma = () => {
    let isDiploma = false;
    if (user?.files) {
      user.files.forEach((item) => {
        if (item?.type) {
          if (item.type !== 'avatar') {
            isDiploma = true;
          }
        }
      });
    }
    return isDiploma;
  };

  const handleGoToLesson = useCallback((id) => {
    setIsOpenLesson(id);
  }, []);

  const handleEvents = useCallback(
    (data) => {
      setLessonModal(lessonsData.find((item) => item?.id === data.event._def.publicId));
      openBookModal();
    },
    [lessonsData],
  );

  const closeBookModal = useCallback(() => {
    setIsBookModal(false);
    setLessonModal(null);
  }, []);

  const openBookModal = useCallback(() => {
    setIsBookModal(true);
  }, []);

  const handleDeleteLesson = useCallback(
    async (id) => {
      try {
        await adminDeleteLessonMutation({ variables: { id } });
        await useListLessons.refetch();
        closeBookModal();
      } catch (error) {
        console.log(error);
        await useListLessons.refetch();
        closeBookModal();
      }
    },
    [adminDeleteLessonMutation, useListLessons, closeBookModal],
  );

  const handleSaveLesson = useCallback(async () => {
    try {
      await adminUpdateLessonMutation({
        variables: {
          topic: lessonModal?.topic || '',
          lessonId: lessonModal.id,
          startAt: moment(+lessonModal.startAt)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          finishAt: moment(+lessonModal.finishAt)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          musicInstrumentId: lessonModal.musicInstrumentId,
        },
      });
      await useListLessons.refetch();
      closeBookModal();
    } catch (error) {
      console.log(error);
      await useListLessons.refetch();
      closeBookModal();
    }
  }, [lessonModal, adminUpdateLessonMutation, useListLessons, closeBookModal]);

  const handleChangeTopic = useCallback(
    (event) => {
      setLessonModal({ ...lessonModal, topic: event.target.value });
    },
    [lessonModal],
  );

  const returnDiploma = () => {
    if (user?.files) {
      return user?.files.map((item, index) => {
        if (item?.type !== 'avatar') {
          return (
            <div key={item?.type + '' + index} className="profileDiplomaContainer">
              <img alt="" src={item?.path || ''} className="profileDiploma" />
              <img
                alt=""
                src={open_modal_icon}
                className="profileDiplomaOpen"
                onClick={() => openImageModal(item?.path || '')}
              />
            </div>
          );
        } else {
          return <div />;
        }
      });
    } else {
      return <div />;
    }
  };

  const renderRightColumn = (
    <div className={`profileRightColumnFieldsAdmin ${!isAuthenticated ? 'limitedFields' : ''}`}>
      <div className="tabs_header">
        <Tabs value={indexTab} onChange={handleChangeTab}>
          <Tab label="General" />
          {user?.role === RolesEnum.Coach && <Tab label="Reviews" />}
          <Tab label="Calendar" />
          <Tab label="Lessons" />
          {user?.role === RolesEnum.Coach && <Tab label="Payments" />}
        </Tabs>
        {user?.role === RolesEnum.Coach ? (
          <SwipeableViews index={indexTab} onChangeIndex={handleChangeIndex}>
            <div
              key="General"
              style={{ marginTop: '32px' }}
              className={`profileRightColumnFieldsAdmin ${!isAuthenticated ? 'limitedFields' : ''}`}
            >
              {isAuthenticated && <MainInfo handleChange={handleChange} values={values} />}
              <Experience
                isAuthenticated={isAuthenticated}
                lessons={lessons}
                handleChangeLessons={handleChangeLessons}
                handleChangeLevel={handleChangeLevel}
                handleChange={handleChange}
                values={values}
                level={level}
                handleKeyPress={handleKeyPress}
                intrument={intrument}
                levelSelect={levelSelect}
                user={user}
              />
              {isAuthenticated && user.role === RolesEnum.Coach && (
                <div className="profileMediaSection">
                  <Typography className="profileTitle">Media files</Typography>
                  <div className={`profileMediaActions`}>
                    <Typography className="profileTitle">Diploma/certificate</Typography>
                    {isFileDiploma() ? <div className="profileImagesOfDiploma">{returnDiploma()}</div> : null}
                    <Typography className="profileTitle">Video</Typography>
                    {user.videoUrl && (
                      <div>
                        <iframe
                          title="youtube"
                          className="video_frame"
                          width={(global.window ? (global.window as any).innerWidth : 0) < 700 ? '300' : '670'}
                          height={(global.window ? (global.window as any).innerWidth : 0) < 700 ? '140' : '380'}
                          src={convertUrlToEmbedYoutube(user.videoUrl)}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div style={{ marginTop: '32px' }} key="Rewiews">
              {user?.feedback?.length !== 0 ? (
                <ReviewList rating={user?.rating} feedback={user?.feedback} />
              ) : (
                <img alt="" src={empty_rewies} style={{ width: '100%' }} />
              )}
            </div>
            <div key="Calendar" className="calendar">
              <Calendar
                handleDateSelect={() => {}}
                handleEventClick={handleEvents}
                handleEvents={() => {}}
                dataB={[]}
                role={user.role}
                lesson={lessonsData}
                selectable={false}
                lessonBook={[]}
                admin={true}
                coach={user.role === RolesEnum.Coach ? user : undefined}
              />
            </div>
            <div key="Lessons" className="lessons">
              {!isOpenLesson ? (
                lessonsData.map((item) => <LessonCard lesson={item} admin={true} adminGoToLesson={handleGoToLesson} />)
              ) : (
                <Lesson user={user} coachId={isOpenLesson} />
              )}
            </div>
            <div style={{ marginTop: '32px' }} key="Payments">
              {isAuthenticated && (user.role === RolesEnum.Coach || user.role === RolesEnum.Adult) && (
                <Prices
                  handleChangeAmount={handleChangeAmount}
                  amount30min={values.amount30min}
                  amount60min={values.amount60min}
                  amountSubscription30min={values.amountSubscription30min}
                  amountSubscription60min={values.amountSubscription60min}
                  lastDigits={user?.balance?.lastDigits || undefined}
                />
              )}
              {editMode && (
                <div className={`profileActions ${!isAuthenticated ? 'profileActionsPartial' : ''}`}>
                  <Button
                    disabled={!isAuthenticated && disabledNext}
                    className={`profileButton ${!isAuthenticated && disabledNext ? 'profileDisabled' : ''}`}
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                  >
                    {isAuthenticated ? 'Save' : 'Next'}
                    {false && <Spinner style={{ position: 'absolute', color: '#fff' }} />}
                  </Button>
                </div>
              )}
            </div>
          </SwipeableViews>
        ) : (
          <SwipeableViews index={indexTab} onChangeIndex={handleChangeIndex}>
            <div
              key="General"
              style={{ marginTop: '32px' }}
              className={`profileRightColumnFieldsAdmin ${!isAuthenticated ? 'limitedFields' : ''}`}
            >
              {isAuthenticated && <MainInfo handleChange={handleChange} values={values} />}
              <Experience
                isAuthenticated={isAuthenticated}
                lessons={lessons}
                handleChangeLessons={handleChangeLessons}
                handleChangeLevel={handleChangeLevel}
                handleChange={handleChange}
                values={values}
                level={level}
                handleKeyPress={handleKeyPress}
                intrument={intrument}
                levelSelect={levelSelect}
                user={user}
              />
            </div>
            <div key="Calendar" className="calendar">
              <Calendar
                handleDateSelect={() => {}}
                handleEventClick={handleEvents}
                handleEvents={() => {}}
                dataB={[]}
                role={RolesEnum.Adult}
                lesson={lessonsData}
                selectable={false}
                lessonBook={[]}
                admin={true}
              />
            </div>
            <div key="Lessons" className="lessons">
              {!isOpenLesson ? (
                lessonsData.map((item) => <LessonCard lesson={item} admin={true} adminGoToLesson={handleGoToLesson} />)
              ) : (
                <Lesson user={user} coachId={isOpenLesson} />
              )}
            </div>
          </SwipeableViews>
        )}
      </div>
    </div>
  );

  const handleChooseInstrument = useCallback(
    (instrument) => {
      setLessonModal({ ...lessonModal, instrument: instrument.target.value });
    },
    [lessonModal],
  );

  const handleChooseDate = useCallback(
    (date) => {
      setLessonModal({
        ...lessonModal,
        startAt: moment(date)
          .set({
            hour: moment(+lessonModal.startAt).hour(),
            minute: moment(+lessonModal.startAt).minute(),
          })
          .format('x'),
        finishAt: moment(date)
          .set({
            hour: moment(+lessonModal.finishAt).hour(),
            minute: moment(+lessonModal.finishAt).minute(),
          })
          .format('x'),
      });
    },
    [lessonModal],
  );

  const handleChooseStart = useCallback(
    (start) => {
      if (moment(start).hours() >= moment(lessonModal.end).hours()) {
        setLessonModal({
          ...lessonModal,
          startAt: moment(start).format('x'),
          finishAt: moment(start).add(30, 'minutes').format('x'),
        });
      } else {
        setLessonModal({
          ...lessonModal,
          startAt: moment(start).format('x'),
        });
      }
    },
    [lessonModal],
  );

  const handleChooseEnd = useCallback(
    (end) => {
      if (+moment(end).format('x') - +moment(lessonModal.start).format('x') > 3600000) {
        setLessonModal({
          ...lessonModal,
          finishAt: moment(end).format('x'),
          startAt: moment(end).subtract(30, 'minutes').format('x'),
        });
      } else {
        setLessonModal({
          ...lessonModal,
          finishAt: moment(end).format('x'),
        });
      }
    },
    [lessonModal],
  );

  return (
    <div className="profileContainerAdmin">
      <Modal open={isOpenImage} onClose={closeImageModal} className="profile_modal">
        <div className="profile_modal_video">
          <div className="profile_modal_video_header">
            <p className="profile_modal_video_title"></p>
            <img alt="" src={close_modal_cross} className="profile_modal_video_close" onClick={closeImageModal} />
          </div>
          <img alt="" src={imageModal} className="profile_modal_image_prewie" />
        </div>
      </Modal>
      <Modal open={isBookModal} onClose={closeBookModal} className="profile_modal">
        <BookModal
          lessonModal={lessonModal}
          closeBookModal={closeBookModal}
          handleChooseDate={handleChooseDate}
          handleChooseStart={handleChooseStart}
          handleChooseEnd={handleChooseEnd}
          handleChooseInstrument={handleChooseInstrument}
          handleDeleteLesson={handleDeleteLesson}
          handleSaveLesson={handleSaveLesson}
          handleChangeTopic={handleChangeTopic}
        />
      </Modal>
      <div className="profileColumn profileRightColumn" style={!isAuthenticated ? { marginTop: '65px' } : {}}>
        {renderRightColumn}
      </div>
    </div>
  );
};

export default Profile;
