import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { RolesEnum } from 'api';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// import Checkbox from '@material-ui/core/Checkbox';

const MainInfo = ({
  handleChange,
  values,
  errors = { firstName: '', lastName: '', email: '', birthdate: '', workWithAges: '' },
  disabledFields = false,
  user,
  handleChangeBirthdate,
  handleChangeAges = (event) => {},
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '36px',
      }}
    >
      <Typography className="profileTitle">Personal Info</Typography>

      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
        <div className="profileFormFields">
          <TextField
            className="profileFormField profileFormFieldFirstName"
            variant="outlined"
            name="firstName"
            label="First name"
            required={true}
            disabled={disabledFields}
            type="text"
            onChange={handleChange}
            value={values.firstName || ''}
            error={!!errors?.firstName}
            helperText={errors?.firstName}
            fullWidth={true}
            InputProps={{
              classes: {
                focused: `focusedInputField`,
              },
            }}
          />
          <TextField
            style={{ width: '100%' }}
            className="profileFormField profileFormFieldSurname"
            variant="outlined"
            name="lastName"
            label="Last name"
            type="text"
            required={true}
            error={!!errors?.lastName}
            helperText={errors?.lastName}
            onChange={handleChange}
            value={values.lastName || ''}
            fullWidth={true}
            disabled={disabledFields}
            InputProps={{
              classes: {
                focused: `focusedInputField`,
              },
            }}
          />
        </div>
        <div className="profileFormFields">
          <TextField
            className="profileFormField profileFormFieldEmail"
            variant="outlined"
            name="email"
            label="Email"
            type="text"
            required={true}
            onChange={handleChange}
            value={values.email || ''}
            error={!!errors?.email}
            helperText={errors?.email}
            fullWidth={true}
            disabled={true}
            InputProps={{
              classes: {
                focused: `focusedInputField`,
              },
            }}
          />
          {user.role !== RolesEnum.Coach && (
            <KeyboardDatePicker
              disabled={user.role === RolesEnum.Child}
              error={!!errors.birthdate}
              className="profileFormField profileFormFieldBirthdate"
              autoOk={true}
              required={true}
              variant="inline"
              inputVariant="outlined"
              name="birthdate"
              label="Birth date"
              openTo="year"
              helperText={"We need to know your age because some coaches specialize in certain age groups."}
              format="MMM DD,yyyy"
              value={values.birthdate || ''}
              InputAdornmentProps={{ position: 'start' }}
              onChange={handleChangeBirthdate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          )}

          {user.role === RolesEnum.Coach && (
            <FormControl className="form_control profileFormField profileFormFieldAges">
              <InputLabel disabled={true} className="lessonsPlaceholder form_control_label">
                Choose ages group you are going to teach
              </InputLabel>
              <Select
                MenuProps={{ variant: 'menu' }}
                labelId="demo-mutiple-name-label"
                className="profileFormField profileFormFieldAges"
                id="demo-mutiple-name"
                required={true}
                input={<OutlinedInput notched={true} />}
                multiple
                value={values.workWithAges}
                error={!!errors.workWithAges}
                autoWidth={true}
                renderValue={(value) => {
                  if (user.role === RolesEnum.Coach) {
                    const selected: any = value;
                    if (selected.length) {
                      return selected.join(', ');
                    } else {
                      return '';
                    }
                  } else {
                    return value;
                  }
                }}
                onChange={handleChangeAges}
              >
                {['5-7', '8-11', '12-17', '18+'].map((ages) => (
                  <MenuItem key={ages} value={ages} disabled={disabledFields}>
                    {ages}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.workWithAges ? errors.workWithAges : ''}</FormHelperText>
            </FormControl>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainInfo;
