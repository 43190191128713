import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { Spinner } from 'component/Button/Button';
import Loader from 'component/Loader/Loader';
import validateEmail from 'utils/emailUtils';
import useWindowDimensions from 'utils/hooks/useWindowDimentions';
import './SignIn.scss';

const mainImage = '/assets/sign_up_image.png';

const Credentials = ({ actionsLink, fieldsLink, error }) => (
  <div className="loginRightColumnFields">
    {fieldsLink}
    {error}
    {actionsLink}
  </div>
);

const INITIAL_VALUES_STATE = {
  email: '',
  password: '',
};

const INITIAL_ERRORS_STATE = { email: '', password: '', auth: '' };

interface SignInProps {
  signIn: (values: any) => any;
}

function SignIn({ signIn }: SignInProps) {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [values, setValues] = useState(INITIAL_VALUES_STATE);
  const [errors, setErrors] = useState(INITIAL_ERRORS_STATE);
  const [error, setError] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    document.querySelector('[type="password"]');
  }, []);

  const handleChangePassword = (event) => {
    setErrors({ ...errors, password: '', auth: '' });
    setError(false);
    if (event.target.name === 'password' && !event.target.value) {
      setErrors({ ...errors, password: 'Please enter a valid password' });
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeEmail = (event) => {
    setErrors({ ...errors, email: '', auth: '' });
    setError(false);
    if (event.target.name === 'email' && !validateEmail(event.target.value)) {
      setErrors({ ...errors, email: 'Please enter a valid email address' });
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleMouseDownPassword = useCallback((event) => event.preventDefault(), []);

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    if (Object.keys(errors).some((k) => errors[k])) {
      setErrors({ email: '', password: '', auth: '' });
    }
  };

  const fieldsLink = (
    <>
      <TextField
        className={`loginFormField ${errors.email ? 'loginFormFieldError' : ''}`}
        error={!!errors.email}
        variant="outlined"
        name="email"
        label="Email"
        type="text"
        onChange={handleChangeEmail}
        value={values.email || ''}
        fullWidth={true}
        onKeyPress={handleKeyPress}
        helperText={errors.email ? errors.email : ''}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />

      <TextField
        className={`loginFormField loginPasswordField ${errors.password ? 'loginFormFieldError' : ''}`}
        error={!!errors.password}
        helperText={errors.password ? errors.password : ''}
        name="password"
        label="Password"
        variant="outlined"
        onKeyPress={handleKeyPress}
        type={showPassword ? 'text' : 'password'}
        onChange={handleChangePassword}
        value={values.password || ''}
        fullWidth={true}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className="loginVisibilityIcon"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  const [hasError, hasContent] = [
    Object.keys(errors).some((k) => errors[k]),
    Object.keys(values).every((k) => values[k]),
  ];
  const disabled = hasError || !hasContent;

  const handleSignUp = () => {
    history.push('/signup');
  };

  const handleLogin = useCallback(async () => {
    try {
      setLoad(true);
      const log = await signIn(values);
      if (log === 'Error') {
        setError(true);
      }
      setLoad(false);
    } catch (e) {
      setLoad(false);
      // tslint:disable-next-line: no-console
      console.log(e);
    }
  }, [values, signIn]);

  const actionsLink = (
    <div className="loginActions">
      <Typography
        onClick={!false ? () => history.push('/pw-reset') : () => {}}
        className={`loginForgotPasswordText ${false ? 'loginDisabled' : ''}`}
        variant="body2"
      >
        Forgot your password?
      </Typography>

      <Button
        disabled={disabled}
        className={`loginButton ${disabled ? 'loginDisabled' : ''}`}
        variant="contained"
        color="secondary"
        onClick={handleLogin}
      >
        Log in
        {false && <Spinner style={{ position: 'absolute', color: '#fff' }} />}
      </Button>
      {width < 480 && (
        <Button className={`signupButton`} variant="contained" color="secondary" onClick={handleSignUp}>
          Sign Up
        </Button>
      )}
    </div>
  );

  return (
    <div className="loginContainer">
      <div className="loginColumn loginLeftColumn">
        <Typography className="loginLeftColumnTitle" variant="h4">
          <span>Log in </span>
          <br />
          <span>to Best Music Coach</span>
        </Typography>
        <img src={mainImage} alt="" className="loginLogo" />
        <div className="loginCoursesWrapper">
          <div className='loginCoursesText'>This Log in page is for music lessons</div>
          <div className='loginCoursesText'>
            If you are looking for your book or course, kindly Log in{' '}
            <a className='loginCoursesLink' target="_blank" href="https://bestmusiccoach.com/books-and-courses-login/">
              Here
            </a>
          </div>
        </div>
      </div>
      <div className="loginColumn loginRightColumn">
        {load ? (
          <Loader />
        ) : (
          <Credentials
            fieldsLink={fieldsLink}
            error={error ? <span className="signinError">Wrong email or password</span> : null}
            actionsLink={actionsLink}
          />
        )}
      </div>
    </div>
  );
}

export default SignIn;
