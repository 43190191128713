import { Modal, Button } from '@material-ui/core';
import Line from 'component/Line/Line';
import React, { useState } from 'react';
import './ManageSubscriptionsModal.scss';
import { useListUpcomingSubscriptionsQuery, useCancelUpcomingSubscriptionMutation, ListUpcomingSubscriptionsDocument } from 'api';
import UserAvatar from 'component/UserAvatar/UserAvatar';

const closeModalIcon = '/assets/close_modal_cross.svg';

const ManageSubscriptionsModal = () => {
  const upcomingSubscriptions = useListUpcomingSubscriptionsQuery({ fetchPolicy: 'network-only' }).data
    ?.listUpcomingSubscriptions;
  const [cancelSubscription] = useCancelUpcomingSubscriptionMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{query: ListUpcomingSubscriptionsDocument}],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Button className="manage__subscriptions__modal__open__button" onClick={() => setIsModalOpen(true)}>
        Manage subscriptions
      </Button>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} className="manage__subscriptions__modal__wrapper">
        <div className="manage__subscriptions__modal">
          <div className="manage__subscriptions__modal__close__button__wrapper">
            <img
              src={closeModalIcon}
              alt="Close Modal Icon"
              className="manage__subscriptions__modal__close__button"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <p className="manage__subscriptions__modal__title">Manage subscriptions</p>
          <Line />
          <div className="manage__subscriptions__modal__items">
            {upcomingSubscriptions?.map((item) => {
              return (
                <div className="manage__subscriptions__modal__item">
                  <UserAvatar
                    avatar={item?.coachAvatarUrl || ''}
                    firstName={item?.coachFirstname || ''}
                    lastName={item?.coachLastname || ''}
                  />
                  <p className="manage__subscriptions__modal__item__name">
                    {item?.coachFirstname}{" "}
                    <br />
                    {item?.coachLastname}
                  </p>
                  <p className="manage__subscriptions__modal__item__lesson">
                    Upcoming{" "}
                    <br />
                    Subsription
                  </p>
                  <p className="manage__subscriptions__modal__item__date">
                    {item?.startDate!} -{' '}
                    {item?.endDate!}
                  </p>
                  <p className="manage__subscriptions__modal__item__costs">$ {item?.amount}</p>
                  <Button
                    onClick={() => {
                      cancelSubscription({ variables: { orderId: item?.orderId! } });
                    }}
                    className="manage__subscriptions__modal__item__button"
                  >
                    Cancel
                  </Button>
                </div>
              );
            })}
          </div>
          <p className="manage__subscriptions__modal__description">
            Cancel any time. Subscription lessons repeat every week for 1 month (5 weeks) at the same time on the same
            day.
            <br />
            For example, if you pick Tuesday at 1 pm, your lessons will be every Tuesday at 1 pm.
            <br />
            You can cancel or reschedule lessons in your calendar tab.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ManageSubscriptionsModal;
