import { Modal } from '@material-ui/core';
import React from 'react';
import './BrowserValidationModal.scss';

const closeModalIcon = '/assets/close_modal_cross.svg';
const chromeLogo = '/assets/chrome_logo.png';
const safariLogo = '/assets/safari_logo.png';

const BrowserValidationModal = ({isModalOpen, setIsModalOpen}) => {
  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} className="browser__validation__modal__wrapper">
      <div className="browser__validation__modal">
        <div className="browser__validation__modal__close__button__wrapper">
          <img
            src={closeModalIcon}
            alt="Close Modal Icon"
            className="browser__validation__modal__close__button"
            onClick={() => setIsModalOpen(false)}
          />
        </div>
        <p className="browser__validation__modal__title">Unsupported browser</p>
        <p className="browser__validation__modal__subtitle">
          You’re using a web browser we don’t support yet. Please use the latest version of google chrome or safari.
        </p>
        <div className="browser__validation__modal__items">
          <div className="browser__validation__modal__item">
            <img className="browser__validation__modal__item__icon" src={chromeLogo} alt="Chrome Logo" />
            <p className="browser__validation__modal__item__text">Google Chrome</p>
          </div>
          <div className="browser__validation__modal__item">
            <img className="browser__validation__modal__item__icon" src={safariLogo} alt="Safari Logo" />
            <p className="browser__validation__modal__item__text">Safari</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BrowserValidationModal;
