import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdminListCouponQuery, CouponType } from 'api/index';
import BasicTable from './components/BasicTable';
import BasicPagination from '../../components/BasicPagination';
import Button from '../../../../component/ButtonMatireal/ButtonMatireal';
import Loader from '../../components/CustomLoader';
import './Coupon.scss';

export default function CouponList() {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const { push } = useHistory();

  const listCouponQuery = useAdminListCouponQuery({
    variables: {
      limit: 10,
      offset: 0,
    },
    fetchPolicy: 'network-only',
  });

  const rows = listCouponQuery?.data?.adminListCoupon?.data || [];
  const total = listCouponQuery?.data?.adminListCoupon?.meta?.count || 0;
  const loading = listCouponQuery?.loading;

  const requestList = useCallback(
    (page, limit) => {
      let variables = {
        limit: limit,
        offset: page * limit,
      };
      listCouponQuery.refetch(variables);
    },
    [listCouponQuery],
  );

  const rowPage = useCallback(
    (event) => {
      setLimit(event.target.value);
      setPage(0);
      requestList(0, event.target.value);
    },
    [requestList],
  );

  const nextPage = useCallback(
    (e, np) => {
      setPage(np);
      requestList(np, limit);
    },
    [requestList, limit],
  );

  const onChangePage = (e, numberOfPage) => {
    global.window && (global.window as any).scrollTo(0, 0);
    nextPage(e, numberOfPage);
  };

  const onPageClick = (e: React.ChangeEvent<unknown>, value: number) => {
    const page = value - 1;
    nextPage(e, page);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="coupon_list">
      <div className="coupon_list_title">
        <p>Coupons</p>
        <Button onClick={() => push('/admin/coupon/new')}>Create Coupon</Button>
      </div>
      <BasicTable rows={rows as Array<CouponType>} />
      <BasicPagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        onChangeRowsPerPage={rowPage}
        onChangePage={onChangePage}
        onPageClick={onPageClick}
        total={total}
        limit={limit}
        page={page}
      />
    </div>
  );
}
