import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../modules/routes';
import LessonsList from './LessonsList';
import LessonsProfile from './LessonsProfile';

export default function Component() {
  return (
    <>
      <Switch>
        <Route exact key={routes.adminLessons} path={routes.adminLessons}>
          <LessonsList />
        </Route>
        <Route exact key={routes.adminLesson} path={routes.adminLesson}>
          <LessonsProfile />
        </Route>
      </Switch>
    </>
  );
}
