import React from 'react';
import Typography from '@material-ui/core/Typography';
import useWindowDimensions from 'utils/hooks/useWindowDimentions';
import Availability from './Availability';
import Instrument from './Instrument';
import Level from './Level';
import './Filters.scss';
import { widthSizeScreen } from 'utils/constans';

interface FiltersProps {
  handleDateFilter: (e: any) => void;
  handleInstrFilter: (e: any) => void;
  handleLevelFilter: (e: any) => void;
  clearAllFilter: () => void;
  handleClearDateFilter: () => void;
  handleClearInstrFilter: () => void;
  handleClearLevelFilter: () => void;
  listInstruments: any;
  dateFilter: any | null;
  instrFilter: any[] | null;
  levelFilter: string | null;
  choosenInstrName: string | null;
  setChosenInstrName: (value: string | null) => void;
}

function Filters({
  dateFilter,
  instrFilter,
  levelFilter,
  handleDateFilter,
  handleInstrFilter,
  handleLevelFilter,
  clearAllFilter,
  handleClearDateFilter,
  handleClearInstrFilter,
  handleClearLevelFilter,
  listInstruments,
  choosenInstrName,
  setChosenInstrName,
}: FiltersProps) {
  const { width } = useWindowDimensions();

  return (
    <div className="filters">
      {width >= widthSizeScreen.sm ? (
        <>
          <div className="filtersMainButtonsContainer">
            <Availability
              dateFilter={dateFilter}
              handleDateFilter={handleDateFilter}
              handleClearDateFilter={handleClearDateFilter}
            />
            <Instrument
              instrFilter={instrFilter}
              handleInstrFilter={handleInstrFilter}
              handleClearInstrFilter={handleClearInstrFilter}
              listInstruments={listInstruments}
              choosenInstrName={choosenInstrName}
              setChosenInstrName={setChosenInstrName}
            />
            <Level
              levelFilter={levelFilter}
              handleLevelFilter={handleLevelFilter}
              handleClearLevelFilter={handleClearLevelFilter}
            />
          </div>
          <div className={`filtersClearAll ${false ? 'withFilters' : ''}`}>
            <Typography
              variant="h6"
              onClick={clearAllFilter}
              className={levelFilter || instrFilter || dateFilter ? 'filtersActive' : ''}
            >
              Clear all
            </Typography>
          </div>
        </>
      ) : (
        <>
          <div className="filtersMainButtonsContainer">
            <Availability
              dateFilter={dateFilter}
              handleDateFilter={handleDateFilter}
              handleClearDateFilter={handleClearDateFilter}
            />
            <Instrument
              instrFilter={instrFilter}
              handleInstrFilter={handleInstrFilter}
              handleClearInstrFilter={handleClearInstrFilter}
              listInstruments={listInstruments}
              choosenInstrName={choosenInstrName}
              setChosenInstrName={setChosenInstrName}
            />
          </div>
          <div className={`filtersClearAll ${false ? 'withFilters' : ''}`}>
            <Level
              levelFilter={levelFilter}
              handleLevelFilter={handleLevelFilter}
              handleClearLevelFilter={handleClearLevelFilter}
            />
            <Typography
              variant="h6"
              onClick={clearAllFilter}
              className={levelFilter || instrFilter || dateFilter ? 'filtersActive' : ''}
            >
              Clear all
            </Typography>
          </div>
        </>
      )}
    </div>
  );
}

export default Filters;
