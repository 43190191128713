import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../modules/routes';
import AdultsList from './AdultsList';
import AdultProfile from './AdultProfile';

export default function Component() {
  return (
    <>
      <Switch>
        <Route exact key={routes.adminAdults} path={routes.adminAdults}>
          <AdultsList />
        </Route>
        <Route exact key={routes.adminAdult} path={routes.adminAdult}>
          <AdultProfile />
        </Route>
      </Switch>
    </>
  );
}
