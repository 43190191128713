import React from 'react';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import { isAvatar, getAvatar } from 'utils/image';
import './Reviews.scss';

const Reviews = ({ review }) => {
  return (
    <div key={review.id} className="reviews_container">
      <div className="reviews_container_header">
        <div className="reviews_container_header_info">
          <UserAvatar
            avatar={isAvatar(review?.files || []) ? getAvatar(review?.files || []) : ''}
            firstName={review?.student?.firstName || ''}
            lastName={review?.student?.lastName || ''}
            classes={'reviews_container_header_info_avatar'}
          />
          <div className="reviews_container_header_info_name_and_date">
            <p className="reviews_container_header_info_name">{review?.student?.firstName || ''}</p>
            <p className="reviews_container_header_info_date">{moment(+review?.createdAt).format('MMM, DD, YYYY')}</p>
          </div>
        </div>
        <Rating name="read-only" value={review?.starCount || 0} readOnly />
      </div>
      <div className="reviews_container_body_comment">{review.comment}</div>
    </div>
  );
};

export default Reviews;
