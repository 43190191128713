import React, { FC, useState, useCallback, useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import UserAvatar from '../../component/UserAvatar/UserAvatar';
import Rating from '../../component/CourseCard/Rating';
import Levels from '../../component/Levels/Levels';
import Reviews from 'component/Reviews/Reviews';
import Line from '../../component/Line/Line';
import Loader from 'component/Loader/Loader';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import useWindowDimensions from 'utils/hooks/useWindowDimentions';
import { AuthModal } from 'component/AuthModal/AuthModal';
import { useGetCoachQuery, RolesEnum, Profile } from 'api';
import { getAvatar, isAvatar } from 'utils/image';
import { getInstrumentNewName, convertUrlToEmbedYoutube } from 'utils/helpers';
import './Coach.scss';

const closeModalCross = '/assets/close_modal_cross.svg';
const openModalIcon = '/assets/open_modal_icon.svg';

type Props = {
  user: Profile;
};

const Coach: FC<Props> = ({ user }) => {
  const { width } = useWindowDimensions();
  const params: any = useRouteMatch().params;
  const history = useHistory();
  const coachQuery = useGetCoachQuery({ variables: { id: params.id }, fetchPolicy: 'network-only' });
  const [imageModal, setImageModal] = useState<any>(null);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const coach = coachQuery?.data?.getCoach || null;

  const coachRating = useMemo(() => {
    return Math.round(coach?.rating || 0);
  }, [coach]);

  const closeImageModal = useCallback(() => {
    setIsOpenImage(false);
    setImageModal(null);
  }, []);

  const isFileDiploma = () => {
    let isDiploma = false;
    if (coach?.files) {
      coach.files.forEach((item) => {
        if (item?.type) {
          if (item.type !== 'avatar') {
            isDiploma = true;
          }
        }
      });
    }
    return isDiploma;
  };

  const openImageModal = useCallback((image) => {
    setIsOpenImage(true);
    setImageModal(image);
  }, []);

  const returnDiploma = () => {
    if (coach?.files) {
      return coach?.files.map((item, index) => {
        if (item?.type !== 'avatar') {
          return (
            <div key={item?.type + '' + index} className="profileDiplomaContainer">
              <div
                className="profileDiploma"
                style={{
                  backgroundImage: `url(${item?.path || ''})`,
                }}
              />
              <img
                alt=""
                src={openModalIcon}
                className="profileDiplomaOpen"
                onClick={() => openImageModal(item?.path || '')}
              />
            </div>
          );
        } else {
          return <div />;
        }
      });
    } else {
      return <div />;
    }
  };

  const CoachInfo = () => {
    return (
      <div className="coach_info">
        {coach?.videoUrl && (
          <iframe
            title="youtube"
            width={(global.window ? (global.window as any).innerWidth : 0) < 700 ? '300' : '670'}
            height={(global.window ? (global.window as any).innerWidth : 0) < 700 ? '140' : '380'}
            src={convertUrlToEmbedYoutube(coach?.videoUrl)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            className="coach_info_img"
          />
        )}
        <div className="coach_info_container">
          <h3>Lessons</h3>
          <div className="coach_info_instruments">
            {coach?.instruments &&
              coach?.instruments?.map((item) => {
                return (
                  <div key={item?.name.toLowerCase()} className="coach_info_instrument">
                    <div className="coach_info_instrument_type">
                      <span key={item?.id} className="coach_info_instruments_circul">
                        <img
                          src={getInstrumentNewName(item?.name || '')}
                          className="coach_info_instruments_img"
                          alt="coach_info_instruments_img"
                        />
                        <span
                          className="coach_info_instruments_img_green"
                          style={{
                            WebkitMaskImage: `url(/assets/new_icon/${item?.name.toLowerCase()}.svg)`,
                            maskImage: `url(/assets/new_icon/${item?.name.toLowerCase()}.svg)`,
                          }}
                        />
                      </span>
                      <p className="coach_info_instruments_name">
                        {`${item?.name.replace('_', '  ').charAt(0)}${item?.name
                          .replace('_', '  ')
                          .slice(1)
                          .toLowerCase()}`}
                      </p>
                    </div>
                    <Levels level={[item?.level] || []} />
                  </div>
                );
              })}
          </div>
          <Line />
          <h3>About</h3>
          <p className="coach_info_about">{coach?.about}</p>
          <Line />
          {isFileDiploma() && <h3>Diploma/Certificate</h3>}
          {isFileDiploma() ? <div className="coach_info_profileImagesOfDiploma">{returnDiploma()}</div> : null}
          {isFileDiploma() && <Line />}
          {coach?.feedback?.length !== 0 ? (
            <>
              <h3>Reviews</h3>
              {coach?.feedback && coach?.feedback.map((item) => <Reviews key={item?.createdAt} review={item} />)}
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const CoachPriceLabelsView = () => (
      <>
        <div className="coach_price_container_row">
          <div className="coach_price_name">Lesson</div>
          <div style={{display:"flex", justifyContent:"space-between"}} className="coach_price_30price">
            <div style={{textDecoration: "line-through", textDecorationColor: "red", color: "#605F5E", fontSize: 12}} className="coach_price_30price">${(coach?.costsPerHalfHour || 100) / 100}</div>
            <div className="coach_price_30price">${(coach?.costsPerHalfHour || 100) / 200}</div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between"}} className="coach_price_60price">
            <div style={{textDecoration: "line-through", textDecorationColor: "red", color: "#605F5E", fontSize: 12}} className="coach_price_60price">${(coach?.costsPerHour || 100) / 100}{" "}</div>
            <div className="coach_price_60price">${(coach?.costsPerHour || 100) / 200}</div>
          </div>
        </div>
        <div className="coach_price_container_row">
          <div className="coach_price_name">Subscription</div>
          <div className="coach_price_30price">${(coach?.subscriptionPerHalfHour || 100) / 100}</div>
          <div className="coach_price_60price">${(coach?.subscriptionPerHour || 100) / 100}</div>
        </div>
      </>
  )

  const CoachPrice = () => {
    return (
      <div className="coach_price">
        {width > 797 ? (
          <>
            <UserAvatar
              avatar={coach?.files && isAvatar(coach?.files) ? getAvatar(coach?.files) : ''}
              firstName={coach?.firstName || ''}
              lastName={coach?.lastName || ''}
              classes={'coach_price_avatar_size'}
            />
            <p>{`${coach?.firstName} ${coach?.lastName}`}</p>
            <Rating value={coachRating} row={false} views={coach?.feedback?.length || 0} />
          </>
        ) : (
          <div className="coach_price_mobile_header">
            <UserAvatar
              avatar={coach?.files && isAvatar(coach?.files) ? getAvatar(coach?.files) : ''}
              firstName={coach?.firstName || ''}
              lastName={coach?.lastName || ''}
              classes={'coach_price_avatar_size'}
            />
            <div className="coach_price_mobile_header_right_block">
              <p className="coach_price_mobile_header_name">{`${coach?.firstName} ${coach?.lastName}`}</p>
              <Rating value={coachRating} row={true} views={coach?.feedback?.length || 0} />
            </div>
          </div>
        )}
        <div className="coach_price_container">
          <div className="coach_price_container_row">
            <div className="coach_price_name" />
            <div className="coach_price_30min">30 min</div>
            <div className="coach_price_60min">60 min</div>
          </div>
          {user ? (
              <>
                <div className="coach_price_container_row">
                  <div className="coach_price_name">Lesson</div>
                  <div className="coach_price_30price">${(coach?.costsPerHalfHour || 100) / 100}</div>
                  <div className="coach_price_60price">${(coach?.costsPerHour || 100) / 100}</div>
                </div>
                <div className="coach_price_container_row">
                  <div className="coach_price_name">Subscription</div>
                  <div className="coach_price_30price">${(coach?.subscriptionPerHalfHour || 100) / 100}</div>
                  <div className="coach_price_60price">${(coach?.subscriptionPerHour || 100) / 100}</div>
                </div>
              </>
          ) : <CoachPriceLabelsView/>}
        </div>
        {user?.role !== RolesEnum.Child && (
          <Button
            className={`coach_price_book `}
            variant="contained"
            color="secondary"
            onClick={() => {
              if (!user?.role) {
                setIsAuthModalOpen(true);
                sessionStorage.setItem('coachId', coach?.id!);
              } else if (user?.role === RolesEnum.Coach) {
                history.push('/calendar');
              } else {
                history.push('/calendar', { id: coach?.id });
              }
            }}
          >
            Book
          </Button>
        )}
        <AuthModal open={isAuthModalOpen} handleClosePopup={() => setIsAuthModalOpen(false)} />
      </div>
    );
  };

  if (coachQuery.loading) {
    return <Loader fullScreen={true} />;
  }
  if (coach === null) {
    return null;
  }
  return (
    <div className="coach">
      <Modal open={isOpenImage} onClose={closeImageModal} className="profile_modal">
        <div className="profile_modal_video">
          <div className="profile_modal_video_header">
            <p className="profile_modal_video_title" />
            <img
              src={closeModalCross}
              className="profile_modal_video_close"
              onClick={closeImageModal}
              alt="profile_modal_video_close"
            />
          </div>
          <img src={imageModal} className="profile_modal_image_prewie" alt="profile_modal_image_prewie" />
        </div>
      </Modal>
      {CoachInfo()}
      {CoachPrice()}
    </div>
  );
};

export default Coach;
