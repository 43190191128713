import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Lessons, LessonStatusType, useListInstrumentsQuery } from 'api/index';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import Line from 'component/Line/Line';
import moment from 'moment';
import { isAvatar, getAvatar } from 'utils/image';
import './LessonCard.scss';
import { getInstrumentName } from 'utils/helpers';

const link_icon = '/assets/link_icon.svg';
const one_to_one_icon = '/assets/one_to_one_icon.svg';

interface LessonCardProps {
  lesson: any | Lessons;
  admin: boolean;
  adminGoToLesson: (id: string) => void;
}

const LessonCard = ({ lesson, admin, adminGoToLesson }: LessonCardProps) => {
  console.log(lesson);
  const history = useHistory();
  const instrumentsQuery = useListInstrumentsQuery();
  const instruments = instrumentsQuery.data?.listInstruments || [];

  const handleGoToCoach = useCallback(
    (event, id: string) => {
      if (admin) {
      } else {
        event.stopPropagation();
        history.push(`/coach/${id}`);
      }
    },
    [history, admin],
  );

  const handleGoToChild = useCallback(
    (event, id: string) => {
      if (admin) {
      } else {
        event.stopPropagation();
        history.push(`/child/${id}`);
      }
    },
    [history, admin],
  );

  const handleGoToLesson = useCallback(
    (id: string, lessonId: string) => {
      if (admin) {
        adminGoToLesson(id);
      } else {
        console.log('pushed', lessonId);
        history.push(`/lesson/${id}`, { lessonId });
      }
    },
    [history, admin],
  );

  const returnInstrument = () => {
    if (lesson?.musicInstrumentId?.length === 1) {
      const inst = instruments.find((item) => Number.parseInt(item?.id || '1') === lesson.musicInstrumentId[0]);
      if (inst) {
        const img = getInstrumentName(inst.name);
        return (
          <>
            <img alt="LessonCard_instr_img" src={img} className="LessonCard_instr_img" />
            <p className="LessonCard_instr_text">{`${inst.name.replace('_', '  ').charAt(0)}${inst.name
              .replace('_', '  ')
              .slice(1)
              .toLowerCase()}`}</p>
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="LessonCard" onClick={() => handleGoToLesson(lesson?.coachProfile?.id, lesson?.id)}>
      <div className="LessonCard_coach">
        <UserAvatar
          avatar={isAvatar(lesson?.coachProfile?.files || []) ? getAvatar(lesson?.coachProfile?.files) : ''}
          firstName={lesson?.coachProfile?.firstName || ''}
          lastName={lesson?.coachProfile?.lastName || ''}
        />
        <div className="LessonCard_name">
          <p className="LessonCard_name_firstName">{lesson?.coachProfile?.firstName || ''}</p>
          <div className="LessonCard_name2">
            <p className="LessonCard_name2_lastName">{lesson?.coachProfile?.lastName || ''}</p>
            <img
              alt="LessonCard_name2_img"
              src={link_icon}
              className="LessonCard_name2_img"
              onClick={(event) => handleGoToCoach(event, lesson?.coachProfile?.id)}
            />
          </div>
        </div>
      </div>
      <div className="LessonCard_children">
        <UserAvatar
          avatar={isAvatar(lesson?.studentProfile?.files || []) ? getAvatar(lesson?.studentProfile?.files) : ''}
          firstName={lesson?.studentProfile?.firstName || ''}
          lastName={lesson?.studentProfile?.lastName || ''}
        />
        <div className="LessonCard_name">
          <p className="LessonCard_name_firstName">{lesson?.studentProfile?.firstName || ''}</p>
          <div className="LessonCard_name2">
            <p className="LessonCard_name2_lastName">{lesson?.studentProfile?.lastName || ''}</p>
            <img
              alt="LessonCard_name2_img"
              src={link_icon}
              className="LessonCard_name2_img"
              onClick={(event) => handleGoToChild(event, lesson.studentProfile.id)}
            />
          </div>
        </div>
      </div>
      <div className="LessonCard_line">
        <Line />
      </div>
      <div className="LessonCard_deta">
        <p className="LessonCard_deta_name">
          {' '}
          {lesson.status === LessonStatusType.Upcoming ? 'Next upcoming' : ''}{' '}
          {lesson.status === LessonStatusType.Finished ? 'Finished' : ''}{' '}
          {lesson.status === LessonStatusType.InProgress ? 'In progress' : ''}{' '}
        </p>
        <p className="LessonCard_deta_deta">{moment(Number.parseInt(lesson.startAt)).format('DD MMM, YYYY, LT')}</p>
      </div>
      <div className="LessonCard_instr">{returnInstrument()}</div>
      <div className="LessonCard_text">Practice Notes</div>
      <div className="LessonCard_type_of_group">
        <img alt="LessonCard_type_of_group_img" className="LessonCard_type_of_group_img" src={one_to_one_icon} />
        <p className="LessonCard_type_of_group_text">1-on-1</p>
      </div>
    </div>
  );
};

export default LessonCard;
