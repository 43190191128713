import { Modal, Button } from '@material-ui/core';
import React, { useState } from 'react';
import ChildInvitation from '../../ChildInvitation/ChildInvitation';
import './InviteChildModal.scss';

const closeModalIcon = '/assets/close_modal_cross.svg';

const InviteChildModal = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Button className="calendar_modal_book_body_buy_addCoupon" onClick={() => setIsModalOpen(true)}>
        + Add Child
      </Button>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} className="invite__child__modal__wrapper">
        <div className="invite__child__modal">
          <div className="invite__child__modal__close__button__wrapper">
            <img
              src={closeModalIcon}
              alt="Close Modal Icon"
              className="invite__child__modal__close__button"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <ChildInvitation user={user} />
        </div>
      </Modal>
    </>
  );
};

export default InviteChildModal;
