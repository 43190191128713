import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import {
  useListLessonsQuery,
  useListInstrumentsQuery,
  useGetCoachQuery,
  RolesEnum,
  LessonStatusType,
  useGetLessonSessionLogsQuery,
} from 'api';
import Line from 'component/Line/Line';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import ButtonComponent from 'component/ButtonMatireal/ButtonMatireal';
import FeedbackModal from 'component/FeedbackModal/FeedbackModal';
import PractiseNote from 'component/PracticeNote/PracticeNote';
import { isAvatar, getAvatar } from 'utils/image';
import './Lesson.scss';
import { getInstrumentName } from 'utils/helpers';
import BrowserValidationModal from 'component/BrowserValidationModal/BrowserValidationModal';
import { isValidBrowserVersion } from 'utils/helpers';
import Loader from '../../component/Loader/Loader';

const one_to_one_icon = '/assets/one_to_one_icon.svg';

const Lesson = ({ user }) => {
  const history: any = useHistory();
  const match: any = useRouteMatch();
  const listLesson = useListLessonsQuery({
                                           variables: {
                                             offset: 0,
                                             limit: 2000,
                                             coachId: match.params.id,
                                             since: '',
                                             until: '',
                                           },
                                         });
  const { data, loading, refetch } = useGetCoachQuery({ variables: { id: match.params.id } });
  let lessons = listLesson?.data?.listLessons?.data || [];
  let lesson;
  const coachFeedbacks = data?.getCoach?.feedback || [];
  const canLeaveFeedback =
    !loading && user?.role === RolesEnum.Adult && !!!coachFeedbacks.find((f) => f?.student?.id === user?.id);
  const instrumentsQuery = useListInstrumentsQuery();
  const instruments = instrumentsQuery.data?.listInstruments || [];
  const [browserModalOpen, setBrowserModalOpen] = useState(false);
  const [selectLesson, setSelectLesen] = useState<string | undefined>();
  const [isSelectLessen, setIsSelectLessen] = useState<boolean>(false);
  const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
  const [isInitialLesson, setIsInitialLesson] = useState(false);

  useEffect(() => {
    if (lessons) {
      if (selectLesson) {
        return;
      }
      lesson = lessons.find((item) => {
        if (item?.startAt) {
          if (moment(+item?.startAt).isSameOrAfter(moment())) {
            return true;
          } else {
            return false;
          }
        }
      });
      if (history?.location?.state?.lessonId) {
        lesson = lessons.find((item) => item?.id === history?.location?.state?.lessonId);
      }
      if (lesson) {
        setSelectLesen(lesson.id);
      } else {
        if (user?.role === RolesEnum.Coach) {
          const lastFinishedLesson = lessons[lessons.length - 1];
          setSelectLesen(lastFinishedLesson?.id);
        }
      }
      setIsInitialLesson(true);
      // console.log(selectLesson);
    }
  });

  const selectedLesson = lessons?.find((item) => item?.id === selectLesson);

  const lessonLogs = useGetLessonSessionLogsQuery({ variables: { lessonId: selectedLesson?.id! } }).data
    ?.getLessonSessionLogs;

  // tslint:disable-next-line:no-shadowed-variable
  const returnInstrument = (lesson) => {
    if (lesson.musicInstrumentId.length === 1) {
      const inst = instruments.find((item) => Number.parseInt(item?.id || '1') === lesson.musicInstrumentId[0]);
      if (inst) {
        const img = getInstrumentName(inst.name);
        return (
          <>
            <img src={img} className='lessons_header_right_instr_img' alt='lessons_header_right_instr_img' />
            <p className='lessons_header_right_instr_text'>{`${inst.name.replace('_', '  ').charAt(0)}${inst.name
              .replace('_', '  ')
              .slice(1)
              .toLowerCase()}`}</p>
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleChangeChooseLesson = useCallback(
    (id) => {
      setSelectLesen(id);
      setIsSelectLessen(true);
      const lesson: any = lessons.find((item) => item?.id === id);
      if (lesson?.coachProfile?.id && lesson?.coachProfile?.id !== data?.getCoach?.id) {
        refetch({ id: lesson?.coachProfile?.id });
      }
    },
    [lessons, data, user, refetch],
  );

  const handleCloseFeedback = () => {
    setFeedbackModal(false);
  };

  const handleOpenFeedback = () => {
    setFeedbackModal(true);
  };

  const returnLesson = ({ lesson, index }) => {
    let lessonClassName = '';
    switch (lesson?.status) {
      case LessonStatusType.Upcoming: {
        lessonClassName = 'lessons_container_left_lesson_upcoming';
        break;
      }
      case LessonStatusType.InProgress: {
        lessonClassName = 'lessons_container_left_lesson_progress';
        break;
      }
      default: {
        lessonClassName = 'lessons_container_left_lesson_finished';
      }
    }
    return (
      <div key={lesson?.id + 'returnLesson'}>
        <div
          onClick={() => handleChangeChooseLesson(lesson.id)}
          key={lesson.id}
          className={`lessons_container_left_lesson ${
            selectLesson === lesson.id ? 'lessons_container_left_lesson_selected' : ''
          }`}
        >
          <p className='lessons_container_left_lesson_title'>
            lesson {index + 1} {lesson.topic}
          </p>
          <div className='lessons_container_left_lesson_down'>
            <p className='lessons_container_left_lesson_date'>{moment(+lesson.startAt).format('DD MMM, YYYY, LT')}</p>
            <p className={lessonClassName}>{lesson?.status?.replace('_', ' ')}</p>
          </div>
        </div>
        <div className='lessons_container_left_line'>
          <Line />
        </div>
      </div>
    );
  };

  const handleGoToLesson = useCallback(() => {
    if (selectLesson) {
      const lessonIndex = lessons.findIndex((item) => item?.id === selectLesson);
      if (isValidBrowserVersion()) {
        history.push('/class-room', {
          coachId: lessons[lessonIndex]?.coachProfile?.id,
          lessonId: lessons[lessonIndex]?.id,
          lessonNumber: lessonIndex + 1 || '',
          lessons: lessons?.slice().sort((a, b) => +b?.startAt! - +a?.startAt!),
          pathReturn: history.location.pathname,
          user: user,
        });
      } else {
        setBrowserModalOpen(true);
      }
    }
  }, [lessons, selectLesson, history]);

  if (lessons.length === 0 || !selectedLesson) {
    return <Loader></Loader>;
  }

  return (
    <div className='lessons'>
      <div className='lessons_header'>
        <div className='lessons_header_left'>
          <div className='lessons_header_left_coach'>
            <UserAvatar
              avatar={
                isAvatar(selectedLesson?.coachProfile?.files || [])
                  ? getAvatar(selectedLesson?.coachProfile?.files || [])
                  : ''
              }
              firstName={selectedLesson?.coachProfile?.firstName || ''}
              lastName={selectedLesson?.coachProfile?.lastName || ''}
            />
            <div className='lessons_header_left_name'>
              <p className='lessons_header_left_name_firstName'>
                {selectedLesson?.coachProfile?.firstName || ''} {selectedLesson?.coachProfile?.lastName || ''}
              </p>
            </div>
          </div>
          <div className='lessons_header_left_children'>
            <UserAvatar
              avatar={
                isAvatar(selectedLesson?.studentProfile?.files || [])
                  ? getAvatar(selectedLesson?.studentProfile?.files || [])
                  : ''
              }
              firstName={selectedLesson?.studentProfile?.firstName || ''}
              lastName={selectedLesson?.studentProfile?.lastName || ''}
            />
            <div className='lessons_header_left_name'>
              <p className='lessons_header_left_name_firstName'>
                {selectedLesson?.studentProfile?.firstName || ''} {selectedLesson?.studentProfile?.lastName || ''}
              </p>
            </div>
          </div>
        </div>
        <div className='lessons_header_right'>
          {canLeaveFeedback && (
            <div className='lesson_review_wrapper'>
              <span className='lesson_review_text'>Did you enjoy your lesson? Leave your coach a review!</span>
              <ButtonComponent className='lesson_review_button' onClick={handleOpenFeedback}>
                Leave Review
              </ButtonComponent>
            </div>
          )}
          <div className='lessons_header_right_instr'>{returnInstrument(selectedLesson)}</div>
          <div className='lessons_header_right_type_of_group'>
            <img
              className='lessons_header_right_type_of_group_img'
              src={one_to_one_icon}
              alt='lessons_header_right_type_of_group_img'
            />
            <p className='lessons_header_right_type_of_group_text'>1-on-1</p>
          </div>
        </div>
      </div>
      <div className='lessons_container'>
        <div className={`lessons_container_left ${!isSelectLessen ? '' : ''}`}>
          {lessons
            .filter((el) => el?.status === 'IN_PROGRESS')
            .sort((a, b) => +a!.startAt! - +b!.startAt!)
            .map((item, index) => returnLesson({ lesson: item, index }))}
          {lessons
            .filter((el) => el?.status === 'UPCOMING')
            .filter((el) => moment(+el?.startAt!).isAfter(moment()))
            .sort((a, b) => +a!.startAt! - +b!.startAt!)
            .map((item, index) => returnLesson({ lesson: item, index }))}
          {lessons
            .filter((el) => el?.status === 'UPCOMING')
            .filter((el) => moment(+el?.startAt!).isBefore(moment()))
            .sort((a, b) => +a!.startAt! - +b!.startAt!)
            .map((item, index) => returnLesson({ lesson: item, index }))}
          {lessons
            .filter((el) => el?.status === 'FINISHED')
            .sort((a, b) => +b!.startAt! - +a!.startAt!)
            .map((item, index) => returnLesson({ lesson: item, index }))}
        </div>
        <div className={`lessons_container_right ${isSelectLessen ? '' : ''}`}>
          {lessons.map((item) => {
            if (item?.id === selectLesson) {
              return (
                <div key={item?.id}>
                  <div className='lessons_container_right_header'>
                    <p className='lessons_container_right_header_date'>
                      {moment(
                        Number.parseInt(
                          // @ts-ignore
                          item?.startAt || '',
                        ),
                      ).format('DD MMM, YYYY, LT')}
                    </p>
                    {user.role === RolesEnum.Coach && (
                      <div className='lesson_logs'>
                        <p>
                          Coach was in the lesson:{' '}
                          {lessonLogs?.coach?.inAt !== null ? moment(+lessonLogs?.coach?.inAt!).format('hh:mm') : ''} -{' '}
                          {lessonLogs?.coach?.lastPingAt !== null
                            ? moment(+lessonLogs?.coach?.lastPingAt!).format('hh:mm')
                            : ''}
                        </p>
                        <p>
                          Student was in the lesson:{' '}
                          {lessonLogs?.students[0]?.inAt !== undefined
                            ? moment(+lessonLogs?.students[0]?.inAt!).format('hh:mm')
                            : ''}{' '}
                          -{' '}
                          {lessonLogs?.students[0]?.lastPingAt !== undefined
                            ? moment(+lessonLogs?.students[0]?.lastPingAt!).format('hh:mm')
                            : ''}
                        </p>
                      </div>
                    )}
                    {moment(+item!.finishAt!)
                      .add(7, 'days')
                      .isAfter(moment()) && (
                      <Button onClick={handleGoToLesson} className='lessons_button'>
                        Join lesson
                      </Button>
                    )}
                  </div>
                  <Line />
                </div>
              );
            } else {
              return null;
            }
          })}
          <div className='notes_container'>
            {selectedLesson && user.role === RolesEnum.Coach && (
              <PractiseNote user={user} lessonId={selectedLesson.id} note={null} />
            )}
            {selectedLesson &&
              lessons
                .filter(
                  (lesson) =>
                    moment(+lesson?.startAt!).isSameOrBefore(+selectedLesson?.startAt!) &&
                    lesson?.studentProfile?.id === selectedLesson?.studentProfile?.id,
                )
                .map((item) => {
                  return item?.lessonMaterials?.map((note) => {
                    return <PractiseNote user={user} lessonId={item.id} note={note} />;
                  });
                })}
          </div>
        </div>
      </div>
      {feedbackModal && (
        <FeedbackModal open={feedbackModal} coachId={match.params.id} handleClose={handleCloseFeedback} />
      )}
      <BrowserValidationModal isModalOpen={browserModalOpen} setIsModalOpen={setBrowserModalOpen} />
    </div>
  );
};

export default Lesson;
