import React from 'react';

const LogoutIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00098 3.00781H21.001C22.101 3.00781 23.001 3.90781 23.001 5.00781V8.99781H21.001V4.98781H3.00098V19.0178H21.001V14.9978H23.001V19.0078C23.001 20.1078 22.101 20.9878 21.001 20.9878H3.00098C1.90098 20.9878 1.00098 20.1078 1.00098 19.0078V5.00781C1.00098 3.89781 1.90098 3.00781 3.00098 3.00781Z"
        fill="#605F5E"
        {...props}
      />
      <path
        d="M3.5 3H21.5C22.6 3 23.5 3.9 23.5 5V8.99H21.5V4.98H3.5V19.01H21.5V14.99H23.5V19C23.5 20.1 22.6 20.98 21.5 20.98H3.5C2.4 20.98 1.5 20.1 1.5 19V5C1.5 3.89 2.4 3 3.5 3Z"
        fill="#605F5E"
        {...props}
      />
      <path d="M13 16L9 12L13 8V11H23V13H13V16Z" fill="#605F5E" {...props} />
    </svg>
  );
};
export default LogoutIcon;
