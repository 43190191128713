import React from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
// import Checkbox from '@material-ui/core/Checkbox';
import './InstrumentLevelModal.scss';

const close_modal_cross = '/assets/close_modal_cross.svg';

const InstrumentLevelModal = ({
  setOpenModal,
  user,
  level,
  handleChangeLevel,
  RolesEnum,
  openModal,
  disabledFields,
  levelSelect,
  chosenInstrumentName,
  setChosenInstrumentName,
  handleChangeLessons,
}) => {
  const handleConfirm = () => {
    handleChangeLessons({ name: chosenInstrumentName, level: levelSelect });
    setChosenInstrumentName('');
    setOpenModal(false);
    handleChangeLevel('Beginner')
  };
  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <div>
        <div className="profile_modal_confirmation">
          <div className="profile_modal_confirmation_header">
            <p className="profile_modal_confirmation_title">Choose level you are going to teach</p>
            <img
              src={close_modal_cross}
              className="profile_modal_confirmation_close"
              onClick={() => setOpenModal(false)}
              alt="close-icon"
            />
          </div>
          {user?.role === RolesEnum.Coach ? (
            <div className="level_modal_select">
              <Select
                value={level ? level : []}
                defaultValue={level ? level : 'Beginner'}
                input={<OutlinedInput notched={true} />}
              >
                {['Beginner', 'Intermediate', 'Advanced'].map((name) => (
                  <MenuItem onClick={() => handleChangeLevel(name)} key={name} value={name} disabled={disabledFields}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : null}
          <div className="profile_modal_confirmation_button_container">
            <Button className="profileButton" variant="contained" color="secondary" onClick={handleConfirm}>
              Ok
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InstrumentLevelModal;
