import React, { useState } from 'react';
import Storage from '@aws-amplify/storage';
import UserAvatarUpload from '../../component/UserAvatarUpload/UserAvatarUpload';
import './AvatarUploader.scss';
import { Spinner } from 'component/Button/Button';
import { FileType } from 'api';
const addIcon = '/assets/add_icon.svg';

const isNewFileAdded = (file) => file instanceof File;

const { REACT_APP_AWS_REGION, REACT_APP_AWSS3_BUCKET } = process.env;

const AvatarUploader = ({ user, reGetUser, disabled = false, updateUser, isChild = false }) => {
  const [file, setFile] = useState(user?.photo);
  const [progress] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);

  const uploadPhoto = async (data) => {
    setLoading(true);
    if (data?.target?.files[0]?.name) {
      const name = data.target.files[0].name;
      const fileOb = data.target.files[0];
      try {
        const res: any = await Storage.put(`userInfo/${user.id}/avatar/${name.replace(/ /g, '')}`, fileOb);
        await updateUser(
          !isChild
            ? {
                variables: {
                  userFiles: [
                    {
                      type: FileType.Avatar,
                      path: `https://${REACT_APP_AWSS3_BUCKET}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/public/${res.key}`,
                    },
                  ],
                },
              }
            : {
                variables: {
                  avatar: `https://${REACT_APP_AWSS3_BUCKET}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/public/${res.key}`,
                  childId: user?.id,
                  level: user?.level,
                },
              },
        );
        reGetUser();
        setLoading(false);
        setFile(fileOb);
      } catch (e) {
        reGetUser();
        setLoading(false);
        // tslint:disable-next-line: no-console
        console.log(e, 'e uploadPhoto');
      }
    }
  };

  const fileUrl = isNewFileAdded(file) ? URL.createObjectURL(file) : user?.photo;

  const isFileAvatar = () => {
    let isAvatar = false;
    if (user?.files) {
      user.files.forEach((item) => {
        if (item.type === 'avatar') {
          isAvatar = true;
        }
      });
    }
    return isAvatar;
  };

  const returnAvatar = () => {
    let isAvatar = '';
    if (user?.files) {
      user.files.forEach((item) => {
        if (item.type === 'avatar') {
          isAvatar = item.path;
        }
      });
    }
    return isAvatar;
  };

  return (
    <div className="avatar-uploader">
      <UserAvatarUpload
        imageUrl={isFileAvatar() ? returnAvatar() : fileUrl}
        firstName={user?.firstName}
        lastName={user?.lastName}
        withProgress={true}
        progress={progress}
      />
      {!disabled && (
        <div className="avatar-uploader_trigger">
          {!loading ? (
            <>
              <label className="avatar-uploader_trigger_label">
                <input
                  className="avatar-uploader_trigger_input"
                  type="file"
                  onChange={(e) => uploadPhoto(e)}
                  placeholder=""
                  id="inputImage"
                />
              </label>
              <img src={addIcon} alt="" className="profileImageAddIcon" />
            </>
          ) : (
            <div className="avatar-uploader_trigger_spinner">
              <Spinner />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AvatarUploader;
