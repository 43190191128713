import React from 'react';
import { RIEInput } from 'riek';

export default class EditableInput extends React.Component<any, any> {
  render() {
    const { value, propName, className, classLoading, classInvalid, editProps, onChange } = this.props;

    return (
      <RIEInput
        value={value}
        propName={propName}
        className={className}
        classLoading={classLoading}
        classInvalid={classInvalid}
        shouldBlockWhileLoading
        editProps={editProps}
        change={(data) => onChange(data)}
      />
    );
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.value === this.props.value) return false;

    return true;
  }
}
