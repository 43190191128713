import React, { useState, useEffect, useCallback } from 'react';
// import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  useAdminListLessonQuery,
  useListInstrumentsQuery,
  RolesEnum,
  // useUpdateLessonMutation,
  LessonMaterialsEnum,
} from 'api';
import Line from 'component/Line/Line';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import { isAvatar, getAvatar } from 'utils/image';
import './Lesson.scss';
import { getInstrumentName } from 'utils/helpers';

const one_to_one_icon = '/assets/one_to_one_icon.svg';

interface LessonProps {
  user: any;
  coachId: string | undefined;
}

const Lesson = ({ user, coachId }: LessonProps) => {
  // const history: any = useHistory();
  // const [updateLessonMutation] = useUpdateLessonMutation();
  const listLesson = useAdminListLessonQuery({
    variables: {
      limit: 2000,
      since: '',
      until: '',
      coachId: coachId,
      userId: user.role === RolesEnum.Adult ? user?.id || undefined : undefined,
    },
  });
  let lessons = listLesson?.data?.adminListLesson?.data || [];
  const instrumentsQuery = useListInstrumentsQuery();
  const instruments = instrumentsQuery.data?.listInstruments || [];
  const [selectLesson, setSelectLesen] = useState(lessons[0]?.id);
  const [notes, setNotes] = useState('');
  const [notesCoach, setNotesCoach] = useState('');
  const [notesTopic, setNotesTopic] = useState('');

  useEffect(() => {
    if (lessons.length !== 0) {
      const lesson = lessons.find((item) => {
        if (item?.startAt) {
          if (moment(+item?.startAt).isSameOrAfter(moment())) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
      if (lesson) {
        setSelectLesen(lesson.id);
        if (user?.role === RolesEnum.Adult) {
          // setNotes(lesson?.lessonMaterials?.find((item) => item?.type === LessonMaterialsEnum.Adult)?.text || '');
        } else {
          // setNotesCoach(lesson?.lessonMaterials?.find((item) => item?.type !== LessonMaterialsEnum.Adult)?.text || '');
          setNotesTopic(lesson?.topic || '');
        }
      }
    }
  }, [lessons, user?.role]);

  const returnInstrument = (lesson) => {
    if (lesson.musicInstrumentId.length === 1) {
      const inst = instruments.find((item) => Number.parseInt(item?.id || '1') === lesson.musicInstrumentId[0]);
      if (inst) {
        const img = getInstrumentName(inst.name);
        return (
          <>
            <img src={img} className="lessonsAdmin_header_right_instr_img" alt="lessonsAdmin_header_right_instr_img" />
            <p className="lessonsAdmin_header_right_instr_text">{`${inst.name
              .replace('_', '  ')
              .charAt(0)}${inst.name.replace('_', '  ').slice(1).toLowerCase()}`}</p>
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleChangeChooseLesson = useCallback(
    (id) => {
      setSelectLesen(id);
      const lesson: any = lessons.find((item) => item?.id === id);
      if (user.role === RolesEnum.Adult) {
        setNotes(lesson?.lessonMaterials?.find((item) => item?.type === LessonMaterialsEnum.Adult)?.text || '');
      } else {
        setNotesCoach(lesson?.lessonMaterials?.find((item) => item?.type !== LessonMaterialsEnum.Adult)?.text || '');
        setNotesTopic(lesson?.topic || '');
      }
    },
    [lessons, user?.role],
  );

  const returnLesson = ({ lesson, index }) => {
    return (
      <div key={lesson?.id + 'returnLesson'}>
        <div
          onClick={() => handleChangeChooseLesson(lesson.id)}
          key={lesson.id}
          className={`lessonsAdmin_container_left_lesson ${
            selectLesson === lesson.id ? 'lessonsAdmin_container_left_lesson_selected' : ''
          }`}
        >
          <p className="lessonsAdmin_container_left_lesson_title">
            lesson {index + 1} {lesson.topic}
          </p>
          <div className="lessonsAdmin_container_left_lesson_down">
            <p className="lessonsAdmin_container_left_lesson_date">
              {moment(+lesson.startAt).format('DD MMM, YYYY, LT')}
            </p>
            <p
              className={
                moment(+lesson.startAt).isSameOrAfter(moment())
                  ? 'lessonsAdmin_container_left_lesson_upcoming'
                  : 'lessonsAdmin_container_left_lesson_finished'
              }
            >
              {moment(+lesson.startAt).isSameOrAfter(moment()) ? 'UPCOMING' : 'FINISHED'}
            </p>
          </div>
        </div>
        <div className="lessonsAdmin_container_left_line">
          <Line />
        </div>
      </div>
    );
  };

  // const handleGoToLesson = useCallback(() => {
  //   if (selectLesson) {
  //     const lesson = lessons.find((item) => item?.id === selectLesson);
  //     history.push('/class-room', {
  //       coachId: lesson?.coachProfile?.id,
  //       lessonId: lesson?.id,
  //       pathReturn: history.location.pathname,
  //     });
  //   }
  // }, [lessons, selectLesson, history]);

  // const handleSubmit = useCallback(
  //   (event) => {
  //     if (user.role === RolesEnum.Coach) {
  //       updateLessonMutation({
  //         variables: {
  //           // text: notesCoach,
  //           topic: notesTopic,
  //           lessonId: selectLesson || '',
  //         },
  //       });
  //     } else {
  //       updateLessonMutation({
  //         variables: {
  //           // text: notes,
  //           topic: undefined,
  //           lessonId: selectLesson || '',
  //         },
  //       });
  //     }
  //   },
  //   [notesCoach, notesTopic, notes, user, selectLesson, updateLessonMutation],
  // );

  if (lessons.length === 0) {
    return null;
  }

  lessons = lessons.slice().sort((a, b) => {
    if (a?.startAt !== null && b?.startAt !== null && a?.startAt !== undefined && b?.startAt !== undefined) {
      if (moment(+a?.startAt).isSameOrAfter(moment())) {
        if (+a?.startAt > +b?.startAt) {
          return 1;
        }
        if (+a?.startAt < +b?.startAt) {
          return -1;
        }
      } else {
        return -1;
      }
    }
    return 0;
  });

  return (
    <div className="lessonsAdmin">
      <div className="lessonsAdmin_header">
        <div className="lessonsAdmin_header_left">
          <div className="lessonsAdmin_header_left_coach">
            <UserAvatar
              avatar={
                isAvatar(lessons[0]?.coachProfile?.files || []) ? getAvatar(lessons[0]?.coachProfile?.files || []) : ''
              }
              firstName={lessons[0]?.coachProfile?.firstName || ''}
              lastName={lessons[0]?.coachProfile?.lastName || ''}
            />
            <div className="lessonsAdmin_header_left_name">
              <p className="lessonsAdmin_header_left_name_firstName">
                {lessons[0]?.coachProfile?.firstName || ''} {lessons[0]?.coachProfile?.lastName || ''}
              </p>
            </div>
          </div>
          <div className="lessonsAdmin_header_left_children">
            <UserAvatar
              avatar={
                isAvatar(lessons[0]?.studentProfile?.files || [])
                  ? getAvatar(lessons[0]?.studentProfile?.files || [])
                  : ''
              }
              firstName={lessons[0]?.studentProfile?.firstName || ''}
              lastName={lessons[0]?.studentProfile?.lastName || ''}
            />
            <div className="lessonsAdmin_header_left_name">
              <p className="lessonsAdmin_header_left_name_firstName">
                {lessons[0]?.studentProfile?.firstName || ''} {lessons[0]?.studentProfile?.lastName || ''}
              </p>
            </div>
          </div>
        </div>
        <div className="lessonsAdmin_header_right">
          <div className="lessonsAdmin_header_right_instr">{returnInstrument(lessons[0])}</div>
          <div className="lessonsAdmin_header_right_type_of_group">
            <img
              className="lessonsAdmin_header_right_type_of_group_img"
              src={one_to_one_icon}
              alt="lessonsAdmin_header_right_type_of_group_img"
            />
            <p className="lessonsAdmin_header_right_type_of_group_text">1-on-1</p>
          </div>
        </div>
      </div>
      <div className="lessonsAdmin_container">
        <div className="lessonsAdmin_container_left">
          {lessons.map((item, index) => returnLesson({ lesson: item, index }))}
        </div>
        <div className="lessonsAdmin_container_right">
          {lessons.map((item) => {
            if (item?.id === selectLesson) {
              return (
                <div key={item?.id}>
                  <div className="lessonsAdmin_container_right_header">
                    <p className="lessonsAdmin_container_right_header_date">
                      {moment(
                        Number.parseInt(
                          // @ts-ignore
                          item?.startAt || '',
                        ),
                      ).format('DD MMM, YYYY, LT')}
                    </p>
                  </div>
                  <Line />
                  <p className="lessonsAdmin_container_right_topic">{item?.topic}</p>
                  <p className="lessonsAdmin_container_right_coache_notes_title">Coach's notes</p>
                  {/* {item?.lessonMaterials?.map((item) => {
                    if (item?.type === LessonMaterialsEnum.Coach) {
                      return (
                        <p key={item?.text} className="lessonsAdmin_container_right_coache_notes_coach">
                          {item?.text}
                        </p>
                      );
                    }
                  })}
                  <p className="lessonsAdmin_container_right_coache_notes_title">Student’s notes</p>
                  {item?.lessonMaterials?.map((item) => {
                    if (item?.type === LessonMaterialsEnum.Adult) {
                      return <p className="lessonsAdmin_container_right_coache_notes_coach">{item?.text}</p>;
                    }
                  })} */}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Lesson;
