import React from 'react';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import './BalanceRefill.scss';
import AddCardModal from '../AddCardModal/AddCardModal';
import ManageSubscriptionsModal from 'component/ManageSubscriptionsModal/ManageSubscriptionsModal';

const BalanceRefill = ({ balance, handleRefill }) => {
  return (
    <div className="balanceRefill">
      <p className="balanceRefill_balance">$ {balance}</p>
      <p className="balanceRefill_text">Balance</p>
      <Button contained={true} onClick={handleRefill}>
        Refill
      </Button>
      <AddCardModal/>
      <p className="balanceRefill_explanation">
        Add to your balance to take one lesson at a time, or to try different coaches and find the best fit. Then, save
        money on weekly lessons with a subscription when you click "book" on your coach's profile.{' '}
      </p>
      <ManageSubscriptionsModal/>
    </div>
  );
};

export default BalanceRefill;
