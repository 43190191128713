import React, { FC } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Launch as LaunchIcon } from '@material-ui/icons';
import { LessonType, Lessons } from 'api/index';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import { isAvatar, getAvatar } from 'utils/image';
import './CoachBalanceCard.scss';

type Props = {
  data: Lessons;
};

const CoachBalanceCard: FC<Props> = ({ data }) => {
  const { push } = useHistory();
  return (
    <div className="coachBalanceCard" onClick={() => push(`/lesson/${data.id}`)}>
      <div className="coachBalanceCard_user">
        <UserAvatar
          avatar={
            data?.studentProfile?.files
              ? isAvatar(data?.studentProfile?.files)
                ? getAvatar(data?.studentProfile?.files)
                : ''
              : ''
          }
          firstName={data?.studentProfile?.firstName || ''}
          lastName={data?.studentProfile?.lastName || ''}
        />
        <div className="coachBalanceCard_name">
          <p className="coachBalanceCard_name_firstName">{data.studentProfile?.firstName}</p>
          <div className="coachBalanceCard_name2">
            <p className="coachBalanceCard_name2_lastName">{data.studentProfile?.lastName}</p>
            <LaunchIcon className="coachBalanceCard_name2_icon" fontSize="small" />
          </div>
        </div>
      </div>
      <div className="coachBalanceCard_type">{data?.type !== LessonType.Individual ? 'Subscription' : 'Lesson'}</div>
      <div className="coachBalanceCard_deta">
        <p className="coachBalanceCard_deta_deta">{data.startAt && moment(+data.startAt).format('DD MMM, YYYY, LT')}</p>
      </div>
      <div className="coachBalanceCard_balance">
        <p>$ {data.costs! / 100}</p>
      </div>
    </div>
  );
};

export default CoachBalanceCard;
