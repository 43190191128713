import React from 'react';
import LandingFooter from 'page/Landing/LandingFooter/LandingFooter';
import './TermsAndConditions.scss';

const TermsAndConditions = ({ user }) => {
  return (
    <>
      <div className="terms-and-conditions">
        <h6 className="terms-and-conditions__title">Terms of Use and Privacy Policy</h6>
        <ul className="terms-and-conditions__list">
          <li>
            We receive, collect, and may store any information you enter on our website or provide us in any other way.
            In addition, we may collect the Internet protocol (IP) address used to connect your computer to the
            Internet; login; e-mail address; password; computer and connection information, and purchase history. We may
            use software tools to measure and collect session information, including page response times, length of
            visits to certain pages, page interaction information, and methods used to browse away from the page. We
            also collect personally identifiable information (including name, email, password, communications); payment
            details (including credit card information), comments, feedback, product reviews, recommendations, and
            personal profile, receive, collect and store any information you enter on our website or provide us in any
            other way. In addition, we collect the Internet protocol (IP) address used to connect your computer to the
            Internet; login; e-mail address; password; computer and connection information, and purchase history. We may
            use software tools to measure and collect session information, including page response times, length of
            visits to certain pages, page interaction information, and methods used to browse away from the page. We
            also collect personally identifiable information (including name, email, password, communications); payment
            details (including credit card information), comments, feedback, product reviews, recommendations, and
            personal profile.
          </li>

          <li>We collect such Non-personal and Personal Information for the following purposes:</li>

          <ol>
            <li>To provide and operate the Services;</li>

            <li>To provide our Users with ongoing customer assistance and technical support;</li>

            <li>
              To be able to contact our Visitors and Users with general or personalized service-related notices and
              promotional messages;
            </li>

            <li>
              To create aggregated statistical data and other aggregated and/or inferred Non-personal Information, which
              we or our business partners may use to provide and improve our respective services;
            </li>

            <li>To comply with any applicable laws and regulations.</li>
          </ol>
          <li>
            Our company is hosted through AWS. For their privacy policy see:{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/privacy/">
              https://aws.amazon.com/privacy
            </a>
          </li>

          <li>
            Payments are processed through Stripe. For their privacy policy see:{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://stripe.com/es-us/privacy">
              https://stripe.com/es-us/privacy
            </a>
          </li>

          <li>
            We may contact you to notify you regarding your account, to troubleshoot problems with your account, to
            resolve a dispute, to collect fees or monies owed, to poll your opinions through surveys or questionnaires,
            to send updates about our company, or as otherwise necessary to contact you to enforce our User Agreement,
            applicable national laws, and any agreement we may have with you. For these purposes, we may contact you via
            email, telephone, text messages, and postal mail.
          </li>

          <li>
            We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and
            clarifications will take effect immediately upon their posting on the website. If we make material changes
            to this policy, we will notify you here that it has been updated, so that you are aware of what information
            we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
          </li>

          <li>We may record and keep records of lessons conducted over videoconference or audio on any platform.</li>

          <li>
            If you would like to: access, correct, amend or delete any personal information we have about you, you are
            invited to contact us at{' '}
            <a target="_blank" rel="noopener noreferrer" href="mailto:admin@bestmusiccoach.com">
              admin@bestmusiccoach.com.
            </a>
          </li>
        </ul>
      </div>
      <LandingFooter user={user} />
    </>
  );
};

export default TermsAndConditions;
