import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import AdultIcon from '../../icons/AdultIcon';
import ChildIcon from '../../icons/ChildIcon';
import CoachIcon from '../../icons/CoachIcon';
import LessonsIcon from '../../icons/LessonsIcon';
import LogoutIcon from '../../icons/LogoutIcon';
import Coupon from '../../icons/Coupon';
import BooksIcon from '../../icons/BooksIcon';
import { routes } from '../../modules/routes';

const logo = '/assets/logo_big.svg';
const logo_small = '/assets/logo_round_small.svg';
const drawerWidth = 190;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      borderRight: 'none',
      paddingLeft: '60px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      paddingLeft: '60px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      borderRight: 'none',
      width: theme.spacing(8) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8) + 1,
      },
    },
    barLogo: {
      // width: "127px",
      height: '24px',
      padding: '16px',
    },
    barLogoSmall: {
      width: '24px',
      height: '24px',
      padding: '16px',
    },
    toggleDrawer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    listItemText: {
      listStyle: 'none',
      fontFamily: 'Avenir !important',
      fontStyle: 'normal !important',
      fontSize: '16px !important',
      lineHeight: '1 !important',
      color: 'black',
    },
    listItemTextCurrent: {
      color: '#00A651',
    },
    link: {
      color: '#E0E0E0',
      textDecoration: 'none',
    },
    linkCurrent: {
      color: '#00A651',
    },
    sideBarlistWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
  }),
);

interface SideBarProps {
  signOut: (callBack: () => void) => void;
}

const SideBar: React.FC<SideBarProps> = ({ signOut }) => {
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles(theme);

  const [current, setCurrent] = React.useState<string>('coaches');
  const [open, setOpen] = React.useState<boolean>(true);

  const handleSetCurrent = () => {
    const newCurrent = location.pathname.split('/')[2];
    newCurrent && setCurrent(newCurrent);
  };

  React.useEffect(handleSetCurrent, [location]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const menuLinks = [
    {
      label: 'Coaches',
      navLink: routes.adminCoaches,
      icon: (color) => <CoachIcon style={{ fill: color }} />,
    },
    {
      label: 'Adults',
      navLink: routes.adminAdults,
      icon: (color) => <AdultIcon style={{ fill: color }} />,
    },
    {
      label: 'Children',
      navLink: routes.adminChildren,
      icon: (color) => <ChildIcon style={{ fill: color }} />,
    },
    {
      label: 'Lessons',
      navLink: routes.adminLessons,
      icon: (color) => <LessonsIcon style={{ fill: color }} />,
    },
    {
      label: 'Coupon',
      navLink: routes.adminCoupon,
      icon: (color) => <Coupon style={{ fill: color }} />,
    },
    {
      label: 'Books',
      navLink: routes.adminBooks,
      icon: (color) => <BooksIcon style={{ fill: color }} />,
    },
    {
      label: 'Log out',
      navLink: routes.adminLogout,
      icon: (color) => <LogoutIcon style={{ fill: color }} />,
    },
  ];

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.sideBarlistWrapper}>
        <List>
          <Link to={menuLinks[0].navLink}>
            <img className={open ? classes.barLogo : classes.barLogoSmall} src={open ? logo : logo_small} alt="logo" />
          </Link>
          {menuLinks?.map((link) => {
            const menuLinks = link.navLink.split('/');
            const isCurrent = menuLinks.includes(current);
            const isLogout = menuLinks.includes('logout');
            return (
              <Link
                key={link.label}
                to={link.navLink}
                className={clsx(classes.link, isCurrent && classes.linkCurrent)}
                onClick={() => {
                  isLogout && signOut(() => setOpen(true));
                }}
              >
                <ListItem button>
                  <ListItemIcon>{link.icon(isCurrent ? '#00A651' : '#E0E0E0')}</ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={link.label}
                      classes={{
                        primary: clsx(classes.listItemText, isCurrent && classes.listItemTextCurrent),
                      }}
                    />
                  )}
                </ListItem>
              </Link>
            );
          })}
        </List>
        <div className={classes.toggleDrawer}>
          <IconButton onClick={toggleDrawer}>{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
        </div>
      </div>
    </Drawer>
  );
};

export default SideBar;
