import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../modules/routes';
import ChildrenList from './ChildrenList';
import ChildProfile from './ChildProfile';

export default function Component() {
  return (
    <>
      <Switch>
        <Route exact key={routes.adminChildren} path={routes.adminChildren}>
          <ChildrenList />
        </Route>
        <Route exact key={routes.adminChild} path={routes.adminChild}>
          <ChildProfile />
        </Route>
      </Switch>
    </>
  );
}
