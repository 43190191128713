import React, { FC, useEffect, useState, FormEvent, ChangeEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { useChildAcceptInviteMutation, Profile } from 'api';
import Button from '../../component/ButtonMatireal/ButtonMatireal';
import './ChildPasswordSetting.scss';

const mainImage = '/assets/resetPass.png';

type InputTypes = 'text' | 'password';

type Props = {
  handleGetProfile: (token: string, credentials: any) => void;
  user: Profile | null;
};

const ChildPasswordSetting: FC<Props> = ({ user, handleGetProfile }) => {
  const [password, setPassword] = useState<string>('');
  const [type, setType] = useState<InputTypes>('password');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation<{ email?: string; password?: string }>();
  const { push } = useHistory();
  const [childAcceptInvite] = useChildAcceptInviteMutation();

  const toggleType = () => {
    setType((old) => (old === 'password' ? 'text' : 'password'));
  };

  const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setError('');
    setLoading(true);

    if (password.length <= 7) {
      setError('Password length must be at least 8 characters');
    }

    try {
      const cognitoUser: CognitoUser = await Auth.signIn(state?.email?.toLowerCase()!, state.password);
      await Auth.completeNewPassword(cognitoUser, password);
      const auth = await Auth.currentAuthenticatedUser();
      const token = auth?.signInUserSession?.idToken?.jwtToken;
      await childAcceptInvite();
      handleGetProfile(token, auth);
      delete state.email;
      delete state.password;
      push('/coaches');
    } catch (err: any) {
      if (err?.message) {
        setError(err.message);
      }
    }
    setLoading(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
  };

  useEffect(() => {
    if (user?.id) {
      push('/');
    }
  }, []);

  return (
    <div className="child-password-setting">
      <div className="child-password-setting_left">
        <p className="child-password-setting_left_text">Create new password</p>
        <img src={mainImage} alt="reset_image" className="child-password-setting_left_image loginLogo" />
      </div>
      <form className="child-password-setting_form">
        <TextField
          label="Password"
          variant="outlined"
          fullWidth={true}
          required={true}
          value={password}
          onChange={handleChange}
          error={!!error}
          helperText={error}
          type={type}
          InputProps={{
            endAdornment:
              type === 'password' ? (
                <VisibilityIcon className="child-password-setting_form_icon" onClick={toggleType} />
              ) : (
                <VisibilityOffIcon className="child-password-setting_form_icon" onClick={toggleType} />
              ),
          }}
        />
        <Button
          className="child-password-setting_form_button"
          contained={true}
          onClick={handleSubmit}
          disabled={loading}
          type="submit"
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default ChildPasswordSetting;
