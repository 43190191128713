import Auth from '@aws-amplify/auth';
import { ApolloCache, ApolloClient, HttpLink, InMemoryCache, concat, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

const createApolloClient = (cookies?: any) => {
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL });

  const authLink = setContext(async (_, { headers }) => {
    let token = null;
    try {
      token = !cookies ? (await Auth.currentSession()).getIdToken().getJwtToken() : cookies.jwt;

      if (token && !cookies) {
        Cookies.set('jwt', token);
      }

      return {
        headers: {
          ...headers,
          // mode: 'no-cors',
          // 'Access-Control-Allow-Origin': '*',
          'x-api-key': token,
        },
      };
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error);
    }

    return {
      headers: {
        ...headers,
        // mode: 'no-cors',
        // 'Access-Control-Allow-Origin': '*',
      },
    };
  });

  const cache = new InMemoryCache({}).restore(
    !cookies ? (global.window as any).__APOLLO_STATE__ : {},
  ) as ApolloCache<NormalizedCacheObject>;
  const client = new ApolloClient<NormalizedCacheObject>({
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
    },
    link: concat(authLink, httpLink),
    ssrMode: !!cookies,
    ssrForceFetchDelay: cookies ? 100 : undefined,
  });
  return client;
};

export default createApolloClient;
