import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import './BasicPagination.scss';

interface BasicPaginationProps {
  total: number;
  limit: number;
  page: number;
  rowsPerPageOptions: number[];
  onChangeRowsPerPage: (event: any) => void;
  onChangePage: (e: any, numberOfPage: any) => void;
  onPageClick: (e: React.ChangeEvent<unknown>, value: number) => void;
}

const BasicPagination: React.FC<BasicPaginationProps> = ({
  total,
  limit,
  page,
  rowsPerPageOptions,
  onChangeRowsPerPage,
  onChangePage,
  onPageClick,
}) => {
  return (
    <div className="basic_pagination">
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        onRowsPerPageChange={onChangeRowsPerPage}
        component="div"
        count={total}
        rowsPerPage={limit}
        page={page}
        onPageChange={onChangePage}
      />
      <Pagination
        count={Math.ceil(total / limit)}
        onChange={onPageClick}
        showFirstButton
        showLastButton
        page={page + 1}
      />
    </div>
  );
};

export default BasicPagination;
