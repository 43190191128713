import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Profile } from 'api';
import './LandingFooter.scss';
import Link from '../../../component/Link/Link';

const logoIcon = 'assets/bright_logo.svg';

type Props = {
  user: Profile | null;
};

const LandingFooter: FC<Props> = ({ user }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <footer className="landing_footer">
      {isTabletOrMobile && <img className="landing_footer_logo" src={logoIcon} alt="best_music_coach_logo" />}
      <div className="landing_footer_head">
        <div className="landing_footer_head_wrapper">
          {!isTabletOrMobile && <img className="landing_footer_logo" src={logoIcon} alt="best_music_coach_logo" />}
          <ul className="landing_footer_pages_list">
            <li className="landing_footer_pages_list_item">
              <a href="https://www.bestmusiccoach.com/blog">Blog</a>
            </li>
            <li className="landing_footer_pages_list_item">
              <a href="https://store.bestmusiccoach.com/">Shop</a>
            </li>
            <li className="landing_footer_pages_list_item">
              <Link to="/books">Books</Link>
            </li>
            <li className="landing_footer_pages_list_item">
              <Link to="/coaches">Coaches</Link>
            </li>
          </ul>
        </div>
        {!user?.id && (
          <ul className="landing_footer_auth_list">
            <li className="landing_footer_pages_list_item">
              <Link to="/signup">Sign Up</Link>
            </li>
            <li className="landing_footer_pages_list_item">
              <Link to="/signin">Login</Link>
            </li>
          </ul>
        )}
      </div>
      <div className="landing_footer_contacts">
        <p className="landing_footer_contacts_help">
          Need help? <span className="landing_footer_contacts_mail">support@bestmusiccoach.com</span>
        </p>
        <p className="landing_footer_contacts_copy">© Best Music Coach, Inc. {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default LandingFooter;
