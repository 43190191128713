import React from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Loader from '../../components/CustomLoader';
import './LessonsList.scss';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { useAdminRefundLessonMutation, useAdminGetLessonQuery, useListInstrumentsQuery } from 'api/index';
import Button from '@material-ui/core/Button';
import { getAdminCoachRoute, getAdminAdultRoute } from '../../modules/routes';
import { getInstrumentName } from 'utils/helpers';
import PractiseNote from '../../../../component/PracticeNote/PracticeNote';

const one_to_one_icon = '/assets/one_to_one_icon.svg';

export default function LessonsProfile() {
  const history = useHistory();
  const params: any = useRouteMatch().params;
  const lessonQuery = useAdminGetLessonQuery({
    variables: { id: params.lessonId },
  });
  const instrumentsQuery = useListInstrumentsQuery();
  const instruments = instrumentsQuery.data?.listInstruments || [];
  const [adminRefundLessonMutation] = useAdminRefundLessonMutation();
  const [lesson, setLesson] = React.useState<any>({
    coachProfile: { firstName: '1', lastName: '2' },
    studentProfile: {
      firstName: '1',
      lastName: '2',
      createdAt: '1424434356565',
    },
  });

  const loading = lessonQuery?.loading;

  React.useEffect(() => {
    if (lessonQuery?.data?.adminGetLesson) {
      setLesson({ ...lesson, ...lessonQuery?.data?.adminGetLesson });
    }
  }, [lessonQuery?.data?.adminGetLesson]);

  if (loading) {
    return <Loader />;
  }

  const returnInstrument = (lesson) => {
    if (lesson?.musicInstrumentId?.length === 1) {
      const inst = instruments.find((item) => Number.parseInt(item?.id || '1') === lesson.musicInstrumentId[0]);
      if (inst) {
        const img = getInstrumentName(inst?.name);
        return (
          <>
            <img src={img} className="lessonsAdmin_header_right_instr_img" alt="lessonsAdmin_header_right_instr_img" />
            <p className="lessonsAdmin_header_right_instr_text">{`${inst?.name.replace('_', '  ').charAt(0)}${inst?.name
              .replace('_', '  ')
              .slice(1)
              .toLowerCase()}`}</p>
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="lessons_profile">
      <div className="lessons_profile_header">
        <div className="lessons_profile_header_left">
          <Link to={() => getAdminCoachRoute(lesson?.coachProfile?.id)}>
            <ProfileCard
              firstName={lesson?.coachProfile?.firstName}
              lastName={lesson?.coachProfile?.lastName}
              id={lesson?.coachProfile?.id}
              date={lesson?.coachProfile?.createdAt}
              files={lesson?.coachProfile?.files}
            />
          </Link>
          <Link to={() => getAdminAdultRoute(lesson?.studentProfile?.id)}>
            <ProfileCard
              firstName={lesson?.studentProfile?.firstName}
              lastName={lesson?.studentProfile?.lastName}
              id={lesson?.studentProfile?.id}
              date={lesson?.studentProfile?.createdAt}
              files={lesson?.studentProfile?.files}
            />
          </Link>
        </div>
        <Button
          onClick={() => {
            adminRefundLessonMutation({ variables: { id: params.lessonId } });
            history.push('/admin/lessons');
          }}
          className="outlinedButton"
          style={{ alignSelf: 'center' }}
        >
          Refund
        </Button>
        <div className="lessons_profile_header_right">
          <div className="lessonsAdmin_header_right">
            <div className="lessonsAdmin_header_right_instr">{returnInstrument(lesson)}</div>
            <div className="lessonsAdmin_header_right_type_of_group">
              <img
                className="lessonsAdmin_header_right_type_of_group_img"
                src={one_to_one_icon}
                alt="lessonsAdmin_header_right_type_of_group_img"
              />
              <p className="lessonsAdmin_header_right_type_of_group_text">1-on-1</p>
            </div>
          </div>
        </div>
      </div>
      <div className="lessons_profile_body">
        <div className="lessons_profile_body_textFieldRow">
          <TextField
            className="lessons_profile_body_textFieldRow_textField"
            variant="outlined"
            name="date"
            label="Date"
            value={moment(+lesson.startAt).format('MMM DD, YYYY')}
            fullWidth
            disabled={true}
            InputProps={{
              classes: {
                focused: `focusedInputField`,
              },
            }}
          />
          <TextField
            className="lessons_profile_body_textFieldRow_textField"
            variant="outlined"
            name="date"
            label="Start"
            value={moment(+lesson.startAt).format('LT')}
            fullWidth
            disabled={true}
            InputProps={{
              classes: {
                focused: `focusedInputField`,
              },
            }}
          />
          <TextField
            className="lessons_profile_body_textFieldRow_textField"
            variant="outlined"
            name="date"
            label="End"
            value={moment(+lesson.finishAt).format('LT')}
            fullWidth
            disabled={true}
            InputProps={{
              classes: {
                focused: `focusedInputField`,
              },
            }}
          />
          <TextField
            className="lessons_profile_body_textFieldRow_textField"
            variant="outlined"
            name="date"
            label="Lesson"
            value={lesson.costs / 100}
            fullWidth
            disabled={true}
            InputProps={{
              classes: {
                focused: `focusedInputField`,
              },
            }}
          />
        </div>
        <p className="lessonsAdmin_container_right_topic">{lesson?.topic}</p>
        <p className="lessonsAdmin_container_right_coache_notes_title">Lesson notes</p>
        {lesson?.lessonMaterials &&
          lesson?.lessonMaterials.map((note) => {
            return <PractiseNote user={{}} lessonId={lesson?.id} note={note} />;
          })}
      </div>
    </div>
  );
}
