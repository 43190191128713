import React, { useState, useEffect, useRef, FC, useMemo } from 'react';
import cn from 'classnames';
import { useListCoachesQuery, CoachesSortByEnum, SortOrderEnum } from 'api/index';
import CoachCard from './CoachCard/CoachCard';
import Loader from 'component/Loader/Loader';
import './CoachesSlider.scss';
import { filterTestCoaches } from 'utils/helpers';

const arrowNextIcon = '/assets/arrow_next_icon.svg';
const arrowBackIcon = '/assets/arrow_back_icon.svg';

const INTERVAL_VALUE = 7000;
const perChunk = 4;

const CoachesSlider: FC = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const interval = useRef<any>(null);
  const [items, setItems] = useState<any>([]);

  const listCoachesQuery = useListCoachesQuery({
    variables: {
      limit: 12,
      offset: 0,
      order: SortOrderEnum.Asc,
      sortBy: CoachesSortByEnum.Rating,
    },
  });

  const data = listCoachesQuery?.data?.listCoaches?.data;
  const filteredData = useMemo(() => filterTestCoaches(data), [data]);

  const handleUpdateSlide = () => {
    clearInterval(interval.current);
    const id = setInterval(() => {
      setCurrentSlide((value) => (value === 2 ? 0 : value + 1));
    }, INTERVAL_VALUE);
    interval.current = id;
  };

  const handleNextSlide = () => {
    if (currentSlide === 2) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
    handleUpdateSlide();
  };

  const handlePrevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(2);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
    handleUpdateSlide();
  };

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentSlide((value) => (value === 2 ? 0 : value + 1));
    }, INTERVAL_VALUE);
    interval.current = id;
    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    const dividedItems = filteredData?.reduce((resultArray: any, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    setItems(dividedItems || []);
  }, [filteredData]);

  if (!items.length || !data) {
    return (
      <div className="coaches_slider-loader">
        <Loader />
      </div>
    );
  }

  const currentItems = items[currentSlide] || items[0];

  return (
    <section className="coaches_slider">
      <div className="coaches_slider_cards">
        {currentItems?.map((i) => (
          <CoachCard key={i.id} coach={i} />
        ))}
      </div>
      {filteredData.length > perChunk * 2 && (
        <div className="coaches_slider_controls">
          <div className="coaches_slider_controls_circles">
            <div
              className={cn('slider-circle', {
                'slider-circle_current': currentSlide === 0,
              })}
            />
            <div
              className={cn('slider-circle', {
                'slider-circle_current': currentSlide === 1,
              })}
            />
            <div
              className={cn('slider-circle', {
                'slider-circle_current': currentSlide === 2,
              })}
            />
          </div>
          <div className="coaches_slider_controls_actions">
            <button className="slider_btn">
              <img src={arrowBackIcon} className="slider_btn_icon" alt="slider_prev_cards" onClick={handlePrevSlide} />
            </button>
            <button className="slider_btn">
              <img src={arrowNextIcon} className="slider_btn_icon" alt="slider_next_cards" onClick={handleNextSlide} />
            </button>
          </div>
        </div>
      )}
      <div className="coaches_slider_controls_mobile">
        <button className="slider_btn">
          <img src={arrowBackIcon} className="slider_btn_icon" alt="slider_prev_cards" onClick={handlePrevSlide} />
        </button>
        <div className="coaches_slider_controls_circles">
          <div
            className={cn('slider-circle', {
              'slider-circle_current': currentSlide === 0,
            })}
          />
          <div
            className={cn('slider-circle', {
              'slider-circle_current': currentSlide === 1,
            })}
          />
          <div
            className={cn('slider-circle', {
              'slider-circle_current': currentSlide === 2,
            })}
          />
        </div>
        <button className="slider_btn">
          <img src={arrowNextIcon} className="slider_btn_icon" alt="slider_next_cards" onClick={handleNextSlide} />
        </button>
      </div>
    </section>
  );
};

export default CoachesSlider;
