import React, { useState, useCallback, useEffect } from 'react';
import Storage from '@aws-amplify/storage';
import Loader from 'component/Loader/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import AvatarUploader from '../../component/AvatarUploader/AvatarUploader';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Info as InfoIcon } from '@material-ui/icons';
import {
  useUpdateProfileInfoMutation,
  Profile as ProfileGraphqlProps,
  // Level,
  useListInstrumentsQuery,
  RolesEnum,
  useDeleteFileMutation,
  useUpdateStripeConnectAccountMutation,
  FileType,
  UserProfileFilesEnum,
} from 'api';
import Reviews from 'component/Reviews/Reviews';
import { Spinner } from 'component/Button/Button';
import LeftPart from './LeftPart';
import Prices from './Prices';
import Experience from './Experience';
import MainInfo from './MainInfo';
import VideoModal from './Modal/VideoModal';
import ImageModal from './Modal/ImageModal';
import { convertUrlToEmbedYoutube } from 'utils/helpers';
import './Info.scss';
import ConfirmationModal from './Modal/ConfirmationModal';
import moment from 'moment';

const closeModalCross = '/assets/close_modal_cross.svg';
const openModalIcon = '/assets/open_modal_icon.svg';
const deleteIcon = '/assets/delete_icon.svg';
const emptyRewies = '/assets/empty_rewies.svg';
const stripeIcon = '/assets/stripe_icon_black.svg';

interface ProfileProps {
  user: ProfileGraphqlProps;
  reGetUser: () => void;
}

const { REACT_APP_AWS_REGION, REACT_APP_AWSS3_BUCKET } = process.env;
const minCostErrorMessage = 'Min $1 is required';

const updateCosts = (role, oldValues, newValues) => {
  if (role !== RolesEnum.Coach) return {};
  const costsPerHalfHour = +newValues?.amount30min * 100 || 0;
  const costsPerHour = +newValues?.amount60min * 100 || 0;
  const subscriptionPerHalfHour = +newValues?.amountSubscription30min * 100 || 0;
  const subscriptionPerHour = +newValues?.amountSubscription60min * 100 || 0;
  return {
    costsPerHalfHour: oldValues?.costsPerHalfHour !== costsPerHalfHour ? costsPerHalfHour : undefined,
    costsPerHour: oldValues?.costsPerHour !== costsPerHour ? costsPerHour : undefined,
    subscriptionPerHalfHour:
      oldValues?.subscriptionPerHalfHour !== subscriptionPerHalfHour ? subscriptionPerHalfHour : undefined,
    subscriptionPerHour: oldValues?.subscriptionPerHour !== subscriptionPerHour ? subscriptionPerHour : undefined,
  };
};

const Profile = ({ user, reGetUser }: ProfileProps) => {
  const history = useHistory();
  const { push } = useHistory();
  const location = useLocation();
  const [values, setValues] = useState<any>({
    skill: '',
    lesson: '',
    about: '',
    firstName: '',
    lastName: '',
    email: '',
    amount30min: null,
    amount60min: null,
    amountSubscription30min: null,
    amountSubscription60min: null,
    birthdate: null,
    workWithAges: [],
  });
  const [errors, setErrors] = useState<any>({
    lessons: '',
    about: '',
    firstName: '',
    lastName: '',
    amount30min: '',
    amount60min: '',
    amountSubscription30min: '',
    amountSubscription60min: '',
    birthdate: '',
    workWithAges: '',
  });
  const [lessons, setLessons] = useState<any>([]);
  const [level, setLevel] = useState<any>('Beginner');
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [updateProfile, updateProfileData] = useUpdateProfileInfoMutation();
  const [deleteFile] = useDeleteFileMutation();
  const intrumentsQuery = useListInstrumentsQuery();
  const intruments = intrumentsQuery?.data?.listInstruments;
  const [intrument, setInstument] = useState<any[]>([]);
  const [levelSelect, setLevelSelect] = useState<any>('Beginner');
  const [isAuthenticated, setisAuthenticated] = useState<boolean>(false);
  const [disabledNext, setDisabledNext] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenImages, setIsOpenImages] = useState<boolean>(false);
  const [isOpenImage, setIsOpenImage] = useState<boolean>(false);
  const [lodaerDiplom, setLodaerDiplom] = useState<boolean>(false);
  const [imageModal, setImageModal] = useState<any>(null);
  const [indexTab, setIndexTab] = useState<number>(0);
  const [imagesFile, setImagesFile] = useState<any>(null);
  const [endpointURL, setEndpoint] = useState<any>(null);
  const [isSavedModalOpen, setIsSavedModalOpen] = useState(false);

  const isChild = user?.role === RolesEnum.Child;

  const handleOpenNewChild = () => {
    if (values.birthdate === null) {
      setErrors({
        ...errors,
        birthdate: 'Please, fill this field.',
      });
      return;
    }
    if (lessons.length === 0) {
      setErrors({
        ...errors,
        lessons: 'Please, fill this field.',
      });
      return;
    }
    push('/invite-children');
  };

  const [updateStripeAccount, { called }] = useUpdateStripeConnectAccountMutation();

  const handleSendStripeCode = async (authorizationCode: string) => {
    try {
      // TODO remove this in case implementing connection on BE
      // fetch('https://connect.stripe.com/oauth/token', {
      //   mode: 'no-cors',
      //   method: 'POST',
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     'Content-Type':'application/x-www-form-urlencoded',
      //     "Authorization": process.env.REACT_APP_STRIPE_SECRET_KEY || '534534',
      //   },
      //   body: `code=${ac}&grant_type=authorization_code`
      // })

      await updateStripeAccount({
        variables: {
          clientId: authorizationCode,
        },
      });
      reGetUser();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error, 'e');
    }
  };

  useEffect(() => {
    if (location.search && user && !called) {
      const authorizationCode = location.search.split('?code=')[1];
      handleSendStripeCode(authorizationCode);
    }
    if (user) {
      setValues({
        lesson: user.instruments,
        about: user.about,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        amount30min: user?.costsPerHalfHour ? user.costsPerHalfHour / 100 : 0,
        amount60min: user?.costsPerHour ? user?.costsPerHour / 100 : 0,
        amountSubscription30min: user?.subscriptionPerHalfHour ? user?.subscriptionPerHalfHour / 100 : 0,
        amountSubscription60min: user?.subscriptionPerHour ? user.subscriptionPerHour / 100 : 0,
        birthdate: user.birthdate ? user.birthdate : null,
        workWithAges: [],
      });
      if (user.workWithAges) {
        if (user.workWithAges.includes(5))
          setValues((prev) => {
            return {
              ...prev,
              workWithAges: [...prev.workWithAges, '5-7'],
            };
          });
        if (user.workWithAges.includes(8))
          setValues((prev) => {
            return {
              ...prev,
              workWithAges: [...prev.workWithAges, '8-11'],
            };
          });
        if (user.workWithAges.includes(12))
          setValues((prev) => {
            return {
              ...prev,
              workWithAges: [...prev.workWithAges, '12-17'],
            };
          });
        if (user.workWithAges.includes(18))
          setValues((prev) => {
            return {
              ...prev,
              workWithAges: [...prev.workWithAges, '18+'],
            };
          });
      }
      if (user.videoUrl) {
        setVideoUrl(user.videoUrl);
      }
      if (user) {
        setisAuthenticated(true);
      }
      const arr: any = [];
      user?.instruments?.forEach((item) => {
        arr.push({ name: item?.name!, level: item?.level! });
        setLevel(item?.level);
        setLevelSelect(item?.level!);
      });
      if (user.role === RolesEnum.Coach) {
        setLevel('Beginner');
        setLevelSelect('Beginner');
      }
      setLessons(arr);
    }
  }, [user, location]);

  const handleOpenVideoDialog = useCallback(() => setIsOpen(true), []);
  const handleOpenImagesDialog = useCallback(() => setIsOpenImages(true), []);

  const handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name !== 'about') {
      if (value.length < 255) {
        setErrors({
          ...errors,
          [name]: '',
        });
      } else {
        setErrors({
          ...errors,
          [name]: 'Must be less than 255 characters long',
        });
      }
    } else {
      if (value.length < 5000) {
        setErrors({
          ...errors,
          about: '',
        });
      } else {
        setErrors({
          ...errors,
          about: 'Must be less than 5000 characters long',
        });
      }
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = useCallback(async () => {
    if (isAuthenticated) {
      const instrumentsArr: any = [];
      intruments?.forEach((item) => {
        lessons.forEach((item2) => {
          if (item?.name.replace(' ', '').toLowerCase() === item2.name.toLowerCase()) {
            instrumentsArr.push({ id: item?.id, level: item2.level });
          }
        });
      });

      if (!lessons.length) {
        setErrors({
          ...errors,
          lessons: "Field can't be empty",
        });
        return;
      }

      if (!values.birthdate && user.role !== RolesEnum.Coach) {
        setErrors({
          ...errors,
          birthdate: 'We need to know your age because some coaches specialize in certain age groups.',
        });
        return;
      }

      if (values.workWithAges.length === 0 && user.role === RolesEnum.Coach) {
        setErrors({
          ...errors,
          workWithAges: 'Please, set age group you are working with.',
        });
        return;
      }

      if (user?.role === RolesEnum.Coach) {
        if (
          +values.amount30min < 1 ||
          +values.amount60min < 1 ||
          +values.amountSubscription30min < 1 ||
          +values.amountSubscription60min < 1
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            amount30min: +values.amount30min < 1 ? minCostErrorMessage : '',
            amount60min: +values.amount60min < 1 ? minCostErrorMessage : '',
            amountSubscription30min: +values.amountSubscription30min < 1 ? minCostErrorMessage : '',
            amountSubscription60min: +values.amountSubscription60min < 1 ? minCostErrorMessage : '',
          }));
          setIndexTab(1);
          window.scrollTo(0, 0);
          return;
        }
      }

      try {
        const instrumentsArr: any = [];
        intruments?.forEach((item) => {
          lessons.forEach((item2) => {
            if (item?.name.replace(' ', '').toLowerCase() === item2.name.replace(' ', '').toLowerCase()) {
              instrumentsArr.push({ id: item?.id, level: item2.level });
            }
          });
        });
        const instrumentsArray = instrumentsArr.map((e) => {
          return {
            id: e.id,
            level: e.level.toUpperCase(),
          };
        });
        const workWithAgesArray: any = [];
        values.workWithAges.map((ages) => {
          switch (ages) {
            case '5-7':
              workWithAgesArray.push(5, 6, 7);
              break;
            case '8-11':
              workWithAgesArray.push(8, 9, 10, 11);
              break;
            case '12-17':
              workWithAgesArray.push(12, 13, 14, 15, 16, 17);
              break;
            case '18+':
          }
        });
        if (values.workWithAges.includes('18+')) {
          for (let i = 18; i <= 120; i++) {
            workWithAgesArray.push(i);
          }
        }
        const newProfile = await updateProfile({
          variables: {
            instruments: instrumentsArray ? instrumentsArray : null,
            about: user.about !== values.about ? values.about : undefined,
            firstName: user.firstName !== values.firstName ? values.firstName : undefined,
            lastName: user.lastName !== values.lastName ? values.lastName : undefined,
            birthdate: user.birthdate !== values.birthdate ? values.birthdate : undefined,
            workWithAges: workWithAgesArray ? workWithAgesArray : null,
            ...updateCosts(user?.role, user, values),
          },
        });
        if (newProfile) {
          setisAuthenticated(true);
          setIsSavedModalOpen(true);
        }
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error, 'error');
      }
    } else {
      const instrumentsArr: any = [];
      intruments?.forEach((item) => {
        intrument.forEach((item2) => {
          if (item?.name.replace(' ', '').toLowerCase() === item2.name.toLowerCase()) {
            instrumentsArr.push({ id: item?.id, level: item2.level });
          }
        });
      });
      const workWithAgesArray: any = [];
      values.workWithAges.map((ages) => {
        switch (ages) {
          case '5-7':
            workWithAgesArray.push(5, 6, 7);
            break;
          case '8-11':
            workWithAgesArray.push(8, 9, 10, 11);
            break;
          case '12-17':
            workWithAgesArray.push(12, 13, 14, 15, 16, 17);
            break;
        }
      });
      if (values.workWithAges.includes('18+')) {
        for (let i = 18; i <= 120; i++) {
          workWithAgesArray.push(i);
        }
      }
      try {
        const instrumentsArray = instrumentsArr.map((e) => {
          return {
            id: e.id,
            level: e.level.toUpperCase(),
          };
        });
        const newProfile = await updateProfile({
          variables: {
            instruments: instrumentsArray ? instrumentsArray : null,
            firstName: values.firstName || user.firstName,
            lastName: values.lastName || user.lastName,
            about: values?.about ? values?.about : '',
            birthdate: values.birthdate || user.birthdate,
            workWithAges: workWithAgesArray ? workWithAgesArray : null,
          },
        });
        if (newProfile) {
          setisAuthenticated(true);
          if (user.role === RolesEnum.Coach) {
            history.push('/calendar');
          } else {
            history.push('/coaches');
          }
        }
      } catch (error) {
        // tslint:disable-next-line: no-console
        console.log(error, 'error');
      }
    }
    reGetUser();
  }, [
    isAuthenticated,
    reGetUser,
    intruments,
    lessons,
    user,
    user?.role,
    updateProfile,
    values.about,
    values.firstName,
    values.lastName,
    values.birthdate,
    values.workWithAges,
    values.amount30min,
    values.amount60min,
    values.amountSubscription30min,
    values.amountSubscription60min,
    history,
  ]);

  const handleChangeAmount = (prop) => (event) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [prop]: '',
    }));
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
  };

  const handleChangeAges = (event) => {
    setErrors({ ...errors, workWithAges: '' });

    if (!event.target.value) {
      setErrors({ ...errors, workWithAges: 'Please, choose ages group' });
    }

    setValues({
      ...values,
      workWithAges: event.target.value,
    });
  };

  const handleChangeBirthdate = (date) => {
    setErrors({ ...errors, birthdate: '' });

    if (!date) {
      setErrors({ ...errors, birthdate: 'Please enter a valid birth date' });
    }

    setValues({
      ...values,
      birthdate: moment(date).format('MMM DD,yyyy'),
    });
  };

  const handleChangeLessons = useCallback(
    (lesson) => {
      setErrors({
        ...errors,
        lessons: '',
      });
      if (lesson.length === 0) {
        setDisabledNext(true);
      } else if (level === undefined) {
        setDisabledNext(true);
      } else {
        setDisabledNext(false);
      }
      if (user.role === RolesEnum.Adult) {
        setLessons((prev) => {
          if (prev[0]) prev.forEach((e) => (e.level = lesson.level));
          return [...prev, lesson];
        });
        setInstument((prev) => {
          if (prev[0]) prev.forEach((e) => (e.level = lesson.level));
          return [...prev, lesson];
        });
      }
      if (user.role === RolesEnum.Coach) {
        setLessons((prev) => [...prev, lesson]);
        setInstument((prev) => [...prev, lesson]);
      }
    },
    [level, errors],
  );

  const handleDeleteLesson = (lesson: string) => {
    const filteredArr = lessons.filter((l) => l.name.replace(' ', '') !== lesson.replace(' ', ''));
    setLessons(filteredArr);
    if (filteredArr.length === 0) {
      setDisabledNext(true);
    }
  };

  const handleChangeLevel = useCallback(
    (name) => {
      if (name === '' && lessons.length === 0) {
        setDisabledNext(true);
      } else {
        if (lessons.length === 0) {
          setDisabledNext(true);
        } else {
          setDisabledNext(false);
        }
      }
      if (user.role === RolesEnum.Coach && name.length === 0) {
        setLevel(undefined);
      }
      setLevel(name);
      setLevelSelect(name);
      if (user.role === RolesEnum.Adult)
        setLessons((prev) => {
          return prev.map((e) => (e = { name: e.name, level: name }));
        });
    },
    [lessons, user],
  );

  const handleChangeTab = (event, value) => {
    setIndexTab(value);
  };
  const handleChangeIndex = (index) => {
    setIndexTab(index);
  };
  const closeImageModal = useCallback(() => {
    setIsOpenImage(false);
    setImageModal(null);
  }, []);
  const openImageModal = useCallback(async (image) => {
    await setIsOpenImage(true);
    setImageModal(image);
  }, []);
  const deleteImage = useCallback(
    async (id) => {
      setLodaerDiplom(true);
      await deleteFile({ variables: { id } });
      await reGetUser();
      setLodaerDiplom(false);
    },
    [deleteFile, reGetUser],
  );

  const stripeConstants = {
    STRIPE_CA: process.env.REACT_APP_STRIPE_CA,
    STRIPE_CONNECT_REDIRECT_URL: process.env.REACT_APP_STRIPE_CONNECT_REDIRECT_URL,
    STRIPE_CONNECT_ENDPOINT: process.env.REACT_APP_STRIPE_CONNECT_ENDPOINT,
  };

  const { STRIPE_CA, STRIPE_CONNECT_REDIRECT_URL, STRIPE_CONNECT_ENDPOINT } = stripeConstants;

  const getStripeUrl = () => {
    const redirectUrl = STRIPE_CONNECT_REDIRECT_URL;

    return `${STRIPE_CONNECT_ENDPOINT}?scope=read_write&client_id=${STRIPE_CA}&redirect_uri=${redirectUrl}`;
  };

  const handleStripeConnect = () => {
    const URL = getStripeUrl();
    setEndpoint(URL);
    console.log('connected', URL);
    window.location.href = URL;
  };

  const handleCloseEndpoint = () => {
    setEndpoint(null);
  };

  const isFileDiploma = () => {
    let isDiploma = false;
    if (user?.files) {
      user.files.forEach((item) => {
        if (item?.type) {
          if (item.type !== 'avatar') {
            isDiploma = true;
          }
        }
      });
    }
    return isDiploma;
  };

  const returnDiploma = () => {
    if (user?.files) {
      return user?.files.map((item, index) => {
        if (item?.type !== 'avatar') {
          return (
            <div key={item?.type + '' + index} className="profileDiplomaContainer">
              <div
                className="profileDiploma"
                style={{
                  background: `url(${item?.path || ''}) no-repeat center center`,
                  backgroundSize: 'cover',
                }}
              />
              <img alt="" src={deleteIcon} className="profileDiplomaDelete" onClick={() => deleteImage(item?.id)} />
              <img
                alt=""
                src={openModalIcon}
                className="profileDiplomaOpen"
                onClick={() => openImageModal(item?.path || '')}
              />
            </div>
          );
        } else {
          return <div />;
        }
      });
    } else {
      return <div />;
    }
  };

  const renderRightColumn = (
    <div className={`profileRightColumnFields ${!isAuthenticated ? 'limitedFields' : ''}`}>
      {user?.role === RolesEnum.Coach ? (
        <>
          {isAuthenticated ? (
            <div className="tabs_header">
              <Tabs value={indexTab} onChange={handleChangeTab}>
                <Tab label="General" />
                <Tab label="Pricing" />
                <Tab label="Reviews" />
              </Tabs>
              <SwipeableViews index={indexTab} onChangeIndex={handleChangeIndex}>
                <div
                  style={{ marginTop: '32px', overflowX: 'hidden' }}
                  className={`profileRightColumnFields ${!isAuthenticated ? 'limitedFields' : ''}`}
                >
                  <AvatarUploader user={user} reGetUser={reGetUser} updateUser={updateProfile} />
                  {isAuthenticated && (
                    <MainInfo
                      errors={errors}
                      values={values}
                      handleChange={handleChange}
                      user={user}
                      handleChangeBirthdate={handleChangeBirthdate}
                      handleChangeAges={handleChangeAges}
                    />
                  )}
                  <Experience
                    isAuthenticated={isAuthenticated}
                    lessons={lessons}
                    handleDeleteLesson={handleDeleteLesson}
                    handleChangeLessons={handleChangeLessons}
                    handleChangeLevel={handleChangeLevel}
                    handleChange={handleChange}
                    values={values}
                    level={level}
                    handleKeyPress={handleKeyPress}
                    intrument={intrument}
                    levelSelect={levelSelect}
                    user={user}
                    errors={errors}
                  />
                  {isAuthenticated && user.role === RolesEnum.Coach && (
                    <div className="profileMediaSection">
                      <Typography className="profileTitle">Media files</Typography>
                      <div className={`profileMediaActions`}>
                        <div onClick={handleOpenImagesDialog}>
                          <AddIcon />
                          <Typography
                            onClick={handleOpenImagesDialog}
                            className={`profileUploadDocumentButton ${false ? 'profileDisabled' : ''}`}
                            variant="body2"
                          >
                            Upload diploma/certificate
                          </Typography>
                        </div>
                        {lodaerDiplom ? (
                          <div className="profileImagesOfDiplomaLoader">
                            <Loader small={true} />
                          </div>
                        ) : isFileDiploma() ? (
                          <div className="profileImagesOfDiploma">{returnDiploma()}</div>
                        ) : null}
                        <div onClick={handleOpenVideoDialog}>
                          <AddIcon />
                          <Typography
                            onClick={handleOpenVideoDialog}
                            className={`profileUploadVideoButton ${false ? 'profileDisabled' : ''}`}
                            variant="body2"
                          >
                            Attach YouTube video
                          </Typography>
                        </div>
                        {user.videoUrl && (
                          <div>
                            <iframe
                              title="youtube"
                              className="video_frame"
                              width={(global.window ? (global.window as any).innerWidth : 0) < 700 ? '300' : '670'}
                              height={(global.window ? (global.window as any).innerWidth : 0) < 700 ? '140' : '380'}
                              src={convertUrlToEmbedYoutube(user.videoUrl)}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className={`profileActions ${!isAuthenticated ? 'profileActionsPartial' : ''}`}>
                    <Button
                      disabled={!isAuthenticated && disabledNext}
                      className={`profileButton ${!isAuthenticated && disabledNext ? 'profileDisabled' : ''}`}
                      variant="contained"
                      color="secondary"
                      onClick={handleSubmit}
                    >
                      {isAuthenticated ? 'Save' : 'Next'}
                      {false && <Spinner style={{ position: 'absolute', color: '#fff' }} />}
                    </Button>
                  </div>
                </div>
                <div style={{ marginTop: '32px' }}>
                  {isAuthenticated && user.role === RolesEnum.Coach && (
                    <Prices
                      lastDigits={user?.balance?.lastDigits?.toString()}
                      errors={errors}
                      handleChangeAmount={handleChangeAmount}
                      amount30min={values.amount30min}
                      amount60min={values.amount60min}
                      amountSubscription30min={values.amountSubscription30min}
                      amountSubscription60min={values.amountSubscription60min}
                      handleStripeConnect={handleStripeConnect}
                    />
                  )}
                  <div className={`profileActions ${!isAuthenticated ? 'profileActionsPartial' : ''}`}>
                    <Button
                      disabled={!isAuthenticated && disabledNext}
                      className={`profileButton ${!isAuthenticated && disabledNext ? 'profileDisabled' : ''}`}
                      variant="contained"
                      color="secondary"
                      onClick={handleSubmit}
                    >
                      {isAuthenticated ? 'Save' : 'Next'}
                      {false && <Spinner style={{ position: 'absolute', color: '#fff' }} />}
                    </Button>
                  </div>
                  <div className="profile-stripe-info">
                    <a href="https://stripe.com" rel="noopener noreferrer" target="_blank">
                      <img src={stripeIcon} alt="stripe-icon" />
                    </a>
                  </div>
                </div>
                <div style={{ marginTop: '32px' }}>
                  <h3 className="infoReviews">Reviews</h3>
                  {user?.feedback?.length !== 0 ? (
                    user?.feedback?.map((item, index) => <Reviews key={'Reviews' + index} review={item} />)
                  ) : (
                    <img alt="" src={emptyRewies} style={{ width: '100%' }} />
                  )}
                </div>
              </SwipeableViews>
            </div>
          ) : (
            <div
              style={{ marginTop: '32px' }}
              className={`profileRightColumnFields ${!isAuthenticated ? 'limitedFields' : ''}`}
            >
              <AvatarUploader user={user} reGetUser={reGetUser} updateUser={updateProfile} />
              {isAuthenticated && (
                <MainInfo
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  user={user}
                  handleChangeBirthdate={handleChangeBirthdate}
                  handleChangeAges={handleChangeAges}
                />
              )}
              <Experience
                isAuthenticated={isAuthenticated}
                lessons={lessons}
                handleDeleteLesson={handleDeleteLesson}
                handleChangeLessons={handleChangeLessons}
                handleChangeLevel={handleChangeLevel}
                handleChange={handleChange}
                values={values}
                level={level}
                handleKeyPress={handleKeyPress}
                intrument={intrument}
                levelSelect={levelSelect}
                user={user}
                errors={errors}
              />
              {isAuthenticated && user.role === RolesEnum.Coach && (
                <div className="profileMediaSection">
                  <Typography className="profileTitle">Media files</Typography>
                  <div className={`profileMediaActions`}>
                    <div onClick={handleOpenImagesDialog}>
                      <AddIcon />
                      <Typography
                        onClick={handleOpenImagesDialog}
                        className={`profileUploadDocumentButton ${false ? 'profileDisabled' : ''}`}
                        variant="body2"
                      >
                        Upload diploma/certificate
                      </Typography>
                    </div>
                    <div onClick={handleOpenVideoDialog}>
                      <AddIcon />
                      <Typography
                        onClick={handleOpenVideoDialog}
                        className={`profileUploadVideoButton ${false ? 'profileDisabled' : ''}`}
                        variant="body2"
                      >
                        Attach YouTube video
                      </Typography>
                    </div>
                    {user.videoUrl && (
                      <div>
                        <iframe
                          title="youtube"
                          className="video_frame"
                          width={(global.window ? (global.window as any).innerWidth : 0) < 700 ? '300' : '670'}
                          height={(global.window ? (global.window as any).innerWidth : 0) < 700 ? '140' : '380'}
                          src={convertUrlToEmbedYoutube(user.videoUrl)}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className={`profileActions ${!isAuthenticated ? 'profileActionsPartial' : ''}`}>
                <Button
                  disabled={!isAuthenticated && disabledNext}
                  className={`profileButton ${!isAuthenticated && disabledNext ? 'profileDisabled' : ''}`}
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit}
                >
                  {isAuthenticated ? 'Save' : 'Next'}
                  {false && <Spinner style={{ position: 'absolute', color: '#fff' }} />}
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <AvatarUploader user={user} reGetUser={reGetUser} disabled={isChild} updateUser={updateProfile} />
          {!isChild ? (
            <Button onClick={handleOpenNewChild} className="my-children_btn-add" startIcon={<AddIcon />}>
            Add child
          </Button>
          ) : null}
          {isAuthenticated && (
            <MainInfo
              handleChange={handleChange}
              values={values}
              disabledFields={isChild}
              errors={errors}
              user={user}
              handleChangeBirthdate={handleChangeBirthdate}
              handleChangeAges={handleChangeAges}
            />
          )}
          <Experience
            isAuthenticated={isAuthenticated}
            lessons={lessons}
            handleChangeLessons={handleChangeLessons}
            handleChangeLevel={handleChangeLevel}
            handleChange={handleChange}
            handleDeleteLesson={handleDeleteLesson}
            values={values}
            level={level}
            handleKeyPress={handleKeyPress}
            intrument={intrument}
            levelSelect={levelSelect}
            user={user}
            disabledFields={isChild}
            errors={errors}
          />
          {!isChild ? (
            <div className={`profileActions ${!isAuthenticated ? 'profileActionsPartial' : ''}`}>
              <Button
                disabled={!isAuthenticated && disabledNext}
                className={`profileButton ${!isAuthenticated && disabledNext ? 'profileDisabled' : ''}`}
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
              >
                {isAuthenticated ? 'Save' : 'Next'}
                {false && <Spinner style={{ position: 'absolute', color: '#fff' }} />}
              </Button>
            </div>
          ) : (
            <div className="child_description">
              <InfoIcon className="child_description_icon" />
              <p className="child_description_text">Adult you’ve been invited has a permission to edit your profile.</p>
            </div>
          )}
        </>
      )}
    </div>
  );

  const handleChangeVideoUrl = (e) => {
    const value = e.target.value;
    setVideoUrl(value);
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const handleCloseImages = useCallback(() => {
    setIsOpenImages(false);
  }, []);

  const handleSubmitVideo = useCallback(async () => {
    const instrumentsArr: any = [];
    intruments?.map((item) => {
      lessons.map((item2) => {
        if (item?.name.toLowerCase() === item2.name.toLowerCase()) {
          instrumentsArr.push({ id: item?.id, level: item2.level });
        }
      });
    });
    try {
      const url: string = `https://www.youtube.com/embed/${videoUrl.split('/')[3]}`;
      const instrumentsArr: any = [];
      intruments?.forEach((item) => {
        lessons.forEach((item2) => {
          if (item?.name.replace(' ', '').toLowerCase() === item2.name.toLowerCase()) {
            instrumentsArr.push({ id: item?.id, level: item2.level });
          }
        });
      });
      const instrumentsArray = instrumentsArr.map((e) => {
        return {
          id: e.id,
          level: e.level.toUpperCase(),
        };
      });
      await updateProfile({
        variables: {
          instruments: instrumentsArray ? instrumentsArray : null,
          about: values.about ? values.about : '',
          videoUrl: videoUrl ? url : '',
        },
      });
      handleClose();
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.log(error, 'error');
    }
    reGetUser();
  }, [intruments, reGetUser, lessons, level, user?.role, videoUrl, updateProfile, values.about, handleClose]);

  const handleChangeDropZone = (files) => {
    setImagesFile(files);
  };

  const handleSubmitImages = async () => {
    if (imagesFile) {
      const userDiplomas = user?.files?.filter((file) => file?.type === UserProfileFilesEnum.Diploma);
      if (imagesFile.length === 0 || !userDiplomas || userDiplomas?.length >= 3) return;
      try {
        setLodaerDiplom(true);
        const resArr: any[] = await Promise.all(
          imagesFile.map(async (file) => {
            return await Storage.put(`userInfo/${user.id}/diploma/${file.name.replace(/ /g, '')}`, file);
          }),
        );
        const userFiles = resArr.map((res) => ({
          type: FileType.Diploma,
          path: `https://${REACT_APP_AWSS3_BUCKET}.s3.${REACT_APP_AWS_REGION}.amazonaws.com/public/${res.key}`,
        }));
        if (userFiles.length > 0) {
          handleCloseImages();
          updateProfile({ variables: { userFiles } });
          setTimeout(async () => {
            reGetUser();
            setLodaerDiplom(false);
          }, 6000);
        }
      } catch (e) {
        handleCloseImages();
        reGetUser();
        // tslint:disable-next-line: no-console
        console.log(e, 'e uploadPhoto');
      }
    }
  };

  return (
    <div className="profileContainer">
      <Modal open={endpointURL} onClose={handleCloseEndpoint} className="profile_modal">
        <div />
      </Modal>
      <Modal open={isOpen} onClose={handleClose} className="profile_modal">
        <VideoModal
          handleClose={handleClose}
          handleChangeVideoUrl={handleChangeVideoUrl}
          videoUrl={videoUrl}
          handleKeyPress={handleKeyPress}
          handleSubmitVideo={handleSubmitVideo}
        />
      </Modal>
      <Modal open={isOpenImages} onClose={handleCloseImages} className="profile_modal">
        <ImageModal
          handleChangeDropZone={handleChangeDropZone}
          handleCloseImages={handleCloseImages}
          handleSubmitImages={handleSubmitImages}
        />
      </Modal>
      <Modal open={isOpenImage} onClose={closeImageModal} className="profile_modal">
        <div className="profile_modal_video">
          <div className="profile_modal_video_header">
            <p className="profile_modal_video_title" />
            <img alt="" src={closeModalCross} className="profile_modal_video_close" onClick={closeImageModal} />
          </div>
          <img alt="" src={imageModal} className="profile_modal_image_prewie" />
        </div>
      </Modal>
      <Modal
        open={isSavedModalOpen}
        onClose={() => {
          const coachId = sessionStorage.getItem('coachId');
          sessionStorage.removeItem('coachId');
          if (coachId) {
            history.push('/calendar', { id: coachId });
          }
          setIsSavedModalOpen(false);
        }}
        className="profile_modal"
      >
        <ConfirmationModal
          title="Saved"
          handleOnOkClick={() => {
            const coachId = sessionStorage.getItem('coachId');
            sessionStorage.removeItem('coachId');
            if (coachId) {
              history.push('/calendar', { id: coachId });
            }
            setIsSavedModalOpen(false);
          }}
          handleClose={() => {
            const coachId = sessionStorage.getItem('coachId');
            sessionStorage.removeItem('coachId');
            if (coachId) {
              history.push('/calendar', { id: coachId });
            }
            setIsSavedModalOpen(false);
          }}
        />
      </Modal>
      {!isAuthenticated && (
        <div className="profileColumn profileLeftColumn">
          <LeftPart />
        </div>
      )}
      <div className="profileColumn profileRightColumn" style={!isAuthenticated ? { marginTop: '65px' } : {}}>
        {renderRightColumn}
      </div>
    </div>
  );
};

export default Profile;
