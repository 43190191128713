import React, { ReactNode, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { StaticRouter, StaticRouterContext } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import Routes from './router';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = (global.window as any)?.innerWidth
  ? require('@stripe/stripe-js').loadStripe(process.env.REACT_APP_STRIPE_KEY || '')
  : null;
interface AppProps {
  context?: StaticRouterContext;
  url?: string;
}
interface RouterProps {
  context?: StaticRouterContext;
  url?: string;
  children?: ReactNode;
}

function Router(props: RouterProps) {
  if (props.url && props.context) {
    return (
      <StaticRouter location={props.url} context={props.context}>
        {props.children}
      </StaticRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Elements stripe={stripePromise}>{props.children}</Elements>
      </BrowserRouter>
    );
  }
}

function App(props: AppProps) {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <Router url={props.url} context={props.context}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </Router>
  );
}

export default App;
