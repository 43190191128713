import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import ModalWindow from './Modal/Modal';
import ChooseCardCarousel from 'component/ChooseCardCarousel/ChooseCardCarousel';
import moment from 'moment';
import {
  useRefillBalanceMutation,
  CurrencyEnum,
  useCreateOrderMutation,
  useCompleteOrderMutation,
  LessonType,
  AppointmentPeriodEnum,
  ProfileDocument,
  useListBankCardsQuery, useListCoachCalendarSlotsLazyQuery,
} from 'api/index';
import StripeInputComponent from './StripeInput';
import { Spinner } from '../../component/Button/Button';
import { delay } from 'utils/helpers';
import './BuyLesson.scss';

const stripeIcon = '/assets/stripe_icon_black.svg';
// const cardCheckMark = '/assets/card-checkmark.png';

const retryMutation = async (callsCounter: number, mutation, orderId) => {
  try {
    return await mutation({
      variables: {
        orderId,
      },
    });
  } catch (e: any) {
    if (callsCounter <= 0) {
      throw new Error(e);
    } else {
      await delay(5000);
      return await retryMutation(callsCounter - 1, mutation, orderId);
    }
  }
};

const roundAmount = (amount) => {
  // console.log('roundAmount::amount', amount);
  const res = Math.ceil(amount * 100) / 100;
  // console.log('roundAmount::res', res);
  return res;
};

const BuyLesson = !global.window
  ? ({ reGetUser }) => null
  : ({ reGetUser, user }) => {
      const history = useHistory();
      const location: any = useLocation();
      const locationState = JSON.parse(location.state.data)
      const stripe = useStripe();
      const elements = useElements();
      const [createOrderMut] = useCreateOrderMutation();
      const cardsList = useListBankCardsQuery({
        fetchPolicy: 'network-only',
      }).data?.listBankCards;
      const createOrder = (...args) => {
        // console.log('createOrder:args', JSON.stringify(args));
        return createOrderMut(...args);
      };
      const [completeOrder] = useCompleteOrderMutation();
      const [refillBalanceMutation] = useRefillBalanceMutation({
        awaitRefetchQueries: true,
        refetchQueries: user?.id
          ? [
              {
                query: ProfileDocument,
                variables: { id: user?.id },
              },
            ]
          : [],
      });
    const [refetchCalendarSlots] = useListCoachCalendarSlotsLazyQuery({ fetchPolicy: 'network-only'});
    // console.log('BuyLesson::locationState.curBalance', locationState.curBalance);
    // console.log('BuyLesson::locationState.cost', locationState.cost);
    const [amount, setAmount] = useState<number>(
      roundAmount(locationState.curBalance && locationState.cost > locationState.curBalance
          ? locationState.cost - locationState.curBalance
          : locationState.cost),
      );
      const [cardNumber, setCardNumber] = useState<string>('');
      const [exp, setExp] = useState<string>('');
      const [cvv, setCvv] = useState<string>('');
      const [lading, setLading] = useState<boolean>(false);
      const [load, setLoad] = useState<boolean>(false);
      const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
      const [isModalState, setIsModalState] = useState<string>('suc');
      const [cardId, setCardId] = useState('');
      const subData = locationState?.subData;
      let payIsDisabled = false;
      const fixInstrumentId = (id) => {
        if (typeof id === 'string') {
          const [userId, instrumentId] = id.split(':');
          return Number(instrumentId);
        }
        return id;
      };

      const handleChangeAmount = useCallback((event) => {
        setAmount(event.target.value);
      }, []);

      const handleChangeCardNumber = useCallback((event) => {
        setCardId('');
        setCardNumber(event.target.value);
      }, []);

      const handleChangeExp = useCallback((event) => {
        setCardId('');
        setExp(event.target.value);
      }, []);

      const handleChangeCVV = useCallback((event) => {
        setCardId('');
        setCvv(event.target.value);
      }, []);

      const handleCancel = useCallback(() => {
        history.push('/calendar');
      }, [history]);

      const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
      }, []);

      const handleOpenModal = useCallback(() => {
        setIsModalOpen(true);
      }, []);

      const handleSubscribe = useCallback(
        async (cardToken: string) => {
          const bookData = locationState?.bookData;
          const coach = locationState?.coach
          
          if (!subData || !bookData || !coach) {
            throw new Error();
          }
          // await refillBalanceMutation({
          //   variables: {
          //     bankCardId: cardToken || '',
          //     amount: +amount * 100,
          //     currency: CurrencyEnum.Usd,
          //   },
          // });
          let time: any = [];
          if (subData?.week.length === 0) {
            time.push({
              startAt: moment(bookData.start).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              finishAt: moment(bookData.end).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
              timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
          }

          const currentWeekday = moment(bookData.start).weekday();

          subData?.week?.forEach((itemW) => {
            const weekdayOffset = itemW.day < currentWeekday ? itemW.day + 7 : itemW.day;
            itemW.since.forEach((item, index) => {
              time.push({
                startAt: moment(item).weekday(weekdayOffset).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                finishAt: moment(itemW.until[index])
                  .weekday(weekdayOffset)
                  .utc()
                  .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
              });
            });
          });

          try {
            const order: any = await createOrder({
              variables: {
                timeSlots: time,
                coachId: coach.id,
                childId: bookData.for,
                costs:
                  moment(bookData.start).diff(moment(bookData.end), 'hour') < 0
                    ? coach?.subscriptionPerHour
                    : coach?.subscriptionPerHalfHour,
                musicInstrumentsId: [+fixInstrumentId(bookData.instrument)],
                type: LessonType.Individual,
                periodCount: +subData.amountOfWeeks,
                periodType: AppointmentPeriodEnum.Subscription,
                bankCardId: cardToken || '',
              },
            });
            if (order?.data?.createOrder?.id) {
              try {
                const complete = await retryMutation(10, completeOrder, order?.data?.createOrder?.id);
                if (complete?.data?.completeOrder?.status) {
                  if (
                    window.location.hostname === 'bestmusiccoach.com/coaches' ||
                    window.location.hostname === 'www.bestmusiccoach.com/coaches'
                  ) {
                    (window as any)?.ga('create', 'UA-161929200-1', 'auto');
                    (window as any)?.ga('send', 'pageview', '/purchase-confirmed');
                  }

                  setIsModalState('sub');
                  refetchCalendarSlots({
                    variables: {
                      coachId: coach.id,
                      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                  });
                  handleOpenModal();
                }
              } catch (e) {
                console.error(e);
                setIsModalState('err');
                handleOpenModal();
              }
            }
          } catch (e) {
            console.error(e);
            setIsModalState('err');
            handleOpenModal();
          }
        },
        [amount, completeOrder, createOrder, handleOpenModal, location, refillBalanceMutation, subData],
      );

      const handlePay = useCallback(async () => {
        if (payIsDisabled) {
          return;
        }
        setLading(true);
        setLoad(true);
        payIsDisabled = true;
        try {
          if (elements && stripe) {
            const card = elements.getElement(CardNumberElement);
            if (card) {
              const result = await stripe.createToken(card);
              if (result.error && cardId === '') {
                console.log(result.error);
                setIsModalState('err');
                handleOpenModal();
              } else {
                if (subData) {
                  await handleSubscribe(result?.token?.id || cardId);
                  setLading(false);
                  setLoad(false);
                  return;
                }
                const balance: any = await refillBalanceMutation({
                  variables: {
                    bankCardId: result?.token?.id || cardId,
                    amount: +amount * 100,
                    currency: CurrencyEnum.Usd,
                  },
                });
                await reGetUser();
                if (locationState?.balance) {
                  handleOpenModal();
                  setIsModalState('refill');
                } else {
                  if ((balance.data?.refillBalance?.balance.amount || 0) / 100 + +amount >= locationState.cost) {
                    let time = [
                      {
                        startAt: moment(locationState.bookData.start).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                        finishAt: moment(locationState.bookData.end).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                        timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                      },
                    ];
                    const create: any = await createOrder({
                      variables: {
                        timeSlots: time,
                        coachId: locationState.id,
                        childId: locationState.bookData.for,
                        costs: parseFloat(locationState.cost) * 100,
                        musicInstrumentsId: [+fixInstrumentId(locationState.bookData.instrument)],
                        type: LessonType.Individual,
                        periodCount: 1,
                        periodType: AppointmentPeriodEnum.OneTime,
                        coupon: locationState.bookData.coupon
                          ? {
                              code: locationState.bookData.coupon,
                            }
                          : undefined,
                      },
                    });
                    if (create?.data?.createOrder?.id) {
                      try {
                        const complete = await retryMutation(10, completeOrder, create?.data?.createOrder?.id);
                        console.log('complete-->',complete);
                        if (complete?.data?.completeOrder?.status) {
                          const coach = locationState?.coach;
                          setIsModalState('suc');
                          setTimeout(() => {
                            refetchCalendarSlots({
                                                   variables: {
                                                     coachId: coach.id,
                                                     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                                                   }
                                                 });
                          }, 100)
                          handleOpenModal();
                        } else {
                          handleOpenModal();
                          setIsModalState('book');
                        }
                      } catch (e) {
                        console.error(e);
                        setIsModalState('err');
                        handleOpenModal();
                      }
                    } else {
                      handleOpenModal();
                      setIsModalState('book');
                    }
                  } else {
                    handleOpenModal();
                    setIsModalState('book');
                  }
                }
              }
            }
          }
          setLading(false);
          setLoad(false);
          payIsDisabled = false;
        } catch (error) {
          setLading(false);
          setLoad(false);
          payIsDisabled = false;
          // tslint:disable-next-line: no-console
          console.log(error, 'error');
          setIsModalState('err');
          handleOpenModal();
        }
      }, [
        locationState,
        reGetUser,
        handleSubscribe,
        subData,
        payIsDisabled,
        amount,
        completeOrder,
        createOrder,
        elements,
        handleOpenModal,
        refillBalanceMutation,
        stripe,
      ]);

      const handleReturnToCalendar = useCallback(() => {
        history.push('/calendar', { coachid: locationState?.id });
      }, [history, location]);

      const handleGoToCoachsList = useCallback(() => {
        history.push('/coaches');
      }, [history]);

      const StripeInput: any = StripeInputComponent;
      // console.log('StripeInput', StripeInput);
      // console.log('CardNumberElement', CardNumberElement);

      return (
        <form className="container" id="form">
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            className="profile_modal"
            disableBackdropClick={isModalState !== 'err'}
          >
            <ModalWindow
              handleClose={handleCloseModal}
              handleGoToCoachsList={handleGoToCoachsList}
              handleReturnToCalendar={handleReturnToCalendar}
              isModalState={isModalState}
            />
          </Modal>
          <div className="buyLesson">
            <div className="buyLesson_row">
              <div className="buyLesson_row_lesson">
                <p className="buyLesson_row_lesson_title">{subData ? 'Subscription' : 'Refill balance'}</p>
                <p className="buyLesson_row_lesson_second">
                  {subData
                    ? `You will be charged $${locationState?.cost} every week.`
                    : 'To start making music add to your balance now.'}
                </p>
              </div>
              <TextField
                className="buyLesson_row_amount"
                variant="outlined"
                name="Amount"
                label="Amount"
                required={true}
                type="text"
                onChange={handleChangeAmount}
                value={amount || ''}
                fullWidth={true}
                disabled={load || subData}
                InputProps={{
                  classes: {
                    focused: `focusedInputField`,
                  },
                }}
              />
            </div>
            <ChooseCardCarousel cardsList={cardsList} setCardId={setCardId} cardId={cardId} />
            <div className="buyLesson_row">
              <TextField
                className="buyLesson_row_cardNumber"
                variant="outlined"
                name="cardNumber"
                placeholder="Card number"
                type="text"
                onChange={handleChangeCardNumber}
                value={cardNumber || ''}
                fullWidth={true}
                disabled={load}
                InputProps={{
                  inputComponent: StripeInput,
                  classes: {
                    focused: `focusedInputField`,
                  },
                  inputProps: {
                    component: CardNumberElement,
                  },
                }}
              />
            </div>
            <div className="buyLesson_row">
              <TextField
                className="buyLesson_row_exp"
                variant="outlined"
                name="exp"
                type="text"
                onChange={handleChangeExp}
                value={exp || ''}
                fullWidth={true}
                disabled={load}
                InputProps={{
                  inputComponent: StripeInput,
                  classes: {
                    focused: `focusedInputField`,
                  },
                  inputProps: {
                    component: CardExpiryElement,
                  },
                }}
              />
              <TextField
                className="buyLesson_row_cvv"
                variant="outlined"
                name="cvv"
                type="text"
                onChange={handleChangeCVV}
                value={cvv || ''}
                fullWidth={true}
                disabled={load}
                InputProps={{
                  inputComponent: StripeInput,
                  classes: {
                    focused: `focusedInputField`,
                  },
                  inputProps: {
                    component: CardCvcElement,
                  },
                }}
              />
            </div>
            <div className="buyLesson_last_row">
              <Button className="buyLesson_last_row_cancel" size="small" variant="contained" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="buyLesson_last_row_pay"
                size="small"
                variant="contained"
                onClick={load ? undefined : handlePay}
                disabled={lading}
              >
                {load ? <Spinner style={{ color: '#fff' }} /> : 'Pay'}
              </Button>
            </div>
            <div className="buyLesson_stripe_row">
              <a href="https://stripe.com" rel="noopener noreferrer" target="_blank">
                <img src={stripeIcon} alt="stripe-icon" />
              </a>
            </div>
          </div>
        </form>
      );
    };

export default BuyLesson;
