import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../modules/routes';
import CouponList from './CouponList';
import Coupon from './Coupon';

export default function Component() {
  return (
    <>
      <Switch>
        <Route exact key={routes.adminCoupon} path={routes.adminCoupon}>
          <CouponList />
        </Route>
        <Route exact key={routes.adminCreateCoupon} path={routes.adminCreateCoupon}>
          <Coupon />
        </Route>
      </Switch>
    </>
  );
}
