import React from 'react';
import { getAvatar } from 'utils/image';
import UserAvatar from '../../../../component/UserAvatar/UserAvatar';
import './ProfileCard.scss';
import moment from 'moment';

interface ProfileCardProps {
  files: any;
  id: string;
  date: string;
  firstName: string;
  lastName: string;
}

export default function ProfileCard({ files, id = '', date = '', firstName = '', lastName = '' }: ProfileCardProps) {
  return (
    <div className="profile_card">
      <UserAvatar
        classes="profile_card_avatar"
        firstName={firstName}
        lastName={lastName}
        avatar={getAvatar(files || []) || ''}
      />
      <div className="profile_card_textBlock">
        <div className="profile_card_title">{`${firstName} ${lastName}`}</div>
        <div className="profile_card_text">{`ID: ${id}`}</div>
        <div className="profile_card_text">{`Registration date: ${moment(Number.parseInt(date || '')).format(
          'DD.MM.YYYY',
        )}`}</div>
      </div>
    </div>
  );
}
