import React from 'react';
import Button from '@material-ui/core/Button';
import { DropzoneArea } from 'material-ui-dropzone';

const close_modal_cross = '/assets/close_modal_cross.svg';

const ImageModal = ({ handleChangeDropZone, handleCloseImages, handleSubmitImages }) => {
  return (
    <div className="profile_modal_video">
      <div className="profile_modal_video_header">
        <p className="profile_modal_video_title">Upload diploma/certificate</p>
        <img alt="" src={close_modal_cross} className="profile_modal_video_close" onClick={handleCloseImages} />
      </div>
      <DropzoneArea
        onChange={handleChangeDropZone}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        dropzoneText={'Drop files here or'}
      />
      <div className="profile_modal_video_button_container">
        <Button className="profileButton" variant="contained" color="secondary" onClick={handleSubmitImages}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ImageModal;
