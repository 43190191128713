import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import CheckedIcon from '@material-ui/icons/Check';
import UncheckedIcon from '@material-ui/icons/Close';
import { Level } from '../../api/index';
import './Levels.scss';

const ucFirst = (string) => `${string}`.charAt(0).toUpperCase() + string.slice(1);

interface LevelsProps {
  level: Level[] | Array<any> | any[];
}

interface rateArr {
  type: string;
  checked: boolean;
  className: string;
}

const Levels = ({ level }: LevelsProps) => {
  const [arr, setArr] = useState<any>([]);
  useEffect(() => {
    setArr([
      {
        type: 'beginner',
        checked: level.includes(Level.Beginner) || level.includes(Level.Intermediate) || level.includes(Level.Advanced),
        className: 'courseCardLevelIconLeft',
      },
      {
        type: 'intermediate',
        checked: level.includes(Level.Intermediate) || level.includes(Level.Advanced),
        className: 'courseCardLevelIconCenter',
      },
      {
        type: 'advanced',
        checked: level.includes(Level.Advanced),
        className: 'courseCardLevelIconRight',
      },
    ]);
  }, [level]);
  return (
    <div className="courseCardLevel">
      <div className="courseCardLevelTitles">
        {arr.map(({ type, checked }) => (
          <Typography
            key={checked + type}
            className={`courseCardLevelTitle ${
              checked ? 'courseCardLevelTitlescheckedText' : 'courseCardLevelTitlesUncheckedText'
            }`}
            variant="caption"
            display="block"
            gutterBottom
          >
            {ucFirst(type || '')}
          </Typography>
        ))}
      </div>
      <div className="courseCardLevelIcons">
        {arr.map(({ checked, className }) => (
          <div className={`courseCardLevelIcon ${className}`} key={checked + className}>
            {!checked ? (
              <UncheckedIcon className="courseCardLevelIconUncheck" />
            ) : (
              <CheckedIcon className="courseCardLevelIconCheck" />
            )}
          </div>
        ))}
      </div>
      <div className="courseCardLevelItem"></div>
    </div>
  );
};

export default Levels;
