import React from 'react';
import Reviews from 'component/Reviews/Reviews';
import './ReviewList.scss';
import Rating from '@material-ui/lab/Rating';

export const ReviewList = ({ feedback, rating }) => {
  return (
    <div className="admin-coach-reviews-list">
      <div className="admin-coach-reviews-total">
        <Rating name="read-only" value={rating} readOnly />
        <span>{`${feedback.length} reviews`}</span>
      </div>
      {feedback.map((review) => (
        <Reviews review={review} />
      ))}
    </div>
  );
};
