import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BookFragment, useListBooksQuery } from 'api';
import Loader from 'component/Loader/Loader';
import BookTable from './BookTable/BookTable';
import BasicPagination from '../../../components/BasicPagination';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import { routes } from '../../../modules/routes';
import './BooksList.scss';

const rowsPerPageOptions = [5, 10, 25, 100];

const BooksList = () => {
  const [limit, setLimit] = useState<number>(rowsPerPageOptions[0]);
  const [page, setPage] = useState<number>(0);
  const { data, loading, refetch } = useListBooksQuery({
    variables: { offset: 0, limit },
    fetchPolicy: 'network-only',
  });
  const history = useHistory();
  const books: BookFragment[] = data?.listBooks.data as BookFragment[];
  const total = data?.listBooks.meta?.count || 0;

  const handleAddBook = useCallback(() => history.push(routes.adminNewBook), [history]);

  const requestBookList = useCallback(
    (page, limit) => {
      const variables = {
        limit: limit,
        offset: page * limit,
      };
      refetch(variables);
    },
    [refetch],
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setLimit(event.target.value);
      setPage(0);
      requestBookList(0, event.target.value);
    },
    [requestBookList],
  );

  const switchToNextPage = useCallback(
    (e, pageNumber: number) => {
      setPage(pageNumber);
      requestBookList(pageNumber, limit);
    },
    [requestBookList, limit],
  );

  const handleChangePage = useCallback(
    (e, numberOfPage) => {
      if (global.window) {
        global.window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
      switchToNextPage(e, numberOfPage);
    },
    [switchToNextPage],
  );

  const handlePageClick = useCallback(
    (e: React.ChangeEvent<unknown>, value: number) => {
      switchToNextPage(e, value - 1);
    },
    [switchToNextPage],
  );

  if (loading) {
    return (
      <div className="admin-books-list">
        <Loader />
      </div>
    );
  }

  return (
    <div className="admin-books-list">
      <div className="admin-books-list__title">
        <p>Books</p>
        <Button onClick={handleAddBook}>Add new</Button>
      </div>
      <BookTable rows={books} />
      {books.length > 0 && (
        <BasicPagination
          rowsPerPageOptions={rowsPerPageOptions}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={handleChangePage}
          onPageClick={handlePageClick}
          total={total}
          limit={limit}
          page={page}
        />
      )}
    </div>
  );
};

export default BooksList;
