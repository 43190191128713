import { CoachesSortByEnum, SortOrderEnum, useListCoachesQuery } from 'api/index';
import React, { useCallback } from 'react';
import Loader from '../../components/CustomLoader';
import BasicPagination from '../../components/BasicPagination';
import './Coaches.scss';
import BasicTable from './components/BasicTable';

export default function CoachesList() {
  const [limit, setLimit] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);

  const listCoachesQuery = useListCoachesQuery({
    variables: {
      limit: 10,
      offset: 0,
      order: SortOrderEnum.Asc,
      sortBy: CoachesSortByEnum.CreatedAt,
    },
  });

  const rows = listCoachesQuery?.data?.listCoaches?.data || [];
  const total = listCoachesQuery?.data?.listCoaches?.meta?.count || 0;
  const loading = listCoachesQuery?.loading;

  const requestList = useCallback(
    (page, limit) => {
      let variables = {
        limit: limit,
        offset: page * limit,
        sortBy: CoachesSortByEnum.CreatedAt,
        order: SortOrderEnum.Asc,
      };
      listCoachesQuery.refetch(variables);
    },
    [listCoachesQuery],
  );

  const rowPage = React.useCallback(
    (event) => {
      setLimit(event.target.value);
      setPage(0);
      requestList(0, event.target.value);
    },
    [requestList],
  );

  const nextPage = React.useCallback(
    (e, np) => {
      setPage(np);
      requestList(np, limit);
    },
    [requestList, limit],
  );

  const onChangePage = (e, numberOfPage) => {
    global.window && (global.window as any).scrollTo(0, 0);
    nextPage(e, numberOfPage);
  };

  const onPageClick = (e: React.ChangeEvent<unknown>, value: number) => {
    const page = value - 1;
    nextPage(e, page);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="coaches_list">
      <div className="coaches_list_title">Coaches</div>
      <BasicTable rows={rows} />
      <BasicPagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        onChangeRowsPerPage={rowPage}
        onChangePage={onChangePage}
        onPageClick={onPageClick}
        total={total}
        limit={limit}
        page={page}
      />
    </div>
  );
}
