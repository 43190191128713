import './utils/amplify';
import { ApolloProvider } from '@apollo/react-hooks';
import createApolloClient from 'api/client';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { getWindowDimensions } from 'utils/hooks/useWindowDimentions';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { hotjar } from 'react-hotjar';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const hjid = 2333458;
const hjsv = 6;
hotjar.initialize(hjid, hjsv);

// Bugfender.init({
//   appKey: process.env.REACT_APP_BUGFENDER_API_KEY!
// });

(getWindowDimensions().width > 767 ? hydrate : render)(
  <ApolloProvider client={createApolloClient() as any}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <App />
    </MuiPickersUtilsProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
