import React from 'react';

const Coupon = (props) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M20 7H17.82C17.93 6.69 18 6.35 18 6C18 4.34 16.66 3 15 3C13.95 3 13.04 3.54 12.5 4.35L12 5.02L11.5 4.34C10.96 3.54 10.05 3 9 3C7.34 3 6 4.34 6 6C6 6.35 6.07 6.69 6.18 7H4C2.89 7 2.01 7.89 2.01 9L2 20C2 21.11 2.89 22 4 22H20C21.11 22 22 21.11 22 20V9C22 7.89 21.11 7 20 7ZM15 5C15.55 5 16 5.45 16 6C16 6.55 15.55 7 15 7C14.45 7 14 6.55 14 6C14 5.45 14.45 5 15 5ZM9 5C9.55 5 10 5.45 10 6C10 6.55 9.55 7 9 7C8.45 7 8 6.55 8 6C8 5.45 8.45 5 9 5ZM20 20H4V18H20V20ZM20 15H4V9H9.08L7 11.83L8.62 13L11 9.76L12 8.4L13 9.76L15.38 13L17 11.83L14.92 9H20V15Z"
          fill="#605F5E"
          {...props}
        />
      </g>
    </svg>
  );
};
export default Coupon;
