import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import TablePagination from '@material-ui/core/TablePagination';
import CourseCard from '../../../component/CourseCard/CourseCard';
import NoCourses from '../../../component/NoCourses/NoCourses';
import { Instruments, CoachProfile } from 'api/index';
import { AuthModal } from 'component/AuthModal/AuthModal';
import { filterTestCoaches } from 'utils/helpers';
import './CoursesList.scss';
interface CoursesListProps {
  page: number;
  listCourses: CoachProfile | any;
  total: number;
  limit: number;
  listInstruments: Instruments[] | any;
  nextPage: (e, np) => void;
  rowPage: (np) => void;
  load: boolean;
  user: any;
}

function CoursesList({
  page,
  listCourses,
  total,
  listInstruments,
  nextPage,
  rowPage,
  limit,
  load,
  user,
}: CoursesListProps) {
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const handleOpenPopup = useCallback(() => {
    setPopupIsOpen(true);
  }, []);
  const handleClosePopup = useCallback(() => {
    setPopupIsOpen(false);
  }, []);

  if (!listCourses) return null;

  const bool = listCourses.length <= 3;
  if (load) {
    return null;
  }

  // const filteredListCourses = filterTestCoaches(listCourses);
  const filteredListCourses = listCourses;

  return (
    <>
      {filteredListCourses?.length ? (
        <div className={classNames('sk-courses-list', { '_one-row': bool })} id="scroll-coursesList">
          {filteredListCourses &&
            filteredListCourses.map((coach, index) => (
              <CourseCard
                openPopup={handleOpenPopup}
                key={coach.id}
                coach={coach}
                listInstruments={listInstruments}
                user={user}
              />
            ))}
        </div>
      ) : (
        <NoCourses />
      )}
      {total > 10 && (
        <div className="sk-courses-list__pagination">
          <TablePagination
            count={total}
            page={page}
            onPageChange={(e, numberOfPage) => {
              global.window && (global.window as any).scrollTo(0, 0);
              nextPage(e, numberOfPage);
            }}
            rowsPerPage={limit}
            onRowsPerPageChange={rowPage}
          />
        </div>
      )}
      <AuthModal open={popupIsOpen} handleClosePopup={handleClosePopup} />
    </>
  );
}

export default CoursesList;
