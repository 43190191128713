const coachesPath = '/admin/coaches';
const adultsPath = '/admin/adults';
const childrenPath = '/admin/children';
const lessonsPath = '/admin/lessons';
const CouponPath = '/admin/coupon';
const NewCouponPath = '/admin/coupon/new';
const booksPath = '/admin/books';

export const routes = {
  adminCoaches: coachesPath,
  adminCoach: `${coachesPath}/:coachId`,
  adminBooks: booksPath,
  adminBook: `${booksPath}/:bookId`,
  adminNewBook: `${booksPath}/new`,
  adminAdults: adultsPath,
  adminAdult: `${adultsPath}/:adultId`,
  adminChildren: childrenPath,
  adminChild: `${childrenPath}/:childId`,
  adminLessons: lessonsPath,
  adminLesson: `${lessonsPath}/:lessonId`,
  adminCoupon: CouponPath,
  adminCreateCoupon: NewCouponPath,
  adminLogout: '/admin/logout',
};

export const getAdminCoachRoute = (coachId) => `${coachesPath}/${coachId}`;
export const getAdminBookRoute = (bookId) => `${booksPath}/${bookId}`;
export const getAdminAdultRoute = (adultId) => `${adultsPath}/${adultId}`;
export const getAdminChildRoute = (childId) => `${childrenPath}/${childId}`;
export const getAdminLessonRoute = (lessonId) => `${lessonsPath}/${lessonId}`;
