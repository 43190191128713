import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BalanceCard from 'component/BalanceCard/BalanceCard';
import BalanceRefill from 'component/BalanceRefill/BalanceRefill';
import Loader from 'component/Loader/Loader';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import { useListLessonsQuery, LessonSortByEnum, SortOrderEnum, useDeleteLessonMutation } from 'api/index';
import './Balance.scss';
import TextField from '@material-ui/core/TextField';
import { Dialog } from '@material-ui/core';

const alert_icon = '/assets/alert_icon.svg';

const Balance = ({ user, reGetUser }) => {
  const history = useHistory();
  const [isRefill, setIsRefill] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [cancelPopup, setCancelPopup] = useState({
    isOpen: false,
    id: '',
  });
  const ListLessonsQuery = useListLessonsQuery({
    variables: {
      limit: 2000,
      query: { sortBy: LessonSortByEnum.CreatedAt, order: SortOrderEnum.Asc },
      userId: user?.id,
      since: '',
      until: '',
    },
  });
  const ListLessons = ListLessonsQuery?.data?.listLessons?.data || [];
  const [deleteLesson] = useDeleteLessonMutation({
    variables: { id: cancelPopup.id },
  });

  const handleOpenCancelPopup = useCallback((event, id) => {
    event.stopPropagation();
    setCancelPopup({ isOpen: true, id });
  }, []);

  const handleCloseCancelPopup = useCallback(() => {
    setCancelPopup({ isOpen: false, id: '' });
  }, []);

  const handleDeleteLesson = async () => {
    if (!cancelPopup.id) return;
    try {
      const isDeleted = await deleteLesson();
      if (isDeleted) {
        handleCloseCancelPopup();
        ListLessonsQuery.refetch();
        reGetUser();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleRefill = useCallback(() => {
    setIsRefill(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsRefill(false);
  }, []);

  const handleChangeAmount = useCallback((event) => {
    setAmount(event.target.value);
  }, []);

  const handleNext = useCallback(() => {
    history.push('buy-lesson', { data: JSON.stringify({ balance: true, cost: amount }) });
  }, [amount]);

  const handleGotoCoach = useCallback(
    (event, id) => {
      event.stopPropagation();
      history.push(`coach/${id}`);
    },
    [amount],
  );

  const handleGoToLesson = useCallback(
    (id) => {
      history.push(`lesson/${id}`);
    },
    [amount],
  );

  if (ListLessonsQuery.loading) {
    return <Loader fullScreen={true} />;
  }

  return (
    <div className={`balance ${isRefill && 'center'}`}>
      {!isRefill ? (
        <>
          <div className="balance_left">
            <h3>Bought lessons</h3>
            {ListLessons &&
              ListLessons?.map((item) => (
                <BalanceCard
                  key={item?.id}
                  handleGoToLesson={handleGoToLesson}
                  balance={item}
                  handleGotoCoach={handleGotoCoach}
                  handleCancel={(event) => handleOpenCancelPopup(event, item?.id)}
                />
              ))}
          </div>
          <div className="balance_right">
            <BalanceRefill balance={user?.balance?.amount / 100 || 0} handleRefill={handleRefill} />
          </div>
        </>
      ) : (
        <>
          <div className="balance_amount">
            <p className="balance_amount_text">Refill your balance</p>
            <TextField
              variant="outlined"
              name="amount"
              label="Your amount"
              type="number"
              onChange={handleChangeAmount}
              value={amount || ''}
              fullWidth
              InputProps={{
                classes: {
                  focused: `focusedInputField`,
                },
              }}
            />
            <div className="balance_amount_button">
              <Button contained={false} onClick={handleCancel}>
                Cancel
              </Button>
              <Button contained={true} onClick={handleNext}>
                Next
              </Button>
            </div>
          </div>
        </>
      )}
      <Dialog open={cancelPopup.isOpen} onClose={handleCloseCancelPopup} aria-labelledby="cancel-lesson-modal">
        <div className="cancel-lesson-modal">
          <img src={alert_icon} alt="alert_icon" />
          <div className="cancel-lesson-modal__title">
            <span>Confirm cancelation.</span>
          </div>
          <div className="cancel-lesson-modal__subtitle">
            <span>Are you sure you want to cancel the lesson?</span>
          </div>
          <div className="cancel-lesson-modal__btns">
            <Button onClick={handleCloseCancelPopup}>No</Button>
            <Button onClick={handleDeleteLesson} contained>
              Yes, continue
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Balance;
