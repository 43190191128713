import React from 'react';
import './Image.scss';

interface ImageProps {
  alt: string;
  className?: string;
  src: string;
}

function Image({ alt, className, src, ...rest }: ImageProps) {
  return <img className={className || 'sk-image'} {...rest} src={src} alt={alt} />;
}

export default Image;
