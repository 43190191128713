import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { routes } from '../../../../modules/routes';
import { BookFragment, Maybe } from 'api';
import './BookTable.scss';

export type TableProps = {
  rows: Maybe<Array<BookFragment>>;
};

const BookTable: FC<TableProps> = ({ rows }) => {
  const history = useHistory();
  const handlePickBook = useCallback(
    (id: string) => () => {
      history.push(`${routes.adminBooks}/${id}`);
    },
    [history],
  );
  return (
    <TableContainer component={Paper} elevation={0} className="books-table-container">
      <Table className="books-table" aria-label="simple table">
        <TableHead>
          <TableRow className="books-table__row">
            <TableCell>Name</TableCell>
            <TableCell>Amount of chapters</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow className="books-table__row" key={row?.id} onClick={handlePickBook(row.id)}>
              <TableCell>{row?.name || ''}</TableCell>
              <TableCell>{row?.bookChapters?.length || ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BookTable;
