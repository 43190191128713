import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  LessonStatusType,
  RolesEnum,
  useGetCoachQuery,
  useGetLessonQuery,
  usePingLessonSessionMutation,
  useUpdateLessonMutation,
} from 'api/index';
import { delay } from 'utils/helpers';
import Loader from 'component/Loader/Loader';
import run from './Soupmedia';
import './ClassRoom.scss';
import './scss/components/ChatInput.scss';
import './scss/components/Me.scss';
import './scss/components/NetworkThrottle.scss';
import './scss/components/Notifications.scss';
import './scss/components/Peer.scss';
import './scss/components/Peers.scss';
import './scss/components/PeerView.scss';
import './scss/components/Room.scss';
import './scss/components/Stats.scss';
import './scss/fonts.scss';
import './scss/index.scss';
import './scss/mixins.scss';
import './scss/reset.scss';
import './scss/variables.scss';

const pingMutation = async (ms: number, mutation, args) => {
  try {
    await mutation(args);
    await delay(ms);
    await pingMutation(ms, mutation, args);
  } catch (e) {
    console.error(e);
  }
};

const ClassRoom = ({ user }) => {
  const location: any = useLocation();
  const history = useHistory();
  const getCoachQuery = useGetCoachQuery({ variables: { id: location?.state?.coachId } });
  const getLessonQuery = useGetLessonQuery({ variables: { id: location?.state?.lessonId } });
  const coach = getCoachQuery?.data?.getCoach || null;
  const lesson = getLessonQuery?.data?.getLesson || null;
  const [domElement, setdomElement] = useState<any>(null);
  const [pingLessonSession] = usePingLessonSessionMutation();
  const [statusIsPing, setStatusIsPing] = useState(false);
  const [updateLesson] = useUpdateLessonMutation();

  const handleTakeDom = useCallback(async () => {
    let dom = await run(
      user,
      coach,
      history,
      { ...lesson, number: location?.state?.lessonNumber },
      location?.state?.pathReturn,
      location?.state?.lessonId,
    );
    setdomElement(dom);
  }, [history, coach, user, lesson, location]);

  useEffect(() => {
    if (location?.pathname === '/class-room' && user && coach) {
      handleTakeDom();
    }
  }, [handleTakeDom, location?.pathname, user, coach, lesson, location?.state?.pathReturn]);

  const coachUpdateLesson = useCallback(
    async (lessonId: string) => {
      try {
        await pingLessonSession({
          variables: {
            lessonId: lessonId,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    [pingLessonSession],
  );

  useEffect(() => {
    if(!statusIsPing && location?.state?.lessonId) {
      pingMutation(90000, coachUpdateLesson, location?.state?.lessonId);
      setStatusIsPing(true);
    }
    if (user?.role === RolesEnum.Coach && !statusIsPing && location?.state?.lessonId) {
      updateLesson({
        variables: {
          lessonId: location?.state?.lessonId,
          status: LessonStatusType.InProgress,
        },
      });
    }
  }, [pingLessonSession, user, coachUpdateLesson, location, statusIsPing, updateLesson]);

  if (getCoachQuery.loading || getLessonQuery.loading) {
    return <Loader fullScreen={true} />;
  }

  return <div id="mediasoup-demo-app-container">{domElement && domElement}</div>;
};

export default ClassRoom;
