import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ButtonMatireal from 'component/ButtonMatireal/ButtonMatireal';

interface PricesProps {
  handleChangeAmount?: any;
  amount30min?: any;
  amount60min?: any;
  amountSubscription30min?: any;
  amountSubscription60min?: any;
  handleStripeConnect: () => void;
  errors: Record<string, string>;
  lastDigits?: string;
}

const Prices = ({
  handleChangeAmount,
  amount30min,
  amount60min,
  amountSubscription30min,
  amountSubscription60min,
  handleStripeConnect,
  errors,
  lastDigits,
}: PricesProps) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '48px',
        }}
      >
        <Typography className="profileTitle">Set your lesson price</Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FormControl fullWidth className="profilePriceField" variant="outlined">
            <OutlinedInput
              type="number"
              id="outlined-adornment-amount"
              value={amount30min}
              onChange={handleChangeAmount('amount30min')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              notched={true}
              error={!!errors.amount30min}
            />
            <div style={{ display: 'flex' }} className="amount-error-message">
              <span>{errors.amount30min}</span>
            </div>
          </FormControl>
          <FormControl fullWidth className="profileTimeField" variant="outlined">
            <OutlinedInput placeholder="30min" id="outlined-adornment-amount" readOnly disabled />
          </FormControl>
          <FormControl fullWidth className="profilePriceField" variant="outlined">
            <OutlinedInput
              type="number"
              id="outlined-adornment-amount"
              value={amount60min}
              onChange={handleChangeAmount('amount60min')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              notched={true}
              error={!!errors.amount60min}
            />
            <div style={{ display: 'flex' }} className="amount-error-message">
              <span>{errors.amount60min}</span>
            </div>
          </FormControl>
          <FormControl fullWidth className="profileTimeField" variant="outlined">
            <OutlinedInput placeholder="60min" id="outlined-adornment-amount" readOnly disabled />
          </FormControl>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: '48px',
        }}
      >
        <Typography className="profileTitle">Lower your price for subscription lessons</Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <FormControl fullWidth className="profilePriceField" variant="outlined">
            <OutlinedInput
              type="number"
              id="outlined-adornment-amount"
              value={amountSubscription30min}
              onChange={handleChangeAmount('amountSubscription30min')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              notched={true}
              error={!!errors.amountSubscription30min}
            />
            <div style={{ display: 'flex' }} className="amount-error-message">
              <span>{errors.amountSubscription30min}</span>
            </div>
          </FormControl>
          <FormControl fullWidth className="profileTimeField" variant="outlined">
            <OutlinedInput placeholder="30min" id="outlined-adornment-amount" readOnly disabled />
          </FormControl>
          <FormControl fullWidth className="profilePriceField" variant="outlined">
            <OutlinedInput
              type="number"
              id="outlined-adornment-amount"
              value={amountSubscription60min}
              onChange={handleChangeAmount('amountSubscription60min')}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              notched={true}
              error={!!errors.amountSubscription60min}
            />
            <div style={{ display: 'flex' }} className="amount-error-message">
              <span>{errors.amountSubscription60min}</span>
            </div>
          </FormControl>
          <FormControl fullWidth className="profileTimeField" variant="outlined">
            <OutlinedInput placeholder="60min" id="outlined-adornment-amount" readOnly disabled />
          </FormControl>
        </div>
      </div>
      <Typography className="profileTitle">
        {!lastDigits ? 'Set Up Your Account to Get Paid' : 'Bank information'}
      </Typography>
      {!lastDigits ? (
        <>
          <p className="profileText">
            Set up your account with Stripe to get paid directly into your bank or onto your debit card. Under “vendor
            website”, you can put www.bestmusiccoach.com.
          </p>
          <div className={`profileMediaActions`}>
            <div onClick={handleStripeConnect}>
              <AddIcon />
              <Typography className={`profileUploadVideoButton ${false ? 'profileDisabled' : ''}`} variant="body2">
                Connect
              </Typography>
            </div>
          </div>
        </>
      ) : (
        <div className="profile-card-info">
          <span>{`Your bank XXXX-XXXX-XXXX-${lastDigits || 'XXXX'} is successfully connected`}</span>
          <ButtonMatireal onClick={handleStripeConnect} type="button">
            Reconnect
          </ButtonMatireal>
        </div>
      )}
    </>
  );
};

export default Prices;
