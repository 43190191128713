import { useState, useCallback, ChangeEvent, useEffect, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Level,
  useGetChildProfileQuery,
  useListInstrumentsQuery,
  useUpdateChildMutation,
} from '../../api';
import moment from 'moment';

export const useChildProfile = () => {
  interface FormValues {
    email: string;
    firstName: string;
    lastName: string;
    lessons: Array<any>;
    level: any;
    about?: string;
    birthdate: string;
  }

  const INITIAL_VALUES_STATE: FormValues = {
    email: '',
    firstName: '',
    lastName: '',
    lessons: [],
    level: '',
    about: '',
    birthdate: ''
  };

  const INITIAL_ERRORS_STATE = {
    email: '',
    firstName: '',
    lastName: '',
    lessons: '',
    level: '',
    birthdate: ''
  };

  const [isSavedModalOpen, setIsSavedModalOpen] = useState(false);
  const [values, setValues] = useState<FormValues>(INITIAL_VALUES_STATE);
  const [errors, setErrors] = useState(INITIAL_ERRORS_STATE);
  // const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data: childData, loading: loadingChild, refetch: refetchChild } = useGetChildProfileQuery({
    variables: { id },
  });
  const { data: instrumentsData, loading: loadingInstruments } = useListInstrumentsQuery();

  const instruments = instrumentsData?.listInstruments;
  const child = childData?.getChildProfile;

  const [updateChildProfile, { loading: childUpdating }] = useUpdateChildMutation({
    variables: {
      childId: child?.id!,
      instruments: values.lessons.map((i) => {
        return { id: i.id, level: values.level.toUpperCase()};
      }),
      about: values.about,
      firstName: values.firstName,
      lastName: values.lastName,
      birthdate: values.birthdate,
    },
    onCompleted: () => {
      setIsSavedModalOpen(true);
    },
  });

  useEffect(() => {
    setValues({
      email: child?.email ?? '',
      firstName: child?.firstName ?? '',
      lastName: child?.lastName ?? '',
      level: child?.instruments ? child?.instruments[0]?.level! : 'Beginner',
      lessons: child?.instruments ?? [],
      birthdate: child?.birthdate ?? ''
    });
  }, [child]);

  const handleChangeLessons = useCallback(
    (name) => {
      const item = instruments?.find((i) => name === i?.name?.replace(' ', ''));

      if (name && errors.lessons.length) {
        setErrors({
          ...errors,
          lessons: '',
        });
      }

      setValues((prev) => {
        return {
          ...values,
          lessons: [...prev.lessons, item],
        };
      });
    },
    [values, errors, instruments],
  );

  const handleChangeText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name, required } = event.target;

      if (value && required && errors[name].length) {
        setErrors({
          ...errors,
          [name]: '',
        });
      }

      setValues({
        ...values,
        [name]: value,
      });
    },
    [values, errors],
  );

  const handleDeleteLesson = (lesson: string) => {
    const updatedValues = { ...values };
    const filteredArr = updatedValues.lessons.filter((l) => l.name.replace(' ', '') !== lesson.replace(' ', ''));
    updatedValues.lessons = filteredArr;
    setValues(updatedValues);
  };

  const handleChangeLevel = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const buff = value as Level;

      if (value) {
        setErrors({
          ...errors,
          level: '',
        });
      }

      setValues({
        ...values,
        level: buff,
      });
    },
    [values],
  );

  const handleChangeBirthdate = (date) => {
    setErrors({ ...errors, birthdate: '' })

    if (!date) {
      setErrors({ ...errors, birthdate: 'Please enter a valid birth date' });
    }
    
    setValues({
      ...values,
      birthdate: moment(date).format("MMM DD,yyyy"),
    });
    
  }

  const handleSubmit = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const err = {
      email: '',
      firstName: '',
      lastName: '',
      lessons: '',
      level: '',
      birthdate: ''
    };

    for (const [key, value] of Object.entries(values)) {
      if (!value || (typeof value === 'object' && !value.length)) {
        err[key] = "Field can't be empty";
      } else {
        err[key] = errors[key];
      }
    }

    setErrors(err);

    if (Object.values(err).every((e) => !e)) {
      updateChildProfile();
    }
  };

  return {
    values,
    errors,
    child,
    instruments,
    loadingProfile: loadingChild || loadingInstruments,
    childUpdating,
    isSavedModalOpen,
    setIsSavedModalOpen,
    handleChangeLessons,
    handleChangeLevel,
    handleDeleteLesson,
    handleChangeText,
    handleSubmit,
    updateChildProfile,
    refetchChild,
    handleChangeBirthdate,
  };
};
