import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import React from 'react';
import SideBar from './components/SideBar';
import Router from './modules/router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adminContent: {
      display: 'flex',
      background: `linear-gradient(
        180deg,
        #f7f7f7 0%,
        rgba(255, 255, 255, 0.96) 110.71%
      )`,
      height: '100vh',
    },
  }),
);

interface AdminPanelProps {
  user: any;
  signOut: (callBack: () => void) => void;
}

export default function AdminPanel({ user, signOut }: AdminPanelProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.adminContent}>
      <SideBar signOut={signOut} />
      <Router />
    </div>
  );
}
