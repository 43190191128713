import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import Adults from '../pages/Adults';
import Children from '../pages/Children';
import Coaches from '../pages/Coaches';
import Lessons from '../pages/Lessons';
import Coupon from '../pages/Coupon';
import Books from '../pages/Books';

export default function Router() {
  return (
    <>
      <Switch>
        <Route path={[routes.adminCoaches, routes.adminCoach]} exact>
          <Coaches />
        </Route>
        <Route path={[routes.adminAdults, routes.adminAdult]} exact>
          <Adults />
        </Route>
        <Route path={[routes.adminChildren, routes.adminChild]} exact>
          <Children />
        </Route>
        <Route path={[routes.adminLessons, routes.adminLesson]} exact>
          <Lessons />
        </Route>
        <Route path={[routes.adminCoupon, routes.adminCreateCoupon]} exact>
          <Coupon />
        </Route>
        <Route path={[routes.adminBooks, routes.adminBook, routes.adminNewBook]} exact>
          <Books />
        </Route>
        {/* <Route path={routes.adminLogout} exact>
          <Redirect to="/signin" />
        </Route> */}
        <Route path="/*">
          <Redirect to={routes.adminCoaches} />
        </Route>
      </Switch>
    </>
  );
}
