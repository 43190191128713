import { UserProfileFilesEnum } from 'api/index';

export const getAvatar = (arr: any[]) => {
  const res = arr.find((item) => item.type.toLowerCase() === UserProfileFilesEnum.Avatar.toLowerCase());
  if (res?.path) {
    return res.path;
  } else {
    return false;
  }
};

export const isAvatar = (arr: any[]) => {
  const res = getAvatar(arr);
  if (res) {
    return true;
  } else {
    return false;
  }
};

export const getDiplom = (arr: any[], index) => {
  if (arr[index].type.toLowerCase() === UserProfileFilesEnum.Diploma.toLowerCase()) {
    const res = arr[index];
    if (res?.path) {
      return res.path;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isDiplom = (arr: any[], index) => {
  const res = getDiplom(arr, index);
  if (res) {
    return true;
  } else {
    return false;
  }
};
