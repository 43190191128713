import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import './Roles.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      className="roleTabPanel"
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Avenir, sans-serif !important',
  },
}));

const ROLE_MAP = {
  0: 'ADULT',
  1: 'COACH',
};

export default function Roles(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (event, newValue) => {
      props.onChangeRole(ROLE_MAP[newValue]);
      setValue(newValue);
    },
    [props],
  );

  const handleChangeIndex = useCallback((index) => setValue(index), []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: '#00A651' } }}
          variant="fullWidth"
          aria-label="full width tabs example"
          className="rolesTabsWrapper"
        >
          <Tab className={`roleTab ${value === 1 ? 'inactiveTab' : ''}`} label="Students" {...a11yProps(0)} />
          <Tab
            className={`roleTab ${value === 0 ? 'inactiveTab' : ''}`}
            label="Coaches"
            // disabled // Coach registration disabled because of client's request. It's temporary and should be removed in the future
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        className="roleTabContainer"
        // disabled // Coach registration disabled because of client's request. It's temporary and should be removed in the future
      >
        <TabPanel
          // className="roleTabContent"
          value={value}
          index={0}
          // dir={theme.direction}
        >
          {props.children}
        </TabPanel>
        <TabPanel
          // className="roleTabContent"
          value={value}
          index={1}
          // dir={theme.direction}
        >
          {props.children}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
