import React, { FC } from 'react';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import './ButtonMatireal.scss';

interface ButtonMatirealProps {
  children: any;
  contained?: boolean;
  onClick?: any;
  className?: string;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset' | undefined;
}

const ButtonMatireal: FC<ButtonMatirealProps> = ({
  children,
  contained,
  onClick,
  className,
  disabled,
  type = 'button',
}) => {
  return (
    <Button
      className={cn(
        {
          containedButton: contained,
          outlinedButton: !contained,
        },
        className,
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ButtonMatireal;
