let protooPort = 4443;

if ((global.window as any)?.location?.hostname === 'test.mediasoup.org') protooPort = 4444;

export function getProtooUrl({ roomId, peerId }) {
  // const hostname = (global.window as any)?.location?.hostname;

  // return `wss://${hostname}:${protooPort}/?roomId=${roomId}&peerId=${peerId}`;
  return `${process.env.REACT_APP_WEBRTC}:${protooPort}/?roomId=${roomId}&peerId=${peerId}`;
}
