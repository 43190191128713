import React from 'react';
import Heading from '../../component/Heading/Heading';
import Image from '../../component/Image/Image';

import './NoCourses.scss';

const NoCourses = () => (
  <div className="sk-no-courses">
    <Heading variant="h2" newLine classes="sk-no-courses__heading">
      There are no courses for your request. Please, use other parameters!
    </Heading>
    <Image className="sk-no-courses" src={'/assets/empty_lesson.svg'} alt="No Courses" />
  </div>
);

export default NoCourses;
