import React from 'react';

const CoachIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.499 8.75C15.499 11.3744 13.4853 13.5 10.999 13.5C8.51277 13.5 6.49902 11.3744 6.49902 8.75C6.49902 6.12562 8.51277 4 10.999 4C13.4853 4 15.499 6.12562 15.499 8.75ZM1.99902 20.625C1.99902 17.4662 7.99527 15.875 10.999 15.875C12.0869 15.875 13.5672 16.0837 15.0131 16.5H14.9997C14.9997 16.5 13.9969 18.5005 15 21C15.6575 22.6384 16.4185 22.951 16.7828 23H3.12402C2.50527 23 1.99902 22.4656 1.99902 21.8125V20.625Z"
        fill="#00A651"
        {...props}
      />
      <path
        d="M21.4969 16.4435C21.3286 16.2177 21.1202 16.0444 20.8715 15.9234C20.6301 15.8024 20.3485 15.7419 20.0266 15.7419C19.7048 15.7419 19.4049 15.8105 19.127 15.9476C18.8563 16.0847 18.6186 16.2782 18.4138 16.5282C18.2163 16.7702 18.059 17.0605 17.942 17.3992C17.8323 17.7379 17.7774 18.1048 17.7774 18.5C17.7774 18.9032 17.8323 19.2742 17.942 19.6129C18.059 19.9435 18.2163 20.2339 18.4138 20.4839C18.6113 20.7258 18.8417 20.9153 19.105 21.0524C19.3757 21.1895 19.6646 21.2581 19.9718 21.2581C20.3229 21.2581 20.6338 21.1774 20.9044 21.0161C21.175 20.8548 21.3981 20.629 21.5737 20.3387L23 21.5121C22.6708 22.0202 22.2539 22.3952 21.7492 22.6371C21.2445 22.879 20.7252 23 20.1912 23C19.5841 23 19.0246 22.8952 18.5125 22.6855C18.0005 22.4758 17.558 22.1774 17.185 21.7903C16.8119 21.3952 16.5193 20.9194 16.3072 20.3629C16.1024 19.8065 16 19.1855 16 18.5C16 17.8145 16.1024 17.1935 16.3072 16.6371C16.5193 16.0806 16.8119 15.6089 17.185 15.2218C17.558 14.8266 18.0005 14.5242 18.5125 14.3145C19.0246 14.1048 19.5841 14 20.1912 14C20.4107 14 20.6374 14.0242 20.8715 14.0726C21.1129 14.1129 21.3469 14.1815 21.5737 14.2782C21.8077 14.375 22.0308 14.504 22.2429 14.6653C22.4551 14.8266 22.6452 15.0242 22.8135 15.2581L21.4969 16.4435Z"
        fill="#00A651"
        {...props}
      />
    </svg>
  );
};
export default CoachIcon;
