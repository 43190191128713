import React from 'react';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import './Modal.scss';

const check_pay = '/assets/check_pay.svg';
const cross_pay = '/assets/croos_pay.svg';
const error_pay = '/assets/error_pay.svg';

const Modal = ({ handleGoToCoachsList, handleReturnToCalendar, isModalState, handleClose }) => {
  const returnTitle = () => {
    switch (isModalState) {
      case 'suc':
      case 'sub':
        return 'Success';
      case 'err':
        return 'Oops!';
      case 'book':
        return 'Not enough balance!';
      case 'refill':
        return 'Success';
    }
  };

  const returnText = () => {
    switch (isModalState) {
      case 'suc':
        return 'Your balance was refilled and the lesson was successfully bought.';
      case 'err':
        return 'Your payment failed. Please, try again.';
      case 'book':
        return 'Your balance was refilled, but the lesson wasn’t bought.';
      case 'refill':
        return 'You have refilled your balance successfully.';
      case 'sub':
        return 'You have subscribed successfully.';
    }
  };

  const returnIcon = () => {
    switch (isModalState) {
      case 'suc':
      case 'sub':
        return check_pay;
      case 'err':
        return cross_pay;
      case 'book':
        return error_pay;
      case 'refill':
        return check_pay;
    }
  };

  return (
    <div className="pay_modal">
      <img alt='' src={returnIcon()} className="pay_modal_image" />
      <div>
        <h4 className="pay_modal_title">{returnTitle()}</h4>
        <p className="pay_modal_text">{returnText()}</p>
      </div>
      <div className="pay_modal_button_container">
        {isModalState !== 'err' ? (
          <>
            <Button onClick={handleGoToCoachsList} contained={false}>
              View other coaches
            </Button>
            <Button onClick={handleReturnToCalendar} contained={true}>
              Review your calendar
            </Button>
          </>
        ) : (
          <div className="closeErrorPayModal">
            <Button onClick={handleClose} contained={false}>
              Try again
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
