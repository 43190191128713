import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import Image from '../../component/Image/Image';
import './UserAvatar.scss';

interface UserAvatarProps {
  avatar: string;
  firstName: string;
  lastName: string;
  classes?: string;
}

interface LetterAvatarProps {
  firstName: string;
  lastName: string;
}

const LetterAvatar = ({ firstName, lastName }: LetterAvatarProps) => {
  return `${firstName[0]?.toUpperCase() || ''}${lastName[0]?.toUpperCase() || ''}`;
};

const UserAvatar = ({ avatar, firstName = 'Not', classes = '', lastName = 'Available' }: UserAvatarProps) => {
  return (
    <div className={classNames('sk-avatar-user_wrapper', { [classes]: classes })}>
      <Avatar style={{ opacity: 1 }} className={classNames('mui-override', 'sk-avatar-user', { [classes]: classes })}>
        {avatar ? <Image src={avatar} alt={`${firstName}-avatar`} /> : LetterAvatar({ lastName, firstName })}
      </Avatar>
    </div>
  );
};

export default UserAvatar;
