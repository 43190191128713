import React, { FC, useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CoursesList from '../../page/Courses/CoursesList/CoursesList';
import Filters from './Filters/Filters';
import Loader from 'component/Loader/Loader';
import {
  useListCoachesQuery,
  SortOrderEnum,
  useListInstrumentsQuery,
  CoachesSortByEnum,
  Profile,
  // Instruments,
} from 'api/index';
import './Courses.scss';

type Props = {
  user: Profile;
  instrument?: any;
};

const Courses: FC<Props> = ({ user, instrument = null }) => {
  const history = useHistory();
  const { state } = useLocation<any>();
  const [choosenInstrName, setChosenInstrName] = useState<string | null>(
    state && state[0] && state[0]?.instrument?.id
      ? state[0]?.instrument?.name.split(' ').join('_').toLowerCase()
      : instrument?.name.split(' ').join('_').toLowerCase(),
  );
  const [instrFilter, setInstrFilter] = useState<number[] | null>(state && state.instruments ? state.instruments : null);
  const listCoachesQuery = useListCoachesQuery({
    variables: {
      limit: 10,
      offset: 0,
      sortBy: CoachesSortByEnum.CreatedAt,
      order: SortOrderEnum.Asc,
      instruments: instrFilter?.map((i) => String(i)),
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (state && state.instruments) {
        delete state.instruments;
        history.replace({ ...history.location, state });
      }
    },
  });

  const listInstrumentsQuery = useListInstrumentsQuery();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const listCourses = listCoachesQuery?.data?.listCoaches?.data || [];
  const total = listCoachesQuery?.data?.listCoaches?.meta?.count || 0;
  const listInstruments = listInstrumentsQuery?.data?.listInstruments;
  const load = listCoachesQuery?.loading;
  const [dateFilter, setDateFilter] = useState<string[] | null>(null);
  const [levelFilter, setLevelFilter] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  console.log(listCourses);

  const requestList = useCallback(
    async (page, limit, dateFilter, instrFilter, levelFilter) => {
      setLoading(true);
      let variables = {
        limit: limit,
        offset: page * limit,
        sortBy: CoachesSortByEnum.CreatedAt,
        order: SortOrderEnum.Asc,
        filter: null,
      };
      variables[`level`] = levelFilter || undefined;
      variables[`instruments`] = instrFilter || undefined;
      variables[`avialableTime`] = dateFilter?.length ? dateFilter : undefined;
      try {
        await listCoachesQuery.refetch(variables);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    },
    [listCoachesQuery],
  );

  const nextPage = useCallback(
    (e, np) => {
      setPage(np);
      requestList(np, limit, dateFilter, instrFilter, levelFilter);
    },
    [limit, dateFilter, instrFilter, levelFilter, requestList],
  );

  const rowPage = useCallback(
    (event) => {
      setLimit(event.target.value);
      setPage(0);
      requestList(0, event.target.value, dateFilter, instrFilter, levelFilter);
    },
    [dateFilter, instrFilter, levelFilter, requestList],
  );

  const clearAllFilter = useCallback(() => {
    setDateFilter(null);
    setInstrFilter(null);
    setLevelFilter(null);
    requestList(0, limit, null, null, null);
    setPage(0);
  }, [limit, requestList]);

  const handleDateFilter = useCallback(
    (date) => {
      setDateFilter(date);
      requestList(0, limit, date, instrFilter, levelFilter);
      setPage(0);
    },
    [limit, instrFilter, levelFilter, requestList],
  );

  const handleClearDateFilter = useCallback(() => {
    setDateFilter(null);
    requestList(0, limit, null, instrFilter, levelFilter);
    setPage(0);
  }, [limit, instrFilter, levelFilter, requestList]);

  const handleInstrFilter = useCallback(
    (instr) => {
      if (instr.length === 1) {
          const filteredInstr = listInstruments?.find(instrument => instrument?.id! === instr[0]);
          history.push(`/${filteredInstr?.slug!}-teachers`);
      }
      if (history.location.pathname !== '/coaches' && instr.length !== 1) {
          history.push('/coaches', {instruments: instr});
      }
      setInstrFilter(instr);
      requestList(0, limit, dateFilter, instr, levelFilter);
      setPage(0);
    },
    [limit, dateFilter, levelFilter, requestList, history],
  );

  const handleClearInstrFilter = useCallback(() => {
    setInstrFilter(null);
    requestList(0, limit, dateFilter, null, levelFilter);
    setPage(0);
  }, [limit, dateFilter, levelFilter, requestList]);

  const handleLevelFilter = useCallback(
    (level) => {
      setLevelFilter(level);
      requestList(0, limit, dateFilter, instrFilter, level);
      setPage(0);
    },
    [limit, dateFilter, instrFilter, requestList],
  );

  const handleClearLevelFilter = useCallback(() => {
    setLevelFilter(null);
    requestList(0, limit, dateFilter, instrFilter, null);
    setPage(0);
  }, [limit, dateFilter, instrFilter, requestList]);

  useEffect(() => {
    if (instrument) {
        setInstrFilter([instrument.id])
    }
  }, []);

  return (
    <div className="sk-courses">
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-5DBJZX2"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}
      <Filters
        dateFilter={dateFilter}
        instrFilter={instrFilter}
        levelFilter={levelFilter}
        handleDateFilter={handleDateFilter}
        handleInstrFilter={handleInstrFilter}
        handleLevelFilter={handleLevelFilter}
        clearAllFilter={clearAllFilter}
        handleClearDateFilter={handleClearDateFilter}
        handleClearInstrFilter={handleClearInstrFilter}
        handleClearLevelFilter={handleClearLevelFilter}
        listInstruments={listInstruments}
        choosenInstrName={choosenInstrName}
        setChosenInstrName={setChosenInstrName}
      />
      {listCoachesQuery.loading || loading ? (
        <Loader />
      ) : (
        <CoursesList
          page={page}
          limit={limit}
          listCourses={listCourses}
          total={total}
          listInstruments={listInstruments}
          nextPage={nextPage}
          rowPage={rowPage}
          load={load}
          user={user}
        />
      )}
    </div>
  );
};

export default Courses;
