import React from 'react';
import classNames from 'classnames';
import './Heading.scss';

interface HeadingProps {
  variant?: any;
  onClick?: any;
  children?: string;
  bold?: any;
  newLine?: true;
  classes?: string;
}

const Heading = ({ variant, onClick, children, bold, newLine, classes }: HeadingProps) => {
  const Tag = variant;
  return (
    <Tag
      onClick={onClick}
      className={classNames(`heading-sk heading-sk__${variant}`, {
        'heading-sk heading-sk__bold': bold,
        'heading-sk heading-sk_new-line': newLine,
      })}
    >
      {children}
    </Tag>
  );
};

export default Heading;
