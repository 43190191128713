import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getAvatar } from 'utils/image';
import UserAvatar from '../../../../../component/UserAvatar/UserAvatar';
import { getAdminAdultRoute } from '../../../modules/routes';
import './BasicTable.scss';

interface BasicTableProps {
  rows: any;
}

export default function BasicTable({ rows }: BasicTableProps) {
  const history = useHistory();
  return (
    <TableContainer component={Paper} elevation={0} className="adults_basic_table_container">
      <Table className="adults_basic_table" aria-label="simple table">
        <TableHead>
          <TableRow className="adults_basic_table_row">
            <TableCell>Photo</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>First name</TableCell>
            <TableCell>Last name</TableCell>
            <TableCell>Level</TableCell>
            <TableCell align="right">Instrument</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow
              className="adults_basic_table_row"
              key={row?.id}
              onClick={() => history.push(getAdminAdultRoute(row.id))}
            >
              <TableCell>
                <UserAvatar
                  classes="adults_basic_table_row_avatar"
                  firstName={row?.firstName}
                  lastName={row?.lastName}
                  avatar={getAvatar(row?.files || []) || ''}
                />
              </TableCell>
              <TableCell title={row?.email}>{row?.email}</TableCell>
              <TableCell title={row?.firstName}>{row?.firstName}</TableCell>
              <TableCell title={row?.lastName}>{row?.lastName}</TableCell>
              <TableCell title={row?.level?.join(', ')}>{row?.level?.join(', ')}</TableCell>
              <TableCell
                align="right"
                title={row?.instruments
                  ?.map((item) => item?.name)
                  .join(', ')
                  .replace('_', ' ')}
              >
                {row?.instruments
                  ?.map((item) => item?.name)
                  .join(', ')
                  .replace('_', ' ')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
