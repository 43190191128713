import { Modal, Button } from '@material-ui/core';
import React, { useState } from 'react';
import moment from 'moment';
import './PurchaseConfirmationModal.scss';
import { usePreviewOrderQuery, AppointmentPeriodEnum } from 'api';
import { getTimezoneDisplayName } from '../../../utils/constans';
import Loader from '../../../component/Loader/Loader';

const closeModalIcon = '/assets/close_modal_cross.svg';

const PurchaseConfirmationModal = ({
  bookData,
  subData,
  confirmPurchase,
  setIsLoading,
  coach,
  frequency,
  returnCost,
  duration
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const timezoneName = getTimezoneDisplayName();

  const timeSlots: any = [];

  if (subData?.week.length === 0) {
    timeSlots.push({
                startAt: moment(bookData.start).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                finishAt: moment(bookData.end).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
              });
  } else {
    subData?.week?.forEach((itemW, i) => {
      const weekdayOffset = itemW.day;
      itemW.since.forEach((item, index) => {
        timeSlots.push({
                    startAt: moment(item).weekday(weekdayOffset).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                    finishAt: moment(itemW.until[index]).weekday(weekdayOffset).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
                    timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  });
      });
    });
  }

  // console.log('timeSlots-->',timeSlots);
  // console.log('subData?.week-->',subData?.week);

  const { data: previewOrderData, loading: previewOrderDataLoading } = usePreviewOrderQuery({
    variables: {
      timeSlots,
      coachId: coach.id,
      periodCount: +subData.amountOfWeeks,
      periodType: AppointmentPeriodEnum.Subscription,
    },
    fetchPolicy: 'network-only',
  });
  const lessonsPreview = previewOrderData?.previewOrder.lessons;

  // console.log(lessonsPreview);
  if (previewOrderDataLoading) {
    return <Loader></Loader>
  }
  return (
    <>
      <Button className="calendar_modal_book_body_buy_bottom_button" onClick={() => setIsModalOpen(true)}>
        Confirm
      </Button>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} className="invite__child__modal__wrapper">
        <div className="invite__child__modal">
          <div className="invite__child__modal__close__button__wrapper">
            <img
              src={closeModalIcon}
              alt="Close Modal Icon"
              className="invite__child__modal__close__button"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          <ul>
            <li>
              Your lesson:{' '}
              {
                coach?.instruments.find((instrument) => {
                  return instrument.id === bookData.instrument;
                }).name
              }
            </li>
            {frequency === 'One time' ? (
              <li>
                At: {moment(bookData.date).format('YYYY-MM-DD, hh:mm A')}, {timezoneName}
              </li>
            ) : (
              ''
            )}
            <li>
              With: {coach.firstName} {coach.lastName}
            </li>
            <li>
              $
              {frequency === 'One time'
                ? returnCost()
                : moment(bookData.start).diff(moment(bookData.end), 'hour') < 0
                ? coach?.subscriptionPerHour / 100
                : coach?.subscriptionPerHalfHour / 100}
            </li>
            <li>Frequency: {frequency}</li>
            {frequency !== 'One time' ? (
              <>
                {lessonsPreview?.map((lesson, i) => (
                  <li>
                    Lesson {i + 1}: {moment(lesson?.startAtHuman).format('HH:mm')} {timezoneName}{' '}
                    {moment(lesson?.startAtHuman).format('MM-DD-YYYY')}
                  </li>
                ))}
                <li>
                  Your subscription will auto-renew on:{' '}
                  {/* @ts-ignore: Object is possibly 'undefined' */}
                  {lessonsPreview ? moment(lessonsPreview[lessonsPreview?.length - 1]?.startAtHuman!).format('MM-DD-YYYY') : null}
                </li>
              </>
            ) : null}
            <li>Duration: {duration() ? "60 minutes" : "30 minutes"}</li>
          </ul>
          <div className="button_wrapper">
            <Button className="calendar_modal_book_body_buy_bottom_button" onClick={confirmPurchase(setIsLoading)}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PurchaseConfirmationModal;
