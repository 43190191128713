import React from 'react';

const BooksIcon = (props) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M6.14959 3.17188H20.4531V2H6.14959C4.99014 2 4.04688 2.78855 4.04688 3.75781V20.2422C4.04688 21.2114 4.99314 22 6.15625 22H20.4531V4.34375H6.14959C5.76555 4.34375 5.45312 4.0809 5.45312 3.75781C5.45312 3.43473 5.76555 3.17188 6.14959 3.17188ZM9.95312 10.1445L15.5781 12.8789L9.95312 15.6133V10.1445Z"
          fill="#605F5E"
          {...props}
        />
      </g>
    </svg>
  );
};

export default BooksIcon;
