import React from 'react';
import Button from '@material-ui/core/Button';

const close_modal_cross = '/assets/close_modal_cross.svg';

const ConfirmationModal = ({ title, handleOnOkClick, handleClose }) => {
  return (
    <div className="profile_modal_confirmation">
      <div className="profile_modal_confirmation_header">
        <p className="profile_modal_confirmation_title">{title}</p>
        <img
          src={close_modal_cross}
          className="profile_modal_confirmation_close"
          onClick={handleClose}
          alt="close-icon"
        />
      </div>
      <div className="profile_modal_confirmation_button_container">
        <Button className="profileButton" variant="contained" color="secondary" onClick={handleOnOkClick}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
