import React, { useCallback, useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import debounce from 'lodash.debounce';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { KeyboardDatePicker, KeyboardTimePicker, TimePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Line from 'component/Line/Line';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { Add as AddIcon } from '@material-ui/icons';
import './../Calendar.scss';
import InviteChildModal from '../InviteChildModal/InviteChildModal';
import PurchaseConfirmationModal from '../PurchaseConfirmationModal/PurchaseConfirmationModal';
import Loader from '../../../component/Loader/Loader';

const closeModalCross = '/assets/close_modal_cross.svg';
const mdiInfo = '/assets/mdi_info.svg';

const weekConst = [
  { name: 'S', allName: 'Sunday' },
  { name: 'M', allName: 'Monday' },
  { name: 'T', allName: 'Tuesday' },
  { name: 'W', allName: 'Wednesday' },
  { name: 'T', allName: 'Thursday' },
  { name: 'F', allName: 'Friday' },
  { name: 'S', allName: 'Saturday' },
];

const ModalBook = ({
  closeBookModal,
  bookData,
  coach,
  user,
  handleChooseChildren,
  handleChooseInstrument,
  handleChooseDate,
  handleChooseStart,
  handleChooseEnd,
  handleConfirmBook,
  handleConfirmSub,
  handleChangeStartWeekSub,
  handleChangeEndWeekSub,
  handleChangeAmountOfLessons,
  handleChangeCoupon,
  subData,
  bookErrors,
  chooseBooKWeek,
  couponError,
  setCouponError,
  singleLessonPrice,
  weeksAmountError,
  coupons,
  handleChangeLessonDuration,
  currentTimeZone,
}) => {
  // console.log('ModalBook:bookData', bookData.start, bookData.end);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [indexTab, setIndexTab] = useState<number>(0);
  const [couponOpen, setCouponVisibility] = useState<boolean>(false);
  const [costSub, setCostSub] = useState<number>(0);
  const isTablet = useMediaQuery({ query: '(max-width: 767px)' });

  const ifLessonDuration60MinAvailable = (indexTabInput = null) => {
    const indexTabEffective = indexTabInput !== null ? indexTabInput : indexTab;
    // tslint:disable-next-line:no-shadowed-variable
    const isLessonDuration60MinAvailable =
      indexTabEffective === 0
        ? bookData?.eventExtendedProps?.oneHourOneTimeLessonAvailable
        : bookData?.eventExtendedProps?.oneHourSubscriptionAvailable;
    // console.log('ifLessonDuration60MinAvailable', indexTabInput, indexTab, indexTabEffective, isLessonDuration60MinAvailable);
    return isLessonDuration60MinAvailable;
  };

  const switchToDurationRef = useRef<string | null>(null);

  useEffect(() => {
    // console.log('useEffect:indexTab', indexTab, switchToDurationRef.current);
    if (switchToDurationRef.current) {
      const switchTo = switchToDurationRef.current;
      switchToDurationRef.current = null;
      handleChangeLessonDuration({ target: { value: switchTo } });
    }
  }, [indexTab]);

  const handleChangeTab = (event, value) => {
    // console.log('handleChangeTab', value);
    // console.log('handleChangeTab:is60MinLesson', is60MinLesson());
    // console.log('handleChangeTab:ifLessonDuration60MinAvailable(value)', ifLessonDuration60MinAvailable(value));
    const ifToSwitchTo30Minutes = is60MinLesson() && !ifLessonDuration60MinAvailable(value);
    const ifToSwitchTo60Minutes = !is60MinLesson() && ifLessonDuration60MinAvailable(value);
    // console.log('handleChangeTab:switches', value, ifToSwitchTo30Minutes, ifToSwitchTo60Minutes);
    setIndexTab(value);
    if (ifToSwitchTo30Minutes) {
      switchToDurationRef.current = '30 minutes';
      // handleChangeLessonDuration({ target: { value: '30 minutes' }})
    } else if (ifToSwitchTo60Minutes) {
      switchToDurationRef.current = '60 minutes';
      // handleChangeLessonDuration({ target: { value: '60 minutes' }})
    } else {
      switchToDurationRef.current = null;
    }
  };

  const handleChangeIndex = useCallback((index) => {
    // console.log('handleChangeIndex', index);
    // if (is60MinLesson() && !ifLessonDuration60MinAvailable(index)) {
    //   handleChangeAmountOfLessons({ target: { value: '30 minutes' }})
    // } else if (!is60MinLesson() && ifLessonDuration60MinAvailable(index)) {
    //   handleChangeAmountOfLessons({ target: { value: '60 minutes' }})
    // }
    setIndexTab(index);
  }, []);

  const returnCost = useCallback(() => {
    if (is60MinLesson()) {
      return singleLessonPrice.costsPerHour / 100;
    } else {
      return singleLessonPrice.costsPerHalfHour / 100;
    }
  }, [bookData.start, bookData.end, singleLessonPrice.costsPerHour, singleLessonPrice.costsPerHalfHour]);

  const returnCostSub = useCallback(() => {
    if (is60MinLesson()) {
      return coach?.subscriptionPerHour / 100;
    } else {
      return coach?.subscriptionPerHalfHour / 100;
    }
  }, [bookData.start, bookData.end, coach]);

  const toggleCouponInput = () => {
    setCouponVisibility((v) => !v);
  };

  const returnSavedPercents = useCallback(() => {
    let cost: number;
    if (moment(bookData.start).diff(moment(bookData.end), 'hour') < 0) {
      cost = 100 - (100 * coach?.subscriptionPerHour) / coach?.costsPerHour;
    } else {
      cost = 100 - (100 * coach?.subscriptionPerHalfHour) / coach?.costsPerHalfHour;
    }

    return cost.toFixed(2);
  }, [bookData.start, bookData.end, coach]);

  const is60MinLesson = () => {
    // console.log('_is60MinLesson', bookData.start, bookData.end);
    // console.log('is60MinLesson:bookData', bookData.start, bookData.end);
    const _is60MinLesson = moment(bookData.start).diff(moment(bookData.end), 'hour') < 0;
    // console.log('_is60MinLesson', _is60MinLesson);
    return _is60MinLesson;
  };

  useEffect(() => {
    let calculatedCost: number = 0;
    const lessonsPerWeek = subData.week.length || 1;
    if (is60MinLesson()) {
      calculatedCost = lessonsPerWeek * (coach?.subscriptionPerHour / 100);
    } else {
      calculatedCost = lessonsPerWeek * (coach?.subscriptionPerHalfHour / 100);
    }
    setCostSub(calculatedCost);
  }, [subData.amountOfWeeks, bookData.start, bookData.end, coach, subData.week]);

  useEffect(() => {
    if (coupons[0]) {
      setCouponVisibility(true);
      handleChangeCoupon(coupons[0].code);
    }
  }, [])

  const isLessonDuration60MinAvailable = ifLessonDuration60MinAvailable();

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <div className="calendar_modal_book">
      <div className="calendar_modal_book_header">
        <p className="calendar_modal_book_header_title" />
        <img
          src={closeModalCross}
          className="calendar_modal_book_header_close"
          onClick={closeBookModal}
          alt="calendar_modal_book_header_close"
        />
      </div>
      <div
        className={cn('calendar_modal_book_body', {
          calendar_modal_book_overflowed: indexTab === 1 || couponOpen,
          calendar_modal_book_body_overflowed: isTablet,
        })}
      >
        <Tabs className="calendar_modal_book_body_tabs" value={indexTab} onChange={handleChangeTab}>
          <Tab label="Buy lesson" />
          {bookData?.eventExtendedProps?.type === 'subscriptionAvailable' && (
            <Tab label={`Subscribe and save ${+returnSavedPercents() ? `${returnSavedPercents()} %` : ''}`} />
          )}
        </Tabs>
        <SwipeableViews index={indexTab} onChangeIndex={handleChangeIndex}>
          {indexTab === 0 && (
            <div className="calendar_modal_book_body_buy">
              <div className="calendar_modal_book_body_buy_row">
                <KeyboardDatePicker
                  disabled={true}
                  className="calendar_modal_book_body_buy_row_date"
                  autoOk={true}
                  variant="inline"
                  inputVariant="outlined"
                  id="Date"
                  label="Date"
                  format="MMM DD,yyyy"
                  value={bookData.date}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={handleChooseDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardTimePicker
                  disabled={true}
                  className="calendar_modal_book_body_buy_row_start"
                  autoOk={true}
                  error={bookErrors.timeError}
                  variant="inline"
                  inputVariant="outlined"
                  id="Start"
                  label="Start time"
                  format="LT"
                  value={bookData.start}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={handleChooseStart}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minutesStep={30}
                />
                <KeyboardTimePicker
                  disabled={true}
                  className="calendar_modal_book_body_buy_row_end"
                  autoOk={true}
                  variant="inline"
                  error={bookErrors.timeError}
                  inputVariant="outlined"
                  id="End"
                  label="End time"
                  format="LT"
                  value={bookData.end}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={handleChooseEnd}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minutesStep={30}
                />
              </div>
              <div className="calendar_modal_book_body_buy_row">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-age-native-simple">Lesson</InputLabel>
                  <Select
                    className="calendar_modal_book_body_buy_row_lesson"
                    label="Timezone"
                    input={<OutlinedInput notched={true} />}
                    value={bookData.instrument || +coach?.instruments[0]?.id || ''}
                    onChange={handleChooseInstrument}
                  >
                    {coach?.instruments.map((instrument) => (
                      <MenuItem key={instrument.name} value={instrument.id || ''}>
                        {instrument.name || ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-age-native-simple">For child</InputLabel>
                  <Select
                    className="calendar_modal_book_body_buy_row_children"
                    label="For child"
                    input={<OutlinedInput notched={true} />}
                    value={bookData.for || 'no'}
                    onChange={handleChooseChildren}
                  >
                    {user.children.map((children) => (
                      <MenuItem key={children.firstName} value={children.id}>
                        {children.firstName + ' ' + children.lastName}
                      </MenuItem>
                    ))}
                    <MenuItem key={'no'} value={'no'}>
                      no
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className="duration_selector">
                  <InputLabel htmlFor="outlined-age-native-simple">Lesson duration</InputLabel>
                  <Select
                    className="calendar_modal_book_body_buy_row_children"
                    label="Lesson duration"
                    input={<OutlinedInput notched={true} />}
                    defaultValue={isLessonDuration60MinAvailable ? '60 minutes' : '30 minutes'}
                    disabled={!isLessonDuration60MinAvailable}
                    onChange={handleChangeLessonDuration}
                  >
                    {['30 minutes', ...(!isLessonDuration60MinAvailable ? [] : ['60 minutes'])].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="calendar_modal_book_body_buy_row_empty" />
              </div>
              <div className="calendar_modal_book_body_buy_buttons">
                {!couponOpen ? (
                  <Button
                    startIcon={<AddIcon />}
                    className="calendar_modal_book_body_buy_addCoupon"
                    onClick={toggleCouponInput}
                  >
                    Add coupon
                  </Button>
                ) : (
                  <TextField
                    variant="outlined"
                    name="coupon"
                    label="Enter code"
                    error={couponError}
                    helperText={couponError}
                    className="book-modal-coupon-field"
                    required={true}
                    defaultValue={coupons[0] ? coupons[0].code : ''}
                    style={{
                      maxWidth: 520,
                    }}
                    onChange={(e) => {
                      e.persist();
                      setCouponError('');
                      const handleDebounce = debounce(() => handleChangeCoupon(e.target.value), 500);
                      handleDebounce(e.target.value);
                    }}
                    fullWidth={true}
                    InputProps={{
                      classes: {
                        focused: `focusedInputField`,
                      },
                    }}
                  />
                )}
                {coupons[0] ? <p className="calendar_modal_book_discount_text">{coupons[0].text}</p> : null}
                <InviteChildModal user={user} />
              </div>
              <Line />
              <div className="calendar_modal_book_body_buy_bottom">
                <div>
                  <img
                    src={mdiInfo}
                    className="calendar_modal_book_body_buy_bottom_img"
                    alt="calendar_modal_book_body_buy_bottom_img"
                  />
                  <p className="calendar_modal_book_body_buy_bottom_yourBalance">
                    Your balance is ${user?.balance?.amount / 100 || 0}.
                  </p>
                </div>
                <div className="calendar_modal_book_body_buy_bottom_actions">
                  <div>
                    <p className="calendar_modal_book_body_buy_bottom_cost">$ {returnCost()}</p>
                    <PurchaseConfirmationModal
                      bookData={bookData}
                      subData={subData}
                      confirmPurchase={handleConfirmBook}
                      setIsLoading={setIsLoading}
                      coach={coach}
                      frequency={'One time'}
                      returnCost={returnCost}
                      duration={is60MinLesson}
                    />
                  </div>
                  <div>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://bestmusiccoach.freshdesk.com/support/solutions/folders/69000336972"
                      className="signinPolicy"
                    >
                      By clicking confirm you agree to Best Music Coach's <br />
                      <span>policies and terms.</span>
                    </a>
                  </div>
                  <p className="calendar_modal_book_body_buy_bottom_actions_error">
                    {couponError || bookErrors.timeError || bookErrors.balanceError}
                  </p>
                </div>
              </div>
            </div>
          )}
          {indexTab === 1 && (
            <div className="calendar_modal_book_body_buy">
              <div className="calendar_modal_book_body_buy_row">
                <KeyboardDatePicker
                  disabled={true}
                  className="calendar_modal_book_body_buy_row_date"
                  autoOk={true}
                  variant="inline"
                  error={bookErrors.timeError}
                  inputVariant="outlined"
                  id="Date"
                  label="Date"
                  format="MMM DD,yyyy"
                  value={bookData.date}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={handleChooseDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardTimePicker
                  disabled={true}
                  className="calendar_modal_book_body_buy_row_start"
                  autoOk={true}
                  variant="inline"
                  error={bookErrors.timeError}
                  inputVariant="outlined"
                  id="Start"
                  label="Start time"
                  format="LT"
                  value={bookData.start}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={handleChooseStart}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minutesStep={30}
                />
                <KeyboardTimePicker
                  disabled={true}
                  className="calendar_modal_book_body_buy_row_end"
                  autoOk={true}
                  variant="inline"
                  error={bookErrors.timeError}
                  inputVariant="outlined"
                  id="End"
                  label="End time"
                  format="LT"
                  value={isLessonDuration60MinAvailable ? bookData.end : moment(bookData.start).add(30, 'minutes')}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={handleChooseEnd}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minutesStep={30}
                />
              </div>
              <div className="calendar_modal_book_body_buy_row">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-age-native-simple">Lesson</InputLabel>
                  <Select
                    className="calendar_modal_book_body_buy_row_lesson"
                    label="Timezone"
                    input={<OutlinedInput notched={true} />}
                    value={bookData.instrument || +coach?.instruments[0]?.id || ''}
                    onChange={handleChooseInstrument}
                  >
                    {coach?.instruments.map((instrument) => (
                      <MenuItem key={instrument.name} value={instrument.id || ''}>
                        {instrument.name || ''}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-age-native-simple">For child</InputLabel>
                  <Select
                    className="calendar_modal_book_body_buy_row_children"
                    label="For child"
                    input={<OutlinedInput notched={true} />}
                    value={bookData.for || 'no'}
                    onChange={handleChooseChildren}
                  >
                    {user.children.map((children) => (
                      <MenuItem key={children.firstName} value={children.id}>
                        {children.firstName + ' ' + children.lastName}
                      </MenuItem>
                    ))}
                    <MenuItem key={'no'} value={'no'}>
                      no
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className="duration_selector">
                  <InputLabel htmlFor="outlined-age-native-simple">Lesson duration</InputLabel>
                  <Select
                    className="calendar_modal_book_body_buy_row_children"
                    label="Lesson duration"
                    input={<OutlinedInput notched={true} />}
                    defaultValue={isLessonDuration60MinAvailable ? '60 minutes' : '30 minutes'}
                    disabled={!isLessonDuration60MinAvailable}
                    onChange={handleChangeLessonDuration}
                  >
                    {['30 minutes', ...(!isLessonDuration60MinAvailable ? [] : ['60 minutes'])].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className="calendar_modal_book_body_buy_row_empty" />
              </div>
              <div className="calendar_modal_availabil_body_weeks">
                {weekConst.map((item, index) => (
                  <div
                    key={item.allName + index}
                    className={cn('calendar_modal_availabil_body_week', {
                      choosed: subData?.week && subData.week.find((i) => i.day === index),
                    })}
                    onClick={() => chooseBooKWeek(index)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <p className="calendar_modal_availabil_body_weektext">You can choose one day or several per week.</p>
              {subData.week.map((item, index, arr) => {
                return item.since.map((itemS, indexS) => {
                  return (
                    <div className="calendar_modal_availabil_body_time" key={item.day + index + itemS}>
                      <div
                        className={`
                          calendar_modal_availabil_body_time_weekName 
                          ${indexS !== 0 && 'calendar_modal_availabil_body_time_weekName_second'}
                        `}
                      >
                        {weekConst[item.day].allName}
                      </div>
                      <TimePicker
                        className="calendar_modal_availabil_body_time_start"
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        id="Start"
                        label="Start"
                        format="LT"
                        value={item.since[indexS]}
                        onChange={(date) => handleChangeStartWeekSub(date, index, indexS)}
                        minutesStep={30}
                      />
                      <TimePicker
                        className="calendar_modal_availabil_body_time_end"
                        autoOk={true}
                        variant="inline"
                        inputVariant="outlined"
                        id="End"
                        label="End"
                        format="LT"
                        disabled
                        // value={item.until[indexS]}
                        value={moment(item.since[indexS]).add(is60MinLesson() ? 60 : 30, 'minutes')}
                        onChange={(date) => handleChangeEndWeekSub(date, index, indexS)}
                        minutesStep={30}
                      />
                    </div>
                  );
                });
              })}
              <TextField
                className="calendar_modal_availabil_body_amount"
                variant="outlined"
                name="amount"
                label="Amount of weeks"
                type="number"
                error={weeksAmountError}
                required={true}
                onChange={handleChangeAmountOfLessons}
                value={subData.amountOfWeeks}
                fullWidth={true}
                InputProps={{
                  classes: {
                    focused: `focusedInputField`,
                  },
                }}
              />
              {!weeksAmountError ? (
                <p className="calendar_modal_availabil_body_text">
                  Minimum amount of lessons for
                  <br />
                  subscription is 5.
                </p>
              ) : (
                <p className="calendar_modal_availabil_body_text calendar_modal_availabil_body_error-text">
                  Amount of lessons is less than 5
                </p>
              )}
              <div className="calendar_modal_book_subscription_description">
                Cancel any time. Subscription lessons repeat every week for 1 month (5 weeks) at the same time on the
                same day. For example, if you pick Tuesday at 1 pm, your lessons will be every Tuesday at 1 pm. You can
                cancel or reschedule lessons in your calendar tab.
              </div>
              <Line />
              <div className="calendar_modal_book_body_buy_bottom">
                <div>
                  <img
                    alt="calendar_modal_book_body_buy_bottom_img"
                    src={mdiInfo}
                    className="calendar_modal_book_body_buy_bottom_img"
                  />
                  <p className="calendar_modal_book_body_buy_bottom_yourBalance">
                    Price per {moment(bookData.start).diff(moment(bookData.end), 'hour') < 0 ? 60 : 30} min lesson is $
                    {returnCostSub()}. Lessons are charged separately each week.
                  </p>
                </div>
                <div className="calendar_modal_book_body_buy_bottom_actions">
                  <div>
                    <p className="calendar_modal_book_body_buy_bottom_cost">$ {costSub}</p>
                    <PurchaseConfirmationModal
                      bookData={bookData}
                      subData={subData}
                      confirmPurchase={handleConfirmSub}
                      setIsLoading={setIsLoading}
                      coach={coach}
                      frequency={'Subsctiption'}
                      returnCost={returnCost}
                      duration={is60MinLesson}
                    />
                  </div>
                  <div>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://bestmusiccoach.freshdesk.com/support/solutions/folders/69000336972"
                      className="signinPolicy"
                    >
                      By clicking confirm you agree to Best Music Coach's <br />
                      <span>policies and terms.</span>
                    </a>
                  </div>
                  <p className="calendar_modal_book_body_buy_bottom_actions_error">
                    {bookErrors.balanceError || bookErrors.timeError}
                  </p>
                </div>
              </div>
            </div>
          )}
        </SwipeableViews>
      </div>
    </div>
  );
};

export default ModalBook;
