import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import { withRoomContext } from '../RoomContext';
import * as requestActions from '../redux/requestActions';
import { Appear } from './transitions';
import Me from './Me';
import Peers from './Peers';
import Stats from './Stats';
import Notifications from './Notifications';
import NetworkThrottle from './NetworkThrottle';
import Button from '@material-ui/core/Button';
import PractiseNote from 'component/PracticeNote/PracticeNote';
import { RolesEnum, useListLessonMaterialsQuery } from 'api/index';
// import moment from 'moment';

// const fetchLessonMaterials = async (lessonId) => {
//   const token = (await Auth.currentSession()).getIdToken().getJwtToken();
//   console.log('fetchLessonMaterials::token', token);
//   const res = await fetch(process.env.REACT_APP_GRAPHQL! as string, {
//     method: 'POST',
//     headers: {
//       'x-api-key': token,
//       'content-type': 'application/json',
//     },
//     body: JSON.stringify({
//       operationName: 'fetchLessonMaterials',
//       query: `query ListLessonMaterials($lesssonId: ID!) {
//   listLessonMaterials(lessonId: $lesssonId) {
//     lessonMaterials {
//       bpm
//       coachNotes
//       createdAt
//       id
//       studentGoal
//       studentNotes
//       timeInSec
//       title
//       updatedAt
//     }
//   }
// } `,
//       variables: {
//         lessonId,
//       },
//     }),
//   });
//   const resp = await res.json();
//   console.log('fetchLessonMaterials::resp', JSON.stringify(resp));
//   return resp;
// };

const PracticeNotes = ({ lessonId, history }: { lessonId: string; history: Record<string, any> }) => {
  const {
    data: lessonMaterialsData,
    loading: lessonMaterialsLoading,
    refetch: refetchLessonMaerials,
  } = useListLessonMaterialsQuery({
    variables: { lesssonId: lessonId as string },
  });
  // const [isRefreshing, setIsRefreshing] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      refetchLessonMaerials({ lesssonId: lessonId as string });
    }, 1000);
    return () => clearInterval(interval);
  }, [lessonId, refetchLessonMaerials]);
  if (lessonMaterialsLoading) {
    return null;
  }
  return (
    <>
      {/*{isRefreshing ? (*/}
      {/*  <div>Refreshing ...</div>*/}
      {/*) : (*/}
      {/*  <button*/}
      {/*    onClick={async () => {*/}
      {/*      console.log('Refresh');*/}
      {/*      setIsRefreshing(true);*/}
      {/*      await refetchLessonMaerials({ lesssonId: lessonId as string });*/}
      {/*      setIsRefreshing(false);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Refresh*/}
      {/*  </button>*/}
      {/*)}*/}
      <div className="notes_container">
        {history.location.state.lessons.find((lesson) => lesson.id === history.location.state.lessonId) &&
          history.location.state.user.role === RolesEnum.Coach && (
            <PractiseNote user={history.location.state.user} lessonId={history.location.state.lessonId} note={null} />
          )}
        {lessonMaterialsData?.listLessonMaterials?.lessonMaterials?.map((lm, i) => {
          return <PractiseNote key={lm?.id} user={history?.location?.state?.user} lessonId={lessonId} note={lm} />;
          // return <div key={i}>shit</div>;
        })}
      </div>
    </>
  );
};

const back_to_lesson = '/assets/imagesChat/back_to_lesson.svg';

class Room extends React.Component<any, any> {
  render() {
    const { roomClient, me, amActiveSpeaker, videoProducer, lesson, history } =
      this.props;
    const handleBackToLesson = () => {
      history.push('/lessons');
    };

    let shareState;

    if (Boolean(videoProducer) && videoProducer.type === 'share') {
      shareState = 'on';
    } else {
      shareState = 'off';
    }

    return (
      <Appear duration={300}>
        <div data-component="Room">
          <div className="left">
            <Notifications />

            {/* <div className='state'>
              <div className={classnames('icon', room.state)} />
              <p className={classnames('text', room.state)}>{room.state}</p>
            </div> */}

            {/* <div className='room-link-wrapper'>
              <div className='room-link'>
                <a
                  className='link'
                  href={room.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={(event) =>
                  {
                    // If this is a 'Open in new window/tab' don't prevent
                    // click default action.
                    if (
                      event.ctrlKey || event.shiftKey || event.metaKey ||
                      // Middle click (IE > 9 and everyone else).
                      (event.button && event.button === 1)
                    )
                    {
                      return;
                    }

                    event.preventDefault();

                    clipboardCopy(room.url)
                      .then(onRoomLinkCopy);
                  }}
                >
                  invitation link
                </a>
              </div>
            </div> */}

            <Peers />

            <div className="sidebar">
              <div className="sidebar_backToLesson" onClick={handleBackToLesson}>
                <img src={back_to_lesson} alt="back_to_lesson" className="sidebar_backToLesson_img" />
                Lesson{` ${lesson?.number || ''}`}
                {`${lesson?.topic ? `: ${lesson?.topic}` : ''}`}
              </div>
              <div className="sidebar_menu">
                {history.location.state.user.role === RolesEnum.Coach && (
                  <Button
                    className="lesson_button"
                    onClick={() => {
                      const lessonIndex = history.location.state.lessons.findIndex(
                        (e) => e.id === history.location.state.lessonId,
                      );
                      // console.log('---');
                      // console.log('cur lesson index', lessonIndex);
                      // console.log('next lesson id', history.location.state.lessons[lessonIndex - 1].id);
                      if (lessonIndex !== 0) {
                        history.push('/class-room', {
                          coachId: history.location.state.coachId,
                          lessonId: history.location.state.lessons[lessonIndex - 1].id,
                          lessons: history.location.state.lessons?.slice().sort((a, b) => +b?.startAt! - +a?.startAt!),
                          user: history.location.state.user,
                        });
                      }
                    }}
                  >
                    Go to next lesson
                  </Button>
                )}
                <div
                  className={classnames('button', 'hide-videos', {
                    on: me.audioOnly,
                    disabled: me.audioOnlyInProgress,
                  })}
                  data-tip={"Show/hide participants' video"}
                  onClick={() => {
                    me.audioOnly ? roomClient.disableAudioOnly() : roomClient.enableAudioOnly();
                  }}
                />

                <div
                  className={classnames('button', 'mute-audio', {
                    on: me.audioMuted,
                  })}
                  data-tip={"Mute/unmute participants' audio"}
                  onClick={() => {
                    me.audioMuted ? roomClient.unmuteAudio() : roomClient.muteAudio();
                  }}
                />

                {/* <div
                  className={classnames('button', 'restart-ice', {
                    disabled : me.restartIceInProgress
                  })}
                  data-tip='Restart ICE'
                  onClick={() => roomClient.restartIce()}
                /> */}
                <div
                  className={classnames('button', 'share', shareState, {
                    disabled: me.shareInProgress || me.webcamInProgress,
                  })}
                  onClick={() => {
                    if (shareState === 'on') {
                      roomClient.disableShare();
                    } else {
                      roomClient.enableShare();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="right">
            <div
              className={classnames('me-container', {
                'active-speaker': amActiveSpeaker,
              })}
            >
              <Me />
            </div>
            <PracticeNotes history={history} lessonId={lesson.id}></PracticeNotes>
          </div>

          {/* <div className='chat-input-container'>
						<ChatInput />
					</div> */}

          <Stats />
          {(global.window as any).NETWORK_THROTTLE_SECRET && (
            <NetworkThrottle secret={(global.window as any).NETWORK_THROTTLE_SECRET} />
          )}
          <ReactTooltip type="light" effect="solid" delayShow={100} delayHide={100} delayUpdate={50} />
        </div>
      </Appear>
    );
  }

  componentDidMount() {
    const { roomClient } = this.props;
    roomClient.join();
  }

  componentWillUnmount() {
    window.location.reload();
  }
}

const mapStateToProps = (state) => {
  return {
    room: state.room,
    me: state.me,
    amActiveSpeaker: state.me.id === state.room.activeSpeakerId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRoomLinkCopy: () => {
      dispatch(
        requestActions.notify({
          text: 'Room link copied to the clipboard',
        } as any),
      );
    },
  };
};

const RoomContainer = withRoomContext(connect(mapStateToProps, mapDispatchToProps)(Room));

export default RoomContainer;
