import { useState, ChangeEvent, FormEvent, useCallback } from 'react';
import { CouponTypeEnum, useAdminCreateCouponMutation, CouponFrequency } from 'api';

export type CouponType = {
  label: string;
  value: CouponTypeEnum;
};

export type FrequencyType = {
  label: string;
  value: CouponFrequency;
};

type Values = {
  name: string;
  type: CouponType;
  value: number;
  frequency: FrequencyType;
};

type Errors = {
  name: string;
  type: string;
  value: string;
};

export const useCreateCoupon = () => {
  const TYPES: Array<CouponType> = [
    {
      label: 'Percentage (%)',
      value: CouponTypeEnum.Percent,
    },
    {
      label: 'First Lesson (-50%)',
      value: CouponTypeEnum.FirstLesson,
    },
    {
      label: 'Fixed price ($)',
      value: CouponTypeEnum.FixedPrice,
    },
  ];

  const FREQUENCY_TYPES: Array<FrequencyType> = [
    {
      label: 'One time',
      value: CouponFrequency.OneTime,
    },
    {
      label: 'Unlimited',
      value: CouponFrequency.Unlimited,
    },
  ];

  const INITIAL_VALUES: Values = {
    name: '',
    type: TYPES[0],
    value: 0,
    frequency: FREQUENCY_TYPES[0],
  };
  const INITIAL_ERRORS: Errors = {
    name: '',
    type: '',
    value: '',
  };
  const [values, setValues] = useState<Values>(INITIAL_VALUES);
  const [errors, setErrors] = useState<Errors>(INITIAL_ERRORS);
  const [modal, setModal] = useState<boolean>(false);
  const [generatedCode, setCode] = useState<string>('');
  const [createCoupon] = useAdminCreateCouponMutation({
    onCompleted: (response) => {
      setCode(response.adminCreateCoupon?.code!);
      setModal(true);
    },
  });

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleChangeText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name, required } = event.target;

      if (value && required && errors[name].length) {
        setErrors({
          ...errors,
          [name]: '',
        });
      }

      setValues({
        ...values,
        [name]: value,
      });
    },
    [values, errors],
  );

  const handleChangeType = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      const { value } = event.target;

      setErrors({
        ...errors,
        value: '',
      });

      if ((value as CouponType).value === CouponTypeEnum.FirstLesson) {
        setValues({
          ...values,
          value: 50,
          type: value as CouponType,
        });
      } else {
        setValues({
          ...values,
          value: 0,
          type: value as CouponType,
        });
      }
    },
    [values, errors],
  );

  const handleChangeFrequencyType = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      const { value } = event.target;

      setValues({
        ...values,
        frequency: value as FrequencyType,
      });
    },
    [values],
  );

  const handleSubmit = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const err = {
      name: '',
      type: '',
      value: '',
    };

    for (const [key, value] of Object.entries(values)) {
      if (!value) {
        err[key] = "Field can't be empty";
      } else {
        err[key] = errors[key];
      }
    }

    setErrors(err);

    if (Object.values(err).every((error) => !error)) {
      setCode('');

      let valToSend: number;

      switch (values.type.value) {
        case CouponTypeEnum.FirstLesson:
          valToSend = 0.5;
          break;

        case CouponTypeEnum.Percent:
          valToSend = values.value / 100;
          break;

        case CouponTypeEnum.FixedPrice:
          valToSend = values.value * 100;
          break;
      }

      createCoupon({
        variables: {
          type: values.type.value,
          amount: valToSend,
          text: values.name,
          frequency: values.frequency.value,
        },
      });
    }
  };

  return {
    values,
    errors,
    modal,
    generatedCode,
    TYPES,
    FREQUENCY_TYPES,
    handleCloseModal,
    handleChangeText,
    handleChangeType,
    handleChangeFrequencyType,
    handleSubmit,
  };
};
