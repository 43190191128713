// Core
import React, { Fragment } from 'react';
// import classNames from "classnames";
import { Link as RRLink } from 'react-router-dom';
// import RouteInstance from "services/route";

// Styles and assets
import './Link.scss';

/**
 * Parse /:locale/something links
 * @param {string|object} to
 * @returns {mixed}
 */
const withLocale = (to) => {
  // const { locale } = RouteInstance.get();
  const locale = '';
  if (typeof to === 'string') {
    return to.replace(':locale/', `${locale}/`);
  }
  if (typeof to === 'object' && to.pathname) {
    to.pathname = to.pathname.replace(':locale/', `${locale}/`);
  }
  return to;
};

interface LinkProps {
  children?: any;
  to?: string;
  href?: string;
  classes?: string;
  target?: string;
}

const Link = ({ children, to, href, classes, target = '_self', ...rest }: LinkProps) => (
  <Fragment>
    {to ? (
      <RRLink to={withLocale(to)} className={`sk-link ${classes}`} {...rest}>
        {children}
      </RRLink>
    ) : (
      <a
        href={href}
        target={target}
        className={`sk-link ${classes}`}
        // className={classNames("sk-link", { [classes]: !!classes })}
        {...rest}
      >
        {children}
      </a>
    )}
  </Fragment>
);

export default Link;
