import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Loader from 'component/Loader/Loader';
import Roles from './Roles';

import useWindowDimensions from 'utils/hooks/useWindowDimentions';
import validateEmail from 'utils/emailUtils';
import './Signup.scss';
import { Checkbox } from '@material-ui/core';

const mainImage = '/assets/sign_up_image.png';
const codeSign = '/assets/code_sign_up.png';
const INITIAL_VALUES_STATE = {
  name: '',
  surname: '',
  email: '',
  password: '',
};

const INITIAL_ERRORS_STATE = { email: '', password: '', name: '', surname: '' };
const coachHardcodedCode = 'Ready2coach!';

interface SignUpProps {
  signIn: ({ email, password }) => void;
  getAuth: () => void;
}

function SignUp({ signIn, getAuth }: SignUpProps) {
  const { width } = useWindowDimensions();
  const history = useHistory<any>();
  const [code, setCode] = useState<string>('');
  const [coachCode, setCoachCode] = useState<string>('');
  const [isCode, setIsCode] = useState<boolean>(false);
  const [isCoachCode, setIsCoachCode] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [values, setValues] = useState(INITIAL_VALUES_STATE);
  const [errors, setError] = useState(INITIAL_ERRORS_STATE);
  const [error, setisError] = useState('');
  const [errorCode, setErrorCode] = useState<boolean>(false);
  const [errorCoachCode, setErrorCoachCode] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isCoachTab, setIsCoachTab] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (history?.location?.state?.notConfirmed) {
      if (isCoachTab || history?.location?.state?.notConfirmedUserRole === 'COACH') {
        setIsCoachCode(true);
      } else {
        setIsCode(true);
      }
      setValues((data) => ({
        ...data,
        email: history.location.state.email,
        password: history.location.state.password,
      }));
      try {
        Auth.resendSignUp(history.location.state.email);
      } catch (e) {
        // tslint:disable-next-line: no-console
        console.log(e);
      }
    }
  }, [history, isCoachTab]);

  const handleChangePassword = useCallback(
    (event) => {
      setError({ ...errors, password: '' });

      if (event.target.name === 'password' && !event.target.value) {
        setError({ ...errors, password: 'Please enter a valid password' });
      }

      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    },
    [values, errors],
  );

  const handleChangeEmail = useCallback(
    (event) => {
      setError({ ...errors, email: '' });

      if (event.target.name === 'email' && !validateEmail(event.target.value)) {
        setError({ ...errors, email: 'Please enter a valid email address' });
      }

      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    },
    [errors, values],
  );

  const handleChangeName = useCallback(
    (event) => {
      setError({ ...errors, name: '' });

      if (!event.target.value) {
        setError({ ...errors, name: 'Please enter a valid name' });
      }

      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    },
    [errors, values],
  );

  const handleChangeSurname = (event) => {
    setError({ ...errors, surname: '' });

    if (!event.target.value) {
      setError({ ...errors, surname: 'Please enter a valid surname' });
    }

    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleMouseDownPassword = useCallback((event) => event.preventDefault(), []);

  const handleSubmit = useCallback(
    async (e) => {
      if (e) {
        e.preventDefault();
      }
      try {
        setLoad(true);
        await Auth.signUp({
          password: values.password,
          username: values.email?.toLowerCase(),
          attributes: {
            'custom:firstName': values.name,
            'custom:lastName': values.surname,
            'custom:role': isCoachTab ? 'COACH' : 'ADULT',
            email: values.email?.toLowerCase(),
            ...(sessionStorage.getItem('signUpFrom') !== null && {
              'custom:signUpFrom': sessionStorage.getItem('signUpFrom'),
            }),
          },
        });
        sessionStorage.clear();
        setisError('');
        if (isCoachTab) {
          setIsCoachCode(true);
        } else {
          setIsCode(true);
        }
        setLoad(false);
      } catch (e: any) {
        if (e.message) {
          setisError(e.message);
        }
        setLoad(false);
        setIsChecked(false);
        // tslint:disable-next-line: no-console
        console.log(e);
      }
    },
    [values, isCoachTab],
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key !== 'Enter') {
        return;
      }

      if (Object.keys(errors).some((k) => errors[k])) {
        setError(INITIAL_ERRORS_STATE);
      }

      event.preventDefault();
    },
    [errors],
  );

  useEffect(() => {
    document.querySelector('[type="password"]');
  }, []);

  const renderFields = (
    <>
      <TextField
        className={`signupFormField ${errors.name ? 'signupFormFieldError' : ''}`}
        error={!!errors.name}
        variant="outlined"
        name="name"
        label="First name"
        type="text"
        onChange={handleChangeName}
        value={values.name || ''}
        fullWidth={true}
        onKeyPress={handleKeyPress}
        helperText={errors.name ? errors.name : ''}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />

      <TextField
        className={`signupFormField ${errors.surname ? 'signupFormFieldError' : ''}`}
        error={!!errors.surname}
        variant="outlined"
        name="surname"
        label="Last name"
        type="text"
        onChange={handleChangeSurname}
        value={values.surname || ''}
        fullWidth={true}
        onKeyPress={handleKeyPress}
        helperText={errors.surname ? errors.surname : ''}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />

      <TextField
        className={`signupFormField ${errors.email ? 'signupFormFieldError' : ''}`}
        error={!!errors.email}
        variant="outlined"
        name="email"
        label="Email"
        type="text"
        onChange={handleChangeEmail}
        value={values.email || ''}
        fullWidth={true}
        onKeyPress={handleKeyPress}
        helperText={errors.email ? errors.email : ''}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />

      <TextField
        className={`signupFormField signupPasswordField ${errors.password ? 'signupFormFieldError' : ''}`}
        error={!!errors.password}
        helperText={errors.password ? errors.password : ''}
        name="password"
        label="Password"
        variant="outlined"
        onKeyPress={handleKeyPress}
        type={showPassword ? 'text' : 'password'}
        onChange={handleChangePassword}
        value={values.password || ''}
        fullWidth={true}
        required={true}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className="signupVisibilityIcon"
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );

  const handleChangeCode = (e) => {
    setErrorCode(false);
    setCode(e.target.value);
  };

  const handleCoachChangeCode = (e) => {
    setErrorCoachCode(false);
    setCoachCode(e.target.value);
  };

  const renderFieldsCode = () => {
    return (
      <>
        <TextField
          className={`signupFormField ${errors.name ? 'signupFormFieldError' : ''} codeFormField`}
          error={!!errors.surname}
          variant="outlined"
          name="code"
          label="Code"
          type="text"
          onChange={handleChangeCode}
          value={code || ''}
          fullWidth={true}
          onKeyPress={handleKeyPress}
          helperText={''}
          required={true}
          InputProps={{
            classes: {
              focused: `focusedInputField`,
            },
          }}
        />
      </>
    );
  };

  const renderCoachFieldsCode = () => {
    return (
      <>
        <TextField
          className={`signupFormField ${errors.name ? 'signupFormFieldError' : ''} codeFormField`}
          error={!!errors.surname}
          variant="outlined"
          name="code"
          label="Code"
          type="text"
          onChange={handleCoachChangeCode}
          value={coachCode || ''}
          fullWidth={true}
          onKeyPress={handleKeyPress}
          helperText={''}
          required={true}
          InputProps={{
            classes: {
              focused: `focusedInputField`,
            },
          }}
        />
      </>
    );
  };

  const handleSubmitCode = useCallback(async () => {
    try {
      setLoad(true);
      const submit = await Auth.confirmSignUp(values.email?.toLowerCase(), code);
      if (submit === 'SUCCESS') {
        await signIn({ email: values.email?.toLowerCase(), password: values.password });
      } else {
        setErrorCode(true);
      }
      setLoad(false);
    } catch (e) {
      setLoad(false);
      setErrorCode(true);
      // tslint:disable-next-line: no-console
      console.log(e);
    }
  }, [values.email, values.password, code, signIn]);

  const handleResendCode = useCallback(async () => {
    try {
      await Auth.resendSignUp(values.email);
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e);
    }
  }, [values]);

  const handleCheckCoachCode = useCallback(() => {
    if (coachCode === coachHardcodedCode) {
      setIsCode(true);
    } else {
      setErrorCoachCode(true);
    }
  }, [coachCode]);

  const renderActionsCode = () => {
    return (
      <div className="signupActionsCode">
        <Typography onClick={handleResendCode} className="signinPolicy" variant="body2">
          <span>Resend code</span>
        </Typography>
        <Button className={`signupButton`} variant="contained" color="secondary" onClick={handleSubmitCode}>
          Confirm
        </Button>
      </div>
    );
  };

  const renderCoachActionsCode = () => {
    return (
      <div className="signupActionsCode">
        <Button className={`signupButton`} variant="contained" color="secondary" onClick={handleCheckCoachCode}>
          Confirm
        </Button>
      </div>
    );
  };

  const [hasError, hasContent] = [
    Object.keys(errors).some((k) => errors[k]),
    Object.keys(values).every((k) => values[k]),
  ];
  const disabled = hasError || !hasContent || isChecked === false;

  const handleSignIn = () => {
    history.push('/signin');
  };

  const renderActions = (
    <div className="signupActions">
      <Checkbox required={true} onChange={() => setIsChecked(!isChecked)} />
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://bestmusiccoach.freshdesk.com/support/solutions/folders/69000336972"
        className="signinPolicy"
      >
        I agree with <br />
        <span>Terms Conditions and Privacy Policy</span> and to receive emails from the Best Music Coach platform.
      </a>
      <Button
        className={`signupButton ${disabled ? 'signupDisabled' : ''}`}
        disabled={disabled}
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
      >
        Sign up
      </Button>
      {width < 767 && (
        <Button className={`signinButton`} variant="contained" color="secondary" onClick={handleSignIn}>
          Sign in
        </Button>
      )}
    </div>
  );

  const onChangeRole = useCallback(() => {
    setIsCoachTab(!isCoachTab);
  }, [isCoachTab]);

  return (
    <div className="signupContainer">
      <div className="signupColumn signupLeftColumn">
        {isCode ? (
          <div style={{ width: '369px' }} className="containerofCodeHeader">
            <Typography className="signupLeftColumnTitle" variant="h4">
              <span>Verification code</span>
              <br />
            </Typography>
            <span className="signupLeftColumnSecondTitle">
              Enter the 6-digit verification code from the "Welcome to BEST MUSIC COACH!" email.
            </span>
            <img src={codeSign} alt="" className="signupLogo" />
          </div>
        ) : isCoachCode ? (
          <div style={{ width: '369px' }} className="containerofCodeHeader">
            <Typography className="signupLeftColumnTitle" variant="h4">
              <span>Verification code</span>
              <br />
            </Typography>
            <span className="signupLeftColumnSecondTitle">Enter the coach verification code.</span>
            <img src={codeSign} alt="" className="signupLogo" />
          </div>
        ) : (
          <>
            <Typography className="signupLeftColumnTitle" variant="h4">
              <span>Sign up</span>
              <br />
              <span>for Best Music Coach!</span>
            </Typography>
            <img src={mainImage} alt="" className="signupLogo" />
            <div className="signupCoursesWrapper">
              <div className="signupCoursesText">This Sign up page is for music lessons</div>
              <div className="signupCoursesText">
                If you are looking for your book or course, kindly Sign up{' '}
                <a
                  className="signupCoursesLink"
                  target="_blank"
                  href="https://bestmusiccoach.com/books-and-courses-login/"
                >
                  Here
                </a>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="signupColumn signupRightColumn signupRightColumn2">
        {load ? (
          <div className="signupLoader">
            <Loader />
          </div>
        ) : isCode ? (
          <div
            className="signupRightColumnFields signup codeRightColumnFields codeRightColumnFields2"
            style={{ marginTop: '10%' }}
          >
            {renderFieldsCode()}
            {errorCode && <span className="signupErrorCode">Wrong code</span>}
            {renderActionsCode()}
          </div>
        ) : isCoachCode ? (
          <div
            className="signupRightColumnFields signup codeRightColumnFields codeRightColumnFields2"
            style={{ marginTop: '10%' }}
          >
            {renderCoachFieldsCode()}
            {errorCoachCode && <span className="signupErrorCode">Wrong code</span>}
            {renderCoachActionsCode()}
          </div>
        ) : (
          <Roles onChangeRole={onChangeRole}>
            <div className="signupRightColumnFields signup">
              <>
                {renderFields}
                {error && <span className="signupError">{error}</span>}
                {renderActions}
              </>
            </div>
          </Roles>
        )}
      </div>
    </div>
  );
}

export default SignUp;
