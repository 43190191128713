import React from 'react';
import Typography from '@material-ui/core/Typography';

const mainImage = '/assets/personal_info_image.png';

const LeftPart = () => {
  return (
    <>
      <Typography className="profileLeftColumnTitle" variant="h4">
        Tell about yourself!
      </Typography>
      <Typography className="profileColumnSubtitle" variant="body1">
        You can edit info later in your profile.
      </Typography>

      <img src={mainImage} alt="" className="profileLogo" />
    </>
  );
};

export default LeftPart;
