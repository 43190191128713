import React from 'react';
import moment from 'moment';
import { LessonType } from 'api/index';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import { isAvatar, getAvatar } from 'utils/image';
import './BalanceCard.scss';
import Button from 'component/ButtonMatireal/ButtonMatireal';

const link_icon = '/assets/link_icon.svg';

const BalanceCrad = ({ balance, handleGotoCoach, handleGoToLesson, handleCancel }) => {
  return (
    <div className="balanceCard" onClick={() => handleGoToLesson(balance?.coachProfile?.id)}>
      <div className="balanceCard_user">
        <UserAvatar
          avatar={isAvatar(balance?.coachProfile?.files || []) ? getAvatar(balance?.coachProfile?.files) : ''}
          firstName={balance?.coachProfile?.firstName || ''}
          lastName={balance?.coachProfile?.lastName || ''}
        />
        <div className="balanceCard_name">
          <p className="balanceCard_name_firstName">{balance?.coachProfile?.firstName || ''}</p>
          <div className="balanceCard_name2">
            <p className="balanceCard_name2_lastName">{balance?.coachProfile?.lastName || ''}</p>
            <img
              alt="balanceCard_name2_img"
              src={link_icon}
              className="balanceCard_name2_img"
              onClick={(event) => handleGotoCoach(event, balance?.coachProfile?.id)}
            />
          </div>
        </div>
      </div>
      <div className="balanceCard_type">
        <span>{balance?.type !== LessonType.Individual ? 'Subscription' : 'Lesson'}</span>
      </div>
      <div className="balanceCard_deta">
        <p className="balanceCard_deta_deta">{moment(Number.parseInt(balance?.startAt)).format('DD MMM, YYYY, LT')}</p>
      </div>
      <div className="balanceCard_balance">
        <p>$ {balance?.costs / 100}</p>
      </div>
      <div className="balanceCard_button">
        {balance?.status === 'UPCOMING' && (
          <Button contained={false} onClick={handleCancel}>
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};

export default BalanceCrad;
