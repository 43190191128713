import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { Close as DeleteIcon } from '@material-ui/icons';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from 'utils/enums';
import { RolesEnum } from 'api';
import { formatCamelCaseString, sliceInstruments } from 'utils/helpers';
import './Experience.scss';
import InstrumentLevelModal from './Modal/InstrumentLevelModal';

const signupAboutMePlaceholder =
  "What are your goals and dreams for music? If you don't know what you want to do yet, that is fine! Your coach will guide you through learning everything you need to know!";
const profileAboutMePlaceholder = 'Write your goals and dreams. What do you want to achieve?';
const intruments = [
  'Guitar',
  'Drums',
  'Piano',
  'Voice',
  'Ukulele',
  'Bass',
  'MusicTheory',
  'EarTraining',
  'MusicProduction',
  'Violin',
  'Viola',
  'Cello',
  'Trumpet',
  'Saxophone',
  'Flute',
  'Clarinet',
  'Banjo',
  'Songwriting',
  'Composition',
];

interface ExperienceProps {
  lessons?: any;
  handleChangeLessons?: any;
  handleChangeLevel?: any;
  handleChange?: any;
  handleDeleteLesson: (lesson: string) => void;
  values?: any;
  level?: any;
  handleKeyPress?: any;
  isAuthenticated?: any;
  intrument?: any[];
  levelSelect?: any[];
  user?: any;
  errors?: any;
  disabledFields?: boolean;
  isParent?: boolean;
}

const Experience = ({
  lessons,
  handleChangeLessons,
  handleChangeLevel,
  handleChange,
  handleDeleteLesson,
  values,
  level,
  handleKeyPress,
  isAuthenticated,
  intrument,
  levelSelect,
  user,
  errors,
  disabledFields = false,
  isParent,
}: ExperienceProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)` });
  const [openModal, setOpenModal] = useState(false);
  const [chosenInstrumentName, setChosenInstrumentName] = useState<any>('');
  return (
    <div
      className={cn('experience', {
        contained: !isAuthenticated,
      })}
    >
      {isAuthenticated && <Typography className="profileTitle">Experience</Typography>}
      <FormControl className="form_control" error={!!errors.lessons}>
        <InputLabel
          disabled={true}
          className={`lessonsPlaceholder form_control_label ${lessons.length ? 'filled' : ''}`}
        >
          {lessons.length
            ? 'Lessons *'
            : user?.role !== RolesEnum.Coach
            ? 'What do you want to learn?'
            : 'What do you want to teach?'}
        </InputLabel>
        <Select
          MenuProps={{ variant: 'menu' }}
          multiple
          input={
            <OutlinedInput
              notched={true}
              className={cn('select-input-box', {
                'select-input-box--empty': !lessons.length,
              })}
            />
          }
          renderValue={(value) => {
            const selected: any = value;
            if (selected.length) {
              const { selectedSlice, lastElementIndex } = sliceInstruments(selected, isMobile);
              return (
                <>
                  {selectedSlice.map((i: any) => (
                    <Chip
                      key={i}
                      label={formatCamelCaseString(i)}
                      disabled={user?.role === RolesEnum.Child && !isParent}
                      className="select-chip"
                      onMouseDown={(event) => {
                        event.stopPropagation();
                      }}
                      onDelete={() => handleDeleteLesson(i)}
                      deleteIcon={<DeleteIcon fontSize="small" className="select-chip_icon" />}
                    />
                  ))}
                  {lastElementIndex !== -1 && <span> ...</span>}
                </>
              );
            } else {
              return '';
            }
          }}
          value={[...lessons.map((e) => e.name.replace(' ', ''))]}
          autoWidth={true}
          defaultValue={[...lessons.map((e) => e.name.replace(' ', ''))]}
        >
          {intruments.map((name) => (
            <MenuItem
              onClick={(e) => {
                setChosenInstrumentName(name);
                if (lessons.findIndex((e) => e.name === name) === -1 && user?.role === RolesEnum.Coach)
                  setOpenModal(true);
                if (lessons.findIndex((e) => e.name === name) === -1 && user?.role === RolesEnum.Adult)
                  handleChangeLessons({ name: name, level: levelSelect || 'BEGINNER' });
                if (lessons.findIndex((e) => e.name === name) === -1 && user?.role === RolesEnum.Child)
                  handleChangeLessons(name);
                if (
                  lessons.findIndex((e) => e.name === name) > -1 ||
                  lessons.findIndex((e) => e.name.replace(' ', '') === name.replace(' ', '')) > -1
                )
                  handleDeleteLesson(name);
              }}
              key={name}
              value={name}
              disabled={disabledFields}
            >
              {intrument && (
                <Checkbox
                  checked={
                    lessons.findIndex((e) => e.name === name) > -1 ||
                    lessons.findIndex((e) => e.name.replace(' ', '') === name.replace(' ', '')) > -1
                  }
                />
              )}
              {name.replace(/([A-Z])/g, ' $1').trim()}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className="select-formError">{errors?.lessons}</FormHelperText>
      </FormControl>
      {user?.role === RolesEnum.Child && (
        <FormControl className="form_control">
          <InputLabel disabled={true} className={`lessonsPlaceholder form_control_label ${!!level ? 'filled' : ''}`}>
            {level
              ? 'Level *'
              : user?.role !== RolesEnum.Coach
              ? 'Choose level of your experience.'
              : 'Choose level you’re going to teach.'}
          </InputLabel>
          <Select
            MenuProps={{ variant: 'menu' }}
            labelId="demo-mutiple-name-label"
            className="lessonsFormField"
            id="demo-mutiple-name"
            input={<OutlinedInput notched={true} />}
            multiple={user && user?.role === RolesEnum.Coach}
            value={level ? level : 'Beginner'}
            autoWidth={true}
            onChange={handleChangeLevel}
            defaultValue={level}
            renderValue={(value) => {
              if (user.role === RolesEnum.Coach) {
                const selected: any = value;
                if (selected.length) {
                  return selected.join(', ');
                } else {
                  return '';
                }
              } else {
                return value;
              }
            }}
          >
            {['Beginner', 'Intermediate', 'Advanced'].map((name) => (
              <MenuItem key={name} value={name} disabled={disabledFields}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className="select-formError">{errors?.level}</FormHelperText>
        </FormControl>
      )}
      {user?.role === RolesEnum.Adult && (
        <FormControl className="form_control">
          <InputLabel disabled={true} className={`lessonsPlaceholder form_control_label ${!!level ? 'filled' : ''}`}>
            {level
              ? 'Level *'
              : user?.role !== RolesEnum.Coach
              ? 'Choose level of your experience.'
              : 'Choose level you’re going to teach.'}
          </InputLabel>
          <Select
            MenuProps={{ variant: 'menu' }}
            labelId="demo-mutiple-name-label"
            className="lessonsFormField"
            id="demo-mutiple-name"
            input={<OutlinedInput notched={true} />}
            multiple={user && user?.role === RolesEnum.Coach}
            value={level ? level : 'Beginner'}
            autoWidth={true}
            defaultValue={level}
            renderValue={(value) => {
              if (user.role === RolesEnum.Coach) {
                const selected: any = value;
                if (selected.length) {
                  return selected.join(', ');
                } else {
                  return '';
                }
              } else {
                return value;
              }
            }}
          >
            {['Beginner', 'Intermediate', 'Advanced'].map((name) => (
              <MenuItem onClick={() => handleChangeLevel(name)} key={name} value={name} disabled={disabledFields}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className="select-formError">{errors?.level}</FormHelperText>
        </FormControl>
      )}
      <TextField
        className="profileFormField profileFormFieldAbout"
        variant="outlined"
        name="about"
        label="About me"
        placeholder={isAuthenticated ? profileAboutMePlaceholder : signupAboutMePlaceholder}
        multiline={true}
        disabled={disabledFields}
        rowsMax={7}
        rows={2}
        onChange={handleChange}
        value={values.about || ''}
        fullWidth
        error={!!errors?.about}
        helperText={errors?.about}
        InputProps={{
          classes: {
            focused: `focusedInputField`,
          },
        }}
      />
      <InstrumentLevelModal
        setOpenModal={setOpenModal}
        user={user}
        level={level}
        handleChangeLevel={handleChangeLevel}
        RolesEnum={RolesEnum}
        openModal={openModal}
        disabledFields={disabledFields}
        levelSelect={levelSelect}
        chosenInstrumentName={chosenInstrumentName}
        setChosenInstrumentName={setChosenInstrumentName}
        handleChangeLessons={handleChangeLessons}
      />
    </div>
  );
};

export default Experience;
