import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import UserAvatar from '../../UserAvatar/UserAvatar';
import Rating from '../../CourseCard/Rating';
import { getAvatar } from '../../../utils/image';
import { getInstrumentName } from '../../../utils/helpers';
import './CoachCard.scss';
import { CoachProfile, Instruments } from 'api';
import { Maybe } from 'graphql/jsutils/Maybe';

interface CoachCardProps {
  coach: CoachProfile;
}

const CoachCard: FC<CoachCardProps> = ({ coach }) => {
  const { push } = useHistory();

  const renderInstruments = (instruments: Array<Maybe<Instruments>>) =>
    instruments.map((item, index) => {
      if (index >= 4) {
        return null;
      }
      return (
        <img
          key={item?.id}
          className="coach_card_instruments_img"
          src={getInstrumentName(item?.name || '')}
          alt={`${item?.name}'s_icon`}
        />
      );
    });

  return (
    <div className="coach_card" onClick={() => push(`/coach/${coach.id}`)}>
      <div>
        <UserAvatar
          avatar={getAvatar(coach.files || [])}
          firstName={coach.firstName}
          lastName={coach.lastName}
          classes="coach_card_avatar"
        />
        <div className="coach_card_name">{coach.firstName} {coach.lastName}</div>
        <div className="coach_card_rating">
          <Rating
            className="coach_card_rating_container"
            starClasses="coach_card_rating_star"
            row={true}
            value={coach.rating ?? 0}
            views={coach?.feedbacksCount!}
          />
        </div>
        <div className="coach_card_instruments">{renderInstruments(coach.instruments || [])}</div>
      </div>
    </div>
  );
};

export default CoachCard;
