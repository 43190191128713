import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { useListLessonsQuery, Lessons, Maybe, Profile, RolesEnum } from 'api';
import LessonCard from 'component/LessonCard/LessonCard';
import Line from 'component/Line/Line';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import Loader from 'component/Loader/Loader';
import { isAvatar, getAvatar } from 'utils/image';
import './Lessons.scss';
import BrowserValidationModal from 'component/BrowserValidationModal/BrowserValidationModal';
import { isValidBrowserVersion } from 'utils/helpers';

const emptyLessonLeftImg = '/assets/empty_lesson.svg';
const emptyCalendarImg = '/assets/empty_calendar.svg';

const emptyLessonLeft = ({ user }) => {
  return (
    <div className="empty_lesson_left">
      <h3 className="empty_lesson_left_title">You have no lessons yet.</h3>
      <p className="empty_lesson_left_text">
        {user.role === RolesEnum.Coach && (
          <>
            To be visible for students, please set your
            <br />
            availability in your calendar.
          </>
        )}
      </p>
      <img src={emptyLessonLeftImg} className="empty_lesson_left_img" alt="empty_lesson_left_img" />
    </div>
  );
};

interface emptyLessonRightProps {
  handleGoToCalendar: () => void;
  user: Profile;
}

const emptyLessonRight = ({ user, handleGoToCalendar }: emptyLessonRightProps) => {
  return (
    <div className="empty_lesson_right">
      <img src={emptyCalendarImg} className="empty_lesson_right_img" alt="empty_lesson_right_img" />
      <Button onClick={handleGoToCalendar} className="lesson_button">
        {user.role === RolesEnum.Coach ? 'Go to calendar' : 'Find coach'}
      </Button>
    </div>
  );
};

interface lessonLeftProps {
  lessons: Array<Maybe<Lessons>>;
}

const lessonLeft = ({ lessons }: lessonLeftProps) => {
  const inprogressLessons = lessons.filter((el) => el!.status === 'IN_PROGRESS');
  const upcomingLessons = lessons.filter((el) => el!.status === 'UPCOMING').sort((a, b) => +a!.startAt! - +b!.startAt!);
  const finishedLessons = lessons.filter((el) => el!.status === 'FINISHED').sort((a, b) => +b!.startAt! - +a!.startAt!);
  return [...inprogressLessons, ...upcomingLessons, ...finishedLessons].map((item) => {
    return <LessonCard lesson={item} key={item?.id || '1'} admin={false} adminGoToLesson={() => {}} />;
  });
};

interface lessonRightProps {
  lessons: Array<Maybe<Lessons>>;
  handleGoToCalendar: () => void;
  handleGoToLesson: () => void;
  user: Profile;
}

const returnNextLesson = (arr) => {
  if (arr.length !== 0) {
    const newArr = arr.find((item) => {
      if (moment(+item.finishAt).isSameOrAfter(moment())) {
        return true;
      } else {
        return false;
      }
    });
    if (newArr) {
      return newArr;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const lessonRight = ({ lessons, handleGoToCalendar, handleGoToLesson, user }: lessonRightProps) => {
  let newArr: any[] = [...lessons];
  let nextLesson = returnNextLesson(newArr);
  if (nextLesson) {
    return (
      <div className="lesson_right_next">
        <div className="lesson_right_next_top">
          <h3 className="lesson_right_next_hour">{moment(Number.parseInt(nextLesson.startAt)).format('LT')}</h3>
          <h4 className="lesson_right_next_date">
            {moment(Number.parseInt(nextLesson.startAt)).format('DD MMM, YYYY')}
          </h4>
        </div>
        <Line />
        <div className="lesson_right_next_container">
          <h3 className="lesson_right_next_topic">{nextLesson.topic}</h3>
          <div className="lesson_right_next_avatarBlock">
            <UserAvatar
              avatar={isAvatar(nextLesson.coachProfile?.files || []) ? getAvatar(nextLesson.coachProfile?.files) : ''}
              firstName={nextLesson.coachProfile.firstName}
              lastName={nextLesson.coachProfile.lastName}
            />
            <div className="lesson_right_next_nameBlock">
              <p className="lesson_right_next_name">
                {nextLesson.coachProfile.firstName} {nextLesson.coachProfile.lastName}
              </p>
            </div>
          </div>
          {moment(+nextLesson?.finishAt!)
            .add(7, 'days')
            .isAfter(moment()) && (
            <Button onClick={handleGoToLesson} className="lesson_button">
              Join lesson
            </Button>
          )}
        </div>
      </div>
    );
  } else {
    return <>{emptyLessonRight({ handleGoToCalendar, user })}</>;
  }
};

const Lesson = ({ user }) => {
  const [browserModalOpen, setBrowserModalOpen] = useState(false);
  const history = useHistory();
  const listLesson = useListLessonsQuery({
    variables: {
      offset: 0,
      limit: 2000,
      since: '',
      until: '',
    },
  });
  let lessons: any = [...(listLesson?.data?.listLessons?.data || [])];
  lessons.sort((a, b) => {
    if (a?.startAt !== null && b?.startAt !== null && a?.startAt !== undefined && b?.startAt !== undefined) {
      if (moment(+a?.startAt).isSameOrAfter(moment())) {
        if (+a?.startAt > +b?.startAt) {
          return 1;
        }
        if (+a?.startAt < +b?.startAt) {
          return -1;
        }
      } else {
        return -1;
      }
    }
    return 0;
  });

  const handleGoToCalendar = useCallback(() => {
    if (user.role === RolesEnum.Adult) {
      history.push('/coaches');
    } else {
      history.push('/calendar');
    }
  }, [history, user]);

  const handleGoToLesson = useCallback(() => {
    const newArr = [...lessons];
    let nextLesson = returnNextLesson(newArr);
    if (isValidBrowserVersion()) {
      if (nextLesson) {
        history.push('/class-room', {
          coachId: nextLesson?.coachProfile?.id,
          lessonId: nextLesson?.id,
          lessons: lessons?.slice().sort((a, b) => +b?.startAt! - +a?.startAt!),
          pathReturn: '/lessons',
          user: user,
        });
      }
    } else {
      setBrowserModalOpen(true);
    }
  }, [history, lessons]);

  return (
    <div className="lesson">
      {listLesson.loading ? (
        <Loader />
      ) : (
        <div className="lesson_container">
          <div className="lesson_left">
            {lessons.length === 0 ? emptyLessonLeft({ user }) : lessonLeft({ lessons })}
          </div>
          <div className="lesson_right">
            {lessons.length === 0
              ? emptyLessonRight({ user, handleGoToCalendar })
              : lessonRight({ lessons, handleGoToCalendar, handleGoToLesson, user })}
          </div>
          <BrowserValidationModal isModalOpen={browserModalOpen} setIsModalOpen={setBrowserModalOpen} />
        </div>
      )}
    </div>
  );
};

export default Lesson;
