import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ChaptersInput,
  LinksInput,
  UpdateBookChapter,
  UpdateBookLink,
  useAdminRemoveBookMutation,
  useAdminUpdateBookMutation,
  useGetBookByIdQuery,
} from 'api';
import Loader from 'component/Loader/Loader';
import BookForm from '../BookForm/BookForm';
import { FormState, ItemIdsToRemove } from '../BookForm/hooks';
import { routes } from '../../../modules/routes';

const Book = () => {
  const { bookId } = useParams<{ bookId: string }>();
  const history = useHistory();
  const { data, loading } = useGetBookByIdQuery({
    variables: { id: bookId },
    onError: (error) => {
      console.error(error);
      console.error(error.message);
      if (error.message === 'NOT_EXIST') {
        history.push(routes.adminBooks);
      }
    },
  });
  const [updateBook] = useAdminUpdateBookMutation();
  const [deleteBook] = useAdminRemoveBookMutation({ variables: { id: bookId } });
  const book: any = data?.getBookById;

  const formState: FormState | undefined = useMemo(() => {
    if (!book) return;
    const sortedChapters = [...book.bookChapters].sort((a, b) => a.order - b.order);
    return {
      book: {
        name: book.name,
        path: book.path,
      },
      chapters: sortedChapters.map((chapter) => ({
        id: chapter.id,
        name: chapter.name,
      })),
      links: sortedChapters
        .map((chapter) => {
          return [...chapter.chapterLinks]
            .sort((a, b) => a.order - b.order)
            .map((link) => ({
              id: link.id,
              name: link.name,
              path: link.path,
              chapterId: chapter.id,
              order: link.order,
            }));
        })
        .reduce((accum, links) => [...accum, ...links], []),
    };
  }, [book]);

  if (loading && !book) {
    return (
      <div className="admin-books-list">
        <Loader />
      </div>
    );
  }

  const handleUpdateBook = async ({ book, chapters, links }: FormState, itemIdsToRemove?: ItemIdsToRemove) => {
    const bookChaptersForCreate: ChaptersInput[] = [];
    const bookChaptersForUpdate: UpdateBookChapter[] = [];
    chapters.forEach((chapter, chapterIndex) => {
      const chapterLinks = links.filter((link) => link.chapterId === chapter.id);
      if (chapter.isCreated) {
        bookChaptersForCreate.push({
          order: chapterIndex + 1,
          name: chapter.name || '',
          description: '',
          links: chapterLinks.map((link, linkIndex) => {
            return {
              name: link.name || '',
              path: link.path || '',
              order: linkIndex + 1,
              description: '',
            };
          }),
        });
      } else {
        const linksToUpdate = chapterLinks.reduce((accum, link, linkIndex) => {
          if (!link.isCreated) {
            return [
              ...accum,
              {
                id: link.id,
                name: link.name,
                path: link.path,
                order: linkIndex + 1,
              },
            ];
          } else {
            return accum;
          }
        }, [] as UpdateBookLink[]);

        const linksToCreate = chapterLinks.reduce((accum, link, linkIndex) => {
          if (link.isCreated) {
            return [
              ...accum,
              {
                name: link.name || '',
                path: link.path || '',
                order: linkIndex + 1,
                description: '',
              },
            ];
          } else {
            return accum;
          }
        }, [] as LinksInput[]);

        bookChaptersForUpdate.push({
          id: chapter.id,
          order: chapterIndex + 1,
          name: chapter.name || '',
          description: '',
          links: linksToUpdate.length > 0 ? linksToUpdate : undefined,
          linksForCreate: linksToCreate.length > 0 ? linksToCreate : undefined,
          linksForDelete: itemIdsToRemove?.removedLinks.find((linkToDelete) => linkToDelete.chapterId === chapter.id)
            ?.linkIds,
        });
      }
    });

    await updateBook({
      variables: {
        id: bookId,
        name: book.name,
        path: book.path || '',
        bookChaptersForDelete: itemIdsToRemove?.removedChapterIds,
        bookChaptersForCreate: bookChaptersForCreate.length > 0 ? bookChaptersForCreate : undefined,
        bookChaptersForUpdate: bookChaptersForUpdate.length > 0 ? bookChaptersForUpdate : undefined,
      },
    });
  };

  const handleRemoveBook = async () => {
    await deleteBook();
  };

  return <BookForm isEditing saveBook={handleUpdateBook} initialState={formState} removeBook={handleRemoveBook} />;
};

export default Book;
