export const EXPERT = 'expert';
export const STUDENT = 'student';
export const ADMIN = 'admin';
export const GUEST = undefined;
export const USERS = [EXPERT, STUDENT];
export const ALL = [EXPERT, STUDENT, GUEST];
export const NOT_STUDENT = [EXPERT, ADMIN];
export const ROWS_PER_PAGE = 7;
export const ITEMS_PER_PAGE = 6;
export const LECTURES_ZERO_BASED_DIGITS = 3;
export const DURATION_ZERO_BASED_DIGITS = 2;
export const SUPPORTED_FORMATS_IMAGE = '.png, .jpg';
export const SUPPORTED_VIDEO_MIME = ['video'];
export const RECOMMENDED_VIDEO_FORMATS = '.MP4, .mp4, .MOV, .mov, .mpg, .MPG';
export const SUPPORTED_FORMATS_AUDIO = '.MP3, .mp3, .mp4, .MP4';
export const SUPPORTED_FORMATS_HOMEWORK = '.pdf';
export const SUPPORTED_FORMATS_ARTICLE = '.pdf';
export const PDF_FILE = 'pdf';
export const TEXT_FILE = 'txt';
export const EDITOR_FILE = 'editor';
export const VIDEO_FILE = 'video';
export const AUDIO_FILE = 'audio';
export const QUIZ_ATTACHMENT = 'quiz';
export const IMAGE_FILE = 'img';
export const FAILED_ATTACHMENT = 'failed';
export const COURSE_DESC_LENGTH = 260;
export const COURSE_DESC_PARAGRAPHS_LENGTH = 5;
export const WEBINAR_DESC_LENGTH = 120;
export const DEFAULT_CALENDAR_EVENTS_AMOUNT = 1000;
export const CATALOG_AMOUNT = 1000;
export const APPLE = 'apple';
export const TOMATO = 'tomato';
export const MAPPING_COURSES_LIST_LIMIT = 20;
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_MAX_FILE_SIZE = 50000 * 1024;
export const TOPPING = 'topping';

export const SUBMITTED_PREVIOUS_HOMEWORK = 'submitted_previous_homework';
export const REVIEWED_MARK_MORE_THAN_60 = 'prev_more_level';

export const ACTIVE_PURCHASED_COURSE = 'ACTIVE';
export const DEACTIVATED_PURCHASED_COURSE = 'DEACTIVATED';
export const COMPLETED_PURCHASED_COURSE = 'COMPLETED';

export const LANGUAGES = [
  { value: 'EN', label: 'English', code: 'en', iso: 'en_US' },
  { value: 'RU', label: 'Русский', code: 'ru', iso: 'ru_RU' },
];

export const CURRENCIES = [
  { code: 'usd', sign: '$', isDefault: true },
  { code: 'eur', sign: '€', isDefault: false },
  { code: 'uah', sign: '₴', isDefault: false },
  { code: 'rub', sign: '₽', isDefault: false },
];

export const COURSE_NEXT_STATUSES_EXPERT = {
  DRAFT: 'FIRST_REVIEW',
  MAIN_FILLING: 'FINAL_REVIEW',
  READY_TO_PUBLISHING: 'ACTIVE',
  ACTIVE: 'DEACTIVATED',
  DEACTIVATED: 'REACTIVATED',
};

export const COURSE_NEXT_STATUSES_ADMIN = {
  FIRST_REVIEW: 'MAIN_FILLING',
  FINAL_REVIEW: 'READY_TO_PUBLISHING',
};

export const COURSE_NEXT_REJECTED_STATUSES_ADMIN = {
  FIRST_REVIEW: 'FIRST_REJECT',
  FINAL_REVIEW: 'SECOND_REJECT',
};

export const COURSE_PREV_STATUSES_ADMIN = {
  FIRST_REVIEW: 'DRAFT',
  FINAL_REVIEW: 'MAIN_FILLING',
};

export const COURSE_DURATION_RANGES = [
  { value: 'short', label: '<2h' },
  { value: 'average', label: '2h-5h' },
  { value: 'long', label: '>5h' },
];

export const timeMask = [/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M'];

export const dateSlashedMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const EMAIL_SUPPORT = 'support@skilleton.net';

export const MOBILE_SCREEN_WIDTH = 480;

export const testCoachesIds: string[] = [
  '018765ba-5791-402d-abb7-7fb47e86f991',
  '8d8c4f86-992c-4e0a-a898-53de0c842318',
  '6c08ceda-e1b4-4785-8913-d01f1cb1be4b',
  '64c0c473-dd49-40a7-b2d7-d5489c756b82',
  '01bbce66-2746-43d6-8183-d0128e14212f',
  '5ff49759-7783-4b9a-9a8c-b8e47d4cad23',
  'e8ff0450-b752-4bf3-b863-db91b4424874',
  '5a48c2d1-5c43-46da-877e-7fbca3c78828',
  '0bfb2a8f-d4db-42c6-9d55-b9ffe4a04f02',
  '557be03f-5c4c-44e5-9e94-4c38afcb190b',
  '2f3cbd95-87b8-46e3-a2d6-44932e1f1226'
];
