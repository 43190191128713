import React, { FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Dialog } from '@material-ui/core';
import {
  RolesEnum,
  useUpdateLessonMutation,
  useRescheduleLessonMutation,
  useGetCalendarLimitsQuery,
  GetCalendarLimitsDocument,
} from 'api';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import Button from 'component/ButtonMatireal/ButtonMatireal';
import { useDeleteLessonMutation } from 'api';
import cn from 'classnames';
import './ModalLesson.scss';
import BrowserValidationModal from 'component/BrowserValidationModal/BrowserValidationModal';
import { isValidBrowserVersion } from 'utils/helpers';

const close_modal_cross = '/assets/close_modal_cross.svg';
const delete_trash_icon = '/assets/cancel_lesson_icon.svg';
const repeat_icon = '/assets/repeat_lesson_icon.svg';
const alert_icon = '/assets/alert_icon.svg';

const getAvatar = (files) => {
  if (files && Array.isArray(files) && files.length > 0) {
    return files.find((file) => file?.type === 'avatar')?.path;
  }
  return '';
};

enum ModalStep {
  Cancel,
  Default,
  Reschedule,
}

enum DateType {
  Date,
  Start,
  End,
}

export type ModalLessonProps = {
  isOpen: boolean;
  handleCloseModal: () => void;
  lesson: any;
  role: RolesEnum;
  updateLessonList: () => Promise<void>;
  lessons: any;
  user: any;
};

export const ModalLesson: FC<ModalLessonProps> = ({
  isOpen,
  handleCloseModal,
  lesson,
  role,
  updateLessonList,
  lessons,
  user,
}) => {
  const calendarLimits = useGetCalendarLimitsQuery({ variables: { lessonId: lesson.id } }).data?.getCalendarLimits;
  const [browserModalOpen, setBrowserModalOpen] = useState(false);
  const [modalStep, setModalStep] = useState<ModalStep>(ModalStep.Default);
  const [openedDatePicker, setOpenedDatePicker] = useState<DateType | undefined>();
  const [dateError, setDateError] = useState('');
  const [cancelationError, setCancelationError] = useState('');
  const [deleteLesson] = useDeleteLessonMutation({
    variables: { id: lesson.id },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetCalendarLimitsDocument,
        variables: { lessonId: lesson.id },
      },
    ],
  });
  const [updateLesson] = useUpdateLessonMutation();
  const [rescheduleLesson] = useRescheduleLessonMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetCalendarLimitsDocument,
        variables: { lessonId: lesson.id },
      },
    ],
  });
  const [rescheduleError, setRescheduleError] = useState('');
  const [lessonDate, setLessonDate] = useState({
    date: moment(+lesson.startAt),
    start: moment(+lesson.startAt),
    end: moment(+lesson.finishAt),
  });
  const history = useHistory();
  const coachEarnMoney = ((lesson?.costs / 100) * 0.8).toFixed(2);
  // const studentEarnMoney = (lesson?.costs / 100).toFixed(2);
  const person = useMemo(() => {
    switch (role) {
      case RolesEnum.Adult: {
        return {
          ...lesson?.coachProfile,
          avatar: getAvatar(lesson?.coachProfile?.files),
        };
      }
      case RolesEnum.Child: {
        return {
          ...lesson?.coachProfile,
          avatar: getAvatar(lesson?.coachProfile?.files),
        };
      }
      case RolesEnum.Coach: {
        return {
          ...lesson?.studentProfile,
          avatar: getAvatar(lesson?.studentProfile?.files),
        };
      }
      default: {
        return null;
      }
    }
  }, [lesson, role]);

  const lessonTime = useMemo(() => {
    return `${moment(+lesson.startAt).format('dddd, MMMM DD - hh:mm a')} - ${moment(+lesson.finishAt).format(
      'hh:mm a',
    )}`;
  }, [lesson]);

  const handleGoToPath = useCallback(
    (path: string) => () => {
      history.push(path);
    },
    [history],
  );

  const handleChangeStep = (step: ModalStep) => () => setModalStep(step);

  const handleJoinLesson = useCallback(() => {
    if (isValidBrowserVersion()) {
      history.push('/class-room', {
        coachId: lesson?.coachProfile?.id,
        lessonId: lesson?.id,
        lessons: lessons.slice().sort((a, b) => +b?.startAt - +a?.startAt),
        pathReturn: history.location.pathname,
        user: user,
      });
    } else {
      setBrowserModalOpen(true);
    }
  }, [history, lesson]);

  const handleDeleteLesson = async () => {
    try {
      const isDeleted = await deleteLesson();
      if (isDeleted) {
        handleCloseModal();
        updateLessonList();
      }
    } catch (e: any) {
      console.error(e);
      setCancelationError(e.message);
      switch (e.message) {
        case 'NOT_ALLOWED_TIME':
          setCancelationError('Invalid reschedule time, please, try another timeslot.');
          break;
        case 'LESS_THAN_48_HOURS_TO_START':
          setCancelationError('Can not cancel 48 hours before lesson.');
          break;
        case 'NOT_ALLOWED_STATUS':
          setCancelationError('Error, lesson has been already finished.');
          break;
        case 'NOT_EXIST':
          setCancelationError('Sorry, lesson does not exist.');
          break;
        case 'EXCEEDED_CANCELLATIONS_LIMIT':
          setCancelationError('More cancellations will be added to your account next month!');
          break;
        default:
          setCancelationError('Error occured, please, try later.');
          break;
      }
    }
  };

  const openDatePicker = useCallback(
    (dateType: DateType) => () => {
      setOpenedDatePicker(dateType);
    },
    [],
  );

  const closeDatePicker = useCallback(() => {
    setOpenedDatePicker(undefined);
  }, []);

  const handleChooseDate = useCallback((date) => {
    setDateError('');
    setLessonDate((prev) => ({ ...prev, date }));
  }, []);

  const handleChooseStart = useCallback(
    (startTime) => {
      setDateError('');
      const minutesOffset = moment(+lesson.startAt).diff(moment(+lesson.finishAt), 'hour') < 0 ? 60 : 30;
      setLessonDate((prev) => ({
        ...prev,
        start: startTime,
        end: moment(startTime).add(minutesOffset, 'minutes'),
      }));
    },
    [lesson],
  );

  const handleRescheduleLesson = useCallback(async () => {
    setRescheduleError('');
    const updatedDate = moment(lessonDate.date);
    const updatedYear = updatedDate.year();
    const updatedDays = updatedDate.dayOfYear();

    try {
      await rescheduleLesson({
        variables: {
          id: lesson.id,
          startAt: moment(lessonDate.start)
            .dayOfYear(updatedDays)
            .year(updatedYear)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          finishAt: moment(lessonDate.end)
            .dayOfYear(updatedDays)
            .year(updatedYear)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        },
      });
      handleCloseModal();
      updateLessonList();
    } catch (e: any) {
      switch (e.message) {
        case 'NOT_AVAILABLE_TIME':
          setRescheduleError('Invalid reschedule time, please, try another timeslot.');
          break;
        case 'LESS_THAN_48_HOURS_TO_START':
          setRescheduleError('Can not reschedule 48 hours before lesson.');
          break;
        case 'NOT_ALLOWED_STATUS':
          setRescheduleError('Error, lesson has been already finished.');
          break;
        case 'CAN_NOT_SCHEDULE_TO_PAST_TIME':
          setRescheduleError('Invalid reschedule time, please, try another timeslot.');
          break;
        case 'EXCEEDED_RESCHEDULE_LIMIT':
          setRescheduleError('More reschedules will be added to your account next month!');
          break;
        default:
          setRescheduleError('Error occured, please, try another timeslot');
          break;
      }
      console.error(e);
    }
  }, [lessonDate, updateLesson, lesson.id, updateLessonList, handleCloseModal]);

  return (
    <Dialog
      className={cn({ 'reschedule-lesson': modalStep === ModalStep.Reschedule })}
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="lesson-modal"
    >
      {modalStep === ModalStep.Default && (
        <div className="lesson-modal">
          <div className="lesson-modal__header">
            <span>{`Lesson ${lesson?.lessonNumber}`}</span>
            <div className="lesson-modal__header-btns">
              {role === RolesEnum.Adult &&
                moment()
                  .add(48, 'hours')
                  .isBefore(moment(+lesson?.startAt)) && (
                  <div className="modal_icon_wrapper">
                    <div className="modal_icon_text">Cancel lesson</div>
                    <div>
                      <img
                        src={delete_trash_icon}
                        alt="delete_modal_icon"
                        onClick={handleChangeStep(ModalStep.Cancel)}
                      />
                    </div>
                  </div>
                )}
              {role === RolesEnum.Adult && (
                <div className="modal_icon_wrapper">
                  <div className="modal_icon_text">Reschedule lesson</div>
                  <div>
                    <img src={repeat_icon} alt="repeat_modal_icon" onClick={handleChangeStep(ModalStep.Reschedule)} />
                  </div>
                </div>
              )}
              {role === RolesEnum.Coach &&
                moment()
                  .add(5, 'minutes')
                  .isBefore(moment(+lesson?.startAt)) && (
                  <div className="modal_icon_wrapper">
                    <div className="modal_icon_text">Cancel lesson</div>
                    <div>
                      <img
                        src={delete_trash_icon}
                        alt="delete_modal_icon"
                        onClick={handleChangeStep(ModalStep.Cancel)}
                      />
                    </div>
                  </div>
                )}
              <img src={close_modal_cross} alt="close_modal_icon" className="close-icon" onClick={handleCloseModal} />
            </div>
          </div>
          {lesson?.topic && (
            <div className="lesson-modal__title">
              <span>{lesson.topic}</span>
            </div>
          )}
          <div className="lesson-modal__date">
            <span>{lessonTime}</span>
          </div>
          {person && (
            <div className="lesson-modal__person">
              <UserAvatar avatar={person?.avatar} firstName={person?.firstName} lastName={person?.lastName} />
              <span className="lesson-modal__name">{`${person?.firstName} ${person?.lastName}`}</span>
            </div>
          )}
          {role === RolesEnum.Adult && lesson?.studentProfile?.__typename !== 'ParentProfile' && (
            <div className="lesson-modal__person">
              <UserAvatar
                avatar={getAvatar(lesson?.studentProfile?.files)}
                firstName={lesson?.studentProfile?.firstName}
                lastName={lesson?.studentProfile?.lastName}
              />
              <span className="lesson-modal__name">
                {`${lesson?.studentProfile?.firstName} ${lesson?.studentProfile?.lastName}`}
              </span>
            </div>
          )}
          <div className="lesson-modal__btns">
            <Button onClick={handleGoToPath('/lessons')}>Go to lessons</Button>
            {moment(+lesson?.finishAt!)
              .add(7, 'days')
              .isAfter(moment()) && (
              <Button onClick={handleJoinLesson} contained>
                Join lesson
              </Button>
            )}
          </div>
        </div>
      )}
      {modalStep === ModalStep.Cancel && (
        <div className="cancel-lesson-modal">
          <img src={alert_icon} alt="alert_icon" />
          <span>Cancelations left: {calendarLimits?.cancellations}</span>
          <div className="cancel-lesson-modal__title">
            <span>Confirm cancelation.</span>
          </div>
          <div className="cancel-lesson-modal__subtitle">
            {role === RolesEnum.Coach && (
              <span>{`If you keep this lesson and do not cancel, you will earn $${coachEarnMoney}`}</span>
            )}
            {role === RolesEnum.Adult && <span>{`Are you sure you want to cancel this lesson?`}</span>}
          </div>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="cancellation-policy"
            href="https://bestmusiccoach.freshdesk.com/support/solutions/articles/69000362523-cancellation-policy"
          >
            Cancellation Policy
          </a>
          <div className="cancel-lesson-modal__btns">
            <Button onClick={handleChangeStep(ModalStep.Default)}>No</Button>
            <Button onClick={handleDeleteLesson} contained>
              Yes, continue
            </Button>
          </div>
          <span className="lesson-modal__error">{cancelationError}</span>
        </div>
      )}
      {modalStep === ModalStep.Reschedule && (
        <div className="lesson-modal">
          <div className="lesson-modal__header">
            <span>{`Lesson ${lesson?.lessonNumber}`}</span>
            <span>Reschedules left: {calendarLimits?.reschedules}</span>
            <div className="lesson-modal__header-btns">
              <img src={close_modal_cross} alt="close_modal_icon" className="close-icon" onClick={handleCloseModal} />
            </div>
          </div>
          {lesson?.topic && (
            <div className="lesson-modal__title">
              <span>{lesson.topic}</span>
            </div>
          )}
          <div className="lesson-modal__date-pick">
            <KeyboardDatePicker
              className="lesson-modal__date-pick-date"
              error={!!dateError}
              autoOk
              open={openedDatePicker === DateType.Date}
              variant="inline"
              inputVariant="outlined"
              id="Date"
              label="Date"
              format="MMM DD,yyyy"
              value={lessonDate.date}
              InputAdornmentProps={{ position: 'start' }}
              onChange={handleChooseDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{ onClick: openDatePicker(DateType.Date), disabled: true }}
              onClose={closeDatePicker}
            />
            <KeyboardTimePicker
              className="lesson-modal__date-pick-start"
              error={!!dateError}
              autoOk
              open={openedDatePicker === DateType.Start}
              variant="inline"
              inputVariant="outlined"
              id="Start"
              label="Start time"
              format="LT"
              value={lessonDate.start}
              InputAdornmentProps={{ position: 'start' }}
              onChange={handleChooseStart}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minutesStep={30}
              InputProps={{ onClick: openDatePicker(DateType.Start), disabled: true }}
              onClose={closeDatePicker}
            />
            <KeyboardTimePicker
              disabled
              className="lesson-modal__date-pick-end"
              error={!!dateError}
              autoOk
              open={openedDatePicker === DateType.End}
              variant="inline"
              inputVariant="outlined"
              id="End"
              label="End time"
              format="LT"
              value={lessonDate.end}
              InputAdornmentProps={{ position: 'start' }}
              onChange={handleChooseStart}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minutesStep={30}
              onClose={closeDatePicker}
            />
          </div>
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
            className="lesson-modal__btns"
          >
            {!!rescheduleError && (
              <div className="lesson-modal__error">
                <span>{rescheduleError}</span>
              </div>
            )}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="reschedule-policy"
              href="https://bestmusiccoach.freshdesk.com/support/solutions/articles/69000362507-rescheduling-policy"
            >
              Rescheduling Policy
            </a>
            <Button onClick={handleRescheduleLesson} contained>
              Reschedule
            </Button>
          </div>
        </div>
      )}
      <BrowserValidationModal isModalOpen={browserModalOpen} setIsModalOpen={setBrowserModalOpen} />
    </Dialog>
  );
};
