// Core
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { isNull } from '../../utils/helpers';
import './UserAvatarUpload.scss';

interface LetterAvatarProps {
  firstName: string;
  lastName: string;
}

const LetterAvatar = ({ firstName, lastName }: LetterAvatarProps) => {
  if (firstName && lastName) {
    return `${firstName[0]?.toUpperCase() || ''}${lastName[0]?.toUpperCase() || ''}`;
  } else {
    return '';
  }
};

const UserAvatarUpload = ({ imageUrl, firstName, lastName, progress, withProgress }) => {
  return (
    <div className="mui-override sk-avatar-upload-user sk-avatar-upload-user_empty">
      {imageUrl ? (
        <>
          <img
            src={imageUrl}
            style={isNull(progress) || progress === 100 ? { opacity: 1 } : {}}
            alt={`${firstName}-avatar`}
            draggable="false"
          />
          {withProgress && progress > 0 && progress < 100 && (
            <div className="sk-user-profile-photo__photo__loader"></div>
          )}
        </>
      ) : (
        <Avatar style={{ opacity: 1 }} className={'mui-override uploder-profile-avatar-user'}>
          {LetterAvatar({ lastName, firstName })}
        </Avatar>
      )}
    </div>
  );
};

export default UserAvatarUpload;
