import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import cn from 'classnames';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import useWindowDimensions from 'utils/hooks/useWindowDimentions';
import Rating from './Rating';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import Levels from 'component/Levels/Levels';
import { CoachProfile, Instruments, RolesEnum } from 'api/index';
import { getAvatar } from 'utils/image';
import { getInstrumentName, formatCamelCaseString, convertUrlToEmbedYoutube } from 'utils/helpers';
import './CourseCard.scss';

const emptyVideo = '/assets/empty_video.svg';

const Type = ({ type = 'music', title = 'Music theory', instrument }) => (
  <div className="courseCardType">
    <img
      src={getInstrumentName(instrument)}
      alt={`${getInstrumentName(instrument)}'s_icon`}
      className="vocalLessonIcon"
    />
    <Typography className="courseCardTypeTitle" variant="caption" display="block" gutterBottom={true}>
      {formatCamelCaseString(instrument)}
    </Typography>
  </div>
);

interface HeaderProps {
  coach: CoachProfile;
  width: number;
}

const Header = ({ width, coach }: HeaderProps) => {
  const coachRating = useMemo(() => {
    return Math.round(coach?.rating || 0);
  }, [coach]);
  return (
    <header className="courseCardHeader">
      <div className="courseCardHeaderInfo">
        <Typography gutterBottom={true} variant="h5" component="h2">
          {`${coach.firstName} ${coach.lastName}`}
        </Typography>
        <Rating value={coachRating} row={true} views={coach?.feedbacksCount!} />
      </div>
      <Typography className="courseCardHeaderPrice" variant="body2" color="textSecondary" component="p">
        {coach.subscriptionPerHalfHour ? `From $${coach.subscriptionPerHalfHour / 100} / 30 min` : ''}
      </Typography>
    </header>
  );
};

interface FooterProps {
  coach: CoachProfile;
  listInstruments: Instruments[] | any;
  role: any;
  openPopup: () => void;
}

const Footer = ({ coach, listInstruments, role, openPopup }: FooterProps) => {
  const history = useHistory();

  const handlePushToCoach = useCallback(
    (id) => () => {
      history.push(`/coach/${id}`);
    },
    [history],
  );

  const handlePushToCalendar = useCallback(
    (id) => {
      if (!role) {
        openPopup();
        sessionStorage.setItem('coachId', id);
        return;
      }
      history.push(`/calendar`, { id });
    },
    [history, role, openPopup],
  );

  return (
    coach && (
      <footer className="courseCardFooter">
        <div className="courseCardMetadata">
          <div className="courseCardMetadataLevel"></div>
          <div className="courseCardTypesWrapper">
            {coach.instruments?.map((item, index) => {
              return (
                <div>
                  <Type instrument={item?.name} key={`${item?.name || ''}` + index} />
                  <Levels level={[item?.level] || []} />
                </div>
              );
            })}
          </div>
        </div>
        <CardActions
          className={cn('courseCardActions', {
            endJustify: role === RolesEnum.Child,
          })}
        >
          <Button size="small" variant="outlined" onClick={handlePushToCoach(coach.id)}>
            View coach
          </Button>
          {role !== RolesEnum.Child && (
            <Button size="small" variant="contained" onClick={() => handlePushToCalendar(coach.id)}>
              Book
            </Button>
          )}
        </CardActions>
      </footer>
    )
  );
};

interface MediaCardProps {
  coach: CoachProfile;
  listInstruments: Instruments[] | any;
  user: any;
  openPopup: () => void;
}

export default function MediaCard({ coach, user, listInstruments, openPopup }: MediaCardProps) {
  const { width } = useWindowDimensions();
  return (
    <Card className="courseCardContainer">
      <div className="courseCardWrapper">
        {width > 770 && (
          <UserAvatar
            classes="courseCardMediaAvatar"
            avatar={getAvatar(coach?.files || []) || ''}
            firstName={coach.firstName}
            lastName={coach.lastName}
          />
        )}
        <CardContent className="courseCardContent">
          {width < 770 ? (
            <div className="courseCardContentHeader">
              {
                <UserAvatar
                  avatar={getAvatar(coach?.files || []) || ''}
                  firstName={coach.firstName}
                  lastName={coach.lastName}
                  classes="courseCardMediaAvatar"
                />
              }
              <Header width={width} coach={coach} />
            </div>
          ) : (
            <Header width={width} coach={coach} />
          )}
          <Divider />
          <Footer coach={coach} role={user?.role} listInstruments={listInstruments} openPopup={openPopup} />
        </CardContent>
        {width > 910 ? (
          coach.videoUrl ? (
            <iframe
              title="youtube"
              className="video_frame"
              width={(global.window ? (global.window as any).innerWidth : 0) < 911 ? '300' : '320'}
              height={(global.window ? (global.window as any).innerWidth : 0) < 911 ? '140' : '180'}
              src={convertUrlToEmbedYoutube(coach.videoUrl)}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
          ) : (
            <img src={emptyVideo} className="courseCardEmptyImageVideo" alt="courseCardEmptyImageVideo" />
          )
        ) : (
          <div />
        )}
      </div>
    </Card>
  );
}
