import React from 'react';
import Loader from '../../../../component/Loader/Loader';

export default function CustomLoader() {
  const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  };

  return (
    <div style={styles}>
      <Loader />
    </div>
  );
}
