import React from 'react';
import BookForm from '../BookForm/BookForm';
import { FormState } from '../BookForm/hooks';
import { useAdminCreateBookMutation } from 'api';

const NewBook = () => {
  const [createBook] = useAdminCreateBookMutation();
  const handleSaveBook = async ({ book, chapters, links }: FormState) => {
    await createBook({
      variables: {
        name: book.name || '',
        author: '',
        description: '',
        path: book.path || '',
        chapters: chapters.map((chapter, chapterIndex) => {
          const chapterLinks = links.filter((link) => link.chapterId === chapter.id);
          return {
            order: chapterIndex + 1,
            name: chapter.name || '',
            description: '',
            links: chapterLinks.map((link, linkIndex) => ({
              order: linkIndex + 1,
              name: link.name || '',
              description: '',
              path: link.path || '',
            })),
          };
        }),
      },
    });
  };

  return <BookForm saveBook={handleSaveBook} />;
};

export default NewBook;
