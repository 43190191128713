import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../modules/routes';
import CoachesList from './CoachesList';
import CoachProfile from './CoachProfile';

export default function Component() {
  return (
    <>
      <Switch>
        <Route exact key={routes.adminCoaches} path={routes.adminCoaches}>
          <CoachesList />
        </Route>
        <Route exact key={routes.adminCoach} path={routes.adminCoach}>
          <CoachProfile />
        </Route>
      </Switch>
    </>
  );
}
