import React from 'react';

const ChildIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9455 12.5867C21.9818 12.4 22 12.2044 22 12C22 11.7956 21.9818 11.6 21.9455 11.4133C21.7182 10.0711 20.7091 8.97778 19.3909 8.59556C18.9091 7.6 18.2273 6.72889 17.4 6.00889C15.9636 4.75556 14.0727 4 12 4C9.92727 4 8.03636 4.75556 6.6 6.00889C5.76364 6.72889 5.08182 7.60889 4.60909 8.59556C3.29091 8.97778 2.28182 10.0622 2.05455 11.4133C2.01818 11.6 2 11.7956 2 12C2 12.2044 2.01818 12.4 2.05455 12.5867C2.28182 13.9289 3.29091 15.0222 4.60909 15.4044C5.08182 16.3911 5.76364 17.2622 6.58182 17.9733C8.01818 19.2356 9.91818 20 12 20C14.0818 20 15.9818 19.2356 17.4273 17.9733C18.2455 17.2622 18.9273 16.3822 19.4 15.4044C20.7091 15.0222 21.7182 13.9378 21.9455 12.5867Z"
        fill="#605F5E"
        {...props}
      />
      <path
        d="M15.25 11.5C15.9404 11.5 16.5 10.9404 16.5 10.25C16.5 9.55964 15.9404 9 15.25 9C14.5596 9 14 9.55964 14 10.25C14 10.9404 14.5596 11.5 15.25 11.5Z"
        fill="white"
      />
      <path
        d="M8.25 11.5C8.94036 11.5 9.5 10.9404 9.5 10.25C9.5 9.55964 8.94036 9 8.25 9C7.55964 9 7 9.55964 7 10.25C7 10.9404 7.55964 11.5 8.25 11.5Z"
        fill="white"
      />
      <path d="M7 14C7.84444 15.77 9.76667 17 12 17C14.2333 17 16.1556 15.77 17 14H7Z" fill="white" />
    </svg>
  );
};
export default ChildIcon;
