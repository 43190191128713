import React, { useEffect, useState, FormEvent, FC } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';
import * as qs from 'query-string';
import TextField from '@material-ui/core/TextField';
import Button from '../../component/ButtonMatireal/ButtonMatireal';
import './SignUpChild.scss';
import { Profile } from 'api';

const mainImage = '/assets/sign_up_image.png';

type InputTypes = 'text' | 'password';

type QueryParams = {
  email: string;
  password: string;
};

type Props = {
  user?: Profile;
  signOut: any;
};

const SignUpChild: FC<Props> = ({ user, signOut }) => {
  const [type, setType] = useState<InputTypes>('password');
  const [error, setError] = useState<string>('');
  const { location, push } = useHistory();

  const { email = '', password = '' } = qs.parse(location.search) as QueryParams;

  const handleSignUp = async (event: FormEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setError('');
    const normalizedEmail = email.replace(' ', '+').toLowerCase();
    try {
      await Auth.signIn(normalizedEmail, password);
      push('/child-password-setting', {
        email: normalizedEmail,
        password: password,
      });
    } catch (error: any) {
      console.log('error', error);
      setError(error?.message || 'Something went wrong');
    }
  };

  const toggleType = () => {
    setType((old) => (old === 'password' ? 'text' : 'password'));
  };

  useEffect(() => {
    if (user?.id) {
      signOut(() => {}, false);
    }
  });

  return (
    <div className="signup-child">
      <div className="signup-child_left">
        <p className="signup-child_left_text">
          Sign up <br /> for Best Music Coach!
        </p>
        <img src={mainImage} alt="signup_image" className="signup-child_left_image" />
      </div>
      <form className="signup-child_form">
        <TextField
          className="signup-child_form_input"
          type={type}
          variant="outlined"
          label="Code"
          value={password}
          disabled={true}
          required={true}
          fullWidth={true}
          error={!!error}
          helperText={error}
          InputProps={{
            endAdornment:
              type === 'password' ? (
                <VisibilityIcon className="signup-child_form_icon" onClick={toggleType} />
              ) : (
                <VisibilityOffIcon className="signup-child_form_icon" onClick={toggleType} />
              ),
          }}
        />
        <div className="signup-child_form_action">
          <Link to="/terms-and-conditions" className="signup-child_form_action_text">
            By creating an account, you agree to our <span>Terms Conditions and Privacy Policy</span>.
          </Link>
          <Button onClick={handleSignUp} contained={true} className="signup-child_form_action_button">
            Sign Up
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignUpChild;
