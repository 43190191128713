import React, { FC } from 'react';
import Divider from '@material-ui/core/Divider';
import { Profile, useListLessonsQuery, Lessons, LessonSortByEnum, SortOrderEnum } from 'api';
import CoachBalanceCard from 'component/CoachBalanceCard/CoachBalanceCard';
import Loader from 'component/Loader/Loader';
import './CoachBalance.scss';

type Props = {
  user: Profile;
};

const CoachBalance: FC<Props> = ({ user }) => {
  const { data, loading } = useListLessonsQuery({
    variables: {
      coachId: user.id,
      limit: 2000,
      query: { sortBy: LessonSortByEnum.CreatedAt, order: SortOrderEnum.Asc },
      userId: user?.id,
      since: '',
      until: '',
    },
    fetchPolicy: 'network-only',
  });
  const lessons = data?.listLessons?.data || [];

  return (
    <div className="coach-balance">
      <div className="coach-balance-content">
        <h4 className="coach-balance-content_title">Paid lessons</h4>
        {loading ? <Loader /> : lessons.map((lesson) => <CoachBalanceCard key={lesson?.id} data={lesson as Lessons} />)}
      </div>
      <div className="coach-balance-widget">
        <h6 className="coach-balance-widget_amount">$ {(user?.balance?.amount || 0) / 100}</h6>
        <p className="coach-balance-widget_title">Balance</p>
        <Divider className="coach-balance-widget_divider" />
        <table className="coach-balance-widget-table">
          <thead>
            <th />
            <th>30 min</th>
            <th>60 min</th>
          </thead>
          <tbody>
            <tr className="coach-balance-widget-table_row">
              <td>Lesson</td>
              <td>$ {user.costsPerHalfHour! / 100}</td>
              <td>$ {user.costsPerHour! / 100}</td>
            </tr>
            <tr className="coach-balance-widget-table_row">
              <td>Subscription</td>
              <td>$ {user.subscriptionPerHalfHour! / 100}</td>
              <td>$ {user.subscriptionPerHour! / 100}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CoachBalance;
