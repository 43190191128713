import React, { FC, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { ReactSVG } from 'react-svg';
import UserAvatar from 'component/UserAvatar/UserAvatar';
import { ChildrenProfile, Instruments, Maybe, useResendInviteMutation } from 'api';
import { getAvatar } from 'utils/image';
import { getInstrumentName } from 'utils/helpers';
import './ChildCard.scss';

type Props = {
  child: ChildrenProfile;
};

const ChildCard: FC<Props> = ({ child }) => {
  const { push } = useHistory();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

  const [resendInvite, { data }] = useResendInviteMutation({ variables: { childId: child.id } });

  const handleOpenProfile = () => {
    push(`/edit-child-profile/${child.id}`);
  };

  const handleResendInvite = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    resendInvite();
  };

  if (isSmallScreen) {
    return (
      <li className="child-card-mobile" onClick={handleOpenProfile}>
        <div className="child-card-mobile_left">
          <UserAvatar firstName={child.firstName} lastName={child.lastName} avatar={getAvatar(child.files ?? [])} />
          <div>
            <p className="child-card_name">{`${child.firstName} ${child.lastName}`}</p>
            {/* <div className="child-card_level">
              {child.level?.map((l: Maybe<Level>) => (
                <p key={l} className="child-card_text">
                  {capitalize(l?.toLowerCase())}
                </p>
              ))}
            </div> */}
            <ul className="child-card_instruments">
              {child?.instruments?.map((i: Maybe<Instruments>) => (
                <img
                  key={i?.id}
                  className="child-card_instruments_item"
                  src={getInstrumentName(i?.name ?? '')}
                  alt={`${i?.name}_icon`}
                />
              ))}
            </ul>
          </div>
        </div>
        <div className="child-card-mobile_right">
          {!child?.acceptInvitationAt && (
            <div className="child-card_status">
              <span className="child-card_status_text">Not verified</span>
              <ReactSVG
                onClick={handleResendInvite}
                src="/assets/resend_icon.svg"
                alt="resend_icon"
                className={cn('child-card_status_img', {
                  sent_invite: !!data?.resendInvite,
                })}
              />
            </div>
          )}
        </div>
      </li>
    );
  }

  return (
    <li className="child-card" onClick={handleOpenProfile}>
      <UserAvatar firstName={child.firstName} lastName={child.lastName} avatar={getAvatar(child.files ?? [])} />
      <div className="child-card_name">
        <span className="child-card_text">{`${child.firstName} ${child.lastName}`}</span>
      </div>
      {/* <div className="child-card_level">
        {child.level?.map((l: Maybe<Level>) => (
          <p key={l} className="child-card_text">
            {capitalize(l?.toLowerCase())}
          </p>
        ))}
      </div> */}
      <div className="child-card_right">
        <ul className="child-card_instruments">
          {child?.instruments?.map((i: Maybe<Instruments>, idx: number) => {
            if (idx > 3) {
              return;
            }
            return (
              <img
                key={i?.id}
                className="child-card_instruments_item"
                src={getInstrumentName(i?.name ?? '')}
                alt={`${i?.name}_icon`}
              />
            );
          })}
        </ul>
        {!child?.acceptInvitationAt && (
          <div className="child-card_status">
            <span className="child-card_status_text">Not verified</span>
            <ReactSVG
              onClick={handleResendInvite}
              src="/assets/resend_icon.svg"
              alt="resend_icon"
              className={cn('child-card_status_img', {
                sent_invite: !!data?.resendInvite,
              })}
            />
          </div>
        )}
      </div>
    </li>
  );
};

export default ChildCard;
